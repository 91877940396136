/* eslint-disable camelcase */

import React from "react";
import { connect } from "react-redux";
import { ITEM_CONFIG } from "../../constants/config";
import { isImageOnly, isVideoOnly } from "../timeline/timeline-helper";
import {
  ProgressiveWorkspaceImage,
  Vignette,
  WorkspaceVideo,
} from "../workspace/workspace-components";
import {
  BASE_IMAGE_DETAILS,
  FRAME_CLASSES,
  getFrameClipFilterId,
  getFrameClipFilterSvgId,
  getFrameClipVignetteId,
  getFrameInnerImageId,
} from "./frame-helper";
import FilterItem from "../workspace/workspace-filter";

function InnerFrameImageComponent(props) {
  const {
    // from parent
    Id,
    clip_id,
    showImage,
    isFraming,
    containerStyles,
    droppableItemData,
    src,
    thumb,
    fallbackSrc,
    playhead,
    frameEnterStart,
    frameExitEnd,
    isImageHovered,
  } = props;

  const {
    // from redux
    browserName,
  } = props;

  let innerItem = null;

  if (showImage && !isFraming) {
    const filterData = droppableItemData.getIn(["imgDetails", "filter"])
      ? droppableItemData.getIn(["imgDetails", "filter"])
      : ITEM_CONFIG.IMAGE.DEFAULT_FILTER;
    const vignetteValue = parseInt(filterData.substr(12, 2), 16);
    const vignetteId = getFrameClipVignetteId(Id, clip_id);
    const filterId = getFrameClipFilterId(Id, clip_id, filterData);
    const filterSvgId = getFrameClipFilterSvgId(Id, clip_id);
    const type = droppableItemData.getIn(["imgDetails", "type"]);
    const subType = droppableItemData.getIn(["imgDetails", "subType"]);

    const filter = (
      <>
        <Vignette
          id={vignetteId}
          className="vignette"
          style={{
            "--vignette": vignetteValue * 0.007,
          }}
        />
        <FilterItem
          svgId={filterSvgId}
          filterId={filterId}
          browserName={browserName}
          filterCode={filterData}
        />
      </>
    );

    let image = null;
    if (isImageHovered || isImageOnly(type, subType) || droppableItemData.getIn(["imgDetails", "id"]) === BASE_IMAGE_DETAILS.get("id")) {
      image = (
        <ProgressiveWorkspaceImage
          id={getFrameInnerImageId(Id, clip_id)}
          className={`img-item ${FRAME_CLASSES.INNER_IMAGE}`}
          style={{
            filter: `url(#${filterId})`,
          }}
          src={src}
          loaderSrc={thumb}
          chromaKey={!isImageHovered && droppableItemData.getIn(["imgDetails", "chromaKey"])}
          tolerance={!isImageHovered && droppableItemData.getIn(["imgDetails", "tolerance"])}
        />
      );
    } else if (isVideoOnly(type, subType)) {
      image = (
        <WorkspaceVideo
          from="workspace"
          id={getFrameInnerImageId(Id, clip_id)}
          style={{
            filter: `url(#${filterId})`,
          }}
          src={src}
          fallbackSrc={fallbackSrc}
          poster={thumb}
          playStart={frameEnterStart}
          playEnd={frameExitEnd}
          mediaStart={droppableItemData.getIn(["imgDetails", "videoStart"])}
          mediaEnd={droppableItemData.getIn(["imgDetails", "videoEnd"])}
          mediaType={"video"}
          volume={droppableItemData.getIn(["imgDetails", "volume"])}
          playhead={playhead}
          allowCustomCaching={type !== "PIXABAY"}
          speed={droppableItemData.getIn(["imgDetails", "speed"])}
          isBackgroundRemoval={droppableItemData.getIn(["imgDetails", "enableBgRemoval"])}
          chromaKey={droppableItemData.getIn(["imgDetails", "chromaKey"])}
          tolerance={droppableItemData.getIn(["imgDetails", "tolerance"])}
          isAvatar={droppableItemData.getIn(["imgDetails", "isAvatar"]) || droppableItemData.getIn(["imgDetails", "isTransparentAvatar"])}
          isBlob={droppableItemData.getIn(["imgDetails", "isBlob"])}
        />
      );
    }

    innerItem = (
      <div style={containerStyles}>
        {image}
        {filter}
      </div>
    );
  }

  return innerItem;
}

const mapStateToProps = (state) => ({
  browserName: state.app.get("browserName"),
  workspaceWidth: state.app.get("projectWidth"),
  workspaceHeight: state.app.get("projectHeight"),
});

const InnerFrameImage = connect(mapStateToProps)(InnerFrameImageComponent);

export default InnerFrameImage;
