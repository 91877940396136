/* eslint-disable camelcase */
import fetch from 'node-fetch';
import _isEmpty from './isEmpty';
import AppConfig from '../constants/config';

const timeoutDuration = 90000;

/**
 * Common function for http call. Replacement for axios
 * @param {*} requestURL
 * @param {*} method
 * @param {*} isFormData
 * @param {*} body
 * @param {*} onSuccess
 * @param {*} onFailure
 * @param {*} customHeader
 * @param {*} requesHeaders
 * @param {*} isHandledError
 */
export default function triggerSimpleAjax(
    requestURL,
    method = 'POST',
    isFormData = false,
    body = {},
    onSuccess,
    onFailure,
    enableToken = true,
    customHeader = {},
    requesHeaders = null,
    isHandledError = false,
    retryCount = 3,
    retryDelay = 5000
) {
    let headerObject = {};
    if (!isFormData) {
        headerObject = {
            'Content-Type': 'application/json',
        };
    }
    let requestBody = {
        ...body,
        product: 'vmaker',
        source: 'vmakerEditor',
        unixtime: Math.floor(Date.now() / 1000),
        platform: window.navigator.platform,
        plugin: 0,
        isEditor: 1,
        renderAnimo: !!AppConfig.ANIMO_RENDER
    }
    const { pathname } = window.location;
    const match = pathname.match(/\/(editproject|shorts|render|export|publish)\/([a-z0-9]+)\/?$/i);
    const pathSegment = match ? match[1] : null;
    const projectId = match ? match[2] : null;

    if (AppConfig.ENVIRONMENT === "LOCAL") {
        requestBody = {
            ...requestBody,
            // userId: "ugxon47698",
            // teamId: "xfrwpnixmi",
            userId: "ugxon47698",
            teamId: "rdojcjlgzl",
            currentplan: "lite"
        }
    }

    if (customHeader && !_isEmpty(customHeader)) {
        headerObject = {
            ...headerObject,
            ...customHeader,
        };
    }

    // if (tokenId && enableToken) {
    //     headerObject = {
    //         ...headerObject,
    //         'authorization': tokenId
    //     };
    // }

    const headers = headerObject;
    const requestDetails = enableToken ? {
        method,
        mode: 'cors',
        credentials: 'include',
        headers,
    } : {
        method,
        mode: 'cors',
        headers
    };

    if (method !== 'GET' && !isFormData)
        requestDetails.body = JSON.stringify(requestBody);

    if (isFormData) {
        const data = new FormData();
        // eslint-disable-next-line array-callback-return
        Object.keys(requestBody).map((key) => {
            data.append(key, requestBody[key]);
        });
        requestDetails.body = data;
    }

    /**
     * This promise will start the network request and will return the data from the server
     * or throw errors in case the network request fails
     */
    const request = new Promise((resolve, reject) => {

        fetch(requestURL, requesHeaders || requestDetails)
            .then((data) => {
                if (data.status === 200) {
                    return data.json();
                }
                if (data.status === 201) {
                    return data.json();
                }
                if (data.status === 204) {
                    return {
                        statusCode: 204
                    };
                }
                if ((data.status === 511)) {
                    window.location.href = `/logout/?path=${pathSegment}/${projectId}&language=en`;
                }
                if (data.status === 400 && isHandledError) {
                    return data.json().then((result) => {
                        return {
                            ...result,
                            statusCode: 400,
                        };
                    });
                }
                return reject(data);
            })
            .then((result) => {
                resolve(result);
            })
            // eslint-disable-next-line consistent-return
            .catch(async (err) => {
                if (err.message === 'Failed to fetch' && retryCount > 0) {
                    await new Promise((resolve) => {
                        setTimeout(resolve, retryDelay)
                    });
                    return triggerSimpleAjax(
                        requestURL, method, isFormData, body, onSuccess, onFailure, enableToken, customHeader,
                        requesHeaders, isHandledError, retryCount - 1
                    );
                }
                reject(err);
            });
    });

    /**
     * Will execute a reject action after the `timeoutDuration`
     * If it executes this will mark the network request as timed out
     */
    const networkTimeOut = (reject) => {
        return setTimeout(() => {
            const errorObject = Error(
                JSON.stringify({
                    status: `Request timed out!`,
                    url: requestURL,
                }),
            );
            reject(errorObject);
        }, timeoutDuration);
    };

    /**
     * Starts both the timeout and the network request
     * and resolves whichever executes first.
     */
    // eslint-disable-next-line no-new
    new Promise((resolve, reject) => {
        const timeoutId = networkTimeOut(reject);
        request
            .then((result) => {
                clearTimeout(timeoutId);
                onSuccess(result);
                resolve(result);
            })
            .catch((error) => {
                clearTimeout(timeoutId);
                // eslint-disable-next-line no-unused-expressions
                onFailure && onFailure(error);
                reject(error);
            });
    });
}
