import styled, { css } from "styled-components";
import { PLAYER_CONTROLS_HEIGHT } from "../timeline-constants";

const VerticalLineCSS = css`
  position: relative;
  ::after {
    content: " ";
    position: absolute;
    width: 1px;
    height: 12px;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.mercuryColor};
  }
`;

export const PlayerControlsContainer = styled.div`
  width: 100%;
  height: ${PLAYER_CONTROLS_HEIGHT}px;
  padding: 14px 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  transition: all 0.4s;
  .pc--is-disabled {
    pointer-events: none;
  }
  @media (max-width: 768px) {
    ${(props) =>
    props.$isSectionVisible &&
    css`
        zindex: 999;
      `}
  }
`;
PlayerControlsContainer.displayName = "PlayerControlsContainer";

export const PlayerControlSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
PlayerControlSection.displayName = "PlayerControlSection";

export const PlayerControlLeftSection = styled(PlayerControlSection)`
  justify-self: left;
  gap: 17px;
`;
PlayerControlLeftSection.displayName = "PlayerControlLeftSection";

export const MobilePlayerControlLeftSection = styled.div`
  position: fixed;
  bottom: 0px;
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  box-shadow: 0px -4px 32px ${(props) => props.theme.rgbaBlack};
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  padding: 20px;
`;
MobilePlayerControlLeftSection.displayName = "MobilePlayerControlLeftSection";

export const MobilePlayerPopup = styled.div`
  .sm-close-icon {
    position: absolute;
    right: 20px;
    top: 22px;
  }
`;
MobilePlayerPopup.displayName = "MobilePlayerPopup";

export const TimeScaleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 12px;
  ${VerticalLineCSS}
`;
TimeScaleContainer.displayName = "TimeScaleContainer";

export const PlayerHoverBtn = styled.div`
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.3s ease-out;

  &:hover {
    transition: all 0.2s;
    cursor: pointer;
  }
  &.timeline-btn-highlight {
    background-color: ${(props) => props.theme.RULER_EXCESS_COLOR};
  }
  &.timeline-btn-active {
    background-color: ${(props) => props.theme.veryLightGray};
  }
  &:active {
    background-color: ${(props) => props.theme.veryLightGray};
    outline: 0;
  }
  .timeline-btn-selected {
    background-color: ${(props) => props.theme.veryLightGray};
  }
`;
PlayerHoverBtn.displayName = "PlayerHoverBtn";

export const TimeScaleButton = styled(PlayerHoverBtn)`
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  &.pc--scale-reset {
    margin-left: 4px;
  }

  > img {
    width: 20px;
    height: 20px;
  }
`;
TimeScaleButton.displayName = "TimeScaleButton";

export const TimelineExpand = styled(PlayerHoverBtn).attrs((props) => {
  const { $type } = props;
  let className = "";

  if ($type === "expand") {
    className = "pc--is-expand";
  }

  return { className };
})`
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 8px;
  > img {
    width: 20px;
    height: 20px;
  }

  &.pc--is-expand {
    > img {
      transform: rotate(180deg);
    }
  }
`;
TimelineExpand.displayName = "TimelineExpand";

export const PlayerControlTextButton = styled(PlayerHoverBtn)`
  padding: 6.5px 6px;
  display: flex;
  align-items: center;
  gap: 6px;

  img {
    width: 20px;
    height: 20px;
  }

  &:not(:last-child) {
    ${VerticalLineCSS}
    ::after {
      left: calc(100% + 8.5px);
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 15px 0px;
    &:hover {
      background: ${(props) => props.theme.polarColor};
      transform: none;
    }
    span {
      font: normal normal normal 14px/21px Poppins;
      color: ${(props) => props.theme.timelineSpanColor};
      text-overflow: unset;
    }
    img {
      width: 24px;
      height: 24px;
      margin: 0px 10px;
    }
  }
`;
PlayerControlTextButton.displayName = "PlayerControlTextButton";

export const PlayerControlMiddleSection = styled(PlayerControlSection)`
  justify-self: center;
  gap: 6px;

  > .pc--timestamp {
    // width to accomodate timestamp: 000:00:00.0 (to prevent layout shift)
    min-width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-width: 150px;
    }
  }
`;
PlayerControlMiddleSection.displayName = "PlayerControlMiddleSection";

export const PlayerControlRightSection = styled(PlayerControlSection)`
  justify-self: right;
`;
PlayerControlRightSection.displayName = "PlayerControlRightSection";

export const ControlButton = styled(PlayerHoverBtn)`
  img {
    width: 100%;
    height: 100%;
    &:hover {
      transform: scale(1.1);
    }
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 24px;
      height: 24px;
    `}
`;
ControlButton.displayName = "ControlButton";

export const FramesSettingContainer = styled.div``;

export const FramesSettingWrapper = styled.div.attrs((props) => {
  return {
    style: {
      "--flt-white": props.theme.polarColor,
      "--flt-boxshadow": props.theme.rgbaBlack,
      "--flt-border": props.theme.subtitleBtnBorder,
    },
  };
})`
  position: absolute;
  padding: 16px 16px 0px;
  top: -66px;
  right: 23px;
  width: 241px;
  z-index: 1;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    left: calc(100% - 340px);
  }
  @media screen and (max-width: 767px) {
    left: calc(100% - 340px);
  }
`;

export const FramesSettingHeader = styled.div`
  width: 241px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #00000029;
  border: 1px solid #e3e4e8;
  border-radius: 8px;
  opacity: 1;
`;

export const TimelineFps = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.isActive ? "#ECEDF1" : "transparent")};
  border-radius: 6px;
  cursor: pointer;
  margin-left: 8px;
  > img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

TimelineFps.displayName = "TimelineFps";