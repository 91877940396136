import ActionTypes from "../../constants/action-types";
import content from "../../constants/content";
import { getFavouriteLibraryType } from "../../helper/getFavouriteLibraryType";
import { getLibraryType } from "../../helper/getLibraryType";

const { MY_FILES } = content;

const {
  SET_PROPERTY_DATASOURCE,
  SET_TEXT_DATASOURCE,
  SET_MUSIC_DATASOURCE,
  SET_SOUND_EFFECT_DATASOURCE,
  SET_IMAGE_DATASOURCE,
  SET_VIDEO_DATASOURCE,
  SET_GIPHY_DATASOURCE,
  SET_DATASOURCE_BY_CATEGORY,
  RESET_DATASOURCE_BY_CATEGORY,
  SET_FILE_MANAGER,
  SET_MOVE_TO,
  SET_UPLOAD_DATA,
  SET_UPLOAD_PROGRESS,
  SET_FAVOURITE,
  SET_VIRAL_STYLES,
  SET_UNSPLASH_DATASOURCE,
  UPDATE_PANEL_ITEMS,
  SET_PEXELS_VIDEO_DATASOURCE,
  SET_FAVOURITE_DATASOURCE,
  SET_PEXELS_DATASOURCE,
  SET_PIXABAY_DATASOURCE,
  SET_PIXABAY_VIDEO_DATASOURCE,
  SET_AVATAR_DATA,
} = ActionTypes;

export const initialState = {
  dataSource: {
    propertyLibrary: {
      hits: [],
      total: null,
    },
    textLibrary: {
      hits: [],
      total: null,
    },
    musicLibrary: {
      hits: [],
      total: null,
    },
    soundEffects: {
      hits: [],
      total: null,
    },
    imageLibrary: {
      hits: [],
      total: null,
    },
    videoLibrary: {
      hits: [],
      total: null,
    },
    giphyLibrary: {
      hits: [],
      total: null,
    },
    filteredData: {
      hits: [],
      type: "",
      total: null,
      isLoading: true,
    },
    uploadLibrary: {
      currentFolder: 0,
      previousFolder: null,
      folderId: [{ id: 0, name: MY_FILES }],
    },
    uploadData: {
      folderData: [],
      fileData: [],
      hits: [],
      total: null,
      isLoading: true,
    },
    moveToLibrary: {
      currentFolder: 0,
      previousFolder: null,
    },
    uploadProgress: {
      progress: null,
      isUploadInProgress: false,
      multiItems: 0
    },
    viralStyles: null,
    unsplashLibrary: {
      hits: [],
      total: null,
    },
    pexelsLibrary: {
      hits: [],
      total: null,
    },
    pexelsVideoLibrary: {
      hits: [],
      total: null,
    },
    pixabayLibrary: {
      hits: [],
      total: null,
    },
    pixabayVideoLibrary: {
      hits: [],
      total: null,
    },
    favouritesLibrary: {
      hits: null,
      total: null,
    },
    avatar: {
      actors: [],
      languages: [],
      voices: [],
      total: null
    },
  },
  panelItems: [],
};

export default function libraryReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PROPERTY_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,

          propertyLibrary: {
            hits: [
              ...state.dataSource.propertyLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_TEXT_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,

          textLibrary: {
            hits: [
              ...state.dataSource.textLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_MUSIC_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,

          musicLibrary: {
            hits: [
              ...state.dataSource.musicLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_SOUND_EFFECT_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,

          soundEffects: {
            hits: [
              ...state.dataSource.soundEffects.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_IMAGE_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,

          imageLibrary: {
            hits: [
              ...state.dataSource.imageLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_UNSPLASH_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          unsplashLibrary: {
            hits: [
              ...state.dataSource.unsplashLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_PEXELS_VIDEO_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          pexelsVideoLibrary: {
            hits: [
              ...state.dataSource.pexelsVideoLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_FAVOURITE_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          favouritesLibrary: {
            hits: (payload.hits || []),
            total: payload.total,
          },
        },
      };
    case SET_PEXELS_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          pexelsLibrary: {
            hits: [
              ...state.dataSource.pexelsLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_PIXABAY_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          pixabayLibrary: {
            hits: [
              ...state.dataSource.pixabayLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_PIXABAY_VIDEO_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          pixabayVideoLibrary: {
            hits: [
              ...state.dataSource.pixabayVideoLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_VIDEO_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,

          videoLibrary: {
            hits: [
              ...state.dataSource.videoLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_GIPHY_DATASOURCE:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          giphyLibrary: {
            hits: [
              ...state.dataSource.giphyLibrary.hits,
              ...(payload.hits || []),
            ],
            total: payload.total,
          },
        },
      };
    case SET_DATASOURCE_BY_CATEGORY:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          filteredData: {
            hits: [
              ...state.dataSource.filteredData.hits,
              ...(payload.hits || []),
            ],
            type: payload.type,
            total: payload.total,
            isLoading: payload.isLoading,
          },
        },
      };
    case RESET_DATASOURCE_BY_CATEGORY:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          filteredData: {
            hits: [],
            total: null,
            isLoading: true,
          },
        },
      };
    case SET_FILE_MANAGER:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          uploadLibrary: {
            ...payload,
          },
        },
      };
    case SET_UPLOAD_DATA:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          uploadData: {
            ...state.dataSource.uploadData,
            ...payload,
          },
        },
      };
    case SET_MOVE_TO:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          moveToLibrary: {
            ...payload,
          },
        },
      };
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          uploadProgress: {
            ...state.dataSource.uploadProgress,
            ...payload,
          },
        },
      };
    case SET_FAVOURITE: {
      const { isFav, assetid, type } = payload;
      const modifyType = type.includes("filter") ? "filter" : type;
      const libraryType = getLibraryType(modifyType);
      let favouriteData = null;
      const favouriteLibraryType = getFavouriteLibraryType(type);
      let favouriteObj = null;
      const updatedData = [...state.dataSource[libraryType].hits];
      switch (libraryType) {
        case "musicLibrary":
          updatedData.some((data) => {
            const item = data._source ? data._source : data;
            if (item.id === assetid) {
              favouriteObj = item;
              item.is_fav = isFav === true ? isFav : false;
              return true;
            }
            return false;
          });
          break;
        case "pexelsLibrary":
        case "pixabayLibrary":
        case "unsplashLibrary":
        case "pexelsVideoLibrary":
        case "pixabayVideoLibrary":
        case "filteredData":
          updatedData.some((data) => {
            const item = data._source ? data._source : data;
            if (item.id === assetid) {
              favouriteObj = item;
              item.is_fav = isFav === true ? isFav : false;
              return true;
            }
            return false;
          });
          break;
        default:
          updatedData.some((ele) => {
            let returnValue = false;
            ele._source.data.some((item) => {
              if (item.id === assetid) {
                favouriteObj = item;
                item.is_fav = isFav === true ? isFav : false;
                returnValue = true;
                return returnValue;
              }
              return false;
            });
            return returnValue;
          });
          break;
      }
      favouriteData = { ...state.dataSource.favouritesLibrary.hits };
      if (!favouriteData[favouriteLibraryType]) {
        favouriteData[favouriteLibraryType] = [];
      }
      if (!isFav) {
        Object.keys(favouriteData).forEach(typeKey => {
          favouriteData[typeKey] = favouriteData[typeKey].filter(item => {
            const objItem = item?._source ? item._source.id : item.id;
            return objItem !== assetid;
          });
        });
      } else if (favouriteObj) {
        favouriteData[favouriteLibraryType] = favouriteData[favouriteLibraryType].filter(item => {
          const itemId = item?._source ? item._source.id : item.id;
          return itemId !== assetid;
        });
        favouriteData[favouriteLibraryType].push(favouriteObj);
      }

      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          [libraryType]: {
            ...state.dataSource[libraryType],
            hits: [...updatedData],
          },
          favouritesLibrary: {
            ...state.dataSource.favouritesLibrary,
            hits: favouriteData,
          }
        },
      };
    }
    case SET_VIRAL_STYLES:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          viralStyles: payload,
        },
      };
    case UPDATE_PANEL_ITEMS:
      return {
        ...state,
        panelItems: action.payload,
      };
    case SET_AVATAR_DATA: {
      if (action.payload.type === 'actor') {
        return {
          ...state,
          dataSource: {
            ...state.dataSource,
            avatar: {
              ...state.dataSource.avatar,
              [`${action.payload.type}s`]: action.payload.data[`${action.payload.type}s`],
              total: action.payload.data.total_actors
            },
          }
        };
      }
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          avatar: {
            ...state.dataSource.avatar,
            totalVoice: action.payload.data.total_voices,
            [`${action.payload.type}s`]: action.payload.data[`${action.payload.type}s`]
          },
        }
      };
    }

    default:
      return state;
  }
}
