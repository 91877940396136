import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  HeaderWrap,
  Anchor,
  SectionSection,
  MobileIcon,
  MenuContainer
} from "./animo-component";
import Typography from "../common-components/Typography";
import { font } from "../constants/font";
import AppConfig, { STATIC_PATH } from "../constants/config";
import Action from "../common-components/Action";
import Download from "./download";
import content from "../constants/content";
import Contact from "./contact";
import vmTheme from "../constants/theme";
import { SmartImage } from "../common-components/SmartImage";
import useMobileDetection from "../helper/hooks/useMobileDetection";

const commonTypographyProps = {
  font: font.normalBase_21,
  color: theme => vmTheme[theme].animoHeaderText,
  cursor: "pointer",
  margin: "0px 20px 0px 8px",
  isAnchor: true,
  target: "_blank"
};

const Header = ({ isExport }) => {
  const [showDownload, setShowDownload] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { isLoggedIn = false, profileimage } = useSelector(
    (state) => state.userDetails
  );
  const theme = useSelector((state) => state.app.get("theme"));
  const isMobile = useMobileDetection();

  const handleMobileClick = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  // Helper function for repeated Typography components
  const renderNavLink = (content, href, className, extraProps = {}) => (
    <Typography
      {...commonTypographyProps}
      content={content}
      color={vmTheme[theme].animoHeaderText}
      href={href}
      className={className}
      {...extraProps}
    />
  );

  return (
    <>
      {showDownload && <Download setShowDownload={setShowDownload} />}
      {showContact && <Contact setShowContact={setShowContact} />}
      <HeaderWrap>
        <a href={AppConfig.SITE_URL}>
          <img src={`${STATIC_PATH}vmaker-logos.svg`} alt="vmaker-logo" />
        </a>
        {!isExport && (
          <Anchor>
            <SectionSection className="animo-header-main">
              {!isLoggedIn && (
                <Typography
                  content={content.PRICING}
                  font={font.normal14}
                  color={vmTheme[theme].animoHeaderText}
                  cursor="pointer"
                  margin="0px 12px 0px 8px"
                  isAnchor
                  href="https://www.vmaker.com/pricing"
                  target="_blank"
                  className="pricing"
                />
              )}
              {!isLoggedIn && (
                <Action
                  text={content.GET_VMAKER_FOR_FREE}
                  height="36px"
                  width="170px"
                  margin="0px 10px"
                  padding="0px 20px 0px 40px"
                  className="download-btn"
                  onClick={() => setShowDownload(true)}
                />
              )}
              {!isLoggedIn && (
                <Typography
                  content={content.LOGIN}
                  font={font.normalBase_21}
                  color={vmTheme[theme].animoHeaderText}
                  cursor="pointer"
                  className="login-link"
                  href={AppConfig.SITE_URL}
                  target="_blank"
                  isAnchor
                />
              )}
              {isLoggedIn && (
                <Typography
                  content={content.DASHBOARD}
                  font={font.normalBase_21}
                  color={vmTheme[theme].animoHeaderText}
                  cursor="pointer"
                  className="dashbaord-link"
                  href={AppConfig.SITE_URL}
                  target="_blank"
                  margin="0px 20px 0px 8px"
                  isAnchor
                />
              )}
              {isLoggedIn && (
                <SmartImage
                  className="profile-pic"
                  imageSource={`${profileimage}`}
                  placeHolderImage={`${STATIC_PATH}vmaker.svg`}
                  cursor="pointer"
                  width="32px"
                  height="32px"
                  alt={"profile-icon"}
                />
              )}
            </SectionSection>
          </Anchor>
        )}
        {isExport && (
          <Anchor className={isMobile ? "mobileAnchor" : ""}>
            {!isMobile ? (
              <>
                {renderNavLink(content.DASHBOARD, AppConfig.SITE_URL, "dashbaord-link")}
                {renderNavLink(content.PRICING, "https://www.vmaker.com/pricing", "dashbaord-link")}
                {renderNavLink(content.SUPPORT, "https://www.vmaker.com/support/", "dashbaord-link")}
              </>
            ) : (
              <>
                <MobileIcon
                  src={showMobileMenu ? `${STATIC_PATH}close-icon2.svg` : `${STATIC_PATH}menu-icon.svg`}
                  alt="publish"
                  isMobileShow={showMobileMenu}
                  className="publish-icon"
                  onClick={handleMobileClick}
                />
                <MenuContainer theme={theme} className={showMobileMenu ? 'slide-down' : ''}>
                  {renderNavLink(content.DASHBOARD, AppConfig.SITE_URL, "dashbaord-link", { display: "block !important" })}
                  {renderNavLink(content.PRICING, "https://www.vmaker.com/pricing", "dashbaord-link", { display: "block !important" })}
                  {renderNavLink(content.SUPPORT, "https://www.vmaker.com/support/", "dashbaord-link", { display: "block !important" })}
                </MenuContainer>
              </>
            )}
          </Anchor>
        )}
      </HeaderWrap>
    </>
  );
};

Header.propTypes = {
  isExport: PropTypes.bool,
};

export default Header;
