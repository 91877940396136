import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Typography from "../common-components/Typography";
import content from "../constants/content";
import vmTheme from "../constants/theme";
import { font } from "../constants/font";
import { STATIC_PATH } from "../constants/config";
import Action from "../common-components/Action";
import { Celebration, ExportSidebar } from "./publish-component";
import useUpgrade from "../helper/hooks/useUpgrade";
import { isPaidUser } from "../helper/checkUser";
import ExportDetails from "./export-details";
import Youtube from "./youtube";
import Progress from "./progress";
import { setStageClarity } from "../helper/trackClarity";
import TRACKERS from "../constants/trackers";
import useExport from "../helper/hooks/useExport";
import useWatermark from "../helper/hooks/useWatermark";

const Publish = ({ isShortsPlayer, shortId, isPublish }) => {
  const history = useHistory();
  const location = useLocation();
  const { showUpgrade } = useUpgrade();
  const userDetails = useSelector((store) => store.userDetails);
  const theme = useSelector((state) => state.app.get("theme"));
  const isPaid = isPaidUser(userDetails.plan);
  const { triggerVC } = useExport();

  const showWatermMark = useWatermark();

  const [isYoutube, setIsYoutube] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const exportType = params.get("export_type");
    // To maintain the state on page refresh.
    if (exportType) {
      setIsDownload(exportType === "download");
      setIsYoutube(exportType === "youtube");
    }
  }, [location.search]);

  /**
   * To Update the Query params
   * @param {string} type 
   */
  const updateExportType = (type) => {
    const params = new URLSearchParams(location.search);
    params.set("export_type", type);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
      state: location.state, // Preserve existing state
    });
  };

  /**
   * To remove the query params
   */
  const removeExportType = () => {
    const params = new URLSearchParams(location.search);
    params.delete("export_type");
    history.push({
      pathname: location.pathname,
      search: params.toString(),
      state: location.state, // Preserve existing state
    });
  };

  const onDownloadClick = () => {
    if (isPaid) {
      updateExportType("download");
      triggerVC(false);
    } else {
      showUpgrade({ show: true, upgradesource: "Direct-download" });
    }
    setStageClarity(TRACKERS.EXPORT_BTN_DOWNLOAD_CLICKED);
  };

  const onYouTubeClick = () => {
    setStageClarity(TRACKERS.EXPORT_BTN_YOUTUBE_CLICKED);
    updateExportType("youtube");
    triggerVC(true);
  };

  const onGoBack = () => {
    if (isYoutube) {
      setIsYoutube(false);
    } else {
      setIsDownload(false);
    }
    removeExportType();
  }

  if (isPublish) {
    return <Progress />;
  }

  if (isYoutube) {
    return <Youtube onBackAction={onGoBack} isShortsPlayer={isShortsPlayer} shortId={shortId} showWatermMark={showWatermMark} />
  }

  if (isDownload) {
    return <ExportDetails
      goBack={onGoBack}
      isShortsPlayer={isShortsPlayer} shortId={shortId}
    />
  }

  return (
    <ExportSidebar>
      <Typography
        content={content.EXPORT_VIDEO}
        color={vmTheme[theme].animoHeaderText}
        enableTrim={false}
        font={font.semiBold_45}
        display={"block"}
        align={"center"}
      />
      <Typography
        content={content.PUBLISH_TO_YOUTUBE}
        color={vmTheme[theme].exportLabelColor}
        enableTrim={false}
        font={font.normal14_400}
        display={"block"}
        align={"center"}
      />
      <Typography
        content={content.SELECT_CHANNEL}
        color={vmTheme[theme].sbSecondaryColor}
        enableTrim={false}
        font={font.mediumBase_21}
        padding={"32px 0px 8px"}
      />
      {!isPaid && !showWatermMark ? <Celebration>
        <Typography
          content={content.CONGRATS_FREE_PUBLISH}
          color={vmTheme[theme].celebrationViolet}
          enableTrim={false}
          font={font.boldMini}
          display={"block"}
          width={"auto"}
          padding={"2px 0px 8px"}
        />
      </Celebration> : null}
      <Action
        text={content.YOUTUBE}
        fontColor={vmTheme[theme].paymentCardTextColor}
        background={vmTheme[theme].polarColor}
        height={"auto"}
        width={"100%"}
        icon={`${STATIC_PATH}vm-youtube.svg`}
        font={font.mediumBase_21}
        hoverColor={vmTheme[theme].polarColor}
        borderColor={vmTheme[theme].socialBorderColor}
        borderWidth={"1px"}
        borderRadius={"12px"}
        marginTop={"8px"}
        customClass={"social-media"}
        top={"6px"}
        onClick={onYouTubeClick}
      />
      <Typography
        content={content.DIRECT_DOWNLOAD}
        color={vmTheme[theme].sbSecondaryColor}
        enableTrim={false}
        font={font.mediumBase_21}
        padding={"8px 0px"}
      />
      <Action
        text={isPaid ? content.DOWNLOAD_VIDEO : content.UPGRADE_TO_DOWNLOAD}
        fontColor={isPaid ? vmTheme[theme].paymentCardTextColor : vmTheme[theme].polarColor}
        background={isPaid ? vmTheme[theme].polarColor : vmTheme[theme].btnLinearColor}
        height={"auto"}
        width={"100%"}
        icon={`${STATIC_PATH}${isPaid ? "download" : "vm-star-icon"}.svg`}
        font={font.mediumBase_21}
        hoverColor={isPaid ? vmTheme[theme].polarColor : vmTheme[theme].btnHoverLinearColor}
        borderColor={isPaid ? vmTheme[theme].socialBorderColor : vmTheme[theme].transparent}
        borderWidth={isPaid ? "1px" : "0px"}
        borderRadius={"12px"}
        marginTop={"0px"}
        customClass={`social-media ${isPaid ? "" : "upgrade"}`}
        top={isPaid ? "6px" : "7px"}
        onClick={onDownloadClick}
      />
    </ExportSidebar>
  );
};

Publish.propTypes = {
  isShortsPlayer: PropTypes.bool,
  shortId: PropTypes.string,
  isPublish: PropTypes.bool
}

export default Publish;
