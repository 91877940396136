/**
 * Determines the library type based on the given asset type.
 *
 * @param {string} type - The input type (e.g., "pexel-image", "video").
 * @returns {string} - The corresponding library type.
 */
export const getLibraryType = (type) => {
    const libraryMap = {
        "property": "propertyLibrary",
        "pexel-image": "pexelsLibrary",
        "pixabay-image": "pixabayLibrary",
        "unsplash-image": "unsplashLibrary",
        "giphy-image": "giphyLibrary",
        "image": "imageLibrary",
        "pexel-video": "pexelsVideoLibrary",
        "pixabay-video": "pixabayVideoLibrary",
        "video": "videoLibrary",
        "music": "musicLibrary",
        "favourite": "favouritesLibrary",
        "filter": "filteredData",
        "effects": "soundEffects"
    };
    return libraryMap[type] || type;
};
