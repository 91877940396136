/* eslint-disable camelcase */
import { Record, fromJS, isImmutable } from "immutable";
import { prepareClipDetails, prepareSingleClipFrame } from "../frame/frame-helper";
import { isImageOnly, isVideoOnly, randomString } from "../timeline/timeline-helper";
import { ITEM_CONFIG } from "../../constants/config";
import { getTextBounds } from "../text/TextHelper";
import { CIRCLE_FRAME } from "../../constants";

export const LibraryDragItemRecord = Record({
  id: "",
  data: null,
  originalData: null,
});

export const DEFAULT_SWAP_HOVER_DROP = fromJS({
  id: "",
  childId: "",
  targetType: ITEM_CONFIG.SWAP_TARGETS.NONE,
  sourceType: ITEM_CONFIG.SWAP_SOURCE.NONE,
  frameClipId: "",
  hovering: false,
  transitionLayer: null
});

export const getDuration = (clipLength) => {
  return parseFloat(
    `${parseFloat(clipLength.split(":")[0] * 3600) +
    parseFloat(clipLength.split(":")[1] * 60) +
    parseFloat(clipLength.split(":")[2])
    }.${parseFloat(clipLength.split(":")[3])}`
  );
};

const isMusicLibItem = (type, subType) => {
  const musicTypes = ["MUSIC", "BGM", "EAB", "SFX", "UPAUDIO", "AUDIO", "SSM"];
  return musicTypes.includes(type) || musicTypes.includes(subType);
};

export const canSwapByDnD = (libItem, workspaceItem) => {
  let canSwap = false;

  if (libItem && workspaceItem) {
    let { type, subType } = libItem;
    if (isImmutable(libItem)) {
      type = libItem.get("type");
      subType = libItem.get("subType");
    }

    if (isImageOnly(workspaceItem.get("type"), workspaceItem.get("subType"))) {
      canSwap = isImageOnly(type, subType);
    }
  }

  return canSwap;
};

const getPartialVideoItem = (params = {}) => {
  const { data, isUserUpload } = params;
  const {
    width,
    height,
    pheight,
    pwidth,
    type,
    subType,
    thumbnail,
    thumbstrip,
    id,
    src,
    proxy,
    duration,
    source_type,
    name,
    isTransparentAvatar,
    tags
  } = data;

  const newItem = {
    pheight,
    pwidth,
    type,
    subType,
    thumb: thumbnail,
    thumbstrip,
    assetId: id,
    volume: 1,
    videoStart: 0,
    filter: ITEM_CONFIG.IMAGE.DEFAULT_FILTER,
  };

  if (isUserUpload && type === "VIDEO") {
    newItem.src = src;
    newItem.videoDuration = duration;
    newItem.videoEnd = duration;
    newItem.type = "UPVIDEO";
    newItem.subType = type;
    newItem.pwidth = width;
    newItem.pheight = height;
    newItem.sourceType = source_type;
    newItem.name = name;

    if (source_type === "webm" && isTransparentAvatar) {
      newItem.isTransparentAvatar = true;
    }

    if (tags === "avatarupload") {
      newItem.isAvatar = true;
    }

    if (subType === "RECORD") {
      newItem.subType = subType;
    } else if (type === "VIDEO") {
      newItem.subType = "UPVIDEO";
    }
  } else {
    let durationValue;
    if (type === "PEXELS_VIDEOS" || type === "PIXABAY_VIDEOS") {
      durationValue = parseFloat(
        `${parseFloat(data.clip_length.split(":")[0] * 0) +
        parseFloat(data.clip_length.split(":")[1] * 3600) +
        parseFloat(data.clip_length.split(":")[2] * 60) +
        parseFloat(data.clip_length.split(":")[3])
        }.${parseFloat(data.clip_length.split(":")[4])}`
      );

      if (type === "PEXELS_VIDEOS") {
        newItem.type = "PEXELS";
      } else if (type === "PIXABAY_VIDEOS") {
        newItem.type = "PIXABAY";
      }
    } else {
      durationValue = getDuration(data.clip_length);
    }

    newItem.src = proxy;
    newItem.videoDuration = durationValue;
    newItem.videoEnd = durationValue;
  }

  return newItem;
};

const getPartialImageItem = (params = {}) => {
  const { data, isUserUpload } = params;

  const newItem = {
    assetId: data.id,
    type: data.type,
    subType: data.subType,
    filter: ITEM_CONFIG.IMAGE.DEFAULT_FILTER,
  };

  if (data.type === "STOCKIMG") {
    newItem.src = data.display_sizes[0].uri;
    newItem.thumb = data.display_sizes[2].uri;
    newItem.width = Number(data.max_dimensions.width);
    newItem.height = Number(data.max_dimensions.height);
    newItem.pwidth = Number(data.max_dimensions.width);
    newItem.pheight = Number(data.max_dimensions.height);
  } else if (data.type === "gif") {
    newItem.type = "IMG";
    newItem.subType = "GIPHY";

    newItem.src = data.original.url;
    newItem.thumb = data.preview.url;
    newItem.width = Number(data.original.width);
    newItem.height = Number(data.original.height);
    newItem.pwidth = Number(data.original.width);
    newItem.pheight = Number(data.original.height);
  } else if (
    data.type === "pexels" ||
    data.type === "PEXELS" ||
    data.type === "pixabay" ||
    data.type === "PIXABAY" ||
    data.subType === "UNSPLASH" ||
    data.subtype === "OBGIMG"
  ) {
    if (data.subType === "UNSPLASH") {
      newItem.type = "UNSPLASH";
      newItem.subType = "IMAGE";
    } else if (data.subtype === "OBGIMG") {
      newItem.type = "IMG";
      newItem.subType = "OBGIMG";
    } else if (data.type === "pexels") {
      newItem.type = "PEXELS";
    } else if (data.type === "pixabay") {
      newItem.type = "PIXABAY";
    }

    newItem.src = data.src;
    newItem.thumb = data.thumbnail;
    newItem.width = Number(data.width);
    newItem.height = Number(data.height);
    newItem.pwidth = Number(data.width);
    newItem.pheight = Number(data.height);
  } else if (isUserUpload || data.subtype === "BRAND") {
    newItem.type = "UPIMAGE";
    newItem.subType = "UPIMAGE";

    if (data.subtype === "BRAND") {
      newItem.subType = data.subtype;
    }

    newItem.src = data.src;
    newItem.thumb = data.thumbnail;
    newItem.width = Number(data.width);
    newItem.height = Number(data.height);
    newItem.pwidth = Number(data.width);
    newItem.pheight = Number(data.height);
    newItem.sourceType = data.source_type;

    if (data.source_type === "svg" && data.color) {
      newItem.colors = JSON.parse(data.color);
      newItem.defaultColor = newItem.colors;
    }
  }

  return newItem;
};

const getPartialShapeItem = (params = {}) => {
  const { data } = params;

  const newItem = {
    assetId: data.id,
    type: data.type,
    subType: data.subType,
    groupBounds: data.groupBounds,
    pathData: data.pathData,
    scaleType: data.scaletype,
    colors: data.colors,
    defaultColor: data.colors,
    thumb: data.thumbnail,
    width: Number(data.width),
    height: Number(data.height),
    pwidth: Number(data.width),
    pheight: Number(data.height),
    xRatio: data.xRatio ? Number(data.xRatio) : 5,
    yRatio: data.yRatio ? Number(data.yRatio) : 5,
    widthRatio: data.widthRatio ? Number(data.widthRatio) : 90,
    heightRatio: data.heightRatio ? Number(data.heightRatio) : 90,
    isVertical: data.isVertical,
    isHorizontal: data.isHorizontal,
  };

  let isAutoFontSize = false;
  if (newItem.subType === "CALLOUT") {
    isAutoFontSize = true;
  }

  newItem.textData = {
    htmlText: " ",
    isDefault: true,
    formats: {
      bullet: {
        isBullet: false,
        type: "disc",
        bulletSpace: 0,
      },
      containerStyle: {
        textAlign: "center",
        fontFamily: "Arvo",
        fontSize: "38px",
        color: "#000000",
        lineHeight: 1.2,
      },
      others: {
        isAutoFontSize,
        isRTL: false,
        minWidth: null,
        isFixedWidth: false,
        family: "Arvo",
      },
    },
  };

  if (newItem.pathData && newItem.pathData.maxBounds) {
    newItem.xRatio = Number(newItem.pathData.maxBounds.x);
    newItem.yRatio = Number(newItem.pathData.maxBounds.y);
    newItem.widthRatio = Number(newItem.pathData.maxBounds.width);
    newItem.heightRatio = Number(newItem.pathData.maxBounds.height);
  }

  newItem.minXRatio = newItem.xRatio;
  newItem.minYRatio = newItem.yRatio;
  newItem.maxWidthRatio = newItem.widthRatio;
  newItem.maxHeightRatio = newItem.heightRatio;

  return newItem;
};

const getPartialFrameItem = (params = {}) => {
  const { data } = params;

  const newItem = {
    assetId: data.id,
    type: data.type,
    subType: data.subType ? data.subType : data.type,
    width: Number(data.width),
    height: Number(data.height),
    defaultWidth: Number(data.width),
    defaultHeight: Number(data.height),
    clipDetails: data.clipDetails,
    d: data.d,
  };
  newItem.clipDetails = prepareClipDetails(fromJS(newItem));

  return newItem;
};

const getPartialPropItem = (params = {}) => {
  const { data } = params;

  const newItem = {
    assetId: data.id,
    charId: data.charId,
    type: data.type,
    subType: data.subType,
    width: Number(data.width),
    height: Number(data.height),
    pwidth: Number(data.width),
    pheight: Number(data.height),
    colors: data.colors,
    defaultColor: data.colors,
    src: data.src,
    thumb: data.thumb,
    animsrc: data.animsrc,
    animdata: data.animdata,
    isAnimated: typeof data.animsrc === "string",
    isRepeated: data.isRepeated,
    repeatLoop: 0,
  };

  if (newItem.type === "EPROP") {
    newItem.type = "PROP";
    newItem.subType = "EPROP";
  } else if (typeof data.sub_type === "string") {
    newItem.subType = data.sub_type;
  }

  return newItem;
};

const getPartialMusicItem = (params = {}) => {
  const { data, isUserUpload } = params;

  const musicDuration = Number(data.duration);

  let type = "MUSIC";
  let subType = data.type;

  if (data.type === "BGM" && data.subType === "EAB") {
    type = "EAB";
  }
  if (data.subType === "SSM") {
    type = data.subType;
    subType = data.type;
  }
  if (isUserUpload) {
    subType = "UPAUDIO";
  }

  const audio = {
    src: data.src,
    type,
    subType,
    assetId: data.id,
    name: data.name,
    volume: 1,
    musicDuration,
    musicStart: 0,
    musicEnd: musicDuration,
    fadeDetails: [],
  };
  if (data.subType === "SSM") {
    audio.songId = data.song_id
  }
  const fadeDetails = [];
  const amp = audio.volume;
  if (audio.musicDuration > 1) {
    fadeDetails.push({ a: amp, t: audio.musicStart + 2 });
    fadeDetails.push({ a: amp, t: audio.musicStart + 4 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 2 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 4 });
  } else if (audio.musicDuration <= 1) {
    fadeDetails.push({ a: amp, t: audio.musicStart + 0.1 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 0.1 });
  }
  audio.fadeDetails = fadeDetails;

  return audio;
};

const getPartialTextItem = (params = {}) => {
  const { data, textOffset, workspaceWidth } = params;

  const fontData = data.data;
  const { fontFamily, lineHeight } = fontData;
  const isBoldSupported = Boolean(fontData.bold);
  const isItalicSupported = Boolean(fontData.italic);

  const fontFamilyToSet = fontData[data.style];
  if (!fontFamilyToSet) {
    return null;
  }

  // data for heading
  const textItem = {
    name: "Add a heading",
    type: "TEXT",
    subType: "DTXT",
    textData: {
      htmlText: `<span data-ff=${fontFamily} data-s=${data.style} data-uf="false" style="font-family: ${fontFamily}-${data.style}, gfs; line-height: ${lineHeight};">Add a heading</span>`,
      splittedText: "Add a heading",
      isDefault: true,
      formats: {
        bullet: { type: "none", bulletSpace: 10 },
        containerStyle: {
          textAlign: "center",
          fontFamily: fontFamilyToSet,
          fontSize: "43px",
          color: "#000000",
          lineHeight,
          margin: `${textOffset / 2}px`,
        },
        others: {
          isAutoFontSize: false,
          isRTL: false,
          family: fontFamily,
          isBold: isBoldSupported,
          isItalic: isItalicSupported,
        },
      },
    },
    width: 390,
    height: 78,
    widthRatio: null,
    heightRatio: null,
    xRatio: null,
    yRatio: null,
    src: "",
    thumb: "text1.png",
  };

  if (data.dropType === "subHeading") {
    textItem.name = "Add a subheading";
    textItem.textData.htmlText = "Add a subheading";
    textItem.textData.splittedText = textItem.textData.htmlText;
    textItem.textData.formats.containerStyle.fontSize = "37px";
  } else if (data.dropType === "body") {
    textItem.name = "Add little bit of body text to your design";
    textItem.textData.htmlText = "Add little bit of body text to your design";
    textItem.textData.splittedText = textItem.textData.htmlText;
    textItem.textData.formats.containerStyle.fontSize = "27px";
  } else if (data.dropType === "bullet") {
    textItem.name = "Add a bullet text";
    textItem.textData.htmlText = `<ul><li><span data-ff=${fontFamily} data-s=${data.style} data-uf="false" style="font-family: ${fontFamily}-${data.style}, gfs; line-height: ${lineHeight};">Add a bullet text</span></li></ul>`;
    textItem.textData.splittedText = textItem.textData.htmlText;
    textItem.textData.formats.containerStyle.fontSize = "27px";
    textItem.textData.formats.containerStyle.textAlign = "left";
  } else if (data.dropType !== "heading") {
    return null;
  }

  const textBounds = getTextBounds(fromJS(textItem.textData), workspaceWidth);
  if (!textBounds) {
    return null;
  }

  textItem.width = textBounds.width;
  textItem.height = textBounds.height;

  return textItem;
};

export const generatePartialLibItem = (params = {}) => {
  const { data } = params;
  const { type, subType, subtype } = data;
  let newItem = null;

  if (isVideoOnly(type, subType)) {
    newItem = getPartialVideoItem(params);
  } else if (
    isImageOnly(type, subType) ||
    type === "gif" ||
    subType === "UNSPLASH" ||
    subtype === "OBGIMG" ||
    subType === "IMAGE"
  ) {
    newItem = getPartialImageItem(params);
  } else if (type === "SHAPE") {
    newItem = getPartialShapeItem(params);
  } else if (type === "FRAME") {
    newItem = getPartialFrameItem(params);
  } else if (type === "PROP" || type === "EPROP") {
    newItem = getPartialPropItem(params);
  } else if (isMusicLibItem(type, subType)) {
    newItem = getPartialMusicItem(params);
  } else if (type === "TEXT") {
    newItem = getPartialTextItem(params);
  }

  return newItem;
};

/**
 * @param {object} params
 * @param {object} params.videoItem
 * @param {string | undefined} params.idToUse
 * @param {string | undefined} params.dropIdToUse
 * @param {boolean | undefined} params.returnImmutable
 * @returns
 */
export const videoItemToAudioItem = (params = {}) => {
  const { idToUse = "", dropIdToUse = "", returnImmutable = false } = params;
  const videoItem = fromJS(params.videoItem);

  let playStart = videoItem.get("enterStart");
  let playEnd = videoItem.get("exitEnd");

  if (playStart === undefined) {
    // workspace bg
    playStart = videoItem.get("playStart");
    playEnd = videoItem.get("playEnd");
  }

  const audio = {
    id: idToUse || randomString("detach"),
    dropId: dropIdToUse || randomString("detach"),
    src: videoItem.get("src"),
    type: videoItem.get("type"),
    subType: videoItem.get("subType"),
    assetId: videoItem.get("assetId"),
    name: videoItem.get("name") || videoItem.get("assetId"),
    volume: videoItem.get("volume"),
    playStart,
    playEnd,
    musicDuration: videoItem.get("videoDuration"),
    musicStart: videoItem.get("videoStart"),
    musicEnd: videoItem.get("videoEnd"),
    fadeDetails: [],
    sourceType: videoItem.get("sourceType"),
    // track: 0, // not setting track here since it has to be decided based on various params like available space
  };

  if (videoItem.get("speed")) {
    audio.speed = videoItem.get("speed");
  }

  const fadeDetails = [];
  const amp = audio.volume;
  if (audio.musicDuration > 1) {
    fadeDetails.push({ a: amp, t: audio.musicStart + 2 });
    fadeDetails.push({ a: amp, t: audio.musicStart + 4 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 2 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 4 });
  } else if (audio.musicDuration <= 1) {
    fadeDetails.push({ a: amp, t: audio.musicStart + 0.1 });
    fadeDetails.push({ a: amp, t: audio.musicEnd - 0.1 });
  }
  audio.fadeDetails = fadeDetails;

  if (returnImmutable) {
    return fromJS(audio);
  }

  return audio;
};

export const audioDetachHelper = (params = {}) => {
  const { item, container } = params;
  if (
    item &&
    isVideoOnly(item.get("type"), item.get("subType")) &&
    !item.get("isAudioDetached")
  ) {
    const detachedAudio = videoItemToAudioItem({
      videoItem: item,
      returnImmutable: false,
    });
    detachedAudio.isDetachedAudio = true;

    const toUpdate = [
      {
        container: "audios",
        id: detachedAudio.id,
        newItemData: detachedAudio,
        isAdd: true,
        isAudioDetach: true,
      },
    ];
    toUpdate.push({
      id: item.get("id"),
      container,
      toUpdate: {
        isAudioDetached: true,
        volume: 0,
        detachedAudioId: detachedAudio.id,
      },
    });

    return toUpdate;
  }

  return null;
};

const frameObjBuilder = (frame, id, workspaceWidth, workspaceHeight) => {
  const partialLibItem = generatePartialLibItem({ data: frame });
  const libItem = { ...partialLibItem };
  const assetWidth = libItem.defaultWidth;
  const assetHeight = libItem.defaultHeight;

  const leastVal = Math.min(workspaceWidth, workspaceHeight) - 50;
  const aspectRatio = assetWidth / assetHeight;

  if (assetWidth > assetHeight) {
    libItem.width = leastVal;
    libItem.height = libItem.width / aspectRatio;
  } else {
    libItem.height = leastVal;
    libItem.width = libItem.height * aspectRatio;
  }

  libItem.x = (workspaceWidth - libItem.width) / 2;
  libItem.y = (workspaceHeight - libItem.height) / 2;
  libItem.opacity = 1;
  libItem.angle = 0;
  libItem.flipPosition = 0;
  libItem.id = id;

  return fromJS(libItem);
}

export function getAvatarWorkspaceItem(item) {
  const { duration, thumb, assetId, dropId, src, sourceType, width, height, script, voice_url, voiceId,
    actorId, workspaceWidth, workspaceHeight, appearance, isTransparentAvatar } = item;
  const dropItem = {
    videoEnd: duration,
    thumb,
    exitEnd: duration,
    exitEffectName: "no_Effect",
    enterEffectName: "no_Effect",
    type: "UPVIDEO",
    subType: "AVATAR",
    videoStart: 0,
    exitStart: 0,
    assetId,
    enterEnd: 0,
    angle: 0,
    id: `avatar${dropId}`,
    track: 0,
    src,
    enterStart: 0,
    pwidth: width,
    pheight: height,
    filter: "6464646464640032",
    volume: 1,
    videoDuration: duration,
    dropId: `avatar${dropId}`,
    sourceType,
    flipPosition: 0,
    width,
    height,
    name: `Avatar${dropId}.${sourceType}`,
    x: 0,
    y: 0,
    opacity: 1,
    isCropped: false,
    isAvatar: true,
    script,
    voice_url,
    voiceId,
    actorId,
    appearance,
    isTransparentAvatar,
  };

  const scale = Math.min(
    workspaceWidth / dropItem.pwidth,
    workspaceHeight / dropItem.pheight
  );
  dropItem.width = dropItem.pwidth * scale;
  dropItem.height = dropItem.pheight * scale;
  dropItem.x = workspaceWidth / 2 - dropItem.width / 2;
  dropItem.y = workspaceHeight / 2 - dropItem.height / 2;
  let dropItemData = dropItem;

  if (appearance === "circle") {
    const frameObj = frameObjBuilder(CIRCLE_FRAME, dropItem.id, workspaceWidth, workspaceHeight);
    dropItemData = prepareSingleClipFrame(frameObj, fromJS(dropItem)).toJS();
  }
  return dropItemData;
}