import { useDispatch } from "react-redux";
import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { font } from "../../constants/font";
import { APP_Z_INDICES } from "../../constants";
import { setWorkspaceTextFocus } from "../../redux/actions/appUtils";
import { NumberInputStyled } from "../../common-components/NumberInputComponent";

export const ItemToolBarStyled = styled.div`
    position: fixed;
    z-index: ${APP_Z_INDICES.itemtoolbar};
    background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    height: 48px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    &.toolbar-hidden, &.toolbar-hidden * {
        pointer-events: none !important;
        visibility: hidden !important;
    }

    &.toolbar-transition {
        transition: all 770ms ease-in-out 0s;
    }
    .setOne{
        display: flex;
        align-items: center;
    }
    @media (max-width: 768px){
        * {
            touch-action: auto;
        }
        position: fixed;
        z-index: 999;
        height: 96px;
        width: 100vw !important;
        left: 0 !important;
        bottom: 0px !important;
        top: auto !important;
        justify-content: flex-start;
        overflow-x: auto;
        touch-action: auto;
        background: #fff;
        padding-top: 20px;
        &.show{
            display: ${(props) => props.isToolBarVisible ? "flex" : "none"};
            align-items: center;
        }
        &.hide{
            display: none;
        }
        &::-webkit-scrollbar {
            height: 20px;
        }
        &.closed{
            display: none;
        }
        &.open{
            display: flex;
        }
    }
`;
ItemToolBarStyled.displayName = "ItemToolBarStyled";

export const CloseItemToolBar = styled.div`
    width: 48px;
    height: 48px;
    background: transparent ${(props) => props.theme.headerColor} 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin: 0px 20px 0px 0px;
    &:after{
        content: "";
        width: 1px;
        height: 24px;
        background: ${(props) => props.theme.veryLightGray} 0% 0% no-repeat padding-box;
        position: absolute;
        right: -10px;
    }
    @media (max-width: 768px){
        display: flex;
    }
`
CloseItemToolBar.displayName = "CloseItemToolBar";

export const ItemToolConfirmationStyled = styled(ItemToolBarStyled)`
    z-index: ${APP_Z_INDICES.itemtoolbarconfirm};
    justify-content: flex-start;
    pointer-events: auto;

    .itc--apply, .itc--cancel {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 0px 16px;
        border-radius: 4px;
        cursor: pointer;
        font: ${font.mediumMini_17};
        @media(max-width: 768px){
            width: 50%;
            height: 40px;
        }
    }

    .itc--apply {
        color: ${(props) => props.theme.polarColor};
        background: var(--apply-btn-background, #00BA70);
        border: 1px solid transparent;
        &:hover{
            color: ${(props) => props.theme.applyHoverColor};
            background: ${(props) => props.theme.polarColor};
            border: 1px solid ${(props) => props.theme.applyHoverColor};
        }
    }

    .itc--cancel {
        background: ${(props) => props.theme.polarColor};
        color: ${(props) => props.theme.secondaryColor};
        margin-left: 10px;
    }
`;
ItemToolConfirmationStyled.displayName = "ItemToolConfirmationStyled";

export const IconShadeCSS = css`
    background-color: transparent;
    transition: background-color 0.3s ease-out;

    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.outerLayerBgColor};
    }

    &.tool-active, &:active {
        cursor: pointer;
        background-color: ${(props) => props.theme.toolActiveColor};
        @media screen and (max-width: 767px) {
            background-color: transparent;
          }
    }

    &.tool-disabled {
        cursor: not-allowed;
        &.setOne-icon.animate {
            opacity: 0.5;
        }
        &.setOne-icon.direction {
            display: none
        }
    }
`;

export const ItemToolShadeCSS = css`
    ${IconShadeCSS}
    &.tool-disabled {
        color: ${(props) => props.theme.toolShadeColor};
    }
`;

export const IconHoverCSS = css`
    > img {
        content: var(--item-tool-src);
    }

    &:hover {
        > img {
            content: var(--item-tool-hoverSrc, --item-tool-src);
            transform: scale(1.1);
        }
    }
`;

export const IconTextPairStyled = styled.li`
    color: ${(props) => props.theme.panelPrimaryColor};
    font: ${font.mediumMini_17};

    position: relative;
    padding: 6px;
    height: 32px;
    border-radius: 4px;

    display: flex;
    align-items: center;

    pointer-events: auto;
    user-select: none;

    &.expand-icon-tool-hidden {
        pointer-events: none;
        visibility: hidden;
        display:none;
    }

    &.setOne-icon.settings {
        outline: 1px solid ${(props) => props.theme.primaryBorderColor};
        img{
            width: 20px;
            height: 20px;
        }
    }
    &.setOne-icon.crop{
        img{
            width: 20px;
            height: 20px;
        }
    }
    &.setOne-icon.animate{
        img{
            width: 20px;
            height: 20px;
        }
    }
    &.setOne-icon.direction{
        img{
            width: 18px;
            height: 18px;
        }
    }
    &.setOne-icon{
        @media(max-width: 768px){
            flex-direction: column;
            height: auto;
            padding: 0;
            border: 0;
            box-shadow: none;
            background: transparent;
            outline: 0 !important;
            margin: 0px 10px;
            p{
                text-align: center;
                font: 12px / 14px Poppins;
                letter-spacing: 0px;
                color: ${(props) => props.theme.itemToolBarParagraph};
                margin: 12px 7px 0;
            }
            img{
                position: relative;
                top: 4px;
            }
            &:hover{
                background: none;
            }
        }
    }

    &.setTwo-icon.swap {
        color: ${(props) => props.theme.propertyColor};
    }

    > img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }

    > p {
        max-width: 115px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ${ItemToolShadeCSS}
    ${IconHoverCSS}
`;
IconTextPairStyled.displayName = "IconTextPairStyled";

export const IconStyled = styled.li`
    position: relative;
    padding: 4px 4px;
    border-radius: 4px;

    display: flex;
    align-items: center;

    pointer-events: auto;
    user-select: none;

    &.expand-icon-tool-hidden {
        pointer-events: none;
        visibility: hidden;
        display:none;
    }

    > img {
        width: 24px;
        height: 24px;
    }

        @media (max-width: 768px){
        padding: 10px;
        flex-direction: column;
        &.color.monocolor{
            margin-left: 11px;
        }
        label{
            text-align: center;
            font: normal normal normal 12px/14px Poppins;
            letter-spacing: 0px;
            color: ${(props) => props.theme.itemToolbarlabelColor};
            margin-top: 5px;
        }
    }

    ${ItemToolShadeCSS}
    ${IconHoverCSS}
`;
IconStyled.displayName = "IconStyled";

export const ItemToolColorStyled = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
    margin: 0;
    margin-right: 5px;

    pointer-events: auto;
    cursor: pointer;
    user-select: none;

    > div {
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        overflow: hidden;
        outline: solid 1px ${(props) => props.theme.gradientBorderColor};
    }

    &.tool-active {
        > div {
            outline-color: ${(props) => props.theme.secondaryBorderColor};
        }
    }

    &.empty-color {
        > div {
            > img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &:not(.empty-color) {
        > div {
            display: grid;
            grid-template-columns: auto auto;
        }
    }

    &.tricolor, &.monocolor {
        > div > div:first-of-type {
            grid-column: span 2 / auto;
        }
    }

    &.text-color {
        > div {   
            grid-template-columns: 1fr;
            grid-template-rows: 16px 8px;

            > div.icon {
                display: flex;
                align-items: flex-end;
                justify-content: center;

                > img {
                    width: 10px;
                    height: 11px;
                }
            }

            > div.colors {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(5.5px, 1fr));

                width: 22px;
                height: 5px;
                margin: auto;

                border-radius: 4px;
                overflow: hidden;
            }
        }
    }
    @media (max-width: 768px){
        flex-direction: column;
        &.color.monocolor{
            margin-left: 11px;
        }
        label{
            text-align: center;
            font: normal normal normal 12px/14px Poppins;
            letter-spacing: 0px;
            color: ${(props) => props.theme.itemToolbarlabelColor};
            margin-top: 5px;
        }
    }
`;
ItemToolColorStyled.displayName = "ItemToolColorStyled";

export const IconSetStyled = styled.div`
    ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        ${IconStyled}.setTwo-icon:not(:first-of-type)::before {
            content: " ";
            position: absolute;
            width: 1px;
            height: 12px;
            top: 50%;
            left: -3px;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.veryLightGray};
            @media(max-width: 768px){
                display: none;
            }
        }

        ${ItemToolColorStyled}.setOne-icon:not(:only-child)::before {
            content: " ";
            position: absolute;
            width: 1px;
            height: 12px;
            top: 50%;
            left: calc(100% + 4px);
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.veryLightGray};
            @media(max-width: 768px){
                display: none;
            }
        }

        .expand-icon-tool-hidden + ${IconStyled}.setTwo-icon::before {
            width: 0;
            height: 0;
        }
    }

    &.setOne > ul {
        gap: 10px;
        @media(max-width: 768px){
            gap: 0px;
        }
    }
    &.setTwo > ul {
        gap: 10px;
        @media(max-width: 768px){
            gap: 0px;
        }
    }
    @media(max-width: 768px){
        .setTwo-icon {
            flex-direction: column;
            height: auto;
            padding: 0;
            border: 0;
            box-shadow: none;
            background: transparent;
            outline: 0 !important;
            margin: 0px 10px;
        
           > p {
                text-align: center;
                font: 12px / 14px Poppins;
                letter-spacing: 0px;
                color: ${(props) => props.theme.itemToolBarParagraph};
                margin: 12px 7px 0;
            }
        
            > img {
                position: relative;
                top: 4px;
            }

        @media (max-width: 768px) {
            > img {
                position: relative;
                top: 0px;
            }
        }
        
            .center , .right{
                position: fixed;
                top: auto;
                bottom: 0;
                left: 0;
                right: 0;
                transform: translateY(0);
                transition: transform 5s ease;
                z-index: 9;
                width: 100%;
                padding: 45px 30px;
                border-radius: 20px 20px 0px 0px;
                box-shadow: none;
                transition: bottom 5s ease; 
            }
        
            &.tool-active {
                .center {
                    transform: translateY(-80px);
                }
            }
        }
    }
`;
IconSetStyled.displayName = "IconSetStyled";

export const MoreOptionsContainer = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    right: -2px;

    background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px ${(props) => props.theme.menuBoxShawdow};
    border: 1px solid ${(props) => props.theme.veryLightGray};
    border-radius: 4px;
    opacity: 1;

    padding: 8px;
    height: 40px;
    display: flex;
    align-items: center;

    &.hide-moreoptions {
        visibility: hidden;
        pointer-events: none;
    }
`;
MoreOptionsContainer.displayName = "MoreOptionsContainer";

export const PopupToolShadeCSS = css`
    ${IconShadeCSS}
    &.tool-disabled > p {
        color: ${(props) => props.theme.contextMenuTxtColor};
    }
`;

export const PopupIconHoverCSS = css`
    > img {
        content: var(--it-popup-src);
    }

    &:not(.tool-disabled):hover {
        > img {
            content: var(--it-popup-hoverSrc, --it-popup-src);
        }
    }

    &.tool-disabled {
        > img {
            content: var(--it-popup-disabledSrc, --it-popup-src);
        }
    }
`;

export const ItemToolPopupStyled = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    left: 0px;
    min-width: 204px;
    background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px ${(props) => props.theme.rgbaLightBlack2};
    border: 1px solid ${(props) => props.theme.veryLightGray};
    border-radius: 8px;
    padding: 14px;
    opacity: 1;
    cursor: auto;

    &.center {
        left: 50%;
        transform: translateX(-50%);
    }
    &.right {
        left: unset;
        transform: unset;
        right: 0px;
    }

    p {
        margin: 0;
        padding: 0;
    }
`;
ItemToolPopupStyled.displayName = "ItemToolPopupStyled";

export const ItemToolPopup = forwardRef((props, ref) => {
    const { className = "" } = props;
    const [positionClass, setPositionClass] = useState("center");
    const dispatch = useDispatch();
    /** @type {React.MutableRefObject<HTMLElement | null>} */
    const popupRef = useRef(null);

    useEffect(() => {
        dispatch(setWorkspaceTextFocus(true));
        return () => {
            dispatch(setWorkspaceTextFocus(false));
        }
    }, [dispatch]);

    useLayoutEffect(() => {
        const popupEl = popupRef.current;
        if (popupEl) {
            const popupBounds = popupRef.current.getBoundingClientRect();
            if (popupBounds.right > window.innerWidth) {
                setPositionClass("right");
            } else if (popupBounds.left < 0) {
                setPositionClass("left");
            }
        }
    }, []);

    const assignRef = (r) => {
        popupRef.current = r;
        if (typeof ref === "function") {
            ref(r);
        } else if (ref && typeof ref === "object") {
            ref.current = r;
        }
    }

    return (
        <ItemToolPopupStyled
            ref={assignRef}
            {...props}
            onClick={(e) => e.stopPropagation()}
            className={`${className} ${positionClass}`}
        />
    );
});
ItemToolPopup.propTypes = {
    className: PropTypes.string,
}
ItemToolPopup.displayName = "ItemToolPopup";

export const TransparencyPopupStyled = styled(ItemToolPopup)`
    width: 204px;
    padding: 16px;

    display: grid;
    grid-template-rows: 24px 16px;
    grid-template-columns: 1fr 48px;
    row-gap: 12px;
    column-gap: 0px;
    align-items: center;

    .item-tool-tps--heading {
        margin: 0;
        padding: 0;
        color: ${(props) => props.theme.propertyColor};
        font: ${font.mediumMini};
    }

    ${NumberInputStyled} {
        width: 100%;
        height: 100%;
        background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
        border: 1px solid ${(props) => props.theme.primaryBorderColor};
        border-radius: 4px;
        cursor: text;

        form {
            border-radius: 4px;
            overflow: hidden;
        }

        input {
            font: ${font.normalMini_12};
            color: ${(props) => props.theme.panelPrimaryColor};
            text-align: center;
        }
    }

    .item-tool-tps--slider {
        grid-column: auto / span 2;
        position: relative;

        input {
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
`;
TransparencyPopupStyled.displayName = "TransparencyPopupStyled";

export const ArrangePopupStyled = styled(ItemToolPopup)`
    border-radius: 4px;
    padding: 14px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    & > ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2px;
        row-gap: 4px;
        list-style: none;
    }

    &.layer-popup {
        & > ul {
            grid-template-columns: 1fr 1fr;
        }
    }
`;
ArrangePopupStyled.displayName = "ArrangePopupStyled";

export const ArrangeListItemStyled = styled.li`
    border-radius: 2px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 3px 2px;
    border-radius: 4px;

    > img {
        width: 24px;
        height: 24px;
        content: var(--arrange-icon-src);
    }

    > p {
        font: ${font.normalMicro_11};
        color: ${(props) => props.theme.propertyColor};
        position: relative;
        white-space: nowrap;
    }

    ${PopupToolShadeCSS}
    ${PopupIconHoverCSS}
`;
ArrangeListItemStyled.displayName = "ArrangeListItem";

export const ArrangeListHeading = styled.li`
    grid-column: 1 / span 3;

    &.layer-popup-item {
        grid-column: 1 / span 2;
    }

    :not(:first-of-type) {
        margin-top: 8px;
    }

    > p {
        font: ${font.boldMicro};
        color: ${(props) => props.theme.propertyColor};
        margin-left: 2px;
        margin-bottom: 4px;
    }
`;
ArrangeListHeading.displayName = "ArrangeListHeading";

export const TransformPopupStyled = styled(ItemToolPopup)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
TransformPopupStyled.displayName = "TransformPopupStyled";

export const TransformButton = styled.div`
    padding: 4px;
    background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.5px 1px ${(props) => props.theme.rgbaBlack};
    border: 1px solid ${(props) => props.theme.primaryBorderColor};
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        width: 20px;
        height: 20px;
    }

    &.is-flip-hor {
        > img {
            transform: scaleX(-1);
        }
    }

    &.is-flip-ver {
        > img {
            transform: scaleY(-1);
        }
    }

    ${PopupToolShadeCSS}
    ${PopupIconHoverCSS}
`;
TransformButton.displayName = "TransformButton";

export const TransformHeading = styled.p`
    grid-column: 1 / span 2;
    font: ${font.boldMicro};
    color: ${(props) => props.theme.propertyColor};

    &.rotate-heading {
        grid-column: 1 / span 3;
    }
`;
TransformHeading.displayName = "TransformHeading";

export const TransformFlipContainer = styled.div`
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
    column-gap: 8px;
`;
TransformFlipContainer.displayName = "TransformFlipContainer";

export const TransformRotateContainer = styled.div`
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 61px 1fr 1fr;
    row-gap: 8px;
    column-gap: 8px;

    ${NumberInputStyled} {
        position: relative;
        width: 54px;
        height: 28px;
        padding: 4px 8px;
        background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
        border: 1px solid ${(props) => props.theme.primaryBorderColor};
        border-radius: 4px;
        cursor: text;

        form {
            border-radius: 4px;
            overflow: hidden;
        }

        input {
            font: ${font.normalBase};
            color: ${(props) => props.theme.panelPrimaryColor};
            text-align: left;
        }

        ::after {
            content: " ";
            position: absolute;
            width: 1px;
            height: 16px;
            top: 50%;
            left: calc(100% + 8px);
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.primaryBorderColor};
        }
    }
`;
TransformRotateContainer.displayName = "TransformRotateContainer";

export const RadiusPopupStyled = styled(ItemToolPopup)`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    > p {
        color: ${(props) => props.theme.propertyColor};
        font: ${font.boldMicro};
    }

    > div {
        position: relative;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        row-gap: 2px;
        column-gap: 2px;

        ${NumberInputStyled} {
            height: 32px;
            padding: 8px;
            background: ${(props) => props.theme.outerLayerBgColor} 0% 0% no-repeat padding-box;
            cursor: text;
            color: ${(props) => props.theme.panelPrimaryColor};

            :nth-of-type(1) {
                border-radius: 4px 0px 0px 0px;
            }
            :nth-of-type(2) {
                border-radius: 0px 4px 0px 0px;
            }
            :nth-of-type(3) {
                border-radius: 0px 0px 0px 4px;
            }
            :nth-of-type(4) {
                border-radius: 0px 0px 4px 0px;
            }

            input {
                font: ${font.normalMini_12};
                color: ${(props) => props.theme.panelPrimaryColor};
                text-align: center;
            }
        }
    }
`;
RadiusPopupStyled.displayName = "BorderRadiusPopup";

export const RadiusLink = styled.div`
    position: absolute;

    width: 24px;
    height: 24px;
    background-color: ${(props) => props.theme.polarColor};
    border: 0.5px solid ${(props) => props.theme.primaryBorderColor};
    border-radius: 100%;
    box-shadow: 0px 0.5px 1px ${(props) => props.theme.rgbaBlack};
    cursor: pointer;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;

    &.linked {
        background-color: ${(props) => props.theme.itemtoolbarLinkColor};
    }

    > img {
        width: 14px;
        height: 6.661px;
    }
`;
RadiusLink.displayName = "RadiusLink";

export const Preview = styled.div`
  background-color: ${(props) => props.theme.previewBgColor};
  height: ${(props) => (props.enableBgRemoval ? "40px" : "0px")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: calc(100% + 0px);
  left: 0px;
  transition: height 0.3s ease;
`;