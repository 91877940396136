import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { ProgressLoaderContainer } from "./workspace-components";
import { font } from "../../constants/font";
import Typography from "../../common-components/Typography";
import vmTheme from "../../constants/theme";

const ProgressLoaderComponent = (props) => {
  const UPDATE_PROGRESS_EVERY = 1; // in percentage
  const prevProgress = useRef(0);
  const theme = useSelector((state)=> state.app.get('theme'));
  const progressToShow = useMemo(() => {
    let currentProgress = props.progressPercent;

    if (Number.isNaN(currentProgress)) {
      currentProgress = 0;
    }

    if (currentProgress - prevProgress.current >= UPDATE_PROGRESS_EVERY) {
      currentProgress = Math.ceil(currentProgress);
      prevProgress.current = currentProgress;
    }

    return prevProgress.current;
  }, [props.progressPercent]);

  const progressBarMemoized = useMemo(() => {
    const dimension = 120;
    const radius = 32;
    const circumference = radius * 2 * Math.PI;

    return (
      <ProgressLoaderContainer>
        <div>
          <div className="player-progress-percent">
            <Typography
              as="span"
              color={"black"}
              innerContent={progressToShow}
              font={font.normalBase}
            />
          </div>
          <svg
            className="player-progress-bar-wrapper"
            strokeLinejoin="round"
            width={`${dimension}`}
            height={`${dimension}`}
          >
            <circle
              className="player-progress-bar"
              stroke={vmTheme[theme].workspaceLoaderBg}
              strokeWidth="10"
              fill={vmTheme[theme].polarColor}
              strokeLinecap="round"
              r={`${radius}`}
              cx="40"
              cy="40"
              style={{
                strokeDasharray: `${circumference},${circumference}`,
                strokeDashoffset:
                  circumference - (progressToShow / 100) * circumference,
              }}
            />
          </svg>
        </div>
      </ProgressLoaderContainer>
    );
  }, [progressToShow, theme]);

  return progressBarMemoized;
};

export default ProgressLoaderComponent;
