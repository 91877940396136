/* eslint-disable no-console */
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import AppConfig from '../constants/config';
import ActionTypes from '../constants/action-types';
import SocketChannels from '../constants/socket-channels';
import { updateSocketStatus } from '../redux/actions/appUtils';
// import triggerSimpleAjax from '../helper/httpHelper';
// import userVersionHistory from '../helper/hooks/useVersionHistory';
// import { removeInvalidProjectKeys } from '../redux/actions/projectUtils';

const { S_UPDATE_PROJECT_DATA, S_PROJECT_TRACKER, S_ADD_USER } = SocketChannels;

let socket;
let timeout;
let isAppEdited;

const SOCKETS_NAME = [S_UPDATE_PROJECT_DATA, S_PROJECT_TRACKER, S_ADD_USER];

export const sendSocketMessage = (name, payload) => {
    if (socket) {
        socket.emit(name, {
            ...payload,
        });
        if (SOCKETS_NAME.indexOf(name) === -1 && !isAppEdited) {
            isAppEdited = true;
            socket.emit(S_PROJECT_TRACKER, {
                isEdited: true
            });
        }
    }
};

export const getSocketId = () => socket ? socket.id : "";

export default ({ children }) => {
    const dispatch = useDispatch();
    let processingCount = 0;
    const userDetails = useSelector((store) => store.userDetails);
    // const details = useSelector((store) => store.projectDetails);
    // const setVersion = userVersionHistory();
    const { projectId, userId, isEdited } = userDetails;
    isAppEdited = isEdited;
    if (!socket && projectId && userId) {
        socket = io.connect(AppConfig.SOCKET_URL, {
            transports: ['websocket'], query: `pr=${projectId}&ur=${userId}`
        });
        socket.on('connect', () => {

            // Connected True
            socket.on('disconnect', (reason) => {
                if (reason === 'io server disconnect') {
                    socket.connect();
                }
                // Connected false
            });

            sendSocketMessage(S_ADD_USER, {
                userId,
                userName: "Akbar",
                imageUrl: ``
            })

        });

        socket.on('connect_error', () => {
            // Connected false
        });

        socket.on('connect_timeout', () => {
            // Connected false
        });

        socket.on('error', (error) => {
            if (error === 'authentication error') {
                socket.disconnect();
                // Auth Error 
            }
            else if (error === 'Internal server error') {
                socket.disconnect();
                // Error 
            } else {
                // Connected false
            }
        });

        socket.on('reconnect', () => {
            // Connected false
        });

        socket.on('reconnect_attempt', () => {
            // Connected false
        });

        socket.on('reconnecting', () => {
            // Connected false
        });

        socket.on('reconnect_error', () => {
            // Connected false
        });


        socket.on('reconnect_failed', () => {
            // Connected false
        });

        socket.on("TEST_COMPLETE", () => {

        });

        socket.on("ACKNOWLEDGEMENT", () => {

        });
        socket.on("connectionComplete", () => {
            // debugger
        });

        socket.on("authenticated", () => {
            if (AppConfig.IS_LOCAL) {
                console.log(`I am Authenticated`)
            }
            dispatch(updateSocketStatus({ established: true }));
        });

        socket.on("unauthorised", () => {
            if (AppConfig.IS_LOCAL) {
                console.log(`I am not authorised`)
            }
        });

        socket.on("processing", () => {
            processingCount += 1;
            clearTimeout(timeout);
            if (processingCount > 10) {
                processingCount = 0;
                // triggerSimpleAjax(`${API_URL}${API.SAVE_PROJECT_REST}`, "POST", false, {
                //     projectId,
                //     data: removeInvalidProjectKeys(details).toJS(),
                // });
            } else {
                timeout = setTimeout(() => {
                    sendSocketMessage("SAVE_ME");
                }, 2000);
            }
        });

        socket.on("ACTION_COMPLETE", (action, socketId) => {
            // setVersion();
            processingCount = 0;
            dispatch({ type: ActionTypes.SAVE_DONE });
            if (socketId !== socket.id) {
                dispatch({
                    type: action.type,
                    payload: action.payload,
                    isCurrentUser: false,
                });
            }
        });
    }

    return children;
};