/* eslint-disable react/sort-comp, camelcase, one-var, prefer-template, jsx-a11y/no-static-element-interactions */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import { getWorkspaceItemSource } from "../../helper/URLHelper";
import { getMouseClientPosition } from "../../helper/TransformManagerHelper";
import { isVideoOnly } from "../timeline/timeline-helper";
import { CropSelectionBox } from "./tm-components";
import { cropImage } from "../../redux/actions/timelineUtils";
import { toggleCrop } from "../../redux/actions/appUtils";
import { STATIC_PATH } from "../../constants/config";
import { ItemToolConfirmation } from "../itemtoolbar/itemtoolbar-confirmation";

class CropComponent extends React.Component {
    constructor(props) {
        super(props);

        this.getInitialState = this.getInitialState.bind(this);
        this.getWorkspaceBounds = this.getWorkspaceBounds.bind(this);

        this.initiateMove = this.initiateMove.bind(this);
        this.moveSelection = this.moveSelection.bind(this);
        this.stopMove = this.stopMove.bind(this);

        this.initiateCropResize = this.initiateCropResize.bind(this);
        this.resizeCropSelection = this.resizeCropSelection.bind(this);
        this.stopCropResize = this.stopCropResize.bind(this);

        this.initiateResize = this.initiateResize.bind(this);
        this.resizeSelection = this.resizeSelection.bind(this);
        this.stopResize = this.stopResize.bind(this);

        this.getUnCroppedXY = this.getUnCroppedXY.bind(this);
        this.getMousePosition = this.getMousePosition.bind(this);
        this.getUnCroppedResizePosition = this.getUnCroppedResizePosition.bind(this);
        this.resetCrop = this.resetCrop.bind(this);

        this.handleCropClick = this.handleCropClick.bind(this);
        this.cancelCrop = this.cancelCrop.bind(this);
        this.handleKeyboardShortcuts = this.handleKeyboardShortcuts.bind(this);

        this.mouseStartPosition = { x: null, y: null, offsetX: null, offsetY: null };
        this.lastDown = null;
        this.moved = false;
        this.xLocked = false;
        this.lockedX = null;
        this.yLocked = false;
        this.lockedY = null;

        this.state = this.getInitialState();
    }

    getRotatedPoint = (px, py, x, y, angle) => {
        const angle_in_rad = angle * Math.PI / 180;
        return {
            x: (x - px) * Math.cos(angle_in_rad) - (y - py) * Math.sin(angle_in_rad) + px,
            y: (x - px) * Math.sin(angle_in_rad) + (y - py) * Math.cos(angle_in_rad) + py,
        }
    }

    getInitialState() {
        const stateToUpdate = {};
        const { workspaceWidth, workspaceHeight, cropItem } = this.props;

        let cropX, cropY, cropWidth, cropHeight;
        let selectionX, selectionY, selectionWidth, selectionHeight;

        if (cropItem) {
            if (cropItem.get("isCropped")) {
                selectionX = cropItem.get("x") + cropItem.getIn(["original", "x"]) * cropItem.get("width");
                selectionY = cropItem.get("y") + cropItem.getIn(["original", "y"]) * cropItem.get("height");
                selectionWidth = cropItem.getIn(["original", "width"]) * cropItem.get("width");
                selectionHeight = cropItem.getIn(["original", "height"]) * cropItem.get("height");

                cropWidth = cropItem.get("width");
                cropHeight = cropItem.get("height");
                cropX = cropItem.get("x") - selectionX;
                cropY = cropItem.get("y") - selectionY;

            } else {
                selectionX = cropItem.get("x");
                selectionY = cropItem.get("y");
                selectionWidth = cropItem.get("width");
                selectionHeight = cropItem.get("height");

                cropWidth = cropItem.get("width") * 0.75;
                cropHeight = cropItem.get("height") * 0.75;

                cropX = ((cropItem.get("width") / 2) - (cropWidth / 2));
                cropY = ((cropItem.get("height") / 2) - (cropHeight / 2));
            }

            let dX = 0, dY = 0;
            if (cropItem.get("flipPosition") === 1) {
                dX = (cropX - (selectionWidth - (cropX + cropWidth)));
            } else if (cropItem.get("flipPosition") === 2) {
                dY = (cropY - (selectionHeight - (cropY + cropHeight)));
            } else if (cropItem.get("flipPosition") === 3) {
                dX = (cropX - (selectionWidth - (cropX + cropWidth)));
                dY = (cropY - (selectionHeight - (cropY + cropHeight)));
            }

            const unCroppedX = dX;
            const unCroppedY = dY;
            const cropImageX = (-1 * cropX) + dX;
            const cropImageY = (-1 * cropY) + dY;
            const { src, thumb } = getWorkspaceItemSource({
                item: {
                    bgremoval: cropItem.get("bgremoval"),
                    isBlob: cropItem.get("isBlob"),
                    src: cropItem.get("src"),
                    stickerify: cropItem.get("stickerify"),
                    subType: cropItem.get("subType"),
                    thumb: cropItem.get("thumb"),
                    thumbnail: cropItem.get("thumbnail"),
                    type: cropItem.get("type"),
                },
                workspaceWidth,
                workspaceHeight,
            });
            let imgSrc = src;
            if (isVideoOnly(cropItem.get("type"), cropItem.get("subType"))) {
                imgSrc = thumb;
            }

            const angle = cropItem.get("angle");
            stateToUpdate.type = cropItem.get("type");
            stateToUpdate.cropSelectionBox = { x: selectionX, y: selectionY, width: selectionWidth, height: selectionHeight, angle };
            stateToUpdate.cropBox = { x: cropX, y: cropY, width: cropWidth, height: cropHeight };
            stateToUpdate.cropImage = { x: cropImageX, y: cropImageY, width: selectionWidth, height: selectionHeight };
            stateToUpdate.unCroppedBox = { x: unCroppedX, y: unCroppedY, width: selectionWidth, height: selectionHeight };
            stateToUpdate.image = imgSrc;
        }

        return stateToUpdate;
    }

    getWorkspaceBounds() {
        return this.props.workspaceRef.current.getBoundingClientRect();
    }

    getMousePosition(e) {
        let pos = { x: 0, y: 0 };
        try {
            const workspaceBounds = this.getWorkspaceBounds();
            const unrotatedPostion = getMouseClientPosition(e, fromJS({ x: -workspaceBounds.x, y: -workspaceBounds.y }));

            /* handle mouse position for rotated item
             * e.g. when image is rotated to 90deg and cropped horizontal movement should actually be vertical movement
             */
            const { angle } = this.state.cropSelectionBox;
            const px = workspaceBounds.width / 2;
            const py = workspaceBounds.height / 2;
            pos = this.getRotatedPoint(px, py, unrotatedPostion.get("x"), unrotatedPostion.get("y"), -angle);
        } catch (error) { }
        return pos;
    }

    initiateMove(e) {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.lastDown = e.timeStamp;
            this.moved = false;
            const mouse_position = this.getMousePosition(e);
            this.mouseStartPosition = Object.assign(this.mouseStartPosition, { x: mouse_position.x, y: mouse_position.y });
            const unCroppedBox = { ...this.state.unCroppedBox };
            this.setState({
                unCroppedBox: Object.assign(unCroppedBox, { transforming: true })
            });
            window.addEventListener('mousemove', this.moveSelection, false);
            window.addEventListener('mouseup', this.stopMove, false);
            window.addEventListener('touchmove', this.moveSelection, false);
            window.addEventListener('touchend', this.stopMove, false);
        } catch (error) { }
    }

    getUnCroppedXY(e) {
        const mouse_position = this.getMousePosition(e);
        let x = this.state.unCroppedBox.x - ((this.mouseStartPosition.x - mouse_position.x) / this.props.zoomFactor);
        let y = this.state.unCroppedBox.y - ((this.mouseStartPosition.y - mouse_position.y) / this.props.zoomFactor);

        if (x >= this.state.cropBox.x)
            x = this.state.cropBox.x;
        if (y >= this.state.cropBox.y)
            y = this.state.cropBox.y;
        if ((x + this.state.unCroppedBox.width) <= (this.state.cropBox.x + this.state.cropBox.width))
            x = (this.state.cropBox.x + this.state.cropBox.width) - this.state.unCroppedBox.width;
        if ((y + this.state.unCroppedBox.height) <= (this.state.cropBox.y + this.state.cropBox.height))
            y = (this.state.cropBox.y + this.state.cropBox.height) - this.state.unCroppedBox.height;

        if (x >= this.state.cropBox.x + this.state.cropBox.width) {
            x = this.state.cropBox.x + this.state.cropBox.width;
        }
        if ((x + this.state.unCroppedBox.width) <= this.state.cropBox.x) {
            x = this.state.cropBox.x - this.state.unCroppedBox.width;
        }
        if (y >= this.state.cropBox.y + this.state.cropBox.height) {
            y = this.state.cropBox.y + this.state.cropBox.height;
        }
        if ((y + this.state.unCroppedBox.height) <= this.state.cropBox.y) {
            y = this.state.cropBox.y - this.state.unCroppedBox.height;
        }

        return { x, y };
    }

    moveSelection(e) {
        try {
            if (this.lastDown + 10 < e.timeStamp) {
                if (!this.moved)
                    this.moved = true;
                e.preventDefault();
                const XY = this.getUnCroppedXY(e);
                this.unCroppedBoxElement.style.transform = "translate(" + XY.x * this.props.zoomFactor + "px, " + XY.y * this.props.zoomFactor + "px)";
                const flip = this.getFlipPosition();
                const angle = this.getAngle();
                this.cropImageElem.style.transform = "translate(" + (this.state.cropImage.x + (XY.x - this.state.unCroppedBox.x)) * this.props.zoomFactor + "px, " + (this.state.cropImage.y + (XY.y - this.state.unCroppedBox.y)) * this.props.zoomFactor + "px) rotate(" + angle + "deg)" + flip;
            }
        } catch (error) { }
    }

    stopMove(e) {
        try {
            e.preventDefault();
            e.stopPropagation();

            let { unCroppedBox, cropImage } = this.state;

            unCroppedBox = { ...unCroppedBox };
            cropImage = { ...cropImage };

            if (this.moved) {
                const XY = this.getUnCroppedXY(e);
                cropImage = Object.assign(
                    cropImage,
                    {
                        x: (cropImage.x + (XY.x - unCroppedBox.x)),
                        y: (cropImage.y + (XY.y - unCroppedBox.y))
                    }
                );
                unCroppedBox = Object.assign(unCroppedBox, { x: XY.x, y: XY.y });
            }
            unCroppedBox.transforming = false;

            this.setState({ unCroppedBox, cropImage });
        } catch (error) { }

        window.removeEventListener('mousemove', this.moveSelection, false);
        window.removeEventListener('mouseup', this.stopMove, false);
        window.removeEventListener('touchmove', this.moveSelection, false);
        window.removeEventListener('touchend', this.stopMove, false);
    }

    handleKeyboardShortcuts(option) {
        if (this.state.unCroppedBox !== null) {
            let { x, y } = this.state.unCroppedBox;

            const d = 1, sd = 10;
            switch (option) {
                case "RIGHT_ARROW":
                case "RIGHT_ARROW_AGAIN":
                    x += d;
                    y = this.state.unCroppedBox.y;
                    break;
                case "LEFT_ARROW":
                case "LEFT_ARROW_AGAIN":
                    x -= d;
                    y = this.state.unCroppedBox.y;

                    break;
                case "UP_ARROW":
                case "UP_ARROW_AGAIN":
                    y -= d;
                    x = this.state.unCroppedBox.x;

                    break;
                case "DOWN_ARROW":
                case "DOWN_ARROW_AGAIN":
                    y += d;
                    x = this.state.unCroppedBox.x;

                    break;
                case "SHIFT_RIGHT_ARROW":
                case "SHIFT_RIGHT_ARROW_AGAIN":
                    x += sd;
                    y = this.state.unCroppedBox.y;

                    break;
                case "SHIFT_LEFT_ARROW":
                case "SHIFT_LEFT_ARROW_AGAIN":
                    x -= sd;
                    y = this.state.unCroppedBox.y;
                    break;
                case "SHIFT_UP_ARROW":
                case "SHIFT_UP_ARROW_AGAIN":
                    y -= sd;
                    x = this.state.unCroppedBox.x;

                    break;
                case "SHIFT_DOWN_ARROW":
                case "SHIFT_DOWN_ARROW_AGAIN":
                    y += sd;
                    x = this.state.unCroppedBox.x;

                    break;
                default:
                    break;
            }

            if (x >= this.state.cropBox.x)
                x = this.state.cropBox.x;
            if (y >= this.state.cropBox.y)
                y = this.state.cropBox.y;
            if ((x + this.state.unCroppedBox.width) <= (this.state.cropBox.x + this.state.cropBox.width))
                x = (this.state.cropBox.x + this.state.cropBox.width) - this.state.unCroppedBox.width;
            if ((y + this.state.unCroppedBox.height) <= (this.state.cropBox.y + this.state.cropBox.height))
                y = (this.state.cropBox.y + this.state.cropBox.height) - this.state.unCroppedBox.height;

            if (x >= this.state.cropBox.x + this.state.cropBox.width) {
                x = this.state.cropBox.x + this.state.cropBox.width;
            }
            if ((x + this.state.unCroppedBox.width) <= this.state.cropBox.x) {
                x = this.state.cropBox.x - this.state.unCroppedBox.width;
            }
            if (y >= this.state.cropBox.y + this.state.cropBox.height) {
                y = this.state.cropBox.y + this.state.cropBox.height;
            }
            if ((y + this.state.unCroppedBox.height) <= this.state.cropBox.y) {
                y = this.state.cropBox.y - this.state.unCroppedBox.height;
            }

            if (!this.moved)
                this.moved = true;
            this.unCroppedBoxElement.style.transform = "translate(" + x * this.props.zoomFactor + "px, " + y * this.props.zoomFactor + "px)";
            const flip = this.getFlipPosition();
            const angle = this.getAngle();

            if ((this.state.cropImage.x + (x - this.state.unCroppedBox.x)) < this.state.cropBox.x) {
                this.cropImageElem.style.transform = "translate(" + (this.state.cropImage.x + (x - this.state.unCroppedBox.x)) * this.props.zoomFactor + "px, " + (this.state.cropImage.y + (y - this.state.unCroppedBox.y)) * this.props.zoomFactor + "px) rotate(" + angle + "deg)" + flip;
            }

            let { unCroppedBox, cropImage } = this.state;
            unCroppedBox = { ...unCroppedBox };
            cropImage = { ...cropImage };

            if (this.moved) {
                cropImage = Object.assign(
                    cropImage, {
                    x: (cropImage.x + (x - unCroppedBox.x)),
                    y: (cropImage.y + (y - unCroppedBox.y))
                }
                );
                unCroppedBox = Object.assign(unCroppedBox, { x, y });
            }
            unCroppedBox.transforming = false;

            this.setState({ unCroppedBox, cropImage });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.shortcutName !== prevProps.shortcutName) {
            this.handleKeyboardShortcuts(this.props.shortcutName)
        }
    }

    getCropBoxPosition(e) {
        const mouse_position = this.getMousePosition(e);

        const dx = (mouse_position.x - this.mouseStartPosition.x) / this.props.zoomFactor;
        const dy = (mouse_position.y - this.mouseStartPosition.y) / this.props.zoomFactor;
        let cropX, cropY, cropWidth, cropHeight;

        if (this.state.cropBox.handle === "top-left") {
            cropX = this.state.cropBox.x + dx;
            cropY = this.state.cropBox.y + dy;
            cropWidth = this.state.cropBox.width - dx;
            cropHeight = this.state.cropBox.height - dy;
        }
        else if (this.state.cropBox.handle === "top-right") {
            cropX = this.state.cropBox.x;
            cropY = this.state.cropBox.y + dy;
            cropWidth = this.state.cropBox.width + dx;
            cropHeight = this.state.cropBox.height - dy;
        }
        else if (this.state.cropBox.handle === "bottom-right") {
            cropX = this.state.cropBox.x;
            cropY = this.state.cropBox.y;
            cropWidth = this.state.cropBox.width + dx;
            cropHeight = this.state.cropBox.height + dy;
        }
        else if (this.state.cropBox.handle === "bottom-left") {
            cropX = this.state.cropBox.x + dx;
            cropY = this.state.cropBox.y;
            cropWidth = this.state.cropBox.width - dx;
            cropHeight = this.state.cropBox.height + dy;
        }
        else if (this.state.cropBox.handle === "left") {
            cropX = this.state.cropBox.x + dx;
            cropWidth = this.state.cropBox.width - dx;
            cropY = this.state.cropBox.y;
            cropHeight = this.state.cropBox.height;
        }
        else if (this.state.cropBox.handle === "top") {
            cropX = this.state.cropBox.x;
            cropY = this.state.cropBox.y + dy;
            cropWidth = this.state.cropBox.width;
            cropHeight = this.state.cropBox.height - dy;
        }
        else if (this.state.cropBox.handle === "right") {
            cropX = this.state.cropBox.x;
            cropY = this.state.cropBox.y;
            cropWidth = this.state.cropBox.width + dx;
            cropHeight = this.state.cropBox.height;
        }
        else if (this.state.cropBox.handle === "bottom") {
            cropX = this.state.cropBox.x;
            cropY = this.state.cropBox.y;
            cropWidth = this.state.cropBox.width;
            cropHeight = this.state.cropBox.height + dy;
        }

        if (cropX <= this.state.unCroppedBox.x) {
            cropWidth -= this.state.unCroppedBox.x - cropX;
            cropX = this.state.unCroppedBox.x;
        }
        if (cropY <= this.state.unCroppedBox.y) {
            cropHeight -= this.state.unCroppedBox.y - cropY;
            cropY = this.state.unCroppedBox.y;
        }

        if (((cropX + cropWidth - this.state.unCroppedBox.width) >= this.state.unCroppedBox.x)) {
            cropWidth -= (cropX + cropWidth - this.state.unCroppedBox.width) - this.state.unCroppedBox.x;
        }

        if ((cropY + cropHeight - this.state.unCroppedBox.height) >= this.state.unCroppedBox.y) {
            cropHeight -= (cropY + cropHeight - this.state.unCroppedBox.height) - this.state.unCroppedBox.y;
        }

        if (cropWidth < 50) {
            if (!this.xLocked)
                this.lockedX = cropX;
            cropX -= cropX - this.lockedX;
            this.xLocked = true;
            cropWidth = 50;
        } else if (this.xLocked) {
            this.xLocked = false;
        }

        if (cropHeight < 50) {
            if (!this.yLocked)
                this.lockedY = cropY;
            cropY -= cropY - this.lockedY;
            this.yLocked = true;
            cropHeight = 50;
        } else if (this.yLocked) {
            this.yLocked = false;
        }

        return { x: cropX, y: cropY, width: cropWidth, height: cropHeight };
    }

    initiateCropResize(e) {
        try {
            e.preventDefault();
            e.stopPropagation();

            const handle = e.target.getAttribute("data-handle");
            const mouse_position = this.getMousePosition(e);
            this.mouseStartPosition = Object.assign(this.mouseStartPosition, { x: mouse_position.x, y: mouse_position.y });
            const cropBox = { ...this.state.cropBox };
            this.setState({ cropBox: Object.assign(cropBox, { "handle": handle }) });

            window.addEventListener('mousemove', this.resizeCropSelection, false);
            window.addEventListener('mouseup', this.stopCropResize, false);
            window.addEventListener('touchmove', this.resizeCropSelection, false);
            window.addEventListener('touchend', this.stopCropResize, false);
        } catch (error) { }
    }

    resizeCropSelection(e) {
        try {
            e.preventDefault();
            e.stopPropagation();
            const crop = this.getCropBoxPosition(e);
            const { cropHandles, cropBoxElement } = this;
            cropBoxElement.style.transform = "translate(" + parseFloat(crop.x * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat(crop.y * this.props.zoomFactor).toFixed(2) + "px)";
            cropBoxElement.style.width = crop.width * this.props.zoomFactor + "px";
            cropBoxElement.style.height = crop.height * this.props.zoomFactor + "px";

            cropHandles.style.transform = "translate(" + parseFloat(crop.x * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat(crop.y * this.props.zoomFactor).toFixed(2) + "px)";
            cropHandles.style.width = crop.width * this.props.zoomFactor + "px";
            cropHandles.style.height = crop.height * this.props.zoomFactor + "px";

            const flip = this.getFlipPosition();
            const angle = this.getAngle();

            this.cropImageElem.style.transform = "translate(" + parseFloat((this.state.cropImage.x - (crop.x - this.state.cropBox.x)) * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat((this.state.cropImage.y - (crop.y - this.state.cropBox.y)) * this.props.zoomFactor).toFixed(2) + "px) rotate(" + angle + "deg)" + flip;
        } catch (error) { }
    }

    stopCropResize(e) {
        try {
            e.preventDefault();
            e.stopPropagation();
            const crop = this.getCropBoxPosition(e);

            let { cropImage, cropBox } = this.state;
            cropImage = { ...cropImage };
            cropBox = { ...cropBox };

            cropImage = Object.assign(
                cropImage,
                { x: (this.state.cropImage.x - (crop.x - this.state.cropBox.x)), y: (this.state.cropImage.y - (crop.y - this.state.cropBox.y)) }
            );
            cropBox = Object.assign(cropBox, { x: crop.x, y: crop.y, width: crop.width, height: crop.height });

            this.setState({ cropImage, cropBox });
        } catch (error) { }
        window.removeEventListener('mousemove', this.resizeCropSelection, false);
        window.removeEventListener('mouseup', this.stopCropResize, false);
        window.removeEventListener('touchmove', this.resizeCropSelection, false);
        window.removeEventListener('touchend', this.stopCropResize, false);
    }

    getUnCroppedResizePosition(e) {
        const mouse_position = this.getMousePosition(e);

        const mouseX = this.state.unCroppedBox.x - (this.mouseStartPosition.x - mouse_position.x);
        const mouseY = this.state.unCroppedBox.x - (this.mouseStartPosition.y - mouse_position.y);

        let unCropX, unCropY, unCropWidth, unCropHeight;

        if (this.state.unCroppedBox.handle === "top-left") {
            unCropWidth = this.state.unCroppedBox.width - (mouseX - this.state.unCroppedBox.x);
            unCropHeight = this.state.unCroppedBox.height - (mouseY - this.state.unCroppedBox.y);
            unCropX = mouseX;
            unCropY = mouseY - ((unCropWidth / this.state.unCroppedBox.width * this.state.unCroppedBox.height) - unCropHeight);
        } else if (this.state.unCroppedBox.handle === "top-right") {
            unCropWidth = mouseX - this.state.unCroppedBox.x + this.state.unCroppedBox.width;
            unCropHeight = this.state.unCroppedBox.height - (mouseY - this.state.unCroppedBox.y);
            unCropX = this.state.unCroppedBox.x;
            unCropY = mouseY - ((unCropWidth / this.state.unCroppedBox.width * this.state.unCroppedBox.height) - unCropHeight);
        } else if (this.state.unCroppedBox.handle === "bottom-right") {
            unCropWidth = mouseX - this.state.unCroppedBox.x + this.state.unCroppedBox.width;
            unCropHeight = mouseY - this.state.unCroppedBox.y + this.state.unCroppedBox.height;
            unCropX = this.state.unCroppedBox.x;
            unCropY = this.state.unCroppedBox.y;
        } else {
            unCropWidth = this.state.unCroppedBox.width - (mouseX - this.state.unCroppedBox.x);
            unCropHeight = mouseY - this.state.unCroppedBox.y;
            unCropX = mouseX;
            unCropY = this.state.unCroppedBox.y;
        }

        unCropHeight = unCropWidth / this.state.unCroppedBox.width * this.state.unCroppedBox.height;

        let dW, dH;
        if (this.state.unCroppedBox.handle === "top-left") {
            if (unCropX >= this.state.cropBox.x) {
                dW = this.state.cropBox.x - unCropX;
                unCropWidth -= dW;
                unCropHeight -= dW / this.state.unCroppedBox.width * this.state.unCroppedBox.height;
                unCropX = this.state.cropBox.x;
                unCropY = this.props.workspaceBounds.get("cy") - (unCropHeight - this.state.unCroppedBox.dB) - (this.props.workspaceBounds.get("cy") - this.props.workspaceHeight / 2);
            }
            if (unCropY >= this.state.cropBox.y) {
                dH = this.state.cropBox.y - unCropY;
                unCropHeight -= dH;
                unCropY = this.state.cropBox.y;
                unCropWidth -= dH / this.state.unCroppedBox.height * this.state.unCroppedBox.width;
                unCropX = this.props.workspaceBounds.get("cx") - (unCropWidth - this.state.unCroppedBox.dR) - (this.props.workspaceBounds.get("cx") - this.props.workspaceWidth / 2);
            }
        }
        else if (this.state.unCroppedBox.handle === "top-right") {
            if (this.state.cropBox.x - unCropX + this.state.cropBox.width >= unCropWidth) {
                dW = unCropWidth - (this.state.cropBox.x - unCropX + this.state.cropBox.width);
                unCropWidth -= dW;
                unCropHeight -= dW / this.state.unCroppedBox.width * this.state.unCroppedBox.height;
                unCropY = this.props.workspaceBounds.get("cy") - (unCropHeight - this.state.unCroppedBox.dB) - (this.props.workspaceBounds.get("cy") - this.props.workspaceHeight / 2);
            }
            if (unCropY >= this.state.cropBox.y) {
                dH = this.state.cropBox.y - unCropY;
                unCropHeight -= dH;
                unCropWidth -= dH / this.state.unCroppedBox.height * this.state.unCroppedBox.width;
                unCropY = this.state.cropBox.y;
            }
        }
        else if (this.state.unCroppedBox.handle === "bottom-right") {

            if (this.state.cropBox.x - unCropX + this.state.cropBox.width >= unCropWidth) {
                dW = unCropWidth - (this.state.cropBox.x - unCropX + this.state.cropBox.width);
                unCropWidth -= dW;
                unCropHeight -= dW / this.state.unCroppedBox.width * this.state.unCroppedBox.height;
            }
            if (this.state.cropBox.y - unCropY + this.state.cropBox.height >= unCropHeight) {
                dH = unCropHeight - (this.state.cropBox.y - unCropY + this.state.cropBox.height);
                unCropHeight -= dH;
                unCropWidth -= dH / this.state.unCroppedBox.height * this.state.unCroppedBox.width;
            }
        } else {
            if (unCropX >= this.state.cropBox.x) {
                dW = this.state.cropBox.x - unCropX;
                unCropWidth -= this.state.cropBox.x - unCropX;
                unCropX = this.state.cropBox.x;
                unCropHeight -= dW / this.state.unCroppedBox.width * this.state.unCroppedBox.height;
            }
            if (this.state.cropBox.y - unCropY + this.state.cropBox.height >= unCropHeight) {
                dH = unCropHeight - (this.state.cropBox.y - unCropY + this.state.cropBox.height);
                unCropHeight -= dH;
                unCropWidth -= dH / this.state.unCroppedBox.height * this.state.unCroppedBox.width;
                unCropX = this.props.workspaceBounds.get("cx") - (unCropWidth - this.state.unCroppedBox.dR) - (this.props.workspaceBounds.get("cx") - this.props.workspaceWidth / 2);
            }
        }

        return { x: unCropX, y: unCropY, width: unCropWidth, height: unCropHeight };
    }

    initiateResize(e) {
        try {
            e.preventDefault();
            e.stopPropagation();
            const handle = e.target.getAttribute("data-handle");
            const mouse_position = this.getMousePosition(e);
            this.mouseStartPosition = Object.assign(this.mouseStartPosition, { x: mouse_position.x, y: mouse_position.y });

            let { unCroppedBox } = this.state;
            unCroppedBox = { ...unCroppedBox };
            unCroppedBox = Object.assign(unCroppedBox, { "handle": handle, transforming: true, dR: (((this.props.workspaceBounds.get("cx") - this.props.workspaceWidth / 2) + this.state.unCroppedBox.x + this.state.unCroppedBox.width) - this.props.workspaceBounds.get("cx")), dB: (((this.props.workspaceBounds.get("cy") - this.props.workspaceHeight / 2) + this.state.unCroppedBox.y + this.state.unCroppedBox.height) - this.props.workspaceBounds.get("cy")) });

            this.setState({ unCroppedBox });
        } catch (error) { }

        window.addEventListener('mousemove', this.resizeSelection, false);
        window.addEventListener('mouseup', this.stopResize, false);
        window.addEventListener('touchmove', this.resizeSelection, false);
        window.addEventListener('touchend', this.stopResize, false);
    }

    resizeSelection(e) {
        try {
            e.preventDefault();
            e.stopPropagation();

            const unCrop = this.getUnCroppedResizePosition(e);

            const { unCroppedBoxElement } = this;
            unCroppedBoxElement.style.transform = "translate(" + parseFloat(unCrop.x * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat(unCrop.y * this.props.zoomFactor).toFixed(2) + "px)";
            unCroppedBoxElement.style.width = unCrop.width * this.props.zoomFactor + "px";
            unCroppedBoxElement.style.height = unCrop.height * this.props.zoomFactor + "px";
            const flip = this.getFlipPosition();
            const angle = this.getAngle();

            this.cropImageElem.style.transform = "translate(" + (this.state.cropImage.x + (unCrop.x - this.state.unCroppedBox.x)) * this.props.zoomFactor + "px, " + (this.state.cropImage.y + (unCrop.y - this.state.unCroppedBox.y)) * this.props.zoomFactor + "px) rotate(" + angle + "deg)" + flip;
            this.cropImageElem.style.width = (this.state.cropImage.width + (unCrop.width - this.state.unCroppedBox.width)) * this.props.zoomFactor + "px";
            this.cropImageElem.style.height = (this.state.cropImage.height + (unCrop.height - this.state.unCroppedBox.height)) * this.props.zoomFactor + "px";
        } catch (error) { }
    }

    stopResize(e) {
        try {
            e.preventDefault();
            e.stopPropagation();
            const unCrop = this.getUnCroppedResizePosition(e);

            let { unCroppedBox, cropImage } = this.state;
            unCroppedBox = { ...unCroppedBox };
            cropImage = { ...cropImage };
            cropImage = Object.assign(
                cropImage,
                {
                    x: (this.state.cropImage.x + (unCrop.x - this.state.unCroppedBox.x)),
                    y: (this.state.cropImage.y + (unCrop.y - this.state.unCroppedBox.y)),
                    width: (this.state.cropImage.width + (unCrop.width - this.state.unCroppedBox.width)),
                    height: (this.state.cropImage.height + (unCrop.height - this.state.unCroppedBox.height))
                }
            );
            unCroppedBox = Object.assign(unCroppedBox, { x: unCrop.x, y: unCrop.y, width: unCrop.width, height: unCrop.height, transforming: false });

            this.setState({ unCroppedBox, cropImage });
        } catch (error) { }

        window.removeEventListener('mousemove', this.resizeSelection, false);
        window.removeEventListener('mouseup', this.stopResize, false);
        window.removeEventListener('touchmove', this.resizeSelection, false);
        window.removeEventListener('touchend', this.stopResize, false);
    }

    handleCropClick() {
        try {
            const oX = this.state.cropSelectionBox.x + this.state.unCroppedBox.x;
            const oY = this.state.cropSelectionBox.y + this.state.unCroppedBox.y;
            const oWidth = this.state.unCroppedBox.width;
            const oHeight = this.state.unCroppedBox.height;

            const cX = (this.state.cropSelectionBox.x + this.state.cropBox.x);
            const cY = (this.state.cropSelectionBox.y + this.state.cropBox.y);
            const cWidth = this.state.cropBox.width;
            const cHeight = this.state.cropBox.height;
            const { cropItem } = this.props;

            let xDiff = ((oWidth - (cWidth + cX - oX))) - (oWidth - cWidth), yDiff = ((oHeight - (cHeight + cY - oY))) - (oHeight - cHeight);

            if (cropItem.get("flipPosition") === 1) {
                xDiff = 0 - ((oWidth - (cWidth + cX - oX)));
            } else if (cropItem.get("flipPosition") === 2) {
                yDiff = 0 - ((oHeight - (cHeight + cY - oY)));
            } else if (cropItem.get("flipPosition") === 3) {
                xDiff = 0 - ((oWidth - (cWidth + cX - oX)));
                yDiff = 0 - ((oHeight - (cHeight + cY - oY)));
            }

            const cropData = {
                x: cX,
                y: cY,
                width: cWidth,
                height: cHeight,
                original: { x: xDiff / cWidth, y: yDiff / cHeight, width: oWidth / cWidth, height: oHeight / cHeight },
                isCropped: true,
            };

            this.props.cropImage({
                container: "workspaceItems",
                selectedItem: this.props.cropItem.get("id"),
                toUpdate: cropData,
            });
        } catch (error) { }
    }

    cancelCrop() {
        try {
            this.props.toggleCrop();
        } catch (error) { }
    }

    getAngle() {
        return 0;
    }

    getFlipPosition() {
        if (this.props.cropItem && this.props.cropItem.get("flipPosition")) {
            const flipValue = this.props.cropItem.get("flipPosition");
            return (flipValue === 1) ? " scaleX(-1)" : (flipValue === 2) ? " scaleY(-1)" : (flipValue === 3) ? " scaleX(-1) scaleY(-1)" : "";
        }
        return "";
    }

    resetCrop() {
        try {
            const stateToUpdate = {};

            stateToUpdate.cropSelectionBox = { x: this.props.cropItem.get("x"), y: this.props.cropItem.get("y"), width: this.props.cropItem.get("width"), height: this.props.cropItem.get("height"), angle: this.props.cropItem.get("angle") };
            stateToUpdate.cropBox = { x: 0, y: 0, width: this.state.cropSelectionBox.width, height: this.state.cropSelectionBox.height, angle: this.props.cropItem.get("angle") };
            stateToUpdate.cropImage = { x: 0, y: 0, width: this.state.cropSelectionBox.width, height: this.state.cropSelectionBox.height, angle: this.props.cropItem.get("angle") };
            stateToUpdate.unCroppedBox = { x: 0, y: 0, width: this.state.cropSelectionBox.width, height: this.state.cropSelectionBox.height, angle: this.props.cropItem.get("angle") };

            this.setState(stateToUpdate);
        } catch (error) { }
    }

    render() {
        const flip = this.getFlipPosition();
        let selectionStyles = {};
        if (this.state.cropSelectionBox) {
            selectionStyles = { transform: "translate(" + parseFloat(this.state.cropSelectionBox.x * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat(this.state.cropSelectionBox.y * this.props.zoomFactor).toFixed(2) + "px) rotateZ(" + this.state.cropSelectionBox.angle + "deg)", width: parseFloat(this.state.cropSelectionBox.width * this.props.zoomFactor).toFixed(2) + "px", height: parseFloat(this.state.cropSelectionBox.height * this.props.zoomFactor).toFixed(2) + "px" };
        }

        const cropStyles = {
            transform: "translate(" + parseFloat(this.state.cropBox.x * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat(this.state.cropBox.y * this.props.zoomFactor).toFixed(2) + "px)",
            width: parseFloat(this.state.cropBox.width * this.props.zoomFactor).toFixed(2) + "px",
            height: parseFloat(this.state.cropBox.height * this.props.zoomFactor).toFixed(2) + "px"
        };
        const unCroppedStyle = {
            transform: "translate(" + parseFloat(this.state.unCroppedBox.x * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat(this.state.unCroppedBox.y * this.props.zoomFactor).toFixed(2) + "px)",
            width: parseFloat(this.state.unCroppedBox.width * this.props.zoomFactor).toFixed(2) + "px",
            height: parseFloat(this.state.unCroppedBox.height * this.props.zoomFactor).toFixed(2) + "px"
        };
        const cropImageStyle = {
            transform: "translate(" + parseFloat(this.state.cropImage.x * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat(this.state.cropImage.y * this.props.zoomFactor).toFixed(2) + "px) rotate(" + this.getAngle() + "deg)" + flip,
            width: parseFloat(this.state.unCroppedBox.width * this.props.zoomFactor).toFixed(2) + "px",
            height: parseFloat(this.state.unCroppedBox.height * this.props.zoomFactor).toFixed(2) + "px"
        };
        const unCroppedImageStyle = { transform: "rotate(" + this.getAngle() + "deg)" + flip };

        const filterSVGId = `scene-item-filtersvg-${this.props.cropItem.get("id")}`;
        const filterId = `${filterSVGId}-filter`;

        const cropImgElem = (
            <div className="crop-imgHolder">
                <img
                    alt="cropImageElem"
                    ref={(instance) => { this.cropImageElem = instance }}
                    src={this.state.image}
                    style={{ ...cropImageStyle, filter: `url(#${filterId})` }}
                />
            </div>
        );
        const unCropImgElem = (
            <div className="crop-imgHolder">
                <img
                    alt="unCroppedImageElem"
                    src={this.state.image}
                    style={{ ...unCroppedImageStyle, filter: `url(#${filterId})` }}
                />
            </div>
        );

        let selectionBoxClass = "cropSelectionBox";
        if (this.state.cropSelectionBox.angle >= 337.5 || this.state.cropSelectionBox.angle <= 22.5)
            selectionBoxClass += " e"
        else if (this.state.cropSelectionBox.angle >= 22.5 && this.state.cropSelectionBox.angle <= 67.5)
            selectionBoxClass += " se"
        else if (this.state.cropSelectionBox.angle >= 67.5 && this.state.cropSelectionBox.angle <= 112.5)
            selectionBoxClass += " s"
        else if (this.state.cropSelectionBox.angle >= 112.5 && this.state.cropSelectionBox.angle <= 157.5)
            selectionBoxClass += " sw"
        else if (this.state.cropSelectionBox.angle >= 157.5 && this.state.cropSelectionBox.angle <= 202.5)
            selectionBoxClass += " w"
        else if (this.state.cropSelectionBox.angle >= 202.5 && this.state.cropSelectionBox.angle <= 247.5)
            selectionBoxClass += " nw"
        else if (this.state.cropSelectionBox.angle >= 247.5 && this.state.cropSelectionBox.angle <= 292.5)
            selectionBoxClass += " n"
        else if (this.state.cropSelectionBox.angle >= 292.5 && this.state.cropSelectionBox.angle <= 337.5)
            selectionBoxClass += " ne"

        const cropSceneStyle = {
            left: 0,
            top: 0,
            position: "absolute",
            pointerEvents: "none",
            width: (this.props.workspaceWidth * this.props.zoomFactor).toFixed(2) + "px",
            height: (this.props.workspaceHeight * this.props.zoomFactor).toFixed(2) + "px",
        };

        return (
            <>
                <div className="crop-scene crop-tool-bar" style={cropSceneStyle}>
                    <CropSelectionBox className={selectionBoxClass} style={selectionStyles} data-html2canvas-ignore="true">
                        <div
                            className="unCroppedBox"
                            ref={(instance) => { this.unCroppedBoxElement = instance }}
                            style={unCroppedStyle}
                            onTouchStart={this.initiateMove}
                            onMouseDown={this.initiateMove}
                        >
                            {unCropImgElem}
                            <div className="uncrop-box-handles">
                                <span className="resize-handle top-left resize-handle-cursor" data-handle="top-left" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />
                                <span className="resize-handle top-right resize-handle-cursor" data-handle="top-right" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />
                                <span className="resize-handle bottom-right resize-handle-cursor" data-handle="bottom-right" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />
                                <span className="resize-handle bottom-left resize-handle-cursor" data-handle="bottom-left" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />
                            </div>
                        </div>
                        <div
                            className="cropBox"
                            ref={(instance) => { this.cropBoxElement = instance }}
                            style={cropStyles}
                            onTouchStart={this.initiateMove}
                            onMouseDown={this.initiateMove}
                        >
                            {cropImgElem}
                        </div>
                        <div ref={(instance) => { this.cropHandles = instance }} className="crop-handles" style={cropStyles}>
                            <img src={`${STATIC_PATH}transform-icons/crop-tl.svg`} alt="crop top left" className="crop-handle top-left resize-handle-cursor" data-handle="top-left" onMouseDown={this.initiateCropResize} onTouchStart={this.initiateCropResize} />
                            <img src={`${STATIC_PATH}transform-icons/crop-t.svg`} alt="crop top" className="crop-handle top resize-handle-cursor" data-handle="top" onMouseDown={this.initiateCropResize} onTouchStart={this.initiateCropResize} />
                            <img src={`${STATIC_PATH}transform-icons/crop-tl.svg`} alt="crop top right" className="crop-handle top-right resize-handle-cursor" data-handle="top-right" onMouseDown={this.initiateCropResize} onTouchStart={this.initiateCropResize} />
                            <img src={`${STATIC_PATH}transform-icons/crop-t.svg`} alt="crop left" className="crop-handle left resize-handle-cursor" data-handle="left" onMouseDown={this.initiateCropResize} onTouchStart={this.initiateCropResize} />
                            <img src={`${STATIC_PATH}transform-icons/crop-tl.svg`} alt="crop bottom left" className="crop-handle bottom-left resize-handle-cursor" data-handle="bottom-left" onMouseDown={this.initiateCropResize} onTouchStart={this.initiateCropResize} />
                            <img src={`${STATIC_PATH}transform-icons/crop-t.svg`} alt="crop bottom" className="crop-handle bottom resize-handle-cursor" data-handle="bottom" onMouseDown={this.initiateCropResize} onTouchStart={this.initiateCropResize} />
                            <img src={`${STATIC_PATH}transform-icons/crop-tl.svg`} alt="crop bottom right" className="crop-handle bottom-right resize-handle-cursor" data-handle="bottom-right" onMouseDown={this.initiateCropResize} onTouchStart={this.initiateCropResize} />
                            <img src={`${STATIC_PATH}transform-icons/crop-t.svg`} alt="crop right" className="crop-handle right resize-handle-cursor" data-handle="right" onMouseDown={this.initiateCropResize} onTouchStart={this.initiateCropResize} />
                        </div>
                    </CropSelectionBox>
                </div>
                <ItemToolConfirmation
                    workspaceStage={this.props.workspaceStage}
                    onApply={this.handleCropClick}
                    onCancel={this.cancelCrop}
                />
            </>
        );
    }
}

CropComponent.propTypes = {
    workspaceStage: PropTypes.object,
    workspaceWidth: PropTypes.number,
    workspaceHeight: PropTypes.number,
    cropItem: PropTypes.object,
    workspaceRef: PropTypes.object,
    zoomFactor: PropTypes.number,
    shortcutName: PropTypes.string,
    workspaceBounds: PropTypes.object,
    cropImage: PropTypes.func,
    toggleCrop: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        workspaceStage: state.app.get("workspaceStage"),
        workspaceWidth: state.projectDetails.get("width"),
        workspaceHeight: state.projectDetails.get("height"),
        cropItem: state.projectDetails.getIn(["workspaceItems", state.app.getIn(["selectedItems", 0])]),
        zoomFactor: state.app.get("zoomFactor"),
        workspaceBounds: state.app.get("workspaceBounds"),
    };
}

const mapDispatchToProps = (dispatch) => ({
    cropImage: (data) => dispatch(cropImage(data)),
    toggleCrop: () => dispatch(toggleCrop()),
});

const Crop = connect(mapStateToProps, mapDispatchToProps)(CropComponent);

export default Crop;
