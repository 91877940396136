import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { AccessWrap, ImageWrap } from "./animo-component";
import openUrl from "../helper/openUrl";
import AppConfig from "../constants/config";
import Action from "../common-components/Action";
import vmTheme from "../constants/theme";
import useCopy from "../helper/hooks/useCopy";

const UserAccess = () => {
  const { projectId, permission } = useSelector((state) => state.userDetails);
  const theme = useSelector((state) => state.app.get("theme"));
  const copy = useCopy();
  const copyText = useCallback(() => {
    copy(`${AppConfig.SITE_URL}preview/${projectId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const openEditor = useCallback(() => {
    openUrl(`${AppConfig.SITE_URL}editproject/${projectId}`);
  }, [projectId]);

  return (
    <AccessWrap>
      {permission ? (
        <Action
          isAnchor
          text={"Edit Project"}
          className="openProject"
          fontColor={vmTheme[theme].polarColor}
          onClick={openEditor}
          marginTop="0px"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g
              id="Group_78586"
              data-name="Group 78586"
              transform="translate(-661 -114)"
            >
              <rect
                id="Rectangle_191837"
                data-name="Rectangle 191837"
                width="24"
                height="24"
                transform="translate(661 114)"
                fill="#fff"
                opacity="0"
              />
              <path
                id="Path_88433"
                data-name="Path 88433"
                d="M4.829.441A1,1,0,0,0,3.171,1.559L7.963,8.673,5.637,12.351a4,4,0,1,0,1.57,1.259l1.977-3.125,1.884,2.8A3.987,3.987,0,1,0,12.751,12.2L9.132,6.826h0ZM2,16a2,2,0,1,1,2,2A2,2,0,0,1,2,16Zm10,0a2,2,0,1,1,2,2A2,2,0,0,1,12,16Zm-.48-9.21,3.325-5.256A1,1,0,0,0,13.155.467L10.3,4.98Z"
                transform="translate(683 117.001) rotate(90)"
                fill="#fff"
              />
            </g>
          </svg>
        </Action>
      ) : null}
      <Action
        isAnchor
        text={"Copy Link"}
        className="copyLink"
        fontColor={vmTheme[theme].paymentCardTextColor}
        onClick={copyText}
      />
      <ImageWrap />
    </AccessWrap>
  );
};

export default UserAccess;
