import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { font } from '../constants/font';
import { STATIC_PATH } from '../constants/config';
import Typography from './Typography';
import vmTheme from '../constants/theme';
import useMobileDetection from '../helper/hooks/useMobileDetection';

const DisclaimerMessage = styled.div`
  position: absolute;
  height: ${(props) => (props.isShow ? "40px" : "0px")};
  background: ${(props) => props.theme.ItemPreviewBgColor};
  width: 100%;
  margin: 0;
  padding: 0px 16px;
  top: calc(100% + 0px);
  left: 0;
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.isShow ? "1" : "0")};
  justify-content: start;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease;
  z-index: 1000;

  @media only screen and (max-width: 767px) {
    top: 48px;
    position: fixed;
    padding: 0px 12px;
    height: ${(props) => (props.isShow ? "54px" : "0px")};
    opacity: ${(props) => (props.isShow ? "1" : "0")};
  }

  .disclaimer-message {
    position: relative;
    padding-left: 32px;
    &::before {
      content: url(${STATIC_PATH}xl-info.svg);
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0px;
      top: 0.4rem;
    }

    @media only screen and (max-width: 767px) {
      padding: 5px 30px;
    }
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: 100%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 1rem;
`;

const DisclaimerBanner = ({ content, isShow, onClick }) => {
  const theme = useSelector((state) => state.app.get("theme"));
  const isMobile = useMobileDetection();

  // Create a portal container only if mobile
  const portalContainer = useMemo(() => {
    if (isMobile) {
      const container = document.createElement("div");
      container.setAttribute("id", "disclaimer-banner-portal");
      document.body.appendChild(container);
      return container;
    }
    return null;
  }, [isMobile]);

  useEffect(() => {
    return () => {
      if (portalContainer && document.body.contains(portalContainer)) {
        document.body.removeChild(portalContainer);
      }
    };
  }, [portalContainer]);

  const bannerContent = (
    <DisclaimerMessage isShow={isShow}>
      <Typography
        content={content}
        font={font.normalBase}
        color={vmTheme[theme].panelPrimaryColor}
        padding="12px 0px"
        enableTrim={false}
        className="disclaimer-message"
      />
      <Image src={`${STATIC_PATH}close-black.svg`} alt="close-black" onClick={onClick} />
    </DisclaimerMessage>
  );

  return isMobile && portalContainer
    ? ReactDOM.createPortal(bannerContent, portalContainer)
    : bannerContent;
};

DisclaimerBanner.propTypes = {
  content: PropTypes.string,
  isShow: PropTypes.bool,
  onClick: PropTypes.func
};

export default DisclaimerBanner;
