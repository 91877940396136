/// <reference path="./timeline-types.js" />
/* eslint-disable operator-assignment, no-restricted-syntax */

import { RULER_OPTIONS, SLIDER_TYPES } from "./timeline-constants";
import {
  addDefaultSnapPoints,
  calculateStepperSize,
  calculateTimelinePlot,
  getSliders,
  getTracks,
  removeDefaultSnapPoints,
  secondsToStep,
  updatePlayheadSeek,
} from "./timeline-helper";

/**
 * @param {GetTimelineTileInitialStateParams} params
 * @returns {TimelineTileState}
 */
export const getTimelineTileInitialState = (params) => {
  const { project, playhead, sideBarMenuWidth, timelineMode, interval } = params;
  const timelinePlot = calculateTimelinePlot({ sideBarMenuWidth });
  const stepper = calculateStepperSize({
    duration: project.get("duration"),
    timelinePlot,
    timeScale: RULER_OPTIONS.timeScale.default,
    interval,
  });
  const tracks = getTracks({ project, stepper, timelineMode, });
  const { sliders, timelineSnapPoints } = getSliders({
    playhead,
    project,
    stepper,
    tracks,
    timelineMode,
  });

  return {
    timelinePlot,
    stepper,
    tracks,
    sliders,
    timelineSnapPoints,
    matchingSnapPoints: {},
  };
};

export const TIMELINE_TILE_ACTIONS = Object.freeze({
  MERGE_STATE: "MERGE_STATE",
  SET_TIMELINE_PLOT: "SET_TIMELINE_PLOT",
  SET_STEPPER: "SET_STEPPER",
  SET_TRACKS: "SET_TRACKS",
  SET_SLIDERS: "SET_SLIDERS",
  SET_TIMELINE_SNAPPOINTS: "SET_TIMELINE_SNAPPOINTS",
  SET_MATCHING_SNAPPOINTS: "SET_MATCHING_SNAPPOINTS",
  SEEK_PLAYHEAD_CHANGED: "SEEK_PLAYHEAD_CHANGED",
});

/**
 * @param {TimelineTileState} state
 * @param {TimelineTileAction} action
 */
const timelineTileReducer = (state, action) => {
  let newState = state;

  if (action.type === TIMELINE_TILE_ACTIONS.MERGE_STATE) {
    for (const key of Reflect.ownKeys(action.payload)) {
      if (newState[key] !== action.payload[key]) {
        newState = { ...newState };
        newState[key] = action.payload[key];
      }
    }
  } else if (
    action.type === TIMELINE_TILE_ACTIONS.SET_TIMELINE_PLOT &&
    newState.timelinePlot !== action.payload
  ) {
    newState = { ...newState };
    newState.timelinePlot = action.payload;
  } else if (
    action.type === TIMELINE_TILE_ACTIONS.SET_STEPPER &&
    newState.stepper !== action.payload
  ) {
    newState = { ...newState };
    newState.stepper = action.payload;
  } else if (
    action.type === TIMELINE_TILE_ACTIONS.SET_TRACKS &&
    newState.tracks !== action.payload
  ) {
    newState = { ...newState };
    newState.tracks = action.payload;
  } else if (
    action.type === TIMELINE_TILE_ACTIONS.SET_SLIDERS &&
    newState.sliders !== action.payload
  ) {
    newState = { ...newState };
    newState.sliders = action.payload;
  } else if (
    action.type === TIMELINE_TILE_ACTIONS.SET_TIMELINE_SNAPPOINTS &&
    newState.timelineSnapPoints !== action.payload
  ) {
    newState = { ...newState };
    newState.timelineSnapPoints = action.payload;
  } else if (
    action.type === TIMELINE_TILE_ACTIONS.SET_MATCHING_SNAPPOINTS &&
    newState.matchingSnapPoints !== action.payload
  ) {
    newState = { ...newState };
    newState.matchingSnapPoints = action.payload;
  } else if (action.type === TIMELINE_TILE_ACTIONS.SEEK_PLAYHEAD_CHANGED) {
    /** @type {Sliders | undefined} */
    let updatedSliders;
    /** @type {TimelineSnapPoints | undefined} */
    let updatedTimelineSnapPoints;

    if (action.payload.isReset) {
      const playheadId = SLIDER_TYPES.PLAYHEAD_THUMB;
      const playheadSlider = newState.sliders[playheadId];
      if (playheadSlider.isSeeking) {
        const playheadThumbStep = secondsToStep({ seconds: action.payload.playhead, stepper: newState.stepper });
        const newPlayheadPosition = {
          ...playheadSlider.enterStart.position,
          x: playheadThumbStep.x,
          step: playheadThumbStep.step,
        };

        const newPlayheadSlider = {
          ...playheadSlider,
          isSeeking: false,
          enterStart: {
            ...playheadSlider.enterStart,
            position: newPlayheadPosition,
            trackPosition: undefined,
          },
        };
        updatedSliders = {
          ...newState.sliders,
          [playheadId]: newPlayheadSlider,
        };

        updatedTimelineSnapPoints = removeDefaultSnapPoints({
          timelineSnapPoints: newState.timelineSnapPoints,
        });
        addDefaultSnapPoints({
          // it is okay to use mutation logic here as we have removed whole trace of default sliders
          mutableTimelineSnapPoints: updatedTimelineSnapPoints,
          sliders: updatedSliders,
        });
      }
    } else {
      updatedSliders = updatePlayheadSeek({
        seekPlayhead: action.payload.time,
        sliders: newState.sliders,
        stepper: newState.stepper,
      });
    }

    if (updatedSliders && newState.sliders !== updatedSliders) {
      newState = {
        ...newState,
        sliders: updatedSliders,
      };
    }

    if (updatedTimelineSnapPoints && newState.timelineSnapPoints !== updatedTimelineSnapPoints) {
      newState = {
        ...newState,
        timelineSnapPoints: updatedTimelineSnapPoints,
      };
    }
  }

  return newState;
};

export default timelineTileReducer;
