import { fromJS } from "immutable";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DroppableAreas from "./frame-droppableareas";
import { getFrameSvgId } from "./frame-helper";
import { SVG } from "./frame-components";
import { selectFrameClip, setPropertyWindow } from "../../redux/actions/appUtils";

function ReturnSVG(props) {
  return (
    <div id={props.id}>
      <SVG width={props.width} height={props.height}>
        <path d={props.d} fill={props.fill} stroke={props.stroke} />
      </SVG>
    </div>
  );
}
ReturnSVG.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  d: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

function FramesComponent(props) {
  const frameContainerRef = useRef(null);
  const canAllowClick = useRef(false);

  const {
    id,
    grouped,
    groupId,
    frameItem,
    zoomFactor,
    propertyWindow,
    isFrameVisible,
    transformStatus,
    isPlay,
    isPlayAll,
    playhead,
  } = props;

  const {
    // dispatch
    selectFrameClip,
    setPropertyWindow,
  } = props;

  useEffect(() => {
    if (transformStatus.get("transforming")) {
      canAllowClick.current = false;
    }
  }, [transformStatus]);

  function renderDroppables() {
    const frameClipDetails = frameItem.get("clipDetails");

    const frameDetails = [];
    frameClipDetails.entrySeq().forEach(([key, item]) => {
      frameDetails.push(
        <DroppableAreas
          key={key}
          Id={id}
          clip_id={key}
          groupId={groupId}
          isGrouped={grouped}
          droppableItemData={fromJS(item)}
          frameItemData={fromJS(frameItem)}
          isPlay={isPlay}
          isPlayAll={isPlayAll}
          isFrameVisible={isFrameVisible}
          playhead={playhead}
        />
      );
    });

    return frameDetails;
  }

  function frameContainerStyles() {
    const defaultWidth = frameItem.get("defaultWidth");
    const defaultHeight = frameItem.get("defaultHeight");
    const frameWidth = frameItem.get("width") * zoomFactor;
    const frameHeight = frameItem.get("height") * zoomFactor;

    return {
      width: `${defaultWidth}px`,
      height: `${defaultHeight}px`,
      transform: `scaleX(${frameWidth / defaultWidth}) scaleY(${
        frameHeight / defaultHeight
      })`,
      transformOrigin: "0 0",
    };
  }

  function renderSVGs() {
    return frameItem.get("d").map((item, key) => {
      return (
        <ReturnSVG
          key={key} // eslint-disable-line react/no-array-index-key
          id={getFrameSvgId(id, key)} // for color pallete
          d={item.get("path")}
          fill={item.get("fill")}
          stroke={item.get("stroke")}
          width={item.get("width")}
          height={item.get("height")}
        />
      );
    });
  }

  const handleMouseDown = () => {
    canAllowClick.current = true;
  };

  const handleMouseUp = (e) => {
    if (canAllowClick.current && e.target === frameContainerRef.current) {
      if (
        propertyWindow.get("isOpened") &&
        propertyWindow.get("component") === "imageSetting"
      ) {
        // close prop window
        setPropertyWindow({ component: "", type: "", isOpened: false });
      }

      const frameClipDetails = frameItem.get("clipDetails");
      frameClipDetails.entrySeq().forEach(([key]) => {
        selectFrameClip({
          id,
          groupId: "",
          clipId: key,
        });
      });
    }
    canAllowClick.current = false;
  };

  return (
    <div
      className={`inner-frame ${id + 1}`}
      style={frameContainerStyles()}
      ref={frameContainerRef}
      onPointerUp={handleMouseUp}
      onPointerDown={handleMouseDown}
    >
      {renderSVGs()}
      {renderDroppables()}
    </div>
  );
}

FramesComponent.propTypes = {
  zoomFactor: PropTypes.number,
  propertyWindow: PropTypes.object,
  transformStatus: PropTypes.object,
  selectFrameClip: PropTypes.func,
  setPropertyWindow: PropTypes.func,
  id: PropTypes.string,
  grouped: PropTypes.bool,
  groupId: PropTypes.string,
  frameItem: PropTypes.object,
  isFrameVisible: PropTypes.bool,
  isPlay: PropTypes.bool,
  isPlayAll: PropTypes.bool,
  playhead: PropTypes.number,
};

const mapStateToProps = (state) => ({
  zoomFactor: state.app.get("zoomFactor"),
  propertyWindow: state.app.get("propertyWindow"),
  transformStatus: state.app.get("transformStatus"),
  playhead: state.app.get("playhead"),
});

const mapDispatchToProps = (dispatch) => ({
  selectFrameClip: (data) => dispatch(selectFrameClip(data)),
  setPropertyWindow: (data) => dispatch(setPropertyWindow(data)),
});

const Frames = connect(mapStateToProps, mapDispatchToProps)(FramesComponent);

export default Frames;
