/// <reference path="../timeline-types.js" />
/* eslint-disable operator-assignment, no-restricted-syntax */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  RULER_OPTIONS,
  SLIDER_TYPES,
  TRACK_TYPES,
} from "../timeline-constants";
import { MatchingSnapPointType, SliderType } from "../timeline-proptypes";
import vmTheme from "../../../constants/theme";

const SnapLineSVG = (props) => {
  const { matchingSnapPoints, sliders, tracks, rulerWidth, trackListHeight } =
    props;
    const theme = useSelector((state) => state.app.get('theme'));

  const snaplines = useMemo(() => {
    const lines = [];

    for (const snapPointId of Reflect.ownKeys(matchingSnapPoints)) {
      /** @type {MatchingSnapPoint} */
      const matchingSnapPoint = matchingSnapPoints[snapPointId];
      const { sliderId, snapPoint, thumbId } = matchingSnapPoint;

      /** @type {Plot | undefined} */
      let snapPointPlot;

      if (sliders[sliderId] && sliders[sliderId][thumbId]) {
        const slider = sliders[sliderId];
        /** @type {Thumb} */
        const thumb = slider[thumbId];
        let { position } = thumb;
        if (thumb.trackPosition) {
          position = thumb.trackPosition;
        }

        const { trackIndex, trackType } = position;

        if (snapPoint.trackType === TRACK_TYPES.RULER) {
          if (snapPoint.sliderId !== SLIDER_TYPES.PLAYHEAD_THUMB) {
            snapPointPlot = {
              x: snapPoint.x,
              y: snapPoint.y,
              width: 0,
              height: trackListHeight,
            };
          }
        } else if (tracks[trackType] && tracks[trackType][trackIndex]) {
          /** @type {TrackDetail} */
          const currentTrack = tracks[trackType][trackIndex];
          const currentTrackPlot = currentTrack.plot;
          const trackPlots = [currentTrackPlot];

          let { aligningSnapPoints } = matchingSnapPoint;
          if (!aligningSnapPoints || aligningSnapPoints.length === 0) {
            aligningSnapPoints = [snapPoint];
          }

          aligningSnapPoints.forEach((snapPoint) => {
            if (
              tracks[snapPoint.trackType] &&
              tracks[snapPoint.trackType][snapPoint.trackIndex]
            ) {
              /** @type {TrackDetail} */
              const snapPointTrack =
                tracks[snapPoint.trackType][snapPoint.trackIndex];
              const snapPointTrackPlot = snapPointTrack.plot;

              trackPlots.push(snapPointTrackPlot);
            }
          });

          snapPointPlot = trackPlots.reduce((plot, trackPlot) => {
            if (plot === undefined) {
              return {
                x: snapPoint.x,
                y: trackPlot.y,
                width: 0,
                height: trackPlot.height,
              };
            }

            let y1 = plot.y;
            let y2 = plot.y + plot.height;

            if (trackPlot.y < y1) {
              y1 = trackPlot.y;
            }
            if (trackPlot.y + trackPlot.height > y2) {
              y2 = trackPlot.y + trackPlot.height;
            }

            plot.y = y1;
            plot.height = y2 - y1;

            return plot;
          }, undefined);
        }
      }

      if (snapPointPlot) {
        lines.push(
          <line
            key={snapPointId}
            x1={snapPointPlot.x}
            y1={snapPointPlot.y}
            x2={snapPointPlot.x}
            y2={snapPointPlot.y + snapPointPlot.height}
            stroke={vmTheme[theme].SNAP_LINE_COLOR}
            strokeWidth={RULER_OPTIONS.snapOptions.strokeWidth}
            strokeDasharray={RULER_OPTIONS.snapOptions.strokeDashArray}
          />
        );
      }
    }

    return lines;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingSnapPoints, sliders, tracks, trackListHeight]);

  return (
    <svg viewBox={`0 0 ${rulerWidth} ${trackListHeight}`}>{snaplines}</svg>
  );
};
SnapLineSVG.propTypes = {
  matchingSnapPoints: PropTypes.objectOf(MatchingSnapPointType).isRequired,
  sliders: PropTypes.objectOf(SliderType).isRequired,
  tracks: PropTypes.object.isRequired,
  rulerWidth: PropTypes.number.isRequired,
  trackListHeight: PropTypes.number.isRequired,
};
SnapLineSVG.displayName = "SnapLineSVG";

export default SnapLineSVG;
