
/* eslint-disable react/no-unknown-property, jsx-a11y/no-static-element-interactions, no-restricted-properties */
/* eslint-disable operator-assignment, prefer-destructuring, prefer-template, no-restricted-syntax */
/* eslint-disable camelcase, react/sort-comp, react/no-unused-state, react/prop-types */
/* eslint-disable object-shorthand, react/no-unused-class-component-methods, prefer-const */
/* eslint-disable one-var, no-unused-vars, max-len, prefer-exponentiation-operator */

/** @todo move all touchstart touchend handlers to pointerdown handlers. Reason: touchstart will lead to hard to reproduce bugs */

import { Map, OrderedMap, fromJS } from "immutable";
import React from "react";
import { connect } from "react-redux";
import { ITEM_CONFIG, STATIC_PATH } from "../../constants/config";
import { getMouseClientPosition, getRadiusClipPath, isMouseOn } from "../../helper/TransformManagerHelper";
import { isImageOnly, isVideoOnly } from "../timeline/timeline-helper";
import { getPath } from "../callout/ShapeHelper";
import { moveSubtitle, moveUpdate, resizeUpdate, rotationUpdate, updateItem, updateObjectById } from "../../redux/actions/timelineUtils";
import { toggleCrop, updateTextStatus, updateTransformStatus } from "../../redux/actions/appUtils";
import { SelectionBox } from "./tm-components";
import getElement, { getElementAll } from "../../helper/getElement";
import { createMemoizedFunction } from "../../helper/createMemoizedFunction";
import { singleClipFrameToFrame } from "../frame/frame-helper";

class TransformManagerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.selectionBox = {};
        this.keyPressUpdate = {};
        this.initialSelectionBox = {};
        this.selectedItems = Map({});
        this.groupedItems = Map({});
        this.mouseStartPosition = {};
        this.currentItemContainer = "workspaceItems";
        this.initiateMove = this.initiateMove.bind(this);
        this.moveSelection = this.moveSelection.bind(this);
        this.stopMove = this.stopMove.bind(this);
        this.moveItem = this.moveItem.bind(this);
        this.initiateResize = this.initiateResize.bind(this);
        this.resizeSelection = this.resizeSelection.bind(this);
        this.stopResize = this.stopResize.bind(this);
        this.resizeTextContainer = this.resizeTextContainer.bind(this);
        this.getSnappedResizePosition = this.getSnappedResizePosition.bind(this);
        this.getResizePosition = this.getResizePosition.bind(this);
        this.initiateRotate = this.initiateRotate.bind(this);
        this.rotateSelection = this.rotateSelection.bind(this);
        this.stopRotate = this.stopRotate.bind(this);
        this.getTextBounds = this.getTextBounds.bind(this);
        this.handleShortcuts = this.handleShortcuts.bind(this);
        this.moveControlPoint = this.moveControlPoint.bind(this);
        this.controlShape = this.controlShape.bind(this);
        this.tabChanged = this.tabChanged.bind(this);
        this.getMousePosition = this.getMousePosition.bind(this);
        this.getWorkspaceBounds = this.getWorkspaceBounds.bind(this);
        this.hideSnaplines = this.hideSnaplines.bind(this);
        this.applyRadiusClipPath = this.applyRadiusClipPath.bind(this);
        this.updateSubtitlePosition = this.updateSubtitlePosition.bind(this);
        this.isPlayheadInSelectionTimeRange = this.isPlayheadInSelectionTimeRange.bind(this);
        this.anchorScales = null;
        this.anchorAngles = null;
        this.restrictedSelectionBox = fromJS({});
        this.restrictBounds = false;
        this.lockRotate = false;
        this.state = { prevSelectedObjects: {} };
        this.multimoveId = null;
        this.snapPoints = [];
        this.xSnapPoints = [];
        this.ySnapPoints = [];
        this.widthLocked = false;
        this.lockedWidth = null;
        this.heightLocked = false;
        this.lockedHeight = null;
        this.cornerResize = this.props.cornerResize;
        this.appUrl = STATIC_PATH;
        this.transformingTimer = null;
        this.transformTimeout = 500;
        this.rotateCount = 0;
        this.SHORT_HANDLE_THRESHOLD = 14;
        this.getSelectedItemType = this.getSelectedItemType.bind(this);
        this.cameraPolygonRef = React.createRef();
        this.innerBoxRef = React.createRef();
        this.touchSwapToken = null;
        this.aspectRatio = true;
        this.resizeThreshold = { corner: 2, center: 2 };
        this.selectedSubtitle = Map({});
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedObjects !== state.prevSelectedObjects) {
            return {
                selectedObjects: props.selectedObjects,
                prevSelectedObjects: state.selectedObjects,
                selectedItems: props.selectedItems,
                prevSelectedItems: state.selectedItems,
            };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.selectionBoxMouseDown && this.innerBoxRef.current) {
            this.simulate(
                this.innerBoxRef.current,
                "pointerdown",
                {
                    pointerX: this.props.selectionBoxMouseDown.get("x"),
                    pointerY: this.props.selectionBoxMouseDown.get("y")
                }
            );
        }

        if (this.props.selectedItems.size === 1 && this.selectedItems.first() && this.selectedItems.first().get("type") === "SCR") {
            document.getElementById(this.props.selectedItems.get(0)).style.display = "block";
        }

        if (this.props.selectedSubtitles.size) {
            this.currentItemContainer = "subtitle";
            this.lockRotate = true;
            this.lockResize = true;
        }

        this.setSnapPoints();
        window.addEventListener("blur", this.tabChanged, false);
    }

    componentWillUnmount() {
        this.hideSnaplines(); // for frame drop, when item is dragged and gets deleted
        window.removeEventListener('pointermove', this.moveSelection, false);
        window.removeEventListener("pointerup", this.stopMove, false);
        window.removeEventListener("blur", this.tabChanged, false);
    }

    tabChanged(e) {
        try {
            this.ctrlPressed = false;
        } catch (error) { }
    }

    setSnapPoints() {
        this.snapPoints = []; this.xSnapPoints = []; this.ySnapPoints = [];
        if (this.props.workspaceGrid) {
            this.snapPoints = [{ x: 0, y: 0, width: this.props.workspaceWidth, height: this.props.workspaceHeight, left: true }, { x: this.props.workspaceWidth / 2, y: this.props.workspaceHeight / 2, width: this.props.workspaceWidth, height: this.props.workspaceHeight, center: true }, { x: this.props.workspaceWidth, y: this.props.workspaceHeight, width: this.props.workspaceWidth, height: this.props.workspaceHeight, right: true }];
            for (let i = 0; i < 8; i++) {
                for (let j = 0; j < 8; j++) {
                    this.snapPoints.push({ x: i * this.props.workspaceWidth / 8, y: j * this.props.workspaceHeight / 8, width: this.props.workspaceWidth, height: this.props.workspaceHeight });
                }
            }
        } else if (this.currentItemContainer === "workspaceItems" || this.currentItemContainer === "subtitle") {
            this.xSnapPoints.push.apply(this.xSnapPoints, [{ x: 0, y: 0, height: this.props.workspaceHeight, left: true }, { x: this.props.workspaceWidth / 2, y: 0, height: this.props.workspaceHeight, center: true }, { x: this.props.workspaceWidth, y: 0, height: this.props.workspaceHeight, right: true }]);
            this.ySnapPoints.push.apply(this.ySnapPoints, [{ x: 0, y: 0, width: this.props.workspaceWidth, top: true }, { x: 0, y: this.props.workspaceHeight / 2, width: this.props.workspaceWidth, middle: true }, { x: 0, y: this.props.workspaceHeight, width: this.props.workspaceWidth, bottom: true }]);
            this.snapPoints.push({ x: 0, y: 0, width: this.props.workspaceWidth, height: this.props.workspaceHeight }, { x: this.props.workspaceWidth / 2, y: this.props.workspaceHeight / 2, width: this.props.workspaceWidth, height: this.props.workspaceHeight }, { x: this.props.workspaceWidth, y: this.props.workspaceHeight, width: this.props.workspaceWidth, height: this.props.workspaceHeight });

            if (this.props.unSelectedObjects !== null && this.props.unSelectedObjects.size > 0) {
                this.props.unSelectedObjects
                    .filter((obj) => obj.get("enterStart") <= this.props.playHeadValue && obj.get("exitEnd") >= this.props.playHeadValue)
                    .entrySeq().forEach(([key, single_item]) => {
                        let bound = this.getSelectionBoxBounds({ x: single_item.get("x"), y: single_item.get("y"), cx: single_item.get("x") + single_item.get("width") / 2, cy: single_item.get("y") + single_item.get("height") / 2, width: single_item.get("width"), height: single_item.get("height"), angle: single_item.get("angle") });

                        this.snapPoints.push({ x: bound.x, y: bound.y, width: single_item.get("width"), height: single_item.get("height"), left: true }, { x: bound.x + bound.width / 2, y: bound.y + bound.height / 2, width: single_item.get("width"), height: single_item.get("height"), center: true }, { x: bound.x + bound.width, y: bound.y + bound.height, width: single_item.get("width"), height: single_item.get("height"), right: true });
                        this.xSnapPoints.push.apply(this.xSnapPoints, [{ x: bound.x, y: bound.y, height: bound.height, left: true }, { x: bound.x + bound.width / 2, y: bound.y, height: bound.height, center: true }, { x: bound.x + bound.width, y: bound.y, height: bound.height, right: true }]);
                        this.ySnapPoints.push.apply(this.ySnapPoints, [{ x: bound.x, y: bound.y, width: bound.width, top: true }, { x: bound.x, y: bound.y + bound.height / 2, width: bound.width, middle: true }, { x: bound.x, y: bound.y + bound.height, width: bound.width, bottom: true }]);
                    });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectionBoxMouseDown !== prevProps.selectionBoxMouseDown && this.props.selectionBoxMouseDown && this.innerBoxRef.current) {
            this.simulate(
                this.innerBoxRef.current,
                "pointerdown",
                {
                    pointerX: this.props.selectionBoxMouseDown.get("x"),
                    pointerY: this.props.selectionBoxMouseDown.get("y")
                }
            );
        }

        if (this.props.selectedItems !== prevProps.selectedItems) {
            if (this.props.selectedItems.size === 1 && this.selectedItems.first().get("type") === "SCR") {
                document.getElementById(this.props.selectedItems.get(0)).style.display = "block";
            } else {
                let elements = getElementAll(".type-scr");
                for (let i = 0; i < elements.length; i++) {
                    elements[i].style.display = "none";
                }
            }
        }

        if (!this.props.isWorkspaceTextFocus && this.props.shortcutName !== prevProps.shortcutName && this.props.shortcutName !== "")
            this.handleShortcuts(this.props.shortcutName);
        else if (!this.props.isWorkspaceTextFocus && this.props.shortcutName !== prevProps.shortcutName && this.props.shortcutName === "") {
            if (prevProps.shortcutName === "LEFT_ARROW" || prevProps.shortcutName === "LEFT_ARROW_AGAIN" || prevProps.shortcutName === "RIGHT_ARROW" || prevProps.shortcutName === "RIGHT_ARROW_AGAIN" || prevProps.shortcutName === "UP_ARROW" || prevProps.shortcutName === "UP_ARROW_AGAIN" || prevProps.shortcutName === "DOWN_ARROW" || prevProps.shortcutName === "DOWN_ARROW_AGAIN" || prevProps.shortcutName === "SHIFT_LEFT_ARROW" || prevProps.shortcutName === "SHIFT_LEFT_ARROW_AGAIN" || prevProps.shortcutName === "SHIFT_RIGHT_ARROW" || prevProps.shortcutName === "SHIFT_RIGHT_ARROW_AGAIN" || prevProps.shortcutName === "SHIFT_UP_ARROW" || prevProps.shortcutName === "SHIFT_UP_ARROW_AGAIN" || prevProps.shortcutName === "SHIFT_DOWN_ARROW" || prevProps.shortcutName === "SHIFT_DOWN_ARROW_AGAIN") {
                this.transformingByKey = false;
                this.hideSnaplines();
                if (this.currentItemContainer === "subtitle") {
                    this.updateSubtitlePosition();
                } else {
                    this.props.moveUpdate({ "items": this.selectedItems, "children": this.groupedItems, isMultimove: this.state.selectedObjects.size === 1 && this.state.selectedObjects.first().get("isMultimove") && this.props.animakerType === 'full', isSwitch: this.state.selectedObjects.size === 1 && this.state.selectedObjects.first().get("isSwitch") && this.props.animakerType === 'full', "currentContainer": this.currentItemContainer, selectedItems: this.props.selectedItems });
                }
                this.updateInitialSelectionBox({ transforming: false, moving: false, x: this.keyPressUpdate.x, y: this.keyPressUpdate.y, cx: this.keyPressUpdate.x + this.keyPressUpdate.width / 2, cy: this.keyPressUpdate.y + this.keyPressUpdate.height / 2 });
            }
        }
        if (this.props.selectedItems !== prevProps.selectedItems && this.props.selectedItems.size > 0 && !this.props.selectedSubtitles.size) {
            if (this.currentItemContainer !== "workspaceItems")
                this.currentItemContainer = "workspaceItems";
            this.restrictBounds = false;
            this.lockRotate = false;
            if (this.props.cornerResize !== undefined && this.props.cornerResize) {
                this.cornerResize = true;
                this.aspectRatio = true;
            }
        }
        else if (this.props.selectedCamera !== prevProps.selectedCamera && this.props.selectedCamera !== "") {
            if (this.currentItemContainer !== "workspaceCameras")
                this.currentItemContainer = "workspaceCameras";
            this.restrictBounds = true;
            this.lockRotate = true;
            this.cornerResize = false;
            this.aspectRatio = false;
        } else if (this.props.selectedSubtitles.size) {
            this.currentItemContainer = "subtitle";
        }

        if (this.props.imperativeRotate.token && this.props.imperativeRotate.token !== prevProps.imperativeRotate.token) {
            this.mouseStartPosition = { x: 0, y: 0 };
            this.rotateSelection({
                type: "<<IMPERATIVE_ROTATE>>",
                angle: this.props.imperativeRotate.rotateTo,
                clientX: 0,
                clientY: 0,
            });
            this.stopRotate({
                type: "<<IMPERATIVE_ROTATE>>",
                angle: this.props.imperativeRotate.rotateTo,
                clientX: 0,
                clientY: 0,
            });
        }

        this.setSnapPoints();
    }

    eventMatchers = {
        'HTMLEvents': /^(?:load|unload|abort|error|select|change|submit|reset|focus|blur|resize|scroll)$/,
        'MouseEvents': /^(?:click|dblclick|mouse(?:down|up|over|move|out)|pointer(?:down|up|over|move|out))$/
    }

    defaultOptions = {
        pointerX: 0,
        pointerY: 0,
        button: 0,
        ctrlKey: false,
        altKey: false,
        shiftKey: false,
        metaKey: false,
        bubbles: true,
        cancelable: true
    }

    simulate(element, eventName, ...args) {
        let options = this.extend(this.defaultOptions, args[0] || {});
        let oEvent, eventType = null;

        for (const name of Reflect.ownKeys(this.eventMatchers)) {
            if (this.eventMatchers[name].test(eventName)) { eventType = name; break; }
        }

        if (!eventType)
            throw new SyntaxError('Only HTMLEvents and MouseEvents interfaces are supported');

        if (document.createEvent) {
            oEvent = document.createEvent(eventType);
            if (eventType === 'HTMLEvents') {
                oEvent.initEvent(eventName, options.bubbles, options.cancelable);
            } else {
                oEvent.initMouseEvent(eventName, options.bubbles, options.cancelable, document.defaultView,
                    options.button, options.pointerX, options.pointerY, options.pointerX, options.pointerY,
                    options.ctrlKey, options.altKey, options.shiftKey, options.metaKey, options.button, element);
            }
            if (oEvent instanceof Event) {
                element.dispatchEvent(oEvent);
            }
        } else {
            options.clientX = options.pointerX;
            options.clientY = options.pointerY;
            const evt = document.createEventObject();
            oEvent = this.extend(evt, options);
            element.fireEvent('on' + eventName, oEvent);
        }
        return element;
    }

    extend(destination, source) {
        for (const property of Reflect.ownKeys(source)) {
            destination[property] = source[property];
        }
        return destination;
    }

    updateInitialSelectionBox(obj) {
        this.selectionBox = Object.assign(this.selectionBox, obj);
        this.initialSelectionBox = Object.assign(this.initialSelectionBox, obj);
    }

    getMousePosition(e) {
        try {
            const workspaceBounds = this.getWorkspaceBounds();
            const pos = getMouseClientPosition(
                e,
                fromJS({ x: -workspaceBounds.x, y: -workspaceBounds.y })
            );
            return { x: pos.get("x"), y: pos.get("y") };
        } catch (error) { }
        return null;
    }

    hideSnaplines(type = "both") {
        try {
            let selector = "snap-line";
            if (type === "vertical-line" || type === "horizontal-line") {
                selector = selector + " " + type;
            }
            const snapLines = document.getElementsByClassName(selector);
            for (let i = 0; i < snapLines.length; i++) {
                snapLines[i].style.display = "none";
            }
        } catch (error) { }
    }

    getWorkspaceBounds() {
        return this.props.workspaceRef.current.getBoundingClientRect();
    }

    isRightMouse(e) {
        let isRightMB;
        try {
            e = e || window.event;
            if ("which" in e) isRightMB = e.which === 3;
            else if ("button" in e) isRightMB = e.button === 2;
        } catch (error) { }
        return isRightMB;
    }

    isMouseOnSelectionBox(e) {
        let result = false;
        try {
            const workspace_bounds = this.getWorkspaceBounds();
            const pos = getMouseClientPosition(
                e,
                fromJS({ x: -workspace_bounds.x, y: -workspace_bounds.y })
            );
            const currentX = pos.get("x"), currentY = pos.get("y");
            if (this.selectionBox.x !== null) {
                result = (
                    (currentX > (this.selectionBox.x) && currentY > (this.selectionBox.y)
                        && currentX < (this.selectionBox.x + this.selectionBox.width)
                        && currentY < (this.selectionBox.y + this.selectionBox.height))
                    && !isMouseOn(e, "handle")
                );
            }
            result = false;
        } catch (error) { }
        return result;
    }

    /*
      Move
    */

    getXSnapPoint(point, r, array) {
        const result = array.filter(arr => Math.abs(arr.x - point) <= r);
        return result;
    }

    getYSnapPoint(point, r, array) {
        const result = array.filter(arr => Math.abs(arr.y - point) <= r);
        return result;
    }

    getSelectionSnapPoints(x, y) {
        const cx = x + (this.selectionBox.width * this.props.zoomFactor) / 2;
        const cy = y + (this.selectionBox.height * this.props.zoomFactor) / 2;

        let bounds = this.getSelectionBoxBounds({ x: x, y: y, cx: cx, cy: cy, width: (this.selectionBox.width * this.props.zoomFactor), height: (this.selectionBox.height * this.props.zoomFactor), angle: this.selectionBox.angle });

        let boundX = bounds.x / this.props.zoomFactor, boundY = bounds.y / this.props.zoomFactor, boundWidth = bounds.width / this.props.zoomFactor, boundHeight = bounds.height / this.props.zoomFactor;
        let selectionLeft = boundX,
            selectionTop = boundY,
            selectionRight = boundX + boundWidth,
            selectionBottom = boundY + boundHeight,
            selectionCenterX = boundX + boundWidth / 2,
            selectionCenterY = boundY + boundHeight / 2;

        return { left: selectionLeft, top: selectionTop, centerX: selectionCenterX, centerY: selectionCenterY, right: selectionRight, bottom: selectionBottom };
    }

    handleShortcuts(option) {
        if (this.selectionBox.x !== null) {
            let x = this.selectionBox.x, y = this.selectionBox.y;
            let d = 1, sd = 10;

            switch (option) {
                case "RIGHT_ARROW":
                case "RIGHT_ARROW_AGAIN":
                    x += d;
                    break;
                case "LEFT_ARROW":
                case "LEFT_ARROW_AGAIN":
                    x -= d;
                    break;
                case "UP_ARROW":
                case "UP_ARROW_AGAIN":
                    y -= d;
                    break;
                case "DOWN_ARROW":
                case "DOWN_ARROW_AGAIN":
                    y += d;
                    break;
                case "SHIFT_RIGHT_ARROW":
                case "SHIFT_RIGHT_ARROW_AGAIN":
                    x += sd;
                    break;
                case "SHIFT_LEFT_ARROW":
                case "SHIFT_LEFT_ARROW_AGAIN":
                    x -= sd;
                    break;
                case "SHIFT_UP_ARROW":
                case "SHIFT_UP_ARROW_AGAIN":
                    y -= sd;
                    break;
                case "SHIFT_DOWN_ARROW":
                case "SHIFT_DOWN_ARROW_AGAIN":
                    y += sd;
                    break;
                default:
                    break;
            }

            if ((this.selectionBox.x !== x || this.selectionBox.y !== y) && !this.props.textStatus.get("isFocused")) {
                this.transformingByKey = true;
                if (!this.props.transformStatus.get("transforming")) {
                    this.props.updateTransformStatus({ transforming: true, moving: true });
                }
                this.moveItem(x * this.props.zoomFactor, y * this.props.zoomFactor);
                if (this.transformingTimer) clearTimeout(this.transformingTimer);
                this.transformingTimer = setTimeout(() => {
                    if (this.currentItemContainer === "subtitle") {
                        this.updateSubtitlePosition();
                    } else {
                        this.props.moveUpdate({
                            "items": this.selectedItems,
                            "children": this.groupedItems,
                            isMultimove: this.state.selectedObjects.size === 1 && this.state.selectedObjects.first().get("isMultimove"),
                            isSwitch: this.state.selectedObjects.size === 1 && this.state.selectedObjects.first().get("isSwitch"),
                            "currentContainer": this.currentItemContainer,
                            selectedItems: this.props.selectedItems
                        });
                    }
                }, this.transformTimeout);
            }
        }
    }

    moveItem(x, y) {
        let selectionBox = this.selectionBoxElement;
        let workspaceBounds = this.getWorkspaceBounds();

        /**
         * To restrict selection within workspace, workspace's offsetWidth or scrollWidth cant be used.
         * so use values without zoom factor for comparison.
         */
        if (this.restrictBounds !== undefined && this.restrictBounds) {
            let outLineWidth = 0;
            let or_x = x / this.props.zoomFactor,
                or_y = y / this.props.zoomFactor; // x and y without zoom factor
            if (or_x - outLineWidth < 0) x = outLineWidth; if (or_y - outLineWidth < 0) y = outLineWidth;
            if (or_y + (this.selectionBox.height) + outLineWidth > this.props.workspaceHeight)
                y = (this.props.workspaceHeight * this.props.zoomFactor) - (this.selectionBox.height * this.props.zoomFactor) - outLineWidth;
            if (or_x + (this.selectionBox.width) + outLineWidth > this.props.workspaceWidth)
                x = this.props.workspaceWidth * this.props.zoomFactor - (this.selectionBox.width * this.props.zoomFactor) - outLineWidth;
        }

        if (!this.transformingByKey) {
            let selectionPoints = this.getSelectionSnapPoints(x, y), i = 0;
            let xSnapped = false, ySnapped = false;

            let r = 2;
            if (this.props.workspaceGrid)
                r = 4;

            for (i = 0; i < this.snapPoints.length; i++) {
                let dX = (selectionPoints.left - this.snapPoints[i].x) * this.props.zoomFactor, dY = (selectionPoints.top - this.snapPoints[i].y) * this.props.zoomFactor;
                let dCX = (selectionPoints.centerX - this.snapPoints[i].x) * this.props.zoomFactor, dCY = (selectionPoints.centerY - this.snapPoints[i].y) * this.props.zoomFactor;

                let dRX = (selectionPoints.right - this.snapPoints[i].x) * this.props.zoomFactor, dRY = (selectionPoints.bottom - this.snapPoints[i].y) * this.props.zoomFactor;

                let snappedX = (Math.abs(dX) <= r) ? dX : (Math.abs(dCX) <= (this.snapPoints[i].center ? 6 : r)) ? dCX : (Math.abs(dRX) <= r) ? dRX : false;
                let snappedY = (Math.abs(dY) <= r) ? dY : (Math.abs(dCY) <= (this.snapPoints[i].center ? 6 : r)) ? dCY : (Math.abs(dRY) <= r) ? dRY : false;

                if (snappedX !== false && !xSnapped) {
                    x = parseFloat((x - snappedX).toFixed(2)); xSnapped = true;
                }

                if (snappedY !== false && !ySnapped) {
                    y = parseFloat((y - snappedY).toFixed(2)); ySnapped = true;
                }
            }

        }

        let selectionCX = (x / this.props.zoomFactor) + (this.selectionBox.width) / 2,
            selectionCy = (y / this.props.zoomFactor) + (this.selectionBox.height) / 2;
        selectionBox.style.transform = "translate(" + (x) + "px, " + (y) + "px) rotateZ(" + this.selectionBox.angle + "deg)";

        let allItems = this.selectedItems;

        if (this.multimoveId !== null) {
            allItems.entrySeq().forEach(([key, item]) => {
                let item_x = Math.cos((item.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(item.get("dis_to_center")) + selectionCX - item.get("width") / 2;
                let item_y = Math.sin((item.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(item.get("dis_to_center")) + selectionCy - item.get("height") / 2;
                let item_to_update = Map({ "x": item_x, "y": item_y });
                allItems = allItems.set(key, item.merge(item_to_update));
            });

            let firstKeyframe = allItems.get(this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "keyframes", 0, "id"]));

            let item_x = Math.cos((firstKeyframe.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(firstKeyframe.get("dis_to_center")) + selectionCX - firstKeyframe.get("width") / 2;
            let item_y = Math.sin((firstKeyframe.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(firstKeyframe.get("dis_to_center")) + selectionCy - firstKeyframe.get("height") / 2;

            this.props.moveItem(item_x, item_y);

            if (document.getElementById(this.props.selectedItems.get(0)) !== null) {
                let flipPos = this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "flipPosition"]);
                let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";

                document.getElementById(this.props.selectedItems.get(0)).style.transform = "translate(" + (item_x * this.props.zoomFactor).toFixed(2) + "px, " + (item_y * this.props.zoomFactor).toFixed(2) + "px) rotateZ(" + firstKeyframe.get("angle") + "deg)" + flip;
            }
        } else {
            allItems.entrySeq().forEach(([key, item]) => {
                let item_x = Math.cos((item.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(item.get("dis_to_center")) + selectionCX - item.get("width") / 2;
                let item_y = Math.sin((item.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(item.get("dis_to_center")) + selectionCy - item.get("height") / 2;

                let item_to_update = Map({ "x": item_x, "y": item_y });
                allItems = allItems.set(key, item.merge(item_to_update));
                if (document.getElementById(key) !== null) {
                    let flipPos = item.get("flipPosition");
                    let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
                    document.getElementById(key).style.transform = "translate(" + (item_x * this.props.zoomFactor).toFixed(2) + "px, " + (item_y * this.props.zoomFactor).toFixed(2) + "px) rotateZ(" + item.get("angle") + "deg)" + flip;
                }
            });
        }

        this.selectedItems = allItems;
        this.selectionBox = Object.assign(this.selectionBox, { "x": x / this.props.zoomFactor, "y": y / this.props.zoomFactor });

        if (this.props.shortcutName !== "")
            this.keyPressUpdate = Object.assign(this.keyPressUpdate, this.selectionBox);

        let selectionSnaps = this.getSelectionSnapPoints(x, y);

        let xSelectionPoints = [selectionSnaps.left, selectionSnaps.centerX, selectionSnaps.right];
        let ySelectionPoints = [selectionSnaps.top, selectionSnaps.centerY, selectionSnaps.bottom];

        if (!this.props.workspaceGrid) {
            let r = 0.5;
            for (let i = 0; i < xSelectionPoints.length; i++) {
                let snapPoint = this.getXSnapPoint(xSelectionPoints[i], r, this.xSnapPoints);
                let verticalLine = document.getElementsByClassName("vertical-line")[i];
                if (snapPoint.length > 0) {
                    let verX = ((snapPoint[0].x * this.props.zoomFactor) + workspaceBounds.x), verY = (snapPoint[0].y * this.props.zoomFactor) + workspaceBounds.y, verHeight = snapPoint[0].height * this.props.zoomFactor;
                    if (verY > (selectionSnaps.top * this.props.zoomFactor) + workspaceBounds.y) {
                        let dY = verY - ((selectionSnaps.top * this.props.zoomFactor) + workspaceBounds.y);
                        verY -= dY;
                        verHeight += dY;
                    }
                    if (verY + verHeight < (selectionSnaps.bottom * this.props.zoomFactor) + workspaceBounds.y) {
                        let dY = ((selectionSnaps.bottom * this.props.zoomFactor) + workspaceBounds.y) - (verY + verHeight);
                        verHeight += dY;
                    }
                    verticalLine.style.height = (verHeight) + "px";
                    verticalLine.style.left = ((verX - 0.5)) + "px";
                    verticalLine.style.top = ((verY - 0.5)) + "px";
                    verticalLine.style.display = "block";
                } else {
                    verticalLine.style.display = "none";
                }
            }

            for (let i = 0; i < ySelectionPoints.length; i++) {
                let snapPoint = this.getYSnapPoint(ySelectionPoints[i], r, this.ySnapPoints);
                let horizontalLine = document.getElementsByClassName("horizontal-line")[i];
                if (snapPoint.length > 0) {
                    let horX = ((snapPoint[0].x * this.props.zoomFactor) + workspaceBounds.x), horY = ((snapPoint[0].y * this.props.zoomFactor) + workspaceBounds.y), horWidth = snapPoint[0].width * this.props.zoomFactor;
                    if (horX > (selectionSnaps.left * this.props.zoomFactor) + workspaceBounds.x) {
                        let dX = horX - ((selectionSnaps.left * this.props.zoomFactor) + workspaceBounds.x);
                        horX -= dX;
                        horWidth += dX;
                    }
                    if (horX + horWidth < (selectionSnaps.right * this.props.zoomFactor) + workspaceBounds.x) {
                        let dX = ((selectionSnaps.right * this.props.zoomFactor) + workspaceBounds.x) - (horX + horWidth);
                        horWidth += dX;
                    }
                    horizontalLine.style.width = (horWidth) + "px";
                    horizontalLine.style.left = (horX - 0.5) + "px";
                    horizontalLine.style.top = (horY - 0.5) + "px";
                    horizontalLine.style.display = "block";
                } else {
                    horizontalLine.style.display = "none";
                }
            }
        }

        if (this.props.isMultimove) {
            this.props.moveItem(this.selectionBox.x + this.selectionBox.width / 2, this.selectionBox.y + this.selectionBox.height / 2);
        }
    }

    initiateMove(e) {
        try {
            this.touchSwapToken = null;
            let target = e.target;
            if (target.className.indexOf("crop-handle") !== -1) {
                return;
            }
            e.stopPropagation();

            // Blocking double click action for subtitle.
            if ((e.timeStamp - this.lastDown) < 250 && !this.isRightMouse(e) && !this.props.selectedSubtitles.size) {
                if (this.props.selectedItems.size === 1) {
                    const selectedItem = this.selectedItems.get(this.props.selectedItems.get(0));
                    if (this.multimoveId !== null && this.multimoveId === this.props.selectedItems.get(0)) {
                        if (this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "isMultimove"])) {
                            this.props.enableMultimove(this.props.selectedItems.get(0));
                        }
                        else if (this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "isSwitch"])) {
                            this.props.enableSwitch(this.props.selectedItems.get(0));
                        }
                    }
                    else if (
                        !this.props.isCropping
                        && (
                            isVideoOnly(selectedItem.get("type"), selectedItem.get("subType"))
                            || (isImageOnly(selectedItem.get("type"), selectedItem.get("subType")) && !this.isUploadSvgImage(selectedItem))
                        )
                    ) {
                        this.props.toggleCrop();
                    }

                    /*
                     * commenting this section as this is causing focus issues for empty shape text.
                     * Focus state will be updated by workspace and text settings component, no need to do it here
                    if (selectedItem.get("type") === "SHAPE" && !this.props.isMultimove && !isMouseOn(e, "text-container")) { // text container already has click events
                        this.props.updateTextStatus({ id: this.props.selectedItems.get(0), isFocused: true, container: "workspaceItems", isRTL: this.selectedItems.getIn([this.props.selectedItems.get(0), "textData", "formats", "others", "isRTL"]), isGrouped: false, type: "SHAPE", fontSize: parseFloat(this.selectedItems.getIn([this.props.selectedItems.get(0), "textData", "formats", "containerStyle", "fontSize"]), 10) });
                    }
                     */
                }
            }
            this.lastDown = e.timeStamp;
            this.moved = false;

            e.preventDefault();

            let isMoveLocked = Boolean(this.lockMove !== undefined && this.lockMove);
            if (!isMoveLocked) {
                this.updateInitialSelectionBox({ transforming: true, moving: true });

                let mouse_position = this.getMousePosition(e);
                this.mouseStartPosition = Object.assign(this.mouseStartPosition, { x: mouse_position.x, y: mouse_position.y, offsetX: ((this.selectionBox.x) - mouse_position.x / this.props.zoomFactor), offsetY: ((this.selectionBox.y) - mouse_position.y / this.props.zoomFactor) });
                if (this.selectionBox && !this.props.transformStatus.get('dontMove')) {
                    window.addEventListener('pointermove', this.moveSelection, false);
                }
                else if (this.props.transformStatus.get('dontMove')) {
                    this.props.updateTransformStatus({ dontMove: false });
                }
                window.addEventListener('pointerup', this.stopMove, false);
            }
        } catch (error) { }
    }

    moveSelection(e) {
        try {
            if (this.lastDown + 10 < e.timeStamp) {
                if (!this.moved) {
                    this.moved = true;
                    if (!this.props.isMultimove) {
                        this.props.updateTransformStatus({ transforming: true, moving: true });
                    }
                }
                e.preventDefault();
                let mouse_position = this.getMousePosition(e), x = parseFloat((mouse_position.x + (this.mouseStartPosition.offsetX * this.props.zoomFactor)).toFixed(2)), y = parseFloat((mouse_position.y + (this.mouseStartPosition.offsetY * this.props.zoomFactor)).toFixed(2));

                this.moveItem(x, y);

                try {
                    if (e.pointerType === "touch") {
                        let elementUnderPointer = document.elementFromPoint(e.clientX, e.clientY);
                        if (elementUnderPointer && elementUnderPointer.dataset.tswapTkn && this.touchSwapToken !== elementUnderPointer.dataset.tswapTkn) {
                            this.touchSwapToken = elementUnderPointer.dataset.tswapTkn;
                            const event = new CustomEvent("touch-swap-enter", {
                                clientX: e.clientX, clientY: e.clientY
                            });
                            elementUnderPointer.dispatchEvent(event);
                        } else if (this.touchSwapToken && this.touchSwapToken !== elementUnderPointer.dataset.tswapTkn) {
                            let triggeredElement = getElement(`[data-tswap-tkn='${this.touchSwapToken}']`);
                            if (triggeredElement) {
                                const event = new CustomEvent("touch-swap-leave", {
                                    clientX: e.clientX, clientY: e.clientY
                                });
                                triggeredElement.dispatchEvent(event);
                            }
                            this.touchSwapToken = null;
                        }
                    }
                } catch (error) {
                    this.touchSwapToken = null;
                }
            }
        } catch (error) { }
    }

    stopMove(e) {
        try {
            const { transformStatus } = this.props;
            this.hideSnaplines();
            this.updateInitialSelectionBox({ transforming: false, moving: false, x: this.selectionBox.x, y: this.selectionBox.y, cx: this.selectionBox.x + this.selectionBox.width / 2, cy: this.selectionBox.y + this.selectionBox.height / 2 });

            if (this.moved) {
                e.preventDefault();
                if (e.pointerType === "touch" && this.touchSwapToken) {
                    let elementUnderPointer = document.elementFromPoint(e.clientX, e.clientY);
                    if (elementUnderPointer && elementUnderPointer.dataset.tswapTkn === this.touchSwapToken) {
                        const event = new CustomEvent("touch-swap-up", {
                            clientX: e.clientX, clientY: e.clientY
                        });
                        elementUnderPointer.dispatchEvent(event);
                    }
                }

                let isDropOnFrameClip = (
                    this.props.swapHoverDrop
                    && this.props.swapHoverDrop.get("hovering")
                    && this.props.swapHoverDrop.get("targetType") === ITEM_CONFIG.SWAP_TARGETS.FRAME_CLIP
                    && this.props.swapHoverDrop.get("sourceType") === ITEM_CONFIG.SWAP_SOURCE.WORKSPACE
                ) || (transformStatus.get("isFrameClipDrop") && !transformStatus.get("moving"));
                let isDropOnGridCell = (
                    this.props.swapHoverDrop
                    && this.props.swapHoverDrop.get("hovering")
                    && this.props.swapHoverDrop.get("targetType") === ITEM_CONFIG.SWAP_TARGETS.GRID_CELL
                    && this.props.swapHoverDrop.get("sourceType") === ITEM_CONFIG.SWAP_SOURCE.WORKSPACE
                );
                if (!this.props.isToolCropping && (!isDropOnFrameClip && !isDropOnGridCell)) {
                    if (this.props.isMultimove) {
                        this.props.stopMove(parseFloat((this.selectionBox.x + this.selectionBox.width / 2).toFixed(2)), parseFloat((this.selectionBox.y + this.selectionBox.height / 2).toFixed(2)));
                    }
                    else if (this.currentItemContainer === "subtitle") {
                        this.updateSubtitlePosition();
                    } else {
                        this.props.moveUpdate({
                            "items": this.selectedItems,
                            "children": this.groupedItems,
                            isMultimove: this.state.selectedObjects.size === 1 && this.state.selectedObjects.first().get("isMultimove") && this.props.animakerType === 'full',
                            isSwitch: this.state.selectedObjects.size === 1 && this.state.selectedObjects.first().get("isSwitch") && this.props.animakerType === 'full',
                            "currentContainer": this.currentItemContainer,
                            selectedItems: this.props.selectedItems
                        });
                    }

                } else if (!isDropOnFrameClip && !isDropOnGridCell) {
                    // let toUpdate = { x: this.selectionBox.x / this.props.workspaceWidth, y: this.selectionBox.y / this.props.workspaceHeight };
                    // this.props.updateBg({ selectedScene: this.props.selectedScene, toUpdate: toUpdate, toolCrop: true });
                }
            } else {
                let selectedItemType = this.getSelectedItemType()
                if (!this.moved && selectedItemType === ' select-scr') {
                    // this.props.manageToast({ action: "add", "message": 'select.scr', formatId: "both" });
                }
            }

            //   this.props.updateCropStatus({id : this.props.selectedItems.get(0), status : false, selectedScene : this.props.selectedScene, selectedItems : this.props.selectedItem});

            this.touchSwapToken = null;
            window.removeEventListener('pointermove', this.moveSelection, false);
            window.removeEventListener('pointerup', this.stopMove, false);
        } catch (error) { }
    }

    isFontAutoSize(id, container) {
        if (!container)
            container = "workspaceItems";

        let active_text = (container === "workspaceItems") ? this.props.selectedObjects.getIn([id, "textData"]) : this.props.selectedChildren.getIn([id, "textData"]);
        let result = false;
        let auto_font_size = active_text.getIn(["formats", "others", "isAutoFontSize"]);
        if (auto_font_size !== undefined)
            result = auto_font_size;
        return result;
    }

    getRotatedPoint = (px, py, x, y, angle) => {
        let angle_in_rad = angle * Math.PI / 180;
        return {
            x: (x - px) * Math.cos(angle_in_rad) - (y - py) * Math.sin(angle_in_rad) + px,
            y: (x - px) * Math.sin(angle_in_rad) + (y - py) * Math.cos(angle_in_rad) + py,
        }
    }

    getTextBounds(e, id) {
        let handle = this.initialSelectionBox.handle;
        let textItem;
        let textId = id;
        if (this.multimoveId !== null)
            textId = this.multimoveId;
        textItem = this.props.selectedObjects.get(id);
        let textElement = document.getElementById(textId), width, height, x, y;
        let textContainer = textElement.getElementsByClassName('text-container')[0];

        let min_width = this.props.textStatus.get('fontSize');
        if (!min_width) {
            min_width = 10;
        }
        let listType = textItem.getIn(["textData", "formats", "bullet", "type"]);
        if (listType === "unOrderedList" || listType === "orderedList") {
            const LIST_PADDING_IN_EM = 1.3; // left padding for ltr or right padding for rtl
            min_width = min_width + min_width * LIST_PADDING_IN_EM;
        }

        if ((handle === "left" || handle === "right") && !this.props.isMultimove) {
            let mouse_position = this.getMousePosition(e);
            let dx = (mouse_position.x - this.mouseStartPosition.x) / this.props.zoomFactor, dy = (mouse_position.y - this.mouseStartPosition.y) / this.props.zoomFactor, angle = this.initialSelectionBox.angle;

            let newX = this.initialSelectionBox.x, newY = this.initialSelectionBox.y, newWidth = this.initialSelectionBox.width, newHeight = this.initialSelectionBox.height;
            let prevX = this.initialSelectionBox.x, prevY = this.initialSelectionBox.y, prevWidth = this.initialSelectionBox.width, prevHeight = this.initialSelectionBox.height;
            let adX = ((dx) * Math.cos(angle * (Math.PI / 180))) + ((dy) * Math.sin(angle * (Math.PI / 180)));

            if (handle === "left") {
                newWidth = prevWidth - adX;

                if (newWidth < min_width) {
                    newWidth = min_width;
                }

                let prev_pivot = { x: prevX + prevWidth / 2, y: prevY + prevHeight / 2 };
                let unrotated_prev_top_right = { x: prevX + prevWidth, y: prevY };
                let rotated_prev_top_right = this.getRotatedPoint(prev_pivot.x, prev_pivot.y, unrotated_prev_top_right.x, unrotated_prev_top_right.y, angle);

                // get future height from dom - to handle text wrap
                textElement.style.width = (newWidth * this.props.zoomFactor) + "px";
                // dont take height from item dom, instead take from text-container... otherwise incorrect height values will be taken
                let currentHeight = (textContainer.clientHeight / this.props.zoomFactor) + this.props.textOffset; /** @note check textOffset if horizontal resize is breaking for template prebuilts */
                if (textItem.getIn(["textData", "formats", "containerStyle", "boxDesign"]) === 'split') {
                    let prevStyleHeight = textContainer.parentElement.style.height
                    textContainer.parentElement.style.height = "auto";
                    currentHeight = (textContainer.parentElement.clientHeight / this.props.zoomFactor);
                    textContainer.parentElement.style.height = prevStyleHeight;
                }

                // new pivot calculation, this pivot must be used for new top-left point rotation
                let pivot_vector = { x: -newWidth / 2, y: currentHeight / 2 };
                let rotated_pivot_vector = this.getRotatedPoint(0, 0, pivot_vector.x, pivot_vector.y, angle);
                let rotated_pivot = {
                    x: rotated_prev_top_right.x + rotated_pivot_vector.x,
                    y: rotated_prev_top_right.y + rotated_pivot_vector.y,
                }

                // new top-left point calculation
                let dimension_vector = { x: -newWidth, y: 0 };
                let rotated_dimension_vector = this.getRotatedPoint(0, 0, dimension_vector.x, dimension_vector.y, angle);
                let rotated_new_top_left = {
                    x: rotated_prev_top_right.x + rotated_dimension_vector.x,
                    y: rotated_prev_top_right.y + rotated_dimension_vector.y,
                };

                let unrotated_new_top_left = this.getRotatedPoint(rotated_pivot.x, rotated_pivot.y, rotated_new_top_left.x, rotated_new_top_left.y, -angle);

                newX = unrotated_new_top_left.x;
                newY = unrotated_new_top_left.y;
            } else if (handle === "right") {
                newWidth = prevWidth + adX;

                if (newWidth < min_width) {
                    newWidth = min_width;
                }

                let prev_pivot = { x: prevX + prevWidth / 2, y: prevY + prevHeight / 2 };
                let unrotated_prev_top_left = { x: prevX, y: prevY };
                let rotated_prev_top_left = this.getRotatedPoint(prev_pivot.x, prev_pivot.y, unrotated_prev_top_left.x, unrotated_prev_top_left.y, angle);

                // get future height from dom - to handle text wrap
                textElement.style.width = (newWidth * this.props.zoomFactor) + "px";
                // dont take height from item dom, instead take from text-container... otherwise incorrect height values will be taken
                let currentHeight = (textContainer.clientHeight / this.props.zoomFactor) + this.props.textOffset; /** @note check textOffset if horizontal resize is breaking for template prebuilts */

                if (textItem.getIn(["textData", "formats", "containerStyle", "boxDesign"]) === 'split') {
                    let prevStyleHeight = textContainer.parentElement.style.height
                    textContainer.parentElement.style.height = "auto";
                    currentHeight = (textContainer.parentElement.clientHeight / this.props.zoomFactor);
                    textContainer.parentElement.style.height = prevStyleHeight;
                }
                // new pivot calculation, this pivot must be used for new top-left point rotation
                let pivot_vector = { x: newWidth / 2, y: currentHeight / 2 };
                let rotated_pivot_vector = this.getRotatedPoint(0, 0, pivot_vector.x, pivot_vector.y, angle);
                let rotated_pivot = {
                    x: rotated_prev_top_left.x + rotated_pivot_vector.x,
                    y: rotated_prev_top_left.y + rotated_pivot_vector.y,
                }

                let unrotated_new_top_left = this.getRotatedPoint(rotated_pivot.x, rotated_pivot.y, rotated_prev_top_left.x, rotated_prev_top_left.y, -angle);

                newX = unrotated_new_top_left.x;
                newY = unrotated_new_top_left.y;
            }

            width = newWidth;
            height = newHeight;
            x = newX;
            y = newY;

            textElement.style.transform = "translate(" + (newX * this.props.zoomFactor) + "px, " + (newY * this.props.zoomFactor) + "px) rotateZ(0deg)";
            textElement.style.width = (width * this.props.zoomFactor) + "px";

            let range = document.createRange();
            range.selectNode(textElement.getElementsByClassName('text-container')[0]);

            let rangeBounds = range.getBoundingClientRect();

            textElement.style.transform = "translate(" + (newX * this.props.zoomFactor) + "px, " + (newY * this.props.zoomFactor) + "px) rotateZ(" + textItem.get("angle") + "deg)";
            let rangeHeight = rangeBounds.height + (this.props.textOffset * this.props.zoomFactor);
            height = rangeHeight / this.props.zoomFactor;
            if (textItem.getIn(["textData", "formats", "containerStyle", "boxDesign"]) === 'split') {
                let prevStyleHeight = textContainer.parentElement.style.height
                textContainer.parentElement.style.height = "auto";
                height = (textContainer.parentElement.clientHeight / this.props.zoomFactor);
                textContainer.parentElement.style.height = prevStyleHeight;
            }
        } else {
            let resize_position = this.getResizePosition(e);

            let scale_x = resize_position.scale_x, scale_y = resize_position.scale_y, cx = resize_position.cx, cy = resize_position.cy;
            let item = this.selectedItems.get(id);

            width = Math.abs(item.get("width") * scale_x); height = Math.abs(item.get("height") * scale_y);
            x = Math.cos((item.get("angle_to_center") + item.get("angle")) * Math.PI / 180) * Math.abs(item.get("dis_to_center") * scale_x) + cx - width / 2;
            y = Math.sin((item.get("angle_to_center") + item.get("angle")) * Math.PI / 180) * Math.abs(item.get("dis_to_center") * scale_y) + cy - height / 2;
            let textOffset = this.props.textOffset;
            if (item.get("type") === "SHAPE")
                textOffset = 0;
            let tsx = ((item.get("width") * scale_x) - textOffset) / item.get("width"), tsy = ((item.get("height") * scale_y) - textOffset) / item.get("height");
            let fontSize = (parseFloat(textItem.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10) * Math.abs(Math.max(tsx, tsy)) * this.props.zoomFactor).toFixed(2);

            if ((item.get("type") === "TEXT") && (item.getIn(["textData", "formats", "containerStyle", "boxDesign"]) === 'split')) {
                const lineHeight = item.getIn(["textData", "formats", "containerStyle", "lineHeight"])
                textContainer.parentElement.style.lineHeight = lineHeight * fontSize + "px";
            }

            textContainer.style.fontSize = fontSize + "px";
            this.props.updateTextStatus({ fontSize: fontSize });
        }
        return { x: x, y: y, width: width, height: height };
    }

    resizeTextContainer(e) {
        try {
            let selectedId = this.selectedItems.keySeq().toArray()[0];
            let textBounds = this.getTextBounds(e, selectedId);
            let selectionBox = this.selectionBoxElement;
            let textWidth = textBounds.width, textHeight = textBounds.height;

            let x = (textBounds.x * this.props.zoomFactor), y = (textBounds.y * this.props.zoomFactor), width = (textWidth * this.props.zoomFactor), height = (textHeight * this.props.zoomFactor);
            selectionBox.style.transform = "translate(" + x + "px, " + y + "px) rotateZ(" + this.selectionBox.angle + "deg)";

            selectionBox.style.width = width + "px";
            selectionBox.style.height = height + "px";

            if (width < this.SHORT_HANDLE_THRESHOLD || height < this.SHORT_HANDLE_THRESHOLD) {
                selectionBox.classList.add("is-short");
            } else {
                selectionBox.classList.remove("is-short");
            }

            if (height < 60) {
                if (getElement(".resize-handle.left").className.indexOf("hide-handle") === -1) {
                    getElement(".resize-handle.left").classList.add("hide-handle");
                }
                if (getElement(".resize-handle.right").className.indexOf("hide-handle") === -1) {
                    getElement(".resize-handle.right").classList.add("hide-handle");
                }
            } else {
                if (getElement(".resize-handle.left").className.indexOf("hide-handle") !== -1) {
                    getElement(".resize-handle.left").classList.remove("hide-handle");
                }
                if (getElement(".resize-handle.right").className.indexOf("hide-handle") !== -1) {
                    getElement(".resize-handle.right").classList.remove("hide-handle");
                }
            }

            let textElement = document.getElementById(selectedId);
            let textItem;

            textItem = this.props.selectedObjects.get(selectedId);
            textElement.style.transform = "translate(" + x + "px, " + y + "px) rotateZ(" + textItem.get("angle") + "deg)";
            textElement.style.width = width + "px";
            textElement.style.height = height + "px";
        } catch (error) { }
    }

    getSnappedResizePosition(params = {}) {
        const { resizePositionResult, initialSelectionBox, cornerResize = false, aspectRatio = true } = params;

        let snapTargets = [];
        const PARALLEL_THRESHOLD_DEG = 0.01;
        const PREVIEW_SNAP_THRESHOLD = 0.01;

        const { handle } = initialSelectionBox;

        const initialPoints = {
            topLeft: { x: initialSelectionBox.x, y: initialSelectionBox.y },
            topRight: { x: initialSelectionBox.x + initialSelectionBox.width, y: initialSelectionBox.y },
            bottomRight: { x: initialSelectionBox.x + initialSelectionBox.width, y: initialSelectionBox.y + initialSelectionBox.height },
            bottomLeft: { x: initialSelectionBox.x, y: initialSelectionBox.y + initialSelectionBox.height },
            center: { x: initialSelectionBox.cx, y: initialSelectionBox.cy },
        };

        if (handle === "top") {
            snapTargets = [
                { target: initialPoints.topLeft, scaleOrigin: initialPoints.bottomLeft },
                { target: initialPoints.topRight, scaleOrigin: initialPoints.bottomRight },
            ];
        } else if (handle === "right") {
            snapTargets = [
                { target: initialPoints.topRight, scaleOrigin: initialPoints.topLeft },
                { target: initialPoints.bottomRight, scaleOrigin: initialPoints.bottomLeft },
            ];
        } else if (handle === "bottom") {
            snapTargets = [
                { target: initialPoints.bottomRight, scaleOrigin: initialPoints.topRight },
                { target: initialPoints.bottomLeft, scaleOrigin: initialPoints.topLeft },
            ];
        } else if (handle === "left") {
            snapTargets = [
                { target: initialPoints.bottomLeft, scaleOrigin: initialPoints.bottomRight },
                { target: initialPoints.topLeft, scaleOrigin: initialPoints.topRight },
            ];
        } else if (handle === "top-left") {
            if (cornerResize) {
                snapTargets = [
                    { target: initialPoints.topLeft, scaleOrigin: initialPoints.bottomRight },
                ];
            } else {
                snapTargets = [
                    { target: initialPoints.topLeft, scaleOrigin: initialPoints.center },
                ];
            }
        } else if (handle === "top-right") {
            if (cornerResize) {
                snapTargets = [
                    { target: initialPoints.topRight, scaleOrigin: initialPoints.bottomLeft },
                ];
            } else {
                snapTargets = [
                    { target: initialPoints.topRight, scaleOrigin: initialPoints.center },
                ];
            }
        } else if (handle === "bottom-right") {
            if (cornerResize) {
                snapTargets = [
                    { target: initialPoints.bottomRight, scaleOrigin: initialPoints.topLeft },
                ];
            } else {
                snapTargets = [
                    { target: initialPoints.bottomRight, scaleOrigin: initialPoints.center },
                ];
            }
        } else if (handle === "bottom-left") {
            if (cornerResize) {
                snapTargets = [
                    { target: initialPoints.bottomLeft, scaleOrigin: initialPoints.topRight },
                ];
            } else {
                snapTargets = [
                    { target: initialPoints.bottomLeft, scaleOrigin: initialPoints.center },
                ];
            }
        }

        /**
         * @param {object} params
         * @param {{ x: number, y: number, width: number | undefined, height: number | undefined, } | null} params.prevSnapPoint
         * @param {{ x: number, y: number, width: number | undefined, height: number | undefined, }} params.snapPoint
         * @param {{ x: number, y: number, }} params.snapTarget
         * @param {"x" | "y"} params.axis
         * @param {number | undefined} params.snapThreshold
         */
        const snapPointReducer = (params = {}) => {
            let { prevSnapPoint, snapPoint, snapTarget, axis = "x", snapThreshold = undefined } = params;

            if (snapThreshold === undefined) {
                snapThreshold = (snapPoint.center || snapPoint.middle) ? this.resizeThreshold.center : this.resizeThreshold.corner;
            }

            const pointVal = snapTarget[axis];
            const curDis = Math.abs(snapPoint[axis] - pointVal);
            const canSnapToCurrentPoint = curDis <= snapThreshold;
            let reqSnapPoint = canSnapToCurrentPoint ? snapPoint : prevSnapPoint;

            if (canSnapToCurrentPoint && prevSnapPoint) {
                const prevDis = Math.abs(prevSnapPoint[axis] - pointVal);
                if (prevDis < curDis) {
                    reqSnapPoint = prevSnapPoint;
                }
            }

            return reqSnapPoint;
        }

        const snapPointDetail = snapTargets.reduce(
            (prevDetail, targetDetail) => {
                const snapTarget = targetDetail.target;
                const scaleOrigin = targetDetail.scaleOrigin;

                const rotScaleOrigin = this.getRotatedPoint(
                    initialSelectionBox.cx,
                    initialSelectionBox.cy,
                    scaleOrigin.x,
                    scaleOrigin.y,
                    initialSelectionBox.angle,
                );
                const scaledRotSnapTarget = this.getRotatedPoint(
                    0,
                    0,
                    (snapTarget.x - scaleOrigin.x) * resizePositionResult.scale_x,
                    (snapTarget.y - scaleOrigin.y) * resizePositionResult.scale_y,
                    initialSelectionBox.angle,
                );
                scaledRotSnapTarget.x = rotScaleOrigin.x + scaledRotSnapTarget.x;
                scaledRotSnapTarget.y = rotScaleOrigin.y + scaledRotSnapTarget.y;

                let snapTargetAngle = Math.atan2(scaledRotSnapTarget.y - rotScaleOrigin.y, scaledRotSnapTarget.x - rotScaleOrigin.x) * 180 / Math.PI;
                snapTargetAngle = (snapTargetAngle % 360 + 360) % 360;

                let xSnapPoint = null;
                let xSnapDis = null;
                let ySnapPoint = null;
                let ySnapDis = null;

                // if results in infinity, then angle between origin and corner is zero (meaning you can't scale if there is no gap)
                const canScaleOnXAxis = Number.isFinite(1 / (rotScaleOrigin.x - scaledRotSnapTarget.x));
                // if results in infinity, then angle between origin and corner is zero (meaning you can't scale if there is no gap)
                const canScaleOnYAxis = Number.isFinite(1 / (rotScaleOrigin.y - scaledRotSnapTarget.y));

                if (canScaleOnXAxis) {
                    xSnapPoint = this.xSnapPoints.reduce(
                        (prevSnapPoint, snapPoint) => snapPointReducer({
                            axis: "x",
                            prevSnapPoint,
                            snapPoint,
                            snapTarget: scaledRotSnapTarget,
                        }),
                        null
                    );
                    if (xSnapPoint) {
                        const snapPointAngles = [90, 270];
                        let isParallel = snapPointAngles.findIndex(angle => Math.abs(snapTargetAngle - angle) <= PARALLEL_THRESHOLD_DEG) !== -1;
                        if (isParallel) {
                            xSnapPoint = null;
                        } else {
                            xSnapDis = Math.abs(xSnapPoint.x - scaledRotSnapTarget.x);
                        }
                    }
                }
                if (canScaleOnYAxis) {
                    ySnapPoint = this.ySnapPoints.reduce(
                        (prevSnapPoint, snapPoint) => snapPointReducer({
                            axis: "y",
                            prevSnapPoint,
                            snapPoint,
                            snapTarget: scaledRotSnapTarget,
                        }),
                        null
                    );
                    if (ySnapPoint) {
                        const snapPointAngles = [0, 180, 360];
                        let isParallel = snapPointAngles.findIndex(angle => Math.abs(snapTargetAngle - angle) <= PARALLEL_THRESHOLD_DEG) !== -1;
                        if (isParallel) {
                            ySnapPoint = null;
                        } else {
                            ySnapDis = Math.abs(ySnapPoint.y - scaledRotSnapTarget.y);
                        }
                    }
                }

                let snapPoint = null;
                let snapPointDis = null;
                let axis = null;

                if (xSnapPoint && ySnapPoint) {
                    if (xSnapDis < ySnapDis) {
                        axis = "x";
                    } else {
                        axis = "y";
                    }
                } else if (xSnapPoint) {
                    axis = "x";
                } else if (ySnapPoint) {
                    axis = "y";
                }

                if (axis === "x") {
                    snapPoint = xSnapPoint;
                    snapPointDis = xSnapDis;
                } else if (axis === "y") {
                    snapPoint = ySnapPoint;
                    snapPointDis = ySnapDis;
                }

                const shouldSnap = (
                    // first snap
                    (snapPoint && !prevDetail.snapPoint)
                    // current snap-point is closer to prev snap-point
                    || (snapPoint && prevDetail.snapPoint && snapPointDis < prevDetail.snapPointDis)
                );

                if (shouldSnap) {
                    prevDetail.snapPoint = snapPoint;
                    prevDetail.snapPointDis = snapPointDis;
                    prevDetail.snapTarget = snapTarget;
                    prevDetail.scaleOrigin = scaleOrigin;
                }

                return prevDetail;
            },
            {
                /** @type {{ x: number, y: number, width: number | undefined, height: number | undefined, } | null} */
                snapPoint: null,
                /** @type {number | null} */
                snapPointDis: null,
                /** @type {{ x: number, y: number } | null} */
                snapTarget: null,
                /** @type {{ x: number, y: number } | null} */
                scaleOrigin: null,
            }
        );

        const { snapPoint, snapTarget, scaleOrigin } = snapPointDetail;
        this.hideSnaplines();
        if (!snapPoint || !snapTarget || !scaleOrigin) {
            return resizePositionResult;
        }

        const updatedResizePositionResult = { ...resizePositionResult };
        const isXSnapped = Number.isFinite(snapPoint.height);
        const isYSnapped = Number.isFinite(snapPoint.width);

        const rotScaleOrigin = this.getRotatedPoint(
            initialSelectionBox.cx,
            initialSelectionBox.cy,
            scaleOrigin.x,
            scaleOrigin.y,
            initialSelectionBox.angle,
        );
        const scaledSnapTarget = {
            x: scaleOrigin.x + (snapTarget.x - scaleOrigin.x) * resizePositionResult.scale_x,
            y: scaleOrigin.y + (snapTarget.y - scaleOrigin.y) * resizePositionResult.scale_y,
        };
        let scaledRotSnapTarget = this.getRotatedPoint(
            0,
            0,
            (snapTarget.x - scaleOrigin.x) * resizePositionResult.scale_x,
            (snapTarget.y - scaleOrigin.y) * resizePositionResult.scale_y,
            initialSelectionBox.angle,
        );
        scaledRotSnapTarget.x = rotScaleOrigin.x + scaledRotSnapTarget.x;
        scaledRotSnapTarget.y = rotScaleOrigin.y + scaledRotSnapTarget.y;

        let snapTargetAngle = Math.atan2(scaledRotSnapTarget.y - rotScaleOrigin.y, scaledRotSnapTarget.x - rotScaleOrigin.x) * 180 / Math.PI;
        snapTargetAngle = (snapTargetAngle % 360 + 360) % 360;
        let snapPointAngles = isXSnapped ? [90, 270] : [0, 180, 360];
        let isParallel = snapPointAngles.findIndex(angle => Math.abs(snapTargetAngle - angle) <= PARALLEL_THRESHOLD_DEG) !== -1;

        if (isParallel) {
            // both scale path and snap path is parallel to each other
            // you cant snap to a parallel point as the distance will never decrease (they wont converge to a single point)
            return resizePositionResult;
        }

        // use scaled rotated point to handle aspect ratio scenarios implicitily
        const m = (scaledRotSnapTarget.y - rotScaleOrigin.y) / (scaledRotSnapTarget.x - rotScaleOrigin.x);
        const snappedAt = { ...snapPoint };

        if (Number.isNaN(m)) {
            // 0 / 0 division, dont snap in this case
            snappedAt.x = scaledRotSnapTarget.x;
            snappedAt.y = scaledRotSnapTarget.y;
        } else if (Number.POSITIVE_INFINITY === m || Number.NEGATIVE_INFINITY === m) {
            // snapped exactly towards up/down
            snappedAt.x = scaledRotSnapTarget.x;
            if (isXSnapped) {
                // user actually shrinked down selection box, so dont snap
                snappedAt.y = scaledRotSnapTarget.y;
            }
        } else if (isXSnapped) {
            snappedAt.y = m * (snappedAt.x - rotScaleOrigin.x) + rotScaleOrigin.y;
        } else if (isYSnapped) {
            snappedAt.x = ((snappedAt.y - rotScaleOrigin.y) / m) + rotScaleOrigin.x;
        }

        if (!Number.isFinite(snappedAt.x) || !Number.isFinite(snappedAt.y)) {
            // failsafe: if some calculation results in NaN, dont snap
            snappedAt.x = scaledRotSnapTarget.x;
            snappedAt.y = scaledRotSnapTarget.y;
        }

        const rotDis = { x: snappedAt.x - scaledRotSnapTarget.x, y: snappedAt.y - scaledRotSnapTarget.y };
        const dis = this.getRotatedPoint(0, 0, rotDis.x, rotDis.y, -initialSelectionBox.angle);

        const snappedTarget = { x: scaledSnapTarget.x + dis.x, y: scaledSnapTarget.y + dis.y };

        const sfx = 1 / (scaleOrigin.x - snapTarget.x); // if this results in infinity, then it probably means there is no gap to scale (0px width)
        const sfy = 1 / (scaleOrigin.y - snapTarget.y); // if this results in infinity, then it probably means there is no gap to scale (0px height)

        if (sfx !== Number.NEGATIVE_INFINITY && sfx !== Number.POSITIVE_INFINITY) {
            updatedResizePositionResult.scale_x = (scaleOrigin.x - snappedTarget.x) * sfx;
        }
        if (sfy !== Number.NEGATIVE_INFINITY && sfy !== Number.POSITIVE_INFINITY) {
            updatedResizePositionResult.scale_y = (scaleOrigin.y - snappedTarget.y) * sfy;
        }

        // recalculate as scale might have changed
        scaledRotSnapTarget = this.getRotatedPoint(
            0,
            0,
            (snapTarget.x - scaleOrigin.x) * updatedResizePositionResult.scale_x,
            (snapTarget.y - scaleOrigin.y) * updatedResizePositionResult.scale_y,
            initialSelectionBox.angle,
        );
        scaledRotSnapTarget.x = rotScaleOrigin.x + scaledRotSnapTarget.x;
        scaledRotSnapTarget.y = rotScaleOrigin.y + scaledRotSnapTarget.y;

        let cx = (initialSelectionBox.cx - scaleOrigin.x) * updatedResizePositionResult.scale_x;
        let cy = (initialSelectionBox.cy - scaleOrigin.y) * updatedResizePositionResult.scale_y;

        ({ x: cx, y: cy } = this.getRotatedPoint(0, 0, cx, cy, initialSelectionBox.angle));
        cx = rotScaleOrigin.x + cx;
        cy = rotScaleOrigin.y + cy;

        updatedResizePositionResult.cx = cx;
        updatedResizePositionResult.cy = cy;

        const resizedSelectionBox = { ...initialSelectionBox };
        resizedSelectionBox.width = resizedSelectionBox.width * updatedResizePositionResult.scale_x;
        resizedSelectionBox.height = resizedSelectionBox.height * updatedResizePositionResult.scale_y;
        resizedSelectionBox.cx = updatedResizePositionResult.cx;
        resizedSelectionBox.cy = updatedResizePositionResult.cy;
        resizedSelectionBox.x = resizedSelectionBox.cx - resizedSelectionBox.width / 2;
        resizedSelectionBox.y = resizedSelectionBox.cy - resizedSelectionBox.height / 2;
        const scaledBounds = this.getSelectionBoxBounds(resizedSelectionBox);

        let previewSnapPoints = [snapPoint];
        let point = null;
        if (isYSnapped) {
            // snap to y-axis, so take x snap-point that matches closely
            point = this.xSnapPoints.reduce(
                (prevSnapPoint, snapPoint) => snapPointReducer({
                    axis: "x",
                    prevSnapPoint,
                    snapPoint,
                    snapTarget: scaledRotSnapTarget,
                    snapThreshold: PREVIEW_SNAP_THRESHOLD
                }),
                null
            );
        } else if (isXSnapped) {
            // snap to x-axis, so take y snap-point that matches closely
            point = this.ySnapPoints.reduce(
                (prevSnapPoint, snapPoint) => snapPointReducer({
                    axis: "y",
                    prevSnapPoint,
                    snapPoint,
                    snapTarget: scaledRotSnapTarget,
                    snapThreshold: PREVIEW_SNAP_THRESHOLD
                }),
                null
            );
        }
        if (point) {
            previewSnapPoints.push(point);
        }

        try {
            let workspaceBounds = this.getWorkspaceBounds();
            let horizontalLine = document.getElementsByClassName("horizontal-line")[0];
            let verticalLine = document.getElementsByClassName("vertical-line")[0];

            for (const previewPoint of previewSnapPoints) {
                if (Number.isFinite(previewPoint.width) && horizontalLine) {
                    // snap to y-axis

                    const snapBounds = { ...previewPoint };
                    const snapx2 = previewPoint.x + previewPoint.width;
                    const scaledx2 = scaledBounds.x + scaledBounds.width;
                    snapBounds.x = Math.min(previewPoint.x, scaledBounds.x);
                    snapBounds.width = Math.max(snapx2, scaledx2) - snapBounds.x;

                    const x = workspaceBounds.x + snapBounds.x * this.props.zoomFactor;
                    const y = workspaceBounds.y + snapBounds.y * this.props.zoomFactor;
                    const width = snapBounds.width * this.props.zoomFactor;
                    horizontalLine.style.width = width + "px";
                    horizontalLine.style.left = (x - 0.5) + "px";
                    horizontalLine.style.top = (y - 0.5) + "px";
                    horizontalLine.style.display = "block";
                } else if (Number.isFinite(previewPoint.height) && verticalLine) {
                    // snap to x-axis

                    const snapBounds = { ...previewPoint };
                    const snapy2 = previewPoint.y + previewPoint.height;
                    const scaledy2 = scaledBounds.y + scaledBounds.height;
                    snapBounds.y = Math.min(previewPoint.y, scaledBounds.y);
                    snapBounds.height = Math.max(snapy2, scaledy2) - snapBounds.y;

                    const x = workspaceBounds.x + snapBounds.x * this.props.zoomFactor;
                    const y = workspaceBounds.y + snapBounds.y * this.props.zoomFactor;
                    const height = snapBounds.height * this.props.zoomFactor;
                    verticalLine.style.height = height + "px";
                    verticalLine.style.left = (x - 0.5) + "px";
                    verticalLine.style.top = (y - 0.5) + "px";
                    verticalLine.style.display = "block";
                }
            }
        } catch (error) { }

        return updatedResizePositionResult;
    }

    getResizePosition(e, restrictResizeBelowMin, otherOptions = { shouldSnap: true }) {
        try {
            let mouse_position = this.getMousePosition(e);
            let angle = this.selectionBox.angle;
            let dx = (mouse_position.x - this.mouseStartPosition.x) / this.props.zoomFactor, dy = (mouse_position.y - this.mouseStartPosition.y) / this.props.zoomFactor;

            const initialSelectionBox = this.initialSelectionBox;
            const { handle } = initialSelectionBox;

            if (handle === "top" || handle === "bottom") {
                ({ y: dy } = this.getRotatedPoint(0, 0, dx, dy, -angle));
                dx = 0;
                if (handle === "top") {
                    dy = -dy;
                }
            } else if (handle === "left" || handle === "right") {
                ({ x: dx } = this.getRotatedPoint(0, 0, dx, dy, -angle));
                if (handle === "left") {
                    dx = -dx;
                }
                dy = 0;
            } else if (handle === "top-left" || handle === "top-right" || handle === "bottom-right" || handle === "bottom-left") {
                ({ x: dx, y: dy } = this.getRotatedPoint(0, 0, dx, dy, -angle));
                if (handle.includes("top")) {
                    dy = -dy;
                }
                if (handle.includes("left")) {
                    dx = -dx;
                }

                // if (this.aspectRatio) {
                dy = dx * (initialSelectionBox.height / initialSelectionBox.width);
                // }
            }

            const scaleOrigin = { x: initialSelectionBox.cx, y: initialSelectionBox.cy };
            if (handle === "top") {
                scaleOrigin.x = initialSelectionBox.cx;
                scaleOrigin.y = initialSelectionBox.y + initialSelectionBox.height;
            } else if (handle === "right") {
                scaleOrigin.x = initialSelectionBox.x;
                scaleOrigin.y = initialSelectionBox.cy;
            } else if (handle === "bottom") {
                scaleOrigin.x = initialSelectionBox.cx;
                scaleOrigin.y = initialSelectionBox.y;
            } else if (handle === "left") {
                scaleOrigin.x = initialSelectionBox.x + initialSelectionBox.width;
                scaleOrigin.y = initialSelectionBox.cy;
            } else if (this.cornerResize) {
                if (handle === "top-left") {
                    scaleOrigin.x = initialSelectionBox.x + initialSelectionBox.width;
                    scaleOrigin.y = initialSelectionBox.y + initialSelectionBox.height;
                } else if (handle === "top-right") {
                    scaleOrigin.x = initialSelectionBox.x;
                    scaleOrigin.y = initialSelectionBox.y + initialSelectionBox.height;
                } else if (handle === "bottom-right") {
                    scaleOrigin.x = initialSelectionBox.x;
                    scaleOrigin.y = initialSelectionBox.y;
                } else if (handle === "bottom-left") {
                    scaleOrigin.x = initialSelectionBox.x + initialSelectionBox.width;
                    scaleOrigin.y = initialSelectionBox.y;
                }
            }

            let newWidth = initialSelectionBox.width + dx;
            let newHeight = initialSelectionBox.height + dy;

            const MIN_WIDTH = 20;
            const MIN_HEIGHT = 20;
            if (restrictResizeBelowMin) {
                if (handle === "top" || handle === "bottom") {
                    if (newHeight < MIN_HEIGHT) { // prevent negative scaling
                        newHeight = MIN_HEIGHT;
                    }
                } else if (handle === "left" || handle === "right") {
                    if (newWidth < MIN_WIDTH) { // prevent negative scaling
                        newWidth = MIN_WIDTH;
                    }
                }
            }

            let scale_x = newWidth / initialSelectionBox.width;
            let scale_y = newHeight / initialSelectionBox.height;

            if (this.restrictBounds) {
                // rotation not handled for this.restrictBounds as this is used only for workspaceCamera
                // handle rotation if required in future
                let nx = scaleOrigin.x + (initialSelectionBox.x - scaleOrigin.x) * scale_x;
                let ny = scaleOrigin.y + (initialSelectionBox.y - scaleOrigin.y) * scale_y;
                let nw = newWidth;
                let nh = newHeight;

                if (scale_x < 0) {
                    nx = nx + nw;
                    nw = -nw;
                }
                if (scale_y < 0) {
                    ny = ny + nh;
                    nh = -nh;
                }

                if (nx < 0) {
                    const oldx = nx;
                    nx = 0;
                    const scaleBy = (scaleOrigin.x - nx) / (scaleOrigin.x - oldx);
                    scale_x *= scaleBy;
                    scale_y *= scaleBy;

                    nx = scaleOrigin.x + (initialSelectionBox.x - scaleOrigin.x) * scale_x;
                    ny = scaleOrigin.y + (initialSelectionBox.y - scaleOrigin.y) * scale_y;
                    nw = initialSelectionBox.width * scale_x;
                    nh = initialSelectionBox.height * scale_y;

                    if (scale_x < 0) {
                        nx = nx + nw;
                        nw = -nw;
                    }
                    if (scale_y < 0) {
                        ny = ny + nh;
                        nh = -nh;
                    }
                }

                if (nx + nw > this.props.workspaceWidth) {
                    const oldx2 = nx + nw;
                    const newx2 = this.props.workspaceWidth;
                    const scaleBy = (scaleOrigin.x - newx2) / (scaleOrigin.x - oldx2);
                    scale_x *= scaleBy;
                    scale_y *= scaleBy;

                    nx = scaleOrigin.x + (initialSelectionBox.x - scaleOrigin.x) * scale_x;
                    ny = scaleOrigin.y + (initialSelectionBox.y - scaleOrigin.y) * scale_y;
                    nw = initialSelectionBox.width * scale_x;
                    nh = initialSelectionBox.height * scale_y;

                    if (scale_x < 0) {
                        nx = nx + nw;
                        nw = -nw;
                    }
                    if (scale_y < 0) {
                        ny = ny + nh;
                        nh = -nh;
                    }
                }

                if (ny < 0) {
                    const oldy = ny;
                    ny = 0;
                    const scaleBy = (scaleOrigin.y - ny) / (scaleOrigin.y - oldy);
                    scale_x *= scaleBy;
                    scale_y *= scaleBy;

                    nx = scaleOrigin.x + (initialSelectionBox.x - scaleOrigin.x) * scale_x;
                    ny = scaleOrigin.y + (initialSelectionBox.y - scaleOrigin.y) * scale_y;
                    nw = initialSelectionBox.width * scale_x;
                    nh = initialSelectionBox.height * scale_y;

                    if (scale_x < 0) {
                        nx = nx + nw;
                        nw = -nw;
                    }
                    if (scale_y < 0) {
                        ny = ny + nh;
                        nh = -nh;
                    }
                }

                if (ny + nh > this.props.workspaceHeight) {
                    const oldy2 = ny + nh;
                    const newy2 = this.props.workspaceHeight;
                    const scaleBy = (scaleOrigin.y - newy2) / (scaleOrigin.y - oldy2);
                    scale_x *= scaleBy;
                    scale_y *= scaleBy;

                    nx = scaleOrigin.x + (initialSelectionBox.x - scaleOrigin.x) * scale_x;
                    ny = scaleOrigin.y + (initialSelectionBox.y - scaleOrigin.y) * scale_y;
                    nw = initialSelectionBox.width * scale_x;
                    nh = initialSelectionBox.height * scale_y;

                    if (scale_x < 0) {
                        nx = nx + nw;
                        nw = -nw;
                    }
                    if (scale_y < 0) {
                        ny = ny + nh;
                        nh = -nh;
                    }
                }
            }

            let cx = (initialSelectionBox.cx - scaleOrigin.x) * scale_x;
            let cy = (initialSelectionBox.cy - scaleOrigin.y) * scale_y;

            ({ x: cx, y: cy } = this.getRotatedPoint(0, 0, cx, cy, angle));
            const rotatedScaleOrigin = this.getRotatedPoint(initialSelectionBox.cx, initialSelectionBox.cy, scaleOrigin.x, scaleOrigin.y, angle);
            cx = rotatedScaleOrigin.x + cx;
            cy = rotatedScaleOrigin.y + cy;

            let resizePositionResult = { scale_x, scale_y, cx, cy };

            if (otherOptions.shouldSnap) {
                resizePositionResult = this.getSnappedResizePosition({
                    initialSelectionBox: this.initialSelectionBox,
                    cornerResize: this.cornerResize,
                    resizePositionResult,
                });
            }

            return resizePositionResult;
        } catch (error) { }
        return undefined;
    }

    /* eslint-disable consistent-return, no-labels, no-unused-labels */
    getFontSize(text) {
        const { lineHeight, isSplitText } = text;
        let container = document.getElementById(text.id);
        let ourText = container.getElementsByClassName("text-container")[0];
        let isTextEmpty = (ourText.innerText.length === 0);

        if (isTextEmpty) {
            ourText.innerText = '...';
        }
        if (ourText.style.fontSize === "")
            ourText.style.fontSize = "16px";
        let fontSize = parseFloat(ourText.style.fontSize, 10).toFixed(2);
        let maxHeight = text.height * this.props.zoomFactor;
        let textHeight = ourText.clientHeight, i, j;
        if (isSplitText) {
            textHeight = ourText.getBoundingClientRect().height
        }
        if (textHeight > maxHeight) {
            reached:
            for (i = 0; i < 500; i++) {
                if (textHeight < maxHeight) {
                    fontSize = fontSize + 1
                    for (j = 0; j < 10; j++) {
                        let decFontSize = fontSize - ((j + 1) / 10);
                        ourText.style.fontSize = decFontSize + "px";
                        textHeight = ourText.clientHeight;
                        if (isSplitText) {
                            ourText.parentElement.style.lineHeight = lineHeight * decFontSize + "px"
                            textHeight = ourText.getBoundingClientRect().height;
                        }
                        if (textHeight <= maxHeight) {
                            ourText.style.fontSize = decFontSize + "px";
                            if (isTextEmpty) {
                                ourText.innerText = "";
                            }
                            return decFontSize + "px";
                            // break reached;
                        }
                    }
                    ourText.style.fontSize = fontSize + "px";
                    if (isTextEmpty) {
                        ourText.innerText = "";
                    }
                    return fontSize + "px";
                    // break;
                }
                fontSize--;
                ourText.style.fontSize = fontSize + "px";
                if (isTextEmpty) {
                    ourText.innerText = "";
                }
                textHeight = ourText.clientHeight;
                if (isSplitText) {
                    ourText.parentElement.style.lineHeight = lineHeight * fontSize + "px"
                    textHeight = ourText.getBoundingClientRect().height
                }
            }
        } else if (textHeight < maxHeight) {
            reached:
            for (i = 0; i < 500; i++) {
                if (textHeight > maxHeight) {
                    for (j = 0; j < 10; j++) {
                        let decFontSize = fontSize - ((j + 1) / 10);
                        ourText.style.fontSize = decFontSize + "px";
                        textHeight = ourText.clientHeight;
                        if (isSplitText) {
                            ourText.parentElement.style.lineHeight = lineHeight * decFontSize + "px"
                            textHeight = ourText.getBoundingClientRect().height
                        }
                        if (textHeight <= maxHeight) {
                            ourText.style.fontSize = decFontSize + "px";
                            if (isTextEmpty) {
                                ourText.innerText = "";
                            }
                            return decFontSize + "px";
                            // break reached;
                        }
                    }
                    fontSize = fontSize - 1;
                    ourText.style.fontSize = fontSize + "px";
                    if (isTextEmpty) {
                        ourText.innerText = "";
                    }
                    return fontSize + "px";
                    // break;
                }
                fontSize++;
                ourText.style.fontSize = fontSize + "px";
                textHeight = ourText.clientHeight;
                if (isSplitText) {
                    ourText.parentElement.style.lineHeight = lineHeight * fontSize + "px"
                    textHeight = ourText.getBoundingClientRect().height
                }
            }
        }
        else {
            if (isTextEmpty) {
                ourText.innerText = "";
            }
            return ourText.style.fontSize + "px";
        }

        if (isTextEmpty) {
            ourText.innerText = "";
        }
    }
    /* eslint-enable consistent-return, no-labels, no-unused-labels */

    round = (n, d = 2) => {
        n = n.toString().split('e');
        let offset = 0;
        if (n[1]) offset = Number(n[1]);
        return Number(Math.round(n[0] + "e" + (offset + d)) + "e" + (offset - d));
    }

    updateResizeValues(e) {
        try {
            let handle = this.initialSelectionBox.handle;
            let selectedId = this.selectedItems.keySeq().toArray()[0];
            let isOneDirectionalScale = false;
            if (this.isTextOnly() && this.multimoveId === null) {
                let textBounds = this.getTextBounds(e, selectedId);
                this.updateInitialSelectionBox({ transforming: false, resizing: false, x: textBounds.x, y: textBounds.y, width: textBounds.width, height: textBounds.height, cx: textBounds.x + textBounds.width / 2, cy: textBounds.y + textBounds.height / 2 });

                this.selectedItems = this.selectedItems.set(selectedId, this.selectedItems.get(selectedId).merge(fromJS(textBounds)));

                if (handle !== "left" && handle !== "right") {
                    const isSplitText = this.selectedItems.getIn([selectedId, "textData", "formats", "containerStyle", "boxDesign"]) === "split";
                    const lineHeight = this.selectedItems.getIn([selectedId, "textData", "formats", "containerStyle", "lineHeight"])
                    let fontSize = parseFloat(this.getFontSize({ id: selectedId, width: textBounds.width - this.props.textOffset, height: textBounds.height - this.props.textOffset, isSplitText, lineHeight }), 10);
                    this.selectedItems = this.selectedItems.setIn([selectedId, "textData", "formats", "containerStyle", "fontSize"], (fontSize / this.props.zoomFactor).toFixed(2) + "px");
                }

                if (!this.props.isMultimove) {
                    this.props.resizeUpdate({
                        "items": this.selectedItems,
                        "children": this.groupedItems,
                        "currentContainer": this.currentItemContainer,
                        selectedItems: this.props.selectedItems,
                        multimoveId: this.multimoveId,
                        anchorScales: this.anchorScales
                    });
                }
                else {
                    this.selectionBox.rotateCount = this.rotateCount;
                    const restrictResizeBelowMin = ((this.isImageOnly() || this.isGridOnly()) && (handle === "left" || handle === "right" || handle === "top" || handle === "bottom"));
                    let resize_position = this.getResizePosition(e, restrictResizeBelowMin);
                    this.props.setKeyframeSelection({ selectedItems: this.selectedItems, selectionBox: this.selectionBox, scaleX: resize_position.scale_x, scaleY: resize_position.scale_y });
                }
            } else {
                const restrictResizeBelowMin = ((this.isImageOnly() || this.isGridOnly()) && (handle === "left" || handle === "right" || handle === "top" || handle === "bottom"));
                let resize_position = this.getResizePosition(e, restrictResizeBelowMin);
                let scale_x = resize_position.scale_x, scale_y = resize_position.scale_y, cx = resize_position.cx, cy = resize_position.cy, new_width = Math.abs(this.initialSelectionBox.width * scale_x), new_height = Math.abs(this.initialSelectionBox.height * scale_y), new_x = cx - new_width / 2, new_y = cy - new_height / 2;

                this.updateInitialSelectionBox({ transforming: false, resizing: false, x: new_x, y: new_y, width: new_width, height: new_height, cx: new_x + new_width / 2, cy: new_y + new_height / 2 });
                let allItems = this.selectedItems;

                this.selectedItems.entrySeq().forEach(([key, item]) => {
                    let item_width = Math.abs(item.get("width") * scale_x), item_height = Math.abs(item.get("height") * scale_y);

                    let item_x = Math.cos((item.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(item.get("dis_to_center") * scale_x) + cx - item_width / 2;
                    let item_y = Math.sin((item.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(item.get("dis_to_center") * scale_y) + cy - item_height / 2;
                    let item_to_update = Map({ "x": item_x, "y": item_y, "width": item_width, "height": item_height });

                    if (item.get("type") === "GROUP") {
                        let groupItems = this.props.selectedChildren;

                        item.get("groupChildren").entrySeq().forEach(([childKey, itemId]) => {
                            let child = groupItems.get(itemId);
                            let childWidth = Math.abs(child.get("width") * scale_x), childHeight = Math.abs(child.get("height") * scale_y);
                            let groupedItem = this.groupedItems.get(itemId);

                            let childGX = Math.cos((groupedItem.get("angle_to_center") + item.get("angle")) * Math.PI / 180) * Math.abs(groupedItem.get("dis_to_center") * scale_x) + (item_x + item_width / 2) - childWidth / 2;
                            let childGY = Math.sin((groupedItem.get("angle_to_center") + item.get("angle")) * Math.PI / 180) * Math.abs(groupedItem.get("dis_to_center") * scale_y) + (item_y + item_height / 2) - childHeight / 2;

                            // let currentChildItem    = document.getElementById(itemId);
                            let childToUpdate = Map({ "x": parseFloat(childGX.toFixed(2) + item_x.toFixed(2)), "y": parseFloat(childGY.toFixed(2) + item_x.toFixed(2)), "height": childHeight });

                            if (item.get("subType") === "TXTGRP" && item.get("isExpands")) {
                                let svgItem = groupItems.filter((obj, key) => item.get("groupChildren").indexOf(key) !== -1).find(x => x.get("type") === "OVLYSVG");
                                let xScale = scale_x;
                                if (handle === "left" || handle === "right")
                                    xScale = 1;

                                if (child.get("type") === "TEXT") {
                                    childToUpdate = childToUpdate.set("width", (item_width - (svgItem.getIn(["groupBounds", "leftItem", "width"]) * svgItem.getIn(["groupBounds", "leftItem", "scaleX"]) * xScale) - (svgItem.getIn(["groupBounds", "rightItem", "width"]) * svgItem.getIn(["groupBounds", "rightItem", "scaleX"]) * xScale)));
                                    childToUpdate = childToUpdate.set("x", item_x + (svgItem.getIn(["groupBounds", "leftItem", "width"]) * svgItem.getIn(["groupBounds", "leftItem", "scaleX"]) * xScale));
                                }
                                else {
                                    childToUpdate = childToUpdate.set("x", parseFloat(childGX.toFixed(2) + item_x.toFixed(2)));
                                    childToUpdate = childToUpdate.set("width", childWidth);
                                }
                            }
                            else {
                                childToUpdate = childToUpdate.set("x", parseFloat(childGX.toFixed(2) + item_x.toFixed(2)));
                                childToUpdate = childToUpdate.set("width", childWidth);
                            }

                            if (child.get("type") === "TEXT" || child.get("type") === "SHAPE") {
                                if (this.isFontAutoSize(itemId, "workspaceChildren") || (handle === "top-left" || handle === "top-right" || handle === "bottom-left" || handle === "bottom-right")) {
                                    let widthRatio = child.get("widthRatio") !== undefined && child.get("widthRatio") !== null ? Math.round(child.get("widthRatio")) : (child.get("type") === "SHAPE") ? 90 : 100;
                                    let heightRatio = child.get("heightRatio") !== undefined && child.get("heightRatio") !== null ? Math.round(child.get("heightRatio")) : (child.get("type") === "SHAPE") ? 90 : 100;

                                    let textWidth = (widthRatio / 100) * childWidth;
                                    let textHeight = (heightRatio / 100) * childHeight;
                                    if (child.get("type") !== "SHAPE" && item.get("subType") !== "TXTGRP") {
                                        textWidth -= this.props.textOffset;
                                        textHeight -= this.props.textOffset;
                                    }
                                    const isSplitText = child.getIn(["textData", "formats", "containerStyle", "boxDesign"]) === 'split';
                                    const lineHeight = child.getIn(["textData", "formats", "containerStyle", "lineHeight"]);
                                    let fontSize = parseFloat(this.getFontSize({ id: itemId, width: textWidth, height: textHeight, isGrouped: true, parentId: key, isSplitText, lineHeight }), 10);
                                    childToUpdate = childToUpdate.set("textData", child.setIn(["textData", "formats", "containerStyle", "fontSize"], (fontSize / this.props.zoomFactor).toFixed(2) + "px").get("textData"));
                                }
                            }

                            if (child.get("type") === "SHAPE") {
                                let currentItem = child.set("width", childWidth).set("height", childHeight);
                                let pathDetails = getPath(currentItem, this.props.zoomFactor, child);
                                let pathData = childToUpdate.get("pathData") !== undefined ? childToUpdate.get("pathData").merge(fromJS(pathDetails.pathData)) : fromJS(pathDetails.pathData);
                                childToUpdate = childToUpdate.set("pathData", pathData);

                                let dataPath = getPath(currentItem, 1, child).pathDetails;
                                childToUpdate = childToUpdate.set("path", fromJS(dataPath));
                            }
                            if (child.get("type") === "GRID") {
                                /*
                                const gridItem = child.get("gridData");
                                let gap = gridItem.get("gap");
                                let cells = child.getIn(["gridData", "gridCells"]);
                                child.getIn(["gridData", "gridCells"]).entrySeq().forEach(([key, val]) => {
                                    let cellDetails = val;
                                    let { gridWidth, gridHeight } = getGridCellBounds({ cellDetails, gridItem, gap, gridWidth: childWidth, gridHeight: childHeight })

                                    let cellWidth = gridWidth;
                                    let cellHeight = gridHeight;
                                    cells = cells.setIn([key, "cellWidth"], cellWidth);
                                    cells = cells.setIn([key, "cellHeight"], cellHeight);
                                });
                                childToUpdate = childToUpdate.set("gridData", child.get("gridData"));
                                childToUpdate = childToUpdate.setIn(["gridData", "gridCells"], cells);
                                */
                            }

                            this.groupedItems = this.groupedItems.set(itemId, groupedItem.merge(childToUpdate));
                        })
                    }

                    if (item.get("type") === "SHAPE") {
                        let currentItem = item.set("width", item_width).set("height", item_height);
                        let pathDetails = getPath(currentItem, 1, item);

                        let dataPath = pathDetails.pathDetails;
                        item_to_update = item_to_update.set("path", fromJS(dataPath));
                    }
                    if ((item.get("type") === "TEXT" || (item.get("type") === "SHAPE"))) {
                        let textWidth = item_width;
                        let textHeight = item_height;
                        if (item.get("type") !== "SHAPE") {
                            textWidth -= this.props.textOffset;
                            textHeight -= this.props.textOffset;
                        } else {
                            let widthRatio = item.get("widthRatio") !== undefined && item.get("widthRatio") !== null ? Math.round(item.get("widthRatio")) : 90;
                            let heightRatio = item.get("heightRatio") !== undefined && item.get("heightRatio") !== null ? Math.round(item.get("heightRatio")) : 90;

                            textWidth = (widthRatio / 100) * textWidth;
                            textHeight = (heightRatio / 100) * textHeight;
                        }

                        /**
                         * -----------------------------------------------------------------------
                         * The following block is used to prevent crash of the component when
                         * computing fontsize for text keyframe when resizing a text having
                         * multimove applied.
                         *
                         * Ideology:
                         *     Identify the parent id for a given keyframe and use that to calculate
                         *     fontSize
                         * -----------------------------------------------------------------------
                         */
                        let keyId = key;

                        if (!getElement(`#${key}`)) {
                            this.props.selectedObjects.entrySeq().forEach(([itemId, obj]) => {
                                if (obj.get('keyframes') && obj.get('keyframes').size > 1) {
                                    obj.get("keyframes").forEach(keyFrameObj => {
                                        if (keyFrameObj.get('id') === key) {
                                            keyId = itemId;
                                        }
                                    })
                                }
                            })
                        }
                        /**
                         * -----------------------------------------------------------------------
                         * END OF BLOCK
                         * -----------------------------------------------------------------------
                         */
                        const isSplitText = item.getIn(["textData", "formats", "containerStyle", "boxDesign"]) === "split";
                        const lineHeight = item.getIn(["textData", "formats", "containerStyle", "lineHeight"]);
                        let fontSize = parseFloat(this.getFontSize({ id: keyId, width: textWidth, height: textHeight, isSplitText, lineHeight }));

                        if (this.multimoveId === null) {
                            if (this.isFontAutoSize(key) || (handle === "top-left" || handle === "top-right" || handle === "bottom-left" || handle === "bottom-right") || item.get("type") === "SHAPE") {
                                item_to_update = item_to_update.set("textData", item.setIn(["textData", "formats", "containerStyle", "fontSize"], (fontSize / this.props.zoomFactor).toFixed(2) + "px").get("textData"));
                            }
                        } else {
                            let actualFontSize = parseFloat(item.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10);
                            let initialSize = parseFloat(this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "textData", "formats", "containerStyle", "fontSize"]), 10);

                            let fontScale = fontSize / initialSize;

                            item_to_update = item_to_update.set("textData", item.setIn(["textData", "formats", "containerStyle", "fontSize"], (actualFontSize * fontScale).toFixed(2) + "px").get("textData"));
                        }
                    }

                    if (item.get('type') === "GRID") {
                        /*
                        const gridItem = item.get("gridData");
                        let gap = gridItem.get("gap");
                        let cells = item.getIn(["gridData", "gridCells"]);
                        item.getIn(["gridData", "gridCells"]).entrySeq().forEach(([key, val]) => {

                            // let gridWidth = item_width; //* (val.get("cols") / colCount);
                            // let gridHeight = item_height; //* (val.get("rows") / rowCount);
                            // gridWidth -= gap - (gap * (val.get("cols") / colCount));
                            // gridHeight -= gap - (gap * (val.get("rows") / rowCount));

                            let cellDetails = val;
                            let { gridWidth, gridHeight } = getGridCellBounds({ cellDetails, gridItem, gap, gridWidth: item_width, gridHeight: item_height });

                            let cellWidth = gridWidth;
                            let cellHeight = gridHeight;

                            cells = cells.setIn([key, "cellWidth"], cellWidth);
                            cells = cells.setIn([key, "cellHeight"], cellHeight);
                            cells = cells.setIn([key, "x"], val.get("x") * scale_x);
                            cells = cells.setIn([key, "y"], val.get("y") * scale_y);
                            if (val.get("imgDetails")) {
                                const imgDetails = val.get("imgDetails")
                                if (!this.aspectRatio) {
                                    let ow = imgDetails.getIn(["original", "width"]),
                                        oh = imgDetails.getIn(["original", "height"]),
                                        ox = imgDetails.getIn(["original", "x"]),
                                        oy = imgDetails.getIn(["original", "y"]);

                                    if (handle === "left" || handle === "right") {
                                        let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                        if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                            dy = 0;
                                        oh = imgDetails.getIn(["original", "height"]) * scale_x;
                                        oy = -1 * ((oh - 1) * (1 - dy));
                                        if (oh + oy <= 1) {
                                            ow = imgDetails.getIn(["original", "width"]) * (1 / oh);
                                            let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                            if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                                dx = 0.5;
                                            ox = -1 * ((ow - 1) * (1 - dx));
                                            oh = 1
                                            oy = 0;
                                        }
                                        if (ow < 1) {
                                            oh = oh * (1 / ow);
                                            let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                            if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                                dy = 0.5;
                                            oy = -1 * ((oh - 1) * (1 - dy));
                                            ow = 1
                                            ox = 0;
                                        }
                                    } else if (handle === "top" || handle === "bottom") {
                                        let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                        if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                            dx = 0;
                                        ow = imgDetails.getIn(["original", "width"]) * scale_y;
                                        ox = -1 * ((ow - 1) * (1 - dx));
                                        if (ow + ox <= 1) {
                                            oh = imgDetails.getIn(["original", "height"]) * (1 / ow);
                                            let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                            if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                                dy = 0.5;
                                            oy = -1 * ((oh - 1) * (1 - dy));
                                            ow = 1
                                            ox = 0;
                                        }
                                        if (oh < 1) {
                                            ow = ow * (1 / oh);
                                            let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                            if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                                dx = 0.5;
                                            ox = -1 * ((ow - 1) * (1 - dx));
                                            oh = 1
                                            oy = 0;
                                        }
                                    }
                                    cells = cells.setIn([key, "imgDetails", "original"], fromJS({ x: ox, y: oy, width: ow, height: oh }));
                                }
                            }

                        })
                        item_to_update = item_to_update.set("gridData", item.get("gridData"));
                        item_to_update = item_to_update.setIn(["gridData", "gridCells"], cells);
                        */
                    }

                    if (this.props.selectedItems.size === 1
                        && (handle === "left" || handle === "right" || handle === "top" || handle === "bottom")
                        && (isImageOnly(item.get("type"), item.get("subType")) || isVideoOnly(item.get("type"), item.get("subType")))) {

                        /* this might be useful when non proportional scaling is introduced for multimove
                        let removeTranslateForKey = (this.multimoveId)? this.multimoveId: this.props.selectedItems.get(0); */

                        let removeTranslateForKey = this.props.selectedItems.get(0);
                        const imgPlot = this.handleImageScaling(item, key, scale_x, scale_y, handle, this.props.zoomFactor, { updateDom: true, removeTranslateForKey: removeTranslateForKey });
                        item_to_update = item_to_update.set("isCropped", true)
                            .setIn(["original", "width"], imgPlot.w / item_width)
                            .setIn(["original", "height"], imgPlot.h / item_height)
                            .setIn(["original", "x"], imgPlot.x / item_width)
                            .setIn(["original", "y"], imgPlot.y / item_height);

                        isOneDirectionalScale = true;
                    }
                    allItems = allItems.set(key, item.merge(item_to_update));
                });
                this.selectedItems = allItems;

                if (!this.props.isMultimove) {
                    if (this.multimoveId !== null) {
                        let item = this.props.selectedObjects.get(this.multimoveId);
                        item.get("keyframes").entrySeq().forEach(([key, keyframe]) => {
                            let anchors = keyframe.get("anchors");
                            if (anchors !== undefined && anchors.size > 0) {
                                if (anchors.size > 0) {
                                    keyframe.get("anchors").entrySeq().forEach(([keyIndex, keyItem]) => {
                                        anchors = anchors.set(keyIndex, fromJS({ x: this.selectionBox.x + (this.selectionBox.width * this.anchorScales[key][keyIndex].sw), y: this.selectionBox.y + (this.selectionBox.height * this.anchorScales[key][keyIndex].sh) }));
                                    });
                                }
                            }
                            this.selectedItems = this.selectedItems.setIn([keyframe.get("id"), "anchors"], fromJS(anchors));
                        });
                    }

                    this.props.resizeUpdate({
                        "items": this.selectedItems,
                        "children": this.groupedItems,
                        "currentContainer": this.currentItemContainer,
                        selectedItems: this.props.selectedItems,
                        multimoveId: this.multimoveId,
                        anchorScales: this.anchorScales,
                        isShapeOnly: this.isShapeOnly(),
                        isOneDirectionalScale: isOneDirectionalScale,
                    });

                }
                else {
                    this.selectionBox.rotateCount = this.rotateCount;
                    const restrictResizeBelowMin = ((this.isImageOnly() || this.isGridOnly()) && (handle === "left" || handle === "right" || handle === "top" || handle === "bottom"));
                    let resize_position = this.getResizePosition(e, restrictResizeBelowMin);
                    this.props.setKeyframeSelection({ selectedItems: this.selectedItems, selectionBox: this.selectionBox, scaleX: resize_position.scale_x, scaleY: resize_position.scale_y });
                }
            }
        } catch (error) { }
    }

    initiateResize(e) {
        try {
            e.preventDefault();

            this.scaleX = 1; this.scaleY = 1;
            let isResizeLocked = Boolean(this.lockResize !== undefined && this.lockResize);

            if (!isResizeLocked) {
                let handle = e.target.getAttribute("data-handle");
                if (this.isTextOnly() && (handle === "top-left" || handle === "top-right" || handle === "bottom-left" || handle === "bottom-right") && !this.props.isMultimove) {
                    this.cornerResize = true;
                }
                if (this.isTextOnly() && this.props.isMultimove)
                    this.cornerResize = false;
                if (this.isGrid() && (handle === "top-left" || handle === "top-right" || handle === "bottom-left" || handle === "bottom-right") && !this.props.isMultimove) {
                    this.aspectRatio = true;
                }
                else this.aspectRatio = false;

                let corner_x = this.selectionBox.x, corner_y = this.selectionBox.y, center_x = this.selectionBox.cx, center_y = this.selectionBox.cy,
                    width = this.selectionBox.width, height = this.selectionBox.height;

                if (!this.cornerResize) {
                    corner_x = corner_x + width / 2;
                    corner_y = corner_y + height / 2;
                }
                else if (handle === "top-right") {
                    corner_y = corner_y + height;
                }
                else if (handle === "top-left") {
                    corner_y = corner_y + height;
                    corner_x = corner_x + width;
                }
                else if (handle === "bottom-left")
                    corner_x = corner_x + width;
                else if (handle === "right") {
                    corner_y = corner_y + height / 2;
                }
                else if (handle === "left") {
                    corner_x = corner_x + width;
                    corner_y = corner_y + height / 2;
                }
                else if (handle === "top") {
                    corner_y = corner_y + height;
                    corner_x = corner_x + width / 2;
                }
                else if (handle === "bottom") {
                    corner_x = corner_x + width / 2;
                }

                if (this.props.isMultiMove) {
                    // this.toolBar.style.display = "none";
                }

                if (this.multimoveId !== null) {
                    let selectedItem = this.props.selectedObjects.get(this.multimoveId);
                    this.anchorScales = [];
                    selectedItem.get("keyframes").entrySeq().forEach(([key, keyframe]) => {
                        let anchors = [];
                        if (selectedItem.get("isMultimove"))
                            anchors = keyframe.get("anchors");
                        else {
                            if (key === 0) {
                                anchors.push({ x: (keyframe.get("x") + keyframe.get("width") / 2), y: (keyframe.get("y") + keyframe.get("height") / 2) }, { x: (keyframe.get("x") + keyframe.get("width") / 2), y: (keyframe.get("y") + keyframe.get("height") / 2) });
                            } else {
                                anchors.push({ x: (selectedItem.getIn(["keyframes", key - 1, "x"]) + selectedItem.getIn(["keyframes", key - 1, "width"]) / 2), y: (selectedItem.getIn(["keyframes", key - 1, "y"]) + selectedItem.getIn(["keyframes", key - 1, "height"]) / 2) }, { x: (selectedItem.getIn(["keyframes", key, "x"]) + selectedItem.getIn(["keyframes", key, "width"]) / 2), y: (selectedItem.getIn(["keyframes", key, "y"]) + selectedItem.getIn(["keyframes", key, "height"]) / 2) });
                            }
                            anchors = fromJS(anchors);
                        }

                        this.anchorScales[key] = [];
                        if (anchors !== undefined && anchors.size > 0) {
                            anchors.entrySeq().forEach(([aIndex, anchor]) => {
                                this.anchorScales[key][aIndex] = { sw: (anchor.get("x") - this.selectionBox.x) / width, sh: (anchor.get("y") - this.selectionBox.y) / height, x: anchor.get("x"), y: anchor.get("y") };
                            });
                        }
                    });
                    this.props.initiateResize(this.anchorScales);
                }

                let distance = Math.sqrt((Math.pow(center_x - corner_x, 2)) + (Math.pow(center_y - corner_y, 2))), angle_to_center = Math.atan2(center_y - corner_y, center_x - corner_x) * (180 / Math.PI), angle_to_corner = Math.atan2(corner_y - center_y, corner_x - center_x) * (180 / Math.PI);

                let tempX = this.selectionBox.x - (this.selectionBox.x + this.selectionBox.width / 2);
                let tempY = this.selectionBox.y - (this.selectionBox.y + this.selectionBox.height / 2);

                // now apply rotation
                let rotatedX = tempX * Math.cos(this.selectionBox.angle * (Math.PI / 180)) - tempY * Math.sin(this.selectionBox.angle * (Math.PI / 180));
                let rotatedY = tempX * Math.sin(this.selectionBox.angle * (Math.PI / 180)) + tempY * Math.cos(this.selectionBox.angle * (Math.PI / 180));

                // translate back
                let rPx = this.selectionBox.x + (rotatedX + (this.selectionBox.width / 2));
                let rPy = this.selectionBox.y + (rotatedY + (this.selectionBox.height / 2));

                this.updateInitialSelectionBox({ transforming: true, resizing: true, handle: handle, distance: distance, angle_to_center: angle_to_center, angle_to_corner: angle_to_corner, rotated_x: rPx, rotated_y: rPy });

                if (!this.props.isMultimove)
                    this.props.updateTransformStatus({ transforming: true, resizing: true });

                let mouse_position = this.getMousePosition(e);
                this.mouseStartPosition = Object.assign(this.mouseStartPosition, { x: mouse_position.x, y: mouse_position.y });

                if (this.isTextOnly() && this.multimoveId === null) {
                    window.addEventListener('mousemove', this.resizeTextContainer, false);
                    window.addEventListener('touchmove', this.resizeTextContainer, false);
                }
                else {
                    window.addEventListener('mousemove', this.resizeSelection, false);
                    window.addEventListener('touchmove', this.resizeSelection, false);
                }

                window.addEventListener('mouseup', this.stopResize, false);
                window.addEventListener('touchend', this.stopResize, false);
            }
        } catch (error) { }
    }

    handleImageWidthScale = (initialWidth, initialHeight, initialY, currentWidth, item_height) => {
        const originalRatio = initialHeight / initialWidth;
        const scaledHeight = currentWidth * originalRatio;
        const dH = scaledHeight - initialHeight;
        const top_ratio = (Math.abs(initialY) + item_height / 2) / initialHeight;
        const currentY = initialY - (dH * top_ratio);

        return { scaledHeight, currentY };
    }

    handleImageHeightScale = (initialWidth, initialHeight, initialX, currentHeight, item_width) => {
        const originalRatio = initialHeight / initialWidth;
        const scaledWidth = currentHeight / originalRatio;
        const dW = scaledWidth - initialWidth;
        const left_ratio = (Math.abs(initialX) + item_width / 2) / initialWidth;
        const currentX = initialX - (dW * left_ratio);

        return { scaledWidth, currentX };
    }

    handleImageScaling = (item, key, scale_x, scale_y, handle, zoomFactor, options = { updateDom: true, removeTranslateForKey: null }) => {
        const updateImageDomObj = (left, top, width, height, options = { useTransform: true, key: null }) => {
            if (options.key === null) return;

            let elIndex = 0;
            let placeholderEl;
            let tagName = "img";
            if (isVideoOnly(item.get("type"), item.get("subType"))) {
                tagName = "video";
            }
            if (item.get("chromaKey") || item.get("enableBgRemoval")) {
                tagName = "canvas";
                placeholderEl = getElement(`#${options.key} img`);
                if (!item.get("enableBgRemoval")) {
                    elIndex = 1;
                }
            }
            let imageEl = document.getElementById(options.key).getElementsByTagName(tagName)[elIndex];

            if (options.useTransform) {
                imageEl.style.left = "0px";
                imageEl.style.top = "0px";
                imageEl.style.transform = `translate(${left * zoomFactor}px, ${top * zoomFactor}px)`;
                if (placeholderEl) {
                    placeholderEl.style.left = "0px";
                    placeholderEl.style.top = "0px";
                    placeholderEl.style.transform = `translate(${left * zoomFactor}px, ${top * zoomFactor}px)`;
                }
            } else {
                imageEl.style.left = left * zoomFactor + "px";
                imageEl.style.top = top * zoomFactor + "px";
                imageEl.style.transform = "";
                if (placeholderEl) {
                    placeholderEl.style.left = left * zoomFactor + "px";
                    placeholderEl.style.top = top * zoomFactor + "px";
                    placeholderEl.style.transform = "";
                }
            }
            imageEl.style.width = width * zoomFactor + "px";
            imageEl.style.height = height * zoomFactor + "px";
            if (placeholderEl) {
                placeholderEl.style.width = width * zoomFactor + "px";
                placeholderEl.style.height = height * zoomFactor + "px";
            }
        }

        const item_new_w = item.get("width") * scale_x,
            item_new_h = item.get("height") * scale_y;

        const img_initial_w = item.getIn(["original", "width"]) ? item.get("width") * item.getIn(["original", "width"]) : item.get("width"),
            img_initial_h = item.getIn(["original", "height"]) ? item.get("height") * item.getIn(["original", "height"]) : item.get("height"),
            img_initial_x = item.getIn(["original", "x"]) ? item.getIn(["original", "x"]) * item.get("width") : 0,
            img_initial_y = item.getIn(["original", "y"]) ? item.getIn(["original", "y"]) * item.get("height") : 0;

        let imgPlot = null;

        if (handle === "right") {
            imgPlot = { x: this.round(img_initial_x), y: this.round(img_initial_y), w: this.round(img_initial_w), h: this.round(img_initial_h) };
            const img_initial_left = Math.abs(img_initial_x);
            const img_current_right = img_initial_w - (img_initial_left + item_new_w);

            if (img_current_right <= 0) { // if no space is on right, scale it
                const item_max_width_before_scale = img_initial_w - img_initial_left;
                const item_width_ratio = item_new_w / item_max_width_before_scale;

                const img_current_x = -(img_initial_left * item_width_ratio);
                const img_current_w = item_new_w + Math.abs(img_current_x);

                const { scaledHeight: img_scaled_h, currentY: img_current_y } = this.handleImageWidthScale(img_initial_w, img_initial_h, img_initial_y, img_current_w, item_new_h);
                imgPlot = { x: this.round(img_current_x), y: this.round(img_current_y), w: this.round(img_current_w), h: this.round(img_scaled_h) };
            }
        } else if (handle === "left") {
            const img_current_x = item_new_w - item.get("width") + img_initial_x;
            imgPlot = { x: this.round(img_current_x), y: this.round(img_initial_y), w: this.round(img_initial_w), h: this.round(img_initial_h) };
            const img_current_left = -img_current_x;

            if (img_current_left <= 0) { // if no space is on left, scale it
                const img_initial_left = Math.abs(img_initial_x);
                let img_right = img_initial_w - (img_initial_left + item.get("width")); // initial img right

                const item_max_width_before_scale = img_initial_w - img_right;
                const item_width_ratio = item_new_w / item_max_width_before_scale;
                img_right = img_right * item_width_ratio; // space on right after scaling

                const img_current_w = item_new_w + img_right;
                const { scaledHeight: img_scaled_h, currentY: img_current_y } = this.handleImageWidthScale(img_initial_w, img_initial_h, img_initial_y, img_current_w, item_new_h);
                imgPlot = { x: 0, y: this.round(img_current_y), w: this.round(img_current_w), h: this.round(img_scaled_h) };
            }
        } else if (handle === "bottom") {
            imgPlot = { x: this.round(img_initial_x), y: this.round(img_initial_y), w: this.round(img_initial_w), h: this.round(img_initial_h) };
            const img_initial_top = Math.abs(img_initial_y);
            const img_bottom = img_initial_h - (img_initial_top + item_new_h);

            if (img_bottom <= 0) { // if no space is on bottom, scale it
                const item_max_height_before_scale = img_initial_h - img_initial_top;
                const item_height_ratio = item_new_h / item_max_height_before_scale;

                const img_current_y = -(img_initial_top * item_height_ratio);
                const img_current_h = item_new_h + Math.abs(img_current_y);

                const { scaledWidth: img_scaled_w, currentX: img_current_x } = this.handleImageHeightScale(img_initial_w, img_initial_h, img_initial_x, img_current_h, item_new_w);
                imgPlot = { x: this.round(img_current_x), y: this.round(img_current_y), w: this.round(img_scaled_w), h: this.round(img_current_h) };
            }
        } else if (handle === "top") {
            const img_current_y = item_new_h - item.get("height") + img_initial_y;
            imgPlot = { x: this.round(img_initial_x), y: this.round(img_current_y), w: this.round(img_initial_w), h: this.round(img_initial_h) };
            const img_current_top = -img_current_y;

            if (img_current_top <= 0) { // if no space is on top, scale it
                const img_initial_top = Math.abs(img_initial_y);
                let img_bottom = img_initial_h - (img_initial_top + item.get("height"));

                const item_max_height_before_scale = img_initial_h - img_bottom;
                const item_height_ratio = item_new_h / item_max_height_before_scale;
                img_bottom = img_bottom * item_height_ratio;

                const img_current_h = item_new_h + img_bottom;
                const { scaledWidth: img_scaled_w, currentX: img_current_x } = this.handleImageHeightScale(img_initial_w, img_initial_h, img_initial_x, img_current_h, item_new_w);
                imgPlot = { x: this.round(img_current_x), y: 0, w: this.round(img_scaled_w), h: this.round(img_current_h) };
            }
        }

        if (imgPlot !== null && options.updateDom) {
            if (options.removeTranslateForKey === null) {
                updateImageDomObj(imgPlot.x, imgPlot.y, imgPlot.w, imgPlot.h, { useTransform: true, key: key });
            } else {
                updateImageDomObj(imgPlot.x, imgPlot.y, imgPlot.w, imgPlot.h, { useTransform: false, key: options.removeTranslateForKey });
            }
        }

        return imgPlot;
    }

    applyRadiusClipPath(item) {
        const itemId = item.get("id");
        const clipEl = document.getElementById(itemId);
        if (clipEl) {
            let clipPath = getRadiusClipPath(item, this.props.zoomFactor);
            clipPath = `path("${clipPath}")`;
            clipEl.style.clipPath = clipPath;
        }
    }

    resizeSelection(e) {
        try {
            e.preventDefault();
            let handle = this.selectionBox.handle;
            const restrictResizeBelowMin = ((this.isImageOnly() || this.isGridOnly()) && (handle === "left" || handle === "right" || handle === "top" || handle === "bottom"));
            let resize_position = this.getResizePosition(e, restrictResizeBelowMin);

            let scale_x = resize_position.scale_x, scale_y = resize_position.scale_y, cx = resize_position.cx, cy = resize_position.cy, new_width = Math.abs(this.initialSelectionBox.width * scale_x), new_height = Math.abs(this.initialSelectionBox.height * scale_y);

            let selectionX = parseFloat(((cx - new_width / 2) * this.props.zoomFactor).toFixed(2)),
                selectionY = parseFloat(((cy - new_height / 2) * this.props.zoomFactor).toFixed(2)),
                selectionWidth = parseFloat((new_width * this.props.zoomFactor).toFixed(2)), selectionHeight = parseFloat((new_height * this.props.zoomFactor).toFixed(2));

            let selectionBox = this.selectionBoxElement;
            selectionBox.style.transform = "translate(" + selectionX + "px, " + selectionY + "px) rotateZ(" + this.selectionBox.angle + "deg)";
            selectionBox.style.width = selectionWidth + "px";
            selectionBox.style.height = selectionHeight + "px";

            if (selectionWidth < this.SHORT_HANDLE_THRESHOLD || selectionHeight < this.SHORT_HANDLE_THRESHOLD) {
                selectionBox.classList.add("is-short");
            } else {
                selectionBox.classList.remove("is-short");
            }

            if (selectionWidth < 60) {
                if (getElement(".resize-handle.top").className.indexOf("hide-handle") === -1) {
                    getElement(".resize-handle.top").classList.add("hide-handle");
                }
                if (getElement(".resize-handle.bottom").className.indexOf("hide-handle") === -1) {
                    getElement(".resize-handle.bottom").classList.add("hide-handle");
                }
            } else {
                if (getElement(".resize-handle.top").className.indexOf("hide-handle") !== -1) {
                    getElement(".resize-handle.top").classList.remove("hide-handle");
                }
                if (getElement(".resize-handle.bottom").className.indexOf("hide-handle") !== -1) {
                    getElement(".resize-handle.bottom").classList.remove("hide-handle");
                }
            }

            if (selectionHeight < 60) {
                if (getElement(".resize-handle.left").className.indexOf("hide-handle") === -1) {
                    getElement(".resize-handle.left").classList.add("hide-handle");
                }
                if (getElement(".resize-handle.right").className.indexOf("hide-handle") === -1) {
                    getElement(".resize-handle.right").classList.add("hide-handle");
                }
            } else {
                if (getElement(".resize-handle.left").className.indexOf("hide-handle") !== -1) {
                    getElement(".resize-handle.left").classList.remove("hide-handle");
                }
                if (getElement(".resize-handle.right").className.indexOf("hide-handle") !== -1) {
                    getElement(".resize-handle.right").classList.remove("hide-handle");
                }
            }

            if (this.multimoveId === null) {
                this.selectedItems.entrySeq().forEach(([key, item]) => {
                    let item_width = Math.abs(item.get("width") * scale_x), item_height = Math.abs(item.get("height") * scale_y);

                    let item_x = Math.cos((item.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(item.get("dis_to_center") * scale_x) + cx - item_width / 2;
                    let item_y = Math.sin((item.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(item.get("dis_to_center") * scale_y) + cy - item_height / 2;

                    let current_item = document.getElementById(key);
                    let flipPos = item.get("flipPosition");
                    let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
                    current_item.style.transform = "translate(" + (item_x * this.props.zoomFactor).toFixed(2) + "px, " + (item_y * this.props.zoomFactor).toFixed(2) + "px) rotateZ(" + item.get("angle") + "deg)" + flip;

                    current_item.style.width = item_width * this.props.zoomFactor + "px";
                    current_item.style.height = item_height * this.props.zoomFactor + "px";

                    let groupFontSize = null;
                    let textOffset = this.props.textOffset;

                    if (item.get("type") === "GROUP") {
                        if (item.get("subType") === "TXTGRP")
                            textOffset = 0;

                        item.get("groupChildren").entrySeq().forEach(([childKey, itemId]) => {
                            let groupItems = this.props.selectedChildren;
                            let child = groupItems.get(itemId);

                            let childWidth = Math.abs(child.get("width") * scale_x), childHeight = Math.abs(child.get("height") * scale_y);
                            let groupedItem = this.groupedItems.get(itemId);
                            let childX = Math.cos((groupedItem.get("angle_to_center") + item.get("angle")) * Math.PI / 180) * Math.abs(groupedItem.get("dis_to_center") * scale_x) + (item_x + item_width / 2) - childWidth / 2;
                            let childY = Math.sin((groupedItem.get("angle_to_center") + item.get("angle")) * Math.PI / 180) * Math.abs(groupedItem.get("dis_to_center") * scale_y) + (item_y + item_height / 2) - childHeight / 2;

                            let currentChildItem = document.getElementById(itemId);

                            let flipPos = child.get("flipPosition");
                            let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";

                            let transform = "translate(" + (childX.toFixed(2) - item_x.toFixed(2)) * this.props.zoomFactor + "px, " + (childY.toFixed(2) - item_y.toFixed(2)) * this.props.zoomFactor + "px) rotateZ(" + child.get("angle") + "deg)" + flip;
                            if (this.props.isMultimove)
                                transform = "translate(" + (childX.toFixed(2) - item_x.toFixed(2)) * this.props.scaleX * this.props.zoomFactor + "px, " + (childY.toFixed(2) - item_y.toFixed(2)) * this.props.scaleY * this.props.zoomFactor + "px) rotateZ(" + child.get("angle") + "deg)" + flip;
                            currentChildItem.style.transform = transform;
                            currentChildItem.style.width = childWidth * this.props.zoomFactor + "px";
                            currentChildItem.style.height = childHeight * this.props.zoomFactor + "px";

                            if (child.get("type") === "TEXT" || child.get("type") === "SHAPE") {
                                let container = document.getElementById(itemId);

                                if (child.get("type") === "SHAPE") {
                                    container = container.getElementsByClassName("callout-text-container")[0];
                                    let xRatio = child.get("xRatio") !== undefined && child.get("xRatio") !== null ? child.get("xRatio") : 10;
                                    let yRatio = child.get("yRatio") !== undefined && child.get("yRatio") !== null ? child.get("yRatio") : 10;
                                    let widthRatio = child.get("widthRatio") !== undefined && child.get("widthRatio") !== null ? child.get("widthRatio") : 90;
                                    let heightRatio = child.get("heightRatio") !== undefined && child.get("heightRatio") !== null ? child.get("heightRatio") : 90;

                                    container.style.transform = "translate(" + ((childWidth * xRatio * this.props.zoomFactor) / 100) + "px, " + ((childHeight * yRatio * this.props.zoomFactor) / 100) + "px)" + flip;
                                    container.style.width = ((childWidth * widthRatio * this.props.zoomFactor) / 100) + "px";
                                    container.style.height = ((childHeight * heightRatio * this.props.zoomFactor) / 100) + "px";
                                }

                                if (this.isFontAutoSize(itemId, "workspaceChildren") || (handle === "top-left" || handle === "top-right" || handle === "bottom-left" || handle === "bottom-right")) {
                                    let fontSize = parseFloat(child.getIn(["textData", "formats", "containerStyle", "fontSize"]));

                                    let ourText = container.getElementsByClassName("text-container")[0];
                                    if (child.get("type") === "SHAPE")
                                        textOffset = 0;
                                    else if (item.get("subType") !== "TXTGRP")
                                        textOffset = this.props.textOffset;
                                    let tsx = (childWidth - textOffset) / child.get("width"), tsy = (childHeight - textOffset) / child.get("height");
                                    let scaledFontsize = Math.abs((fontSize * (tsx + tsy) / 2));
                                    if (this.props.isMultimove)
                                        scaledFontsize *= Math.abs(Math.min(this.props.scaleX, this.props.scaleY));

                                    if ((child.get("type") === "TEXT") && (child.getIn(["textData", "formats", "containerStyle", "boxDesign"]) === 'split')) {
                                        const lineHeight = child.getIn(["textData", "formats", "containerStyle", "lineHeight"])
                                        ourText.parentElement.style.lineHeight = lineHeight * (scaledFontsize * this.props.zoomFactor) + "px"
                                    }
                                    ourText.style.fontSize = (scaledFontsize * this.props.zoomFactor) + "px";
                                    if (scaledFontsize > groupFontSize || groupFontSize === null)
                                        groupFontSize = scaledFontsize;
                                    if (this.props.textStatus.get("isFocused") && this.props.textStatus.get("id") === itemId) {
                                        this.props.updateTextStatus({ fontSize: (scaledFontsize).toFixed(2) });
                                    }
                                }
                            }

                            if ((isImageOnly(child.get("type"), child.get("subType")) || isVideoOnly(child.get("type"), child.get("subType"))) && child.get("isCropped")) {
                                let elIndex = 0;
                                let placeholderEl;
                                let tagName = "img";
                                if (isVideoOnly(child.get("type"), child.get("subType"))) {
                                    tagName = "video";
                                }
                                if (item.get("chromaKey") || item.get("enableBgRemoval")) {
                                    tagName = "canvas";
                                    placeholderEl = getElement(`#${itemId} img`);
                                    if (!item.get("enableBgRemoval")) {
                                        elIndex = 1;
                                    }
                                }
                                let cropImageElem = document.getElementById(itemId).getElementsByTagName(tagName)[elIndex];
                                cropImageElem.style.left = ((child.getIn(["original", "x"]) * childWidth) * this.props.zoomFactor) + "px";
                                cropImageElem.style.top = ((child.getIn(["original", "y"]) * childHeight) * this.props.zoomFactor) + "px";
                                cropImageElem.style.width = ((child.getIn(["original", "width"]) * childWidth) * this.props.zoomFactor) + "px";
                                cropImageElem.style.height = ((child.getIn(["original", "height"]) * childHeight) * this.props.zoomFactor) + "px";
                                if (placeholderEl) {
                                    placeholderEl.style.left = ((child.getIn(["original", "x"]) * childWidth) * this.props.zoomFactor) + "px";
                                    placeholderEl.style.top = ((child.getIn(["original", "y"]) * childHeight) * this.props.zoomFactor) + "px";
                                    placeholderEl.style.width = ((child.getIn(["original", "width"]) * childWidth) * this.props.zoomFactor) + "px";
                                    placeholderEl.style.height = ((child.getIn(["original", "height"]) * childHeight) * this.props.zoomFactor) + "px";
                                }
                            }

                            if (child.get("type") === "FRAME") {
                                currentChildItem.getElementsByClassName("inner-frame")[0].style.transform = `scaleX(${(childWidth / child.get("defaultWidth") * this.props.zoomFactor)}) scaleY(${(childHeight / child.get("defaultHeight")) * this.props.zoomFactor})`;
                            }

                            if (child.get("type") === "GRID") {
                                /*
                                const gridItem = child.get("gridData");
                                let gap = gridItem.get("gap");
                                child.getIn(["gridData", "gridCells"]).entrySeq().forEach(([key, val]) => {
                                    // let gridWidth = childWidth;// * (val.get("cols") / colCount);
                                    // let gridHeight = childHeight;// * (val.get("rows") / rowCount);
                                    // gridWidth -= gap - (gap * (val.get("cols") / colCount));
                                    // gridHeight -= gap - (gap * (val.get("rows") / rowCount));

                                    let cellDetails = val;
                                    let { gridWidth, gridHeight } = getGridCellBounds({ cellDetails, gridItem, gap, gridWidth: childWidth, gridHeight: childHeight });
                                    // let currentRowGap = gridItem.getIn(["gapCount", "gapCountAlongRow", cellDetails.get("currentRow")]);
                                    // let currentColGap = gridItem.getIn(["gapCount", "gapCountAlongCol", cellDetails.get("currentCol")]);
                                    // gridWidth = (gridWidth - currentRowGap * gap) * cellDetails.get("cols") / colCount;
                                    // gridHeight = (gridHeight - currentColGap * gap) * cellDetails.get("rows") / rowCount;

                                    let cellWidth = gridWidth * this.props.zoomFactor;
                                    let cellHeight = gridHeight * this.props.zoomFactor;
                                    // let cellWidth = (val.get("cellWidth") * scale_x);
                                    // let cellHeight = (val.get("cellHeight") * scale_y);
                                    // cellWidth = cellWidth * this.props.zoomFactor;
                                    // cellHeight = cellHeight * this.props.zoomFactor;

                                    currentChildItem.getElementsByClassName("cell-" + key)[0].style.width = `${cellWidth}px`;
                                    currentChildItem.getElementsByClassName("cell-" + key)[0].style.height = `${cellHeight}px`;

                                    if (val.get("imgDetails")) {
                                        const imgDetails = val.get("imgDetails")
                                        const gridImageItem = currentChildItem.getElementsByClassName(`grid_image_container_${key}`)[0]
                                        if (this.aspectRatio) {
                                            gridImageItem.style.width = `${cellWidth * imgDetails.getIn(["original", "width"])}px`
                                            gridImageItem.style.height = `${cellHeight * imgDetails.getIn(["original", "height"])}px`
                                            gridImageItem.style.transform = `translate(${cellWidth * imgDetails.getIn(["original", "x"])}px, ${cellHeight * imgDetails.getIn(["original", "y"])}px)`
                                        }
                                    }
                                });
                                */
                            }

                            if (child.get("radius")) {
                                const radiusItem = child.set("width", childWidth).set("height", childHeight);
                                this.applyRadiusClipPath(radiusItem);
                            }

                            if (child.get("type") === "SHAPE" && child.get("subType") === "CSTMPTH") {
                                let currentItem = child.set("width", childWidth).set("height", childHeight);

                                let pathDetails = getPath(currentItem, this.props.zoomFactor, child);
                                let dataPath = pathDetails.pathDetails;
                                if (Array.isArray(dataPath)) {
                                    for (let p = 0; p < dataPath.length; p++) {
                                        document.getElementById(itemId).getElementsByTagName("svg")[0].getElementsByClassName("path" + p)[0].setAttribute("d", dataPath[p].data);

                                        if (dataPath[p].pathStyle !== undefined)
                                            document.getElementById(itemId).getElementsByTagName("svg")[0].getElementsByTagName("g")[0].style.transform = "translate(" + dataPath[p].pathStyle.left + "px, " + dataPath[p].pathStyle.top + "px)";
                                    }
                                }
                            }
                        });

                        if (this.props.textStatus.get("isSelected") && this.props.textStatus.get("isGrouped") && this.props.textStatus.get("id") === key && groupFontSize !== null) {
                            this.props.updateTextStatus({ fontSize: groupFontSize.toFixed(2) });
                        }
                    }

                    if ((item.get("type") === "TEXT" || (item.get("type") === "SHAPE"))) {
                        let container = document.getElementById(key);

                        if (item.get("type") === "SHAPE") {
                            let xRatio = item.get("xRatio") !== undefined && item.get("xRatio") !== null ? item.get("xRatio") : 10;
                            let yRatio = item.get("yRatio") !== undefined && item.get("yRatio") !== null ? item.get("yRatio") : 10;
                            let widthRatio = item.get("widthRatio") !== undefined && item.get("widthRatio") !== null ? item.get("widthRatio") : 90;
                            let heightRatio = item.get("heightRatio") !== undefined && item.get("heightRatio") !== null ? item.get("heightRatio") : 90;
                            container = container.getElementsByClassName("callout-text-container")[0];

                            if (item.get("subType") === "CSTMPTH") {
                                let currentItem = item.set("width", item_width).set("height", item_height);

                                let pathDetails = getPath(currentItem, this.props.zoomFactor, item);
                                let dataPath = pathDetails.pathDetails;
                                if (Array.isArray(dataPath)) {
                                    for (let p = 0; p < dataPath.length; p++) {
                                        document.getElementById(key).getElementsByTagName("svg")[0].getElementsByClassName("path" + p)[0].setAttribute("d", dataPath[p].data);

                                        if (dataPath[p].pathStyle !== undefined)
                                            document.getElementById(key).getElementsByTagName("svg")[0].getElementsByTagName("g")[0].style.transform = "translate(" + dataPath[p].pathStyle.left + "px, " + dataPath[p].pathStyle.top + "px)";
                                    }
                                }

                                let flipPos = item.get("flipPosition");
                                let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";

                                container.style.transform = "translate(" + ((item_width * xRatio * this.props.zoomFactor) / 100) + "px, " + ((item_height * yRatio * this.props.zoomFactor) / 100) + "px)" + flip;
                                container.style.width = ((item_width * widthRatio * this.props.zoomFactor) / 100) + "px";
                                container.style.height = ((item_height * heightRatio * this.props.zoomFactor) / 100) + "px";

                                if (pathDetails.shapeStyle !== undefined) {
                                    document.getElementById(key).getElementsByTagName("svg")[0].style.left = pathDetails.shapeStyle.left;
                                    document.getElementById(key).getElementsByTagName("svg")[0].style.top = pathDetails.shapeStyle.top;
                                    document.getElementById(key).getElementsByTagName("svg")[0].style.width = pathDetails.shapeStyle.width;
                                    document.getElementById(key).getElementsByTagName("svg")[0].style.height = pathDetails.shapeStyle.height;
                                }

                                if (!this.props.isMultimove && item.get("subType") === "CSTMPTH" && this.selectedItems.size === 1) {
                                    let pathData = fromJS(pathDetails.pathData);
                                    if (pathDetails.newControlPoints !== undefined) {
                                        let newControlPoints = this.getFlipPosition(pathDetails.newControlPoints, item.get("flipPosition"));
                                        for (let p of Reflect.ownKeys(newControlPoints)) {
                                            document.getElementsByClassName("control-point " + p)[0].style.left = "calc(" + newControlPoints[p].x + "% - 7.5px)";
                                            document.getElementsByClassName("control-point " + p)[0].style.top = "calc(" + newControlPoints[p].y + "% - 7.5px)";
                                        }
                                    }
                                    else if (pathData) {
                                        pathData.get("controlPoints").entrySeq().forEach(([key, pos]) => {
                                            document.getElementsByClassName("control-point " + key)[0].style.left = "calc(" + pos.get("x") + "% - 7.5px)";
                                            document.getElementsByClassName("control-point " + key)[0].style.top = "calc(" + pos.get("y") + "% - 7.5px)";
                                        });
                                    }
                                }
                            }
                        }

                        if ((this.isFontAutoSize(key) || (handle === "top-left" || handle === "top-right" || handle === "bottom-left" || handle === "bottom-right") || item.get("type") === "SHAPE")) {
                            let fontSize = parseFloat(item.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10);
                            let ourText = container.getElementsByClassName("text-container")[0];

                            if (item.get("type") === "SHAPE")
                                textOffset = 0;
                            let tsx = (item_width - textOffset) / item.get("width"), tsy = (item_height - textOffset) / item.get("height");

                            let newFontSize = parseFloat(((fontSize * Math.abs(Math.max(tsx, tsy))) * this.props.zoomFactor).toFixed(2))
                            if ((item.get("type") === "TEXT") && (item.getIn(["textData", "formats", "containerStyle", "boxDesign"]) === 'split')) {
                                const lineHeight = item.getIn(["textData", "formats", "containerStyle", "lineHeight"])
                                ourText.parentElement.style.lineHeight = lineHeight * newFontSize + "px"
                            }
                            ourText.style.fontSize = newFontSize + "px";
                            if ((this.props.textStatus.get("isSelected") || this.props.textStatus.get("isFocused")) && this.props.textStatus.get("id") === key) {
                                if (document.getElementsByClassName("font-size-input")[0] !== undefined && document.getElementsByClassName("font-size-input")[0] !== null) {
                                    document.getElementsByClassName("font-size-input")[0].value = ((fontSize * Math.abs(Math.max(tsx, tsy))) * this.props.zoomFactor).toFixed(2);
                                }
                            }
                        }
                    }


                    if (this.props.isMultimove) {
                        let selectedItem = this.props.selectedItem; // eslint-disable-next-line
                        if ((isImageOnly(selectedItem.get("type"), selectedItem.get("subType")) || isVideoOnly(selectedItem.get("type"), selectedItem.get("subType"))) && selectedItem.get("isCropped")) {
                            let elIndex = 0
                            let placeholderEl;
                            let tagName = "img";
                            if (isVideoOnly(selectedItem.get("type"), selectedItem.get("subType"))) {
                                tagName = "video";
                            }
                            if (item.get("chromaKey") || item.get("enableBgRemoval")) {
                                tagName = "canvas";
                                placeholderEl = getElement(`#${key} img`);
                                if (!item.get("enableBgRemoval")) {
                                    elIndex = 1;
                                }
                            }
                            let cropImageElem = document.getElementById(key).getElementsByTagName(tagName)[elIndex];
                            cropImageElem.style.left = ((selectedItem.getIn(["original", "x"]) * item_width) * this.props.zoomFactor) + "px";
                            cropImageElem.style.top = ((selectedItem.getIn(["original", "y"]) * item_height) * this.props.zoomFactor) + "px";
                            cropImageElem.style.width = ((selectedItem.getIn(["original", "width"]) * item_width) * this.props.zoomFactor) + "px";
                            cropImageElem.style.height = ((selectedItem.getIn(["original", "height"]) * item_height) * this.props.zoomFactor) + "px";
                            if (placeholderEl) {
                                placeholderEl.style.left = ((selectedItem.getIn(["original", "x"]) * item_width) * this.props.zoomFactor) + "px";
                                placeholderEl.style.top = ((selectedItem.getIn(["original", "y"]) * item_height) * this.props.zoomFactor) + "px";
                                placeholderEl.style.width = ((selectedItem.getIn(["original", "width"]) * item_width) * this.props.zoomFactor) + "px";
                                placeholderEl.style.height = ((selectedItem.getIn(["original", "height"]) * item_height) * this.props.zoomFactor) + "px";
                            }
                        }
                        else if (item.get("type") === "FRAME") {
                            current_item.getElementsByClassName("inner-frame")[0].style.transform = `scaleX(${(item_width / item.get("defaultWidth")) * this.props.zoomFactor}) scaleY(${(item_height / item.get("defaultHeight")) * this.props.zoomFactor})`;
                        }
                        if (item.get('type') === "GRID") {
                            /*
                            const gridItem = item.get("gridData");
                            let gap = gridItem.get("gap");
                            item.getIn(["gridData", "gridCells"]).entrySeq().forEach(([key, val]) => {
                                // let gridWidth = item_width; // * (val.get("cols") / colCount);
                                // let gridHeight = item_height; // * (val.get("rows") / rowCount);
                                // gridWidth -= gap - (gap * (val.get("cols") / colCount));
                                // gridHeight -= gap - (gap * (val.get("rows") / rowCount));

                                let cellDetails = val;
                                let { gridWidth, gridHeight } = getGridCellBounds({ cellDetails, gridItem, gap, gridWidth: item_width, gridHeight: item_height });

                                let cellWidth = gridWidth * this.props.zoomFactor;
                                let cellHeight = gridHeight * this.props.zoomFactor;

                                // let cellWidth = (val.get("cellWidth") * scale_x);
                                // let cellHeight = (val.get("cellHeight") * scale_y);
                                // cellWidth = cellWidth * this.props.zoomFactor;
                                // cellHeight = cellHeight * this.props.zoomFactor;

                                current_item.getElementsByClassName("cell-" + key)[0].style.width = `${cellWidth}px`;
                                current_item.getElementsByClassName("cell-" + key)[0].style.height = `${cellHeight}px`;

                                if (val.get("imgDetails")) {
                                    const imgDetails = val.get("imgDetails")
                                    const gridImageItem = current_item.getElementsByClassName(`grid_image_container_${key}`)[0]

                                    let flipPos = imgDetails.get("flipPosition");
                                    let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";

                                    if (this.aspectRatio) {
                                        gridImageItem.style.width = `${cellWidth * imgDetails.getIn(["original", "width"])}px`
                                        gridImageItem.style.height = `${cellHeight * imgDetails.getIn(["original", "height"])}px`
                                        gridImageItem.style.transform = `translate(${cellWidth * imgDetails.getIn(["original", "x"])}px, ${cellHeight * imgDetails.getIn(["original", "y"])}px) ${flip}`
                                    } else {
                                        let originalWidth = imgDetails.getIn(["original", "width"]),
                                            originalHeight = imgDetails.getIn(["original", "height"]),
                                            originalX = imgDetails.getIn(["original", "x"]),
                                            originalY = imgDetails.getIn(["original", "y"]);
                                        if (handle === "left" || handle === "right") {
                                            let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                            if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                                dy = 0;
                                            originalHeight = imgDetails.getIn(["original", "height"]) * scale_x;
                                            originalY = -1 * ((originalHeight - 1) * (1 - dy));
                                            if (originalHeight + originalY <= 1) {
                                                originalWidth = imgDetails.getIn(["original", "width"]) * (1 / originalHeight);
                                                let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                                if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                                    dx = 0.5;
                                                originalX = -1 * ((originalWidth - 1) * (1 - dx));
                                                originalHeight = 1
                                                originalY = 0;
                                            }
                                            if (originalWidth < 1) {
                                                originalHeight = originalHeight * (1 / originalWidth);
                                                let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                                if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                                    dy = 0.5;
                                                originalY = -1 * ((originalHeight - 1) * (1 - dy));
                                                originalWidth = 1
                                                originalX = 0;
                                            }
                                        } else if (handle === "top" || handle === "bottom") {
                                            let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                            if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                                dx = 0;
                                            originalWidth = imgDetails.getIn(["original", "width"]) * scale_y;
                                            originalX = -1 * ((originalWidth - 1) * (1 - dx));
                                            if (originalWidth + originalX <= 1) {
                                                originalHeight = imgDetails.getIn(["original", "height"]) * (1 / originalWidth);
                                                let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                                if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                                    dy = 0.5;
                                                originalY = -1 * ((originalHeight - 1) * (1 - dy));
                                                originalWidth = 1
                                                originalX = 0;
                                            }
                                            if (originalHeight < 1) {
                                                originalWidth = originalWidth * (1 / originalHeight);
                                                let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                                if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                                    dx = 0.5;
                                                originalX = -1 * ((originalWidth - 1) * (1 - dx));
                                                originalHeight = 1
                                                originalY = 0;
                                            }
                                        }
                                        gridImageItem.style.width = `${cellWidth * originalWidth}px`
                                        gridImageItem.style.height = `${cellHeight * originalHeight}px`
                                        gridImageItem.style.transform = `translate(${cellWidth * originalX}px, ${cellHeight * originalY}px) ${flip}`
                                    }
                                }
                            })
                            */
                        }
                        if (selectedItem.get("radius")) {
                            const radiusItem = selectedItem.set("width", item_width).set("height", item_height);
                            this.applyRadiusClipPath(radiusItem);
                        }
                    } else if ((isImageOnly(item.get("type"), item.get("subType")) || isVideoOnly(item.get("type"), item.get("subType"))) && item.get("isCropped")) {
                        let elIndex = 0;
                        let placeholderEl;
                        let tagName = "img";
                        if (isVideoOnly(item.get("type"), item.get("subType"))) {
                            tagName = "video";
                        }
                        if (item.get("chromaKey") || item.get("enableBgRemoval")) {
                            tagName = "canvas";
                            placeholderEl = getElement(`#${key} img`);
                            if (!item.get("enableBgRemoval")) {
                                elIndex = 1;
                            }
                        }
                        let cropImageElem = document.getElementById(key).getElementsByTagName(tagName)[elIndex];
                        switch (handle) {
                            case "left":
                            case "right":
                            case "top":
                            case "bottom":
                                this.handleImageScaling(item, key, scale_x, scale_y, handle, this.props.zoomFactor);
                                break;
                            default:
                                cropImageElem.style.left = ((item.getIn(["original", "x"]) * item_width) * this.props.zoomFactor) + "px";
                                cropImageElem.style.top = ((item.getIn(["original", "y"]) * item_height) * this.props.zoomFactor) + "px";
                                cropImageElem.style.width = ((item.getIn(["original", "width"]) * item_width) * this.props.zoomFactor) + "px";
                                cropImageElem.style.height = ((item.getIn(["original", "height"]) * item_height) * this.props.zoomFactor) + "px";
                                if (placeholderEl) {
                                    placeholderEl.style.left = ((item.getIn(["original", "x"]) * item_width) * this.props.zoomFactor) + "px";
                                    placeholderEl.style.top = ((item.getIn(["original", "y"]) * item_height) * this.props.zoomFactor) + "px";
                                    placeholderEl.style.width = ((item.getIn(["original", "width"]) * item_width) * this.props.zoomFactor) + "px";
                                    placeholderEl.style.height = ((item.getIn(["original", "height"]) * item_height) * this.props.zoomFactor) + "px";
                                }
                                break;
                        }
                    } else if (
                        (isImageOnly(item.get("type"), item.get("subType")) || isVideoOnly(item.get("type"), item.get("subType")))
                        && (handle === "left" || handle === "right" || handle === "top" || handle === "bottom")
                    ) {
                        this.handleImageScaling(item, key, scale_x, scale_y, handle, this.props.zoomFactor);
                    } else if (item.get("type") === "FRAME") {
                        current_item.getElementsByClassName("inner-frame")[0].style.transform = `scaleX(${(item_width / item.get("defaultWidth")) * this.props.zoomFactor}) scaleY(${(item_height / item.get("defaultHeight")) * this.props.zoomFactor})`;
                    } else if (item.get('type') === "GRID") {
                        /*
                        const gridItem = item.get("gridData");
                        let gap = gridItem.get("gap");
                        item.getIn(["gridData", "gridCells"]).entrySeq().forEach(([key, val]) => {
                            // let gridWidth = item_width;// * (val.get("cols") / colCount);
                            // let gridHeight = item_height;// * (val.get("rows") / rowCount);
                            // gridWidth -= gap - (gap * (val.get("cols") / colCount));
                            // gridHeight -= gap - (gap * (val.get("rows") / rowCount));

                            // gridData.gapCount.gapCountAlongRow[cellDetails.get("currentRow")]
                            // gridData.gapCount.gapCountAlongCol[cellDetails.get("currentCol")]
                            let cellDetails = val;
                            let { gridWidth, gridHeight } = getGridCellBounds({ cellDetails, gridItem, gap, gridWidth: item_width, gridHeight: item_height });

                            let cellWidth = gridWidth * this.props.zoomFactor;
                            let cellHeight = gridHeight * this.props.zoomFactor;

                            current_item.getElementsByClassName("cell-" + key)[0].style.width = `${cellWidth}px`;
                            current_item.getElementsByClassName("cell-" + key)[0].style.height = `${cellHeight}px`;

                            if (val.get("imgDetails")) {
                                const imgDetails = val.get("imgDetails")
                                const gridImageItem = current_item.getElementsByClassName(`grid_image_container_${key}`)[0]

                                let flipPos = imgDetails.get("flipPosition");
                                let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";

                                if (this.aspectRatio) {
                                    gridImageItem.style.width = `${cellWidth * imgDetails.getIn(["original", "width"])}px`
                                    gridImageItem.style.height = `${cellHeight * imgDetails.getIn(["original", "height"])}px`
                                    gridImageItem.style.transform = `translate(${cellWidth * imgDetails.getIn(["original", "x"])}px, ${cellHeight * imgDetails.getIn(["original", "y"])}px) ${flip}`
                                } else {
                                    let originalWidth = imgDetails.getIn(["original", "width"]),
                                        originalHeight = imgDetails.getIn(["original", "height"]),
                                        originalX = imgDetails.getIn(["original", "x"]),
                                        originalY = imgDetails.getIn(["original", "y"]);
                                    if (handle === "left" || handle === "right") {
                                        let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                        if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                            dy = 0;
                                        originalHeight = imgDetails.getIn(["original", "height"]) * scale_x;
                                        originalY = -1 * ((originalHeight - 1) * (1 - dy));
                                        if (originalHeight + originalY <= 1) {
                                            originalWidth = imgDetails.getIn(["original", "width"]) * (1 / originalHeight);
                                            let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                            if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                                dx = 0.5;
                                            originalX = -1 * ((originalWidth - 1) * (1 - dx));
                                            originalHeight = 1
                                            originalY = 0;
                                        }
                                        if (originalWidth < 1) {
                                            originalHeight = originalHeight * (1 / originalWidth);
                                            let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                            if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                                dy = 0.5;
                                            originalY = -1 * ((originalHeight - 1) * (1 - dy));
                                            originalWidth = 1
                                            originalX = 0;
                                        }
                                    } else if (handle === "top" || handle === "bottom") {
                                        let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                        if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                            dx = 0;
                                        originalWidth = imgDetails.getIn(["original", "width"]) * scale_y;
                                        originalX = -1 * ((originalWidth - 1) * (1 - dx));
                                        if (originalWidth + originalX <= 1) {
                                            originalHeight = imgDetails.getIn(["original", "height"]) * (1 / originalWidth);
                                            let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                            if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                                dy = 0.5;
                                            originalY = -1 * ((originalHeight - 1) * (1 - dy));
                                            originalWidth = 1
                                            originalX = 0;
                                        }
                                        if (originalHeight < 1) {
                                            originalWidth = originalWidth * (1 / originalHeight);
                                            let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                            if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                                dx = 0.5;
                                            originalX = -1 * ((originalWidth - 1) * (1 - dx));
                                            originalHeight = 1
                                            originalY = 0;
                                        }
                                    }
                                    gridImageItem.style.width = `${cellWidth * originalWidth}px`
                                    gridImageItem.style.height = `${cellHeight * originalHeight}px`
                                    gridImageItem.style.transform = `translate(${cellWidth * originalX}px, ${cellHeight * originalY}px) ${flip}`
                                }
                            }
                        })
                        */
                    }

                    if (!this.props.isMultimove && item.get("radius")) {
                        const radiusItem = item.set("width", item_width).set("height", item_height);
                        this.applyRadiusClipPath(radiusItem);
                    }
                });
            } else {
                let firstKeyframe = this.selectedItems.get(this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "keyframes", 0, "id"]));
                this.props.resizeSelection(cx - new_width / 2, cy - new_height / 2, new_width, new_height);

                let item_width = Math.abs(firstKeyframe.get("width") * scale_x), item_height = Math.abs(firstKeyframe.get("height") * scale_y);

                let item_x = Math.cos((firstKeyframe.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(firstKeyframe.get("dis_to_center") * scale_x) + cx - item_width / 2;
                let item_y = Math.sin((firstKeyframe.get("angle_to_center") + this.initialSelectionBox.angle) * Math.PI / 180) * Math.abs(firstKeyframe.get("dis_to_center") * scale_y) + cy - item_height / 2;

                let current_item = document.getElementById(this.props.selectedItems.get(0));
                let flipPos = this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "flipPosition"]);
                let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
                current_item.style.transform = "translate(" + (item_x * this.props.zoomFactor).toFixed(2) + "px, " + (item_y * this.props.zoomFactor).toFixed(2) + "px) rotateZ(" + firstKeyframe.get("angle") + "deg)" + flip;

                current_item.style.width = item_width * this.props.zoomFactor + "px";
                current_item.style.height = item_height * this.props.zoomFactor + "px";
                let textOffset = this.props.textOffset;
                let groupFontSize = null;
                if (firstKeyframe.get("type") === "GROUP") {
                    if (firstKeyframe.get("subType") === "TXTGRP")
                        textOffset = 0;
                    firstKeyframe.get("groupChildren").entrySeq().forEach(([childKey, itemId]) => {
                        let groupItems = this.props.selectedChildren;
                        let child = groupItems.get(itemId);

                        let childWidth = Math.abs(child.get("width") * scale_x), childHeight = Math.abs(child.get("height") * scale_y);

                        let currentChildItem = document.getElementById(itemId);

                        let flipPos = child.get("flipPosition");
                        let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";

                        let transform = "translate(" + ((child.get("x") - firstKeyframe.get("x")) * scale_x) * this.props.zoomFactor + "px, " + ((child.get("y") - firstKeyframe.get("y")) * scale_y) * this.props.zoomFactor + "px) rotateZ(" + child.get("angle") + "deg)" + flip;
                        currentChildItem.style.transform = transform;
                        currentChildItem.style.width = childWidth * this.props.zoomFactor + "px";
                        currentChildItem.style.height = childHeight * this.props.zoomFactor + "px";

                        if (child.get("type") === "TEXT" || child.get("type") === "SHAPE") {
                            let container = document.getElementById(itemId);

                            if (child.get("type") === "SHAPE") {
                                container = container.getElementsByClassName("callout-text-container")[0];
                                let xRatio = child.get("xRatio") !== undefined ? child.get("xRatio") : 10;
                                let yRatio = child.get("yRatio") !== undefined ? child.get("yRatio") : 10;
                                let widthRatio = child.get("widthRatio") !== undefined ? child.get("widthRatio") : 90;
                                let heightRatio = child.get("heightRatio") !== undefined ? child.get("heightRatio") : 90;

                                container.style.transform = "translate(" + ((childWidth * xRatio * this.props.zoomFactor) / 100) + "px, " + ((childHeight * yRatio * this.props.zoomFactor) / 100) + "px)" + flip;
                                container.style.width = ((childWidth * widthRatio * this.props.zoomFactor) / 100) + "px";
                                container.style.height = ((childHeight * heightRatio * this.props.zoomFactor) / 100) + "px";
                            }

                            if (this.isFontAutoSize(itemId, "workspaceChildren") || (handle === "top-left" || handle === "top-right" || handle === "bottom-left" || handle === "bottom-right")) {
                                let fontSize = parseFloat(child.getIn(["textData", "formats", "containerStyle", "fontSize"]));

                                if (child.get("type") === "SHAPE")
                                    textOffset = 0;
                                else if (firstKeyframe.get("subType") !== "TXTGRP")
                                    textOffset = this.props.textOffset;
                                let tsx = (childWidth - textOffset) / child.get("width"), tsy = (childHeight - textOffset) / child.get("height");

                                let ourText = container.getElementsByClassName("text-container")[0];
                                let scaledFontsize = Math.abs((fontSize * (tsx + tsy) / 2));
                                ourText.style.fontSize = (scaledFontsize * this.props.zoomFactor) + "px";

                                if (scaledFontsize > groupFontSize || groupFontSize === null)
                                    groupFontSize = scaledFontsize;
                                if (this.props.textStatus.get("isFocused") && this.props.textStatus.get("id") === itemId) {
                                    this.props.updateTextStatus({ fontSize: (scaledFontsize).toFixed(2) });
                                }
                            }
                        }

                        if ((isImageOnly(child.get("type"), child.get("subType")) || isVideoOnly(child.get("type"), child.get("subType"))) && child.get("isCropped")) {
                            let tagName = "img";
                            if (isVideoOnly(child.get("type"), child.get("subType"))) {
                                tagName = "video";
                            }
                            let cropImageElem = document.getElementById(itemId).getElementsByTagName(tagName)[0];
                            cropImageElem.style.left = ((child.getIn(["original", "x"]) * childWidth) * this.props.zoomFactor) + "px";
                            cropImageElem.style.top = ((child.getIn(["original", "y"]) * childHeight) * this.props.zoomFactor) + "px";
                            cropImageElem.style.width = ((child.getIn(["original", "width"]) * childWidth) * this.props.zoomFactor) + "px";
                            cropImageElem.style.height = ((child.getIn(["original", "height"]) * childHeight) * this.props.zoomFactor) + "px";
                        }

                        if (child.get("type") === "FRAME") {
                            currentChildItem.getElementsByClassName("inner-frame")[0].style.transform = `scaleX(${(childWidth / child.get("defaultWidth")) * this.props.zoomFactor}) scaleY(${(childHeight / child.get("defaultHeight")) * this.props.zoomFactor})`;
                        }

                        if (child.get("type") === "GRID") {
                            /*
                            const gridItem = child.get("gridData");
                            let gap = gridItem.get("gap");
                            child.getIn(["gridData", "gridCells"]).entrySeq().forEach(([key, val]) => {
                                // let gridWidth = childWidth;// * (val.get("cols") / colCount);
                                // let gridHeight = childHeight;// * (val.get("rows") / rowCount);
                                // gridWidth -= gap - (gap * (val.get("cols") / colCount));
                                // gridHeight -= gap - (gap * (val.get("rows") / rowCount));

                                let cellDetails = val;
                                let { gridWidth, gridHeight } = getGridCellBounds({ cellDetails, gridItem, gap, gridWidth: childWidth, gridHeight: childHeight });

                                // let cellWidth = (val.get("cellWidth") * scale_x)
                                // let cellHeight = (val.get("cellHeight") * scale_y)
                                let cellWidth = gridWidth * this.props.zoomFactor;
                                let cellHeight = gridHeight * this.props.zoomFactor;

                                currentChildItem.getElementsByClassName("cell-" + key)[0].style.width = `${cellWidth}px`;
                                currentChildItem.getElementsByClassName("cell-" + key)[0].style.height = `${cellHeight}px`;

                                if (val.get("imgDetails")) {
                                    const imgDetails = val.get("imgDetails")
                                    const gridImageItem = currentChildItem.getElementsByClassName(`grid_image_container_${key}`)[0]
                                    if (this.aspectRatio) {
                                        gridImageItem.style.width = `${cellWidth * imgDetails.getIn(["original", "width"])}px`
                                        gridImageItem.style.height = `${cellHeight * imgDetails.getIn(["original", "height"])}px`
                                        gridImageItem.style.transform = `translate(${cellWidth * imgDetails.getIn(["original", "x"])}px, ${cellHeight * imgDetails.getIn(["original", "y"])}px)`
                                    }
                                }
                            });
                            */
                        }

                        if (child.get("radius")) {
                            const radiusItem = child.set("width", childWidth).set("height", childHeight);
                            this.applyRadiusClipPath(radiusItem);
                        }

                        if ((child.get("type") === "SHAPE" && child.get("subType") === "CSTMPTH")) {
                            let currentItem = child.set("width", childWidth).set("height", childHeight);

                            let pathDetails = getPath(currentItem, this.props.zoomFactor, child);
                            let dataPath = pathDetails.pathDetails;
                            if (Array.isArray(dataPath)) {
                                for (let p = 0; p < dataPath.length; p++) {
                                    document.getElementById(itemId).getElementsByTagName("svg")[0].getElementsByClassName("path" + p)[0].setAttribute("d", dataPath[p].data);

                                    if (dataPath[p].pathStyle !== undefined)
                                        document.getElementById(itemId).getElementsByTagName("svg")[0].getElementsByTagName("g")[0].style.transform = "translate(" + dataPath[p].pathStyle.left + "px, " + dataPath[p].pathStyle.top + "px)";
                                }
                            }
                        }
                    });
                }

                if (firstKeyframe.get("type") === "TEXT" || firstKeyframe.get("type") === "SHAPE") {
                    let container = document.getElementById(this.props.selectedItems.get(0));

                    if (firstKeyframe.get("type") === "SHAPE") {
                        container = container.getElementsByClassName("callout-text-container")[0];

                        if (firstKeyframe.get("subType") === "CSTMPTH") {
                            let currentItem = firstKeyframe.set("width", item_width).set("height", item_height);
                            let pathDetails = getPath(currentItem, this.props.zoomFactor, firstKeyframe);
                            let dataPath = pathDetails.pathDetails;
                            if (Array.isArray(dataPath)) {
                                for (let p = 0; p < dataPath.length; p++) {
                                    current_item.getElementsByTagName("svg")[0].getElementsByClassName("path" + p)[0].setAttribute("d", dataPath[p].data);

                                    if (dataPath[p].pathStyle !== undefined)
                                        current_item.getElementsByTagName("svg")[0].getElementsByTagName("g")[0].style.transform = "translate(" + dataPath[p].pathStyle.left + "px, " + dataPath[p].pathStyle.top + "px)";
                                }
                            }

                            let flipPos = this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "flipPosition"]);
                            let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";

                            let xRatio = firstKeyframe.get("xRatio") !== undefined ? firstKeyframe.get("xRatio") : 10;
                            let yRatio = firstKeyframe.get("yRatio") !== undefined ? firstKeyframe.get("yRatio") : 10;
                            let widthRatio = firstKeyframe.get("widthRatio") !== undefined ? firstKeyframe.get("widthRatio") : 90;
                            let heightRatio = firstKeyframe.get("heightRatio") !== undefined ? firstKeyframe.get("heightRatio") : 90;

                            container.style.transform = "translate(" + ((item_width * xRatio * this.props.zoomFactor) / 100) + "px, " + ((item_height * yRatio * this.props.zoomFactor) / 100) + "px)" + flip;
                            container.style.width = ((item_width * widthRatio * this.props.zoomFactor) / 100) + "px";
                            container.style.height = ((item_height * heightRatio * this.props.zoomFactor) / 100) + "px";

                            if (pathDetails.shapeStyle !== undefined) {
                                current_item.getElementsByTagName("svg")[0].style.left = pathDetails.shapeStyle.left;
                                current_item.getElementsByTagName("svg")[0].style.top = pathDetails.shapeStyle.top;
                                current_item.getElementsByTagName("svg")[0].style.width = pathDetails.shapeStyle.width;
                                current_item.getElementsByTagName("svg")[0].style.height = pathDetails.shapeStyle.height;
                            }
                        }
                    }


                    if (this.isFontAutoSize(this.props.selectedItems.get(0)) || (handle === "top-left" || handle === "top-right" || handle === "bottom-left" || handle === "bottom-right") || firstKeyframe.get("type") === "SHAPE") {
                        let fontSize = parseFloat(firstKeyframe.getIn(["textData", "formats", "containerStyle", "fontSize"]), 10);
                        container = document.getElementById(this.props.selectedItems.get(0));
                        if (firstKeyframe.get("type") === "SHAPE")
                            container = container.getElementsByClassName("callout-text-container")[0];

                        let ourText = container.getElementsByClassName("text-container")[0];

                        if (firstKeyframe.get("type") === "SHAPE")
                            textOffset = 0;
                        let tsx = (item_width - textOffset) / firstKeyframe.get("width"), tsy = (item_height - textOffset) / firstKeyframe.get("height");

                        let newFontSize = parseFloat(((fontSize * Math.abs(tsx + tsy) / 2) * this.props.zoomFactor).toFixed(2))
                        if ((firstKeyframe.get("type") === "TEXT") && (firstKeyframe.getIn(["textData", "formats", "containerStyle", "boxDesign"]) === 'split')) {
                            const lineHeight = firstKeyframe.getIn(["textData", "formats", "containerStyle", "lineHeight"])
                            ourText.parentElement.style.lineHeight = lineHeight * newFontSize + "px"
                        }
                        ourText.style.fontSize = newFontSize + "px";


                        if ((this.props.textStatus.get("isSelected") || this.props.textStatus.get("isFocused")) && this.props.textStatus.get("id") === this.props.selectedItems.get(0)) {
                            this.props.updateTextStatus({ fontSize: ((fontSize * Math.abs(tsx + tsy) / 2)).toFixed(2) });
                        }
                    }
                }

                if (isImageOnly(firstKeyframe.get("type"), firstKeyframe.get("subType")) && firstKeyframe.get("isCropped")) {
                    let cropImageElem = document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("img")[0];
                    cropImageElem.style.left = ((firstKeyframe.getIn(["original", "x"]) * item_width) * this.props.zoomFactor) + "px";
                    cropImageElem.style.top = ((firstKeyframe.getIn(["original", "y"]) * item_height) * this.props.zoomFactor) + "px";
                    cropImageElem.style.width = ((firstKeyframe.getIn(["original", "width"]) * item_width) * this.props.zoomFactor) + "px";
                    cropImageElem.style.height = ((firstKeyframe.getIn(["original", "height"]) * item_height) * this.props.zoomFactor) + "px";
                }

                if (firstKeyframe.get("type") === "FRAME") {
                    current_item.getElementsByClassName("inner-frame")[0].style.transform = `scaleX(${(item_width / firstKeyframe.get("defaultWidth")) * this.props.zoomFactor}) scaleY(${(item_height / firstKeyframe.get("defaultHeight")) * this.props.zoomFactor})`;
                }

                if (firstKeyframe.get('type') === "GRID") {
                    /*
                    const gridItem = firstKeyframe.get("gridData");
                    let gap = gridItem.get("gap");
                    firstKeyframe.getIn(["gridData", "gridCells"]).entrySeq().forEach(([key, val]) => {
                        // let gridWidth = item_width;// * (val.get("cols") / colCount);
                        // let gridHeight = item_height;// * (val.get("rows") / rowCount);
                        // gridWidth -= gap - (gap * (val.get("cols") / colCount));
                        // gridHeight -= gap - (gap * (val.get("rows") / rowCount));

                        let cellDetails = val;
                        let { gridWidth, gridHeight } = getGridCellBounds({ cellDetails, gridItem, gap, gridWidth: item_width, gridHeight: item_height });

                        // let cellWidth = (val.get("cellWidth") * scale_x);
                        // let cellHeight = (val.get("cellHeight") * scale_y);
                        let cellWidth = gridWidth * this.props.zoomFactor;
                        let cellHeight = gridHeight * this.props.zoomFactor;
                        current_item.getElementsByClassName("cell-" + key)[0].style.width = `${cellWidth}px`;
                        current_item.getElementsByClassName("cell-" + key)[0].style.height = `${cellHeight}px`;

                        if (val.get("imgDetails")) {
                            const imgDetails = val.get("imgDetails")
                            const gridImageItem = current_item.getElementsByClassName(`grid_image_container_${key}`)[0]

                            let flipPos = imgDetails.get("flipPosition");
                            let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";

                            if (this.aspectRatio) {
                                gridImageItem.style.width = `${cellWidth * imgDetails.getIn(["original", "width"])}px`
                                gridImageItem.style.height = `${cellHeight * imgDetails.getIn(["original", "height"])}px`
                                gridImageItem.style.transform = `translate(${cellWidth * imgDetails.getIn(["original", "x"])}px, ${cellHeight * imgDetails.getIn(["original", "y"])}px) ${flip}`
                            } else {
                                let originalWidth = imgDetails.getIn(["original", "width"]),
                                    originalHeight = imgDetails.getIn(["original", "height"]),
                                    originalX = imgDetails.getIn(["original", "x"]),
                                    originalY = imgDetails.getIn(["original", "y"]);
                                if (handle === "left" || handle === "right") {
                                    let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                    if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                        dy = 0;
                                    originalHeight = imgDetails.getIn(["original", "height"]) * scale_x;
                                    originalY = -1 * ((originalHeight - 1) * (1 - dy));
                                    if (originalHeight + originalY <= 1) {
                                        originalWidth = imgDetails.getIn(["original", "width"]) * (1 / originalHeight);
                                        let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                        if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                            dx = 0.5;
                                        originalX = -1 * ((originalWidth - 1) * (1 - dx));
                                        originalHeight = 1
                                        originalY = 0;
                                    }
                                    if (originalWidth < 1) {
                                        originalHeight = originalHeight * (1 / originalWidth);
                                        let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                        if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                            dy = 0.5;
                                        originalY = -1 * ((originalHeight - 1) * (1 - dy));
                                        originalWidth = 1
                                        originalX = 0;
                                    }
                                } else if (handle === "top" || handle === "bottom") {
                                    let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                    if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                        dx = 0;
                                    originalWidth = imgDetails.getIn(["original", "width"]) * scale_y;
                                    originalX = -1 * ((originalWidth - 1) * (1 - dx));
                                    if (originalWidth + originalX <= 1) {
                                        originalHeight = imgDetails.getIn(["original", "height"]) * (1 / originalWidth);
                                        let dy = ((imgDetails.getIn(["original", "height"]) - 1) + imgDetails.getIn(["original", "y"])) / (imgDetails.getIn(["original", "height"]) - 1);
                                        if (imgDetails.getIn(["original", "height"]) - 1 === 0)
                                            dy = 0.5;
                                        originalY = -1 * ((originalHeight - 1) * (1 - dy));
                                        originalWidth = 1
                                        originalX = 0;
                                    }
                                    if (originalHeight < 1) {
                                        originalWidth = originalWidth * (1 / originalHeight);
                                        let dx = ((imgDetails.getIn(["original", "width"]) - 1) + imgDetails.getIn(["original", "x"])) / (imgDetails.getIn(["original", "width"]) - 1);
                                        if (imgDetails.getIn(["original", "width"]) - 1 === 0)
                                            dx = 0.5;
                                        originalX = -1 * ((originalWidth - 1) * (1 - dx));
                                        originalHeight = 1
                                        originalY = 0;
                                    }
                                }
                                gridImageItem.style.width = `${cellWidth * originalWidth}px`
                                gridImageItem.style.height = `${cellHeight * originalHeight}px`
                                gridImageItem.style.transform = `translate(${cellWidth * originalX}px, ${cellHeight * originalY}px) ${flip}`
                            }
                        }
                    })
                    */
                }

                if (firstKeyframe.get("radius")) {
                    const radiusItem = firstKeyframe.set("width", item_width).set("height", item_height);
                    this.applyRadiusClipPath(radiusItem);
                }

                /* this might be useful when non proportional scaling is introduced for multimove
                if (((firstKeyframe.get("type") === "IMG" || firstKeyframe.get("type") === "UPLOADS"  || firstKeyframe.get("type") === "STOCKIMG" || firstKeyframe.get("type") === "PEXELS" || firstKeyframe.get("type") === "PIXABAY" || firstKeyframe.get("type") === "UNSPLASH") && firstKeyframe.get("isCropped")) || firstKeyframe.get("type") === "FRAME") {
                    let cropImageElem = document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("img")[0];
                    switch (handle) {

                        case "left":
                        case "right":
                        case "top":
                        case "bottom":
                            this.handleImageScaling(firstKeyframe, this.props.selectedItems.get(0), scale_x, scale_y, handle, this.props.zoomFactor);
                        break;

                        default:
                            cropImageElem.style.left = ((firstKeyframe.getIn(["original", "x"]) * item_width) * this.props.zoomFactor) + "px";
                            cropImageElem.style.top = ((firstKeyframe.getIn(["original", "y"]) * item_height) * this.props.zoomFactor) + "px";
                            cropImageElem.style.width = ((firstKeyframe.getIn(["original", "width"]) * item_width) * this.props.zoomFactor) + "px";
                            cropImageElem.style.height = ((firstKeyframe.getIn(["original", "height"]) * item_height) * this.props.zoomFactor) + "px";
                        break;
                    }
                } else if ((firstKeyframe.get("type") === "IMG" || firstKeyframe.get("type") === "UPLOADS" || firstKeyframe.get("type") === "STOCKIMG" || firstKeyframe.get("type") === "PEXELS" || firstKeyframe.get("type") === "PIXABAY" || firstKeyframe.get("type") === "UNSPLASH")
                    && (handle === "left" || handle === "right" || handle === "top" || handle === "bottom")) {

                    this.handleImageScaling(firstKeyframe, this.props.selectedItems.get(0), scale_x, scale_y, handle, this.props.zoomFactor);
                } */
            }
        } catch (error) { }
    }

    stopResize(e) {
        try {
            e.preventDefault();
            if (!this.props.isToolCropping) {
                this.updateResizeValues(e);
            }
            else {
                /*
                let resize_position = this.getResizePosition(e);
                let scale_x = resize_position.scale_x, scale_y = resize_position.scale_y, cx = resize_position.cx, cy = resize_position.cy, new_width = Math.abs(this.initialSelectionBox.width * scale_x), new_height = Math.abs(this.initialSelectionBox.height * scale_y);
                // let handle = this.selectionBox.handle;

                let selectionX = cx - new_width / 2,
                    selectionY = cy - new_height / 2,
                    selectionWidth = new_width, selectionHeight = new_height;

                let toUpdate = { x: selectionX / this.props.workspaceWidth, y: selectionY / this.props.workspaceHeight, width: selectionWidth / this.props.workspaceWidth, height: selectionHeight / this.props.workspaceHeight };
                this.props.updateBg({ selectedScene: this.props.selectedScene, toUpdate: toUpdate, toolCrop: true });
                */
            }

            if (this.multimoveId !== null) {
                this.props.stopResize();
                this.anchorScales = null;
            }
            window.removeEventListener('mousemove', this.resizeSelection, false);
            window.removeEventListener('mousemove', this.resizeTextContainer, false);
            window.removeEventListener('mouseup', this.stopResize, false);

            window.removeEventListener('touchmove', this.resizeSelection, false);
            window.removeEventListener('touchmove', this.resizeTextContainer, false);
            window.removeEventListener('touchend', this.stopResize, false);

            this.hideSnaplines();
        } catch (error) { }
    }

    getQuadrant(_deg) {
        let r = 0;
        if (_deg >= 0 && _deg <= 90) {
            r = 1;
        } else if (_deg > 90 && _deg <= 180) {
            r = 2;
        } else if (_deg > 180 && _deg <= 270) {
            r = 3;
        } else if (_deg > 270 && _deg <= 360) {
            r = 4;
        }
        return r;
    }

    getAngle(e) {
        let workspaceBounds = this.getWorkspaceBounds();

        let angle = Math.atan2((e.clientY - workspaceBounds.y - (this.selectionBox.cy * this.props.zoomFactor)), e.clientX - workspaceBounds.x - (this.selectionBox.cx * this.props.zoomFactor)) * (180 / Math.PI);

        this.prevY = e.clientY - workspaceBounds.y; this.prevX = e.clientX - workspaceBounds.x;

        let rotation = angle - this.startAngle;
        let rAngle = rotation + this.initialSelectionBox.angle;

        if (rAngle < 0)
            rAngle += 360;

        let increements = 8;
        let snapAngle = 360 / increements;
        let snapPoint = 5;

        if (Math.abs(rAngle) % snapAngle < (snapPoint)) {
            rAngle -= (rAngle % snapAngle);

            document.getElementsByClassName("rotationLine")[0].style.left = (((this.selectionBox.x + this.selectionBox.width / 2) * this.props.zoomFactor) + workspaceBounds.x) + "px";
            document.getElementsByClassName("rotationLine")[0].style.top = (((this.selectionBox.y) * this.props.zoomFactor) + workspaceBounds.y) + "px";
            document.getElementsByClassName("rotationLine")[0].style.height = (this.selectionBox.height * this.props.zoomFactor) + "px";
            document.getElementsByClassName("rotationLine")[0].style.transform = "rotateZ(" + rAngle + "deg)";
            document.getElementsByClassName("rotationLine")[0].style.display = "block";
        } else {
            document.getElementsByClassName("rotationLine")[0].style.display = "none";
        }

        if (this.restrictBounds) {
            let bounds = this.getSelectionBoxBounds({ x: this.selectionBox.x, y: this.selectionBox.y, cx: this.selectionBox.cx, cy: this.selectionBox.cy, width: this.selectionBox.width, height: this.selectionBox.height, angle: rAngle });

            let x = bounds.x, y = bounds.y, sWidth = bounds.width, sHeight = bounds.height;

            x = (x < 0) ? 0 : x;
            y = (y < 0) ? 0 : y;

            let widthRestricted = Boolean((x + sWidth) >= workspaceBounds.width);
            let heightRestricted = Boolean((y + sHeight) >= workspaceBounds.height);

            if ((x === 0 || y === 0 || widthRestricted || heightRestricted)) {
                if (!this.restrictedSelectionBox.isRestricted) {
                    Object.assign(this.restrictedSelectionBox, { isRestricted: true, angle: rAngle });
                }
                rAngle = this.restrictedSelectionBox.angle;
            }
            else if (this.restrictedSelectionBox.isRestricted) {
                Object.assign(this.restrictedSelectionBox, { isRestricted: false });
            }
        }

        let oldQuad = this.getQuadrant(this.angle);
        let newQuad = this.getQuadrant(rAngle);

        if (oldQuad !== newQuad) {
            if (newQuad - oldQuad === 1 || oldQuad - newQuad === 1) {
                //
            } else if (oldQuad === 4 && newQuad === 1) {
                this.rotateCount++;
            } else if (oldQuad === 1 && newQuad === 4) {
                this.rotateCount--;
            }
        }

        this.angle = rAngle;

        return (Math.abs(this.angle) > 360) ? this.angle % 360 : this.angle;
    }

    initiateRotate(e) {
        try {
            e.preventDefault();
            let isRotateLocked = Boolean(this.lockRotate !== undefined && this.lockRotate);

            if (!isRotateLocked) {
                this.angle = this.selectionBox.angle;
                this.rotateCount = 0;
                let workspace_bounds = this.getWorkspaceBounds();

                let wpx = e.clientX - workspace_bounds.x, wpy = e.clientY - workspace_bounds.y, scx = this.selectionBox.cx * this.props.zoomFactor, scy = this.selectionBox.cy * this.props.zoomFactor;
                this.diffX = wpx; this.diffY = wpy;
                this.prevY = wpx; this.prevX = wpy;
                this.startAngle = Math.atan2((wpy - scy), (wpx - scx)) * (180 / Math.PI);

                this.updateInitialSelectionBox({ transforming: true, rotating: true, handle: e.target.getAttribute("data-handle") });

                if (this.multimoveId !== null) {
                    let selectedItem = this.props.selectedObjects.get(this.multimoveId);
                    this.anchorAngles = [];
                    selectedItem.get("keyframes").entrySeq().forEach(([key, keyframe]) => {
                        let anchors = [];
                        if (selectedItem.get("isMultimove"))
                            anchors = keyframe.get("anchors");
                        else {
                            if (key === 0) {
                                anchors.push({ x: (keyframe.get("x") + keyframe.get("width") / 2), y: (keyframe.get("y") + keyframe.get("height") / 2) }, { x: (keyframe.get("x") + keyframe.get("width") / 2), y: (keyframe.get("y") + keyframe.get("height") / 2) });
                            } else {
                                anchors.push({ x: (selectedItem.getIn(["keyframes", key - 1, "x"]) + selectedItem.getIn(["keyframes", key - 1, "width"]) / 2), y: (selectedItem.getIn(["keyframes", key - 1, "y"]) + selectedItem.getIn(["keyframes", key - 1, "height"]) / 2) }, { x: (selectedItem.getIn(["keyframes", key, "x"]) + selectedItem.getIn(["keyframes", key, "width"]) / 2), y: (selectedItem.getIn(["keyframes", key, "y"]) + selectedItem.getIn(["keyframes", key, "height"]) / 2) });
                            }
                            anchors = fromJS(anchors);
                        }
                        this.anchorAngles[key] = [];
                        if (anchors !== undefined && anchors.size > 0) {
                            anchors.entrySeq().forEach(([aIndex, anchor]) => {
                                let scx = this.selectionBox.cx, scy = this.selectionBox.cy, icx = (anchor.get("x") / this.props.zoomFactor), icy = (anchor.get("y") / this.props.zoomFactor);

                                let item_angle = Math.atan2((icy * this.props.zoomFactor) - scy, (icx * this.props.zoomFactor) - scx) * (180 / Math.PI);

                                let dis = Math.sqrt((Math.pow(scx - (icx * this.props.zoomFactor), 2)) + (Math.pow(scy - (icy * this.props.zoomFactor), 2)));

                                this.anchorAngles[key][aIndex] = { angle_to_center: item_angle, distance: dis };
                            })
                        }
                    })

                    this.props.initiateRotate(this.anchorAngles);
                }

                if (!this.props.isMultimove)
                    this.props.updateTransformStatus({ transforming: true, rotating: true });

                document.getElementsByClassName("rotationDisplay")[0].style.left = (workspace_bounds.x + ((this.selectionBox.x + this.selectionBox.width / 2) * this.props.zoomFactor)) - 15 + "px";
                document.getElementsByClassName("rotationDisplay")[0].style.top = (workspace_bounds.y + ((this.selectionBox.y + this.selectionBox.height / 2) * this.props.zoomFactor)) - 15 + "px";
                document.getElementsByClassName("rotationDisplay")[0].style.display = "flex";
                if (this.props.isMultiMove) {
                    // this.toolBar.style.display = "none";
                }

                window.addEventListener('mousemove', this.rotateSelection, false);
                window.addEventListener('mouseup', this.stopRotate, false);
            }
        } catch (error) { }
    }

    rotateSelection(e) {
        try {
            let rAngle;
            let canUpdateDOM = true;
            if (e.type === "<<IMPERATIVE_ROTATE>>") {
                rAngle = e.angle;
                canUpdateDOM = false;
            } else {
                e.preventDefault();
                rAngle = this.getAngle(e);
            }
            let angleDisplay = rAngle;
            if (angleDisplay > 180)
                angleDisplay = angleDisplay - 360;

            let selectionBox = this.selectionBoxElement;
            selectionBox.style.transform = "translate(" + this.selectionBox.x * this.props.zoomFactor + "px, " + this.selectionBox.y * this.props.zoomFactor + "px) rotateZ(" + rAngle + "deg)";
            if (canUpdateDOM) {
                document.getElementById("rotationAngle").innerText = Math.round(angleDisplay).toFixed(0);
            }
            let allItems = this.selectedItems;
            rAngle = parseFloat(rAngle);

            if (this.multimoveId === null) {
                this.selectedItems.entrySeq().forEach(([key, item]) => {
                    let flipPos = item.get("flipPosition");
                    let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
                    let item_x = (Math.cos((item.get("angle_to_center") + rAngle) * Math.PI / 180) * item.get("dis_to_center") + this.selectionBox.cx) - item.get("width") / 2,
                        item_y = (Math.sin((item.get("angle_to_center") + rAngle) * Math.PI / 180) * item.get("dis_to_center") + this.selectionBox.cy) - item.get("height") / 2;
                    let initialAngle = parseFloat(this.state.selectedObjects.getIn([key, "angle"]));
                    let item_angle = rAngle + initialAngle;

                    if (allItems.size === 1)
                        item_angle -= initialAngle;

                    item_angle = (Math.abs(item_angle) > 360) ? item_angle % 360 : item_angle;

                    if (canUpdateDOM) {
                        let current_item = document.getElementById(key);
                        current_item.style.transform = "translate(" + (item_x * this.props.zoomFactor).toFixed(4) + "px, " + (item_y * this.props.zoomFactor).toFixed(4) + "px) rotateZ(" + (item_angle) + "deg) " + flip;
                    }

                    let item_to_update = Map({ "x": item_x, "y": item_y, "angle": item_angle, "rotationCount": this.rotateCount });

                    allItems = allItems.set(key, item.merge(item_to_update));
                });
            }
            else {
                this.selectedItems.entrySeq().forEach(([key, item]) => {
                    let item_x = (Math.cos((item.get("angle_to_center") + rAngle) * Math.PI / 180) * item.get("dis_to_center") + this.selectionBox.cx) - item.get("width") / 2,
                        item_y = (Math.sin((item.get("angle_to_center") + rAngle) * Math.PI / 180) * item.get("dis_to_center") + this.selectionBox.cy) - item.get("height") / 2;
                    let initialAngle = parseFloat(item.get("sAngle"));
                    let item_angle = rAngle + initialAngle;

                    if (allItems.size === 1)
                        item_angle -= initialAngle;

                    item_angle = (Math.abs(item_angle) > 360) ? item_angle % 360 : item_angle;

                    let item_to_update = Map({ "x": item_x, "y": item_y, "angle": item_angle, "rotationCount": this.rotateCount });

                    allItems = allItems.set(key, item.merge(item_to_update));
                });

                let firstKeyframe = this.selectedItems.get(this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "keyframes", 0, "id"]));

                let flipPos = this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "flipPosition"]);
                let flip = (flipPos === 1) ? " scaleX(-1)" : (flipPos === 2) ? " scaleY(-1)" : (flipPos === 3) ? " scaleX(-1) scaleY(-1)" : "";
                let item_x = (Math.cos((firstKeyframe.get("angle_to_center") + rAngle) * Math.PI / 180) * firstKeyframe.get("dis_to_center") + this.selectionBox.cx) - firstKeyframe.get("width") / 2,
                    item_y = (Math.sin((firstKeyframe.get("angle_to_center") + rAngle) * Math.PI / 180) * firstKeyframe.get("dis_to_center") + this.selectionBox.cy) - firstKeyframe.get("height") / 2;
                let initialAngle = parseFloat(this.state.selectedObjects.getIn([this.props.selectedItems.get(0), "angle"]));
                let item_angle = rAngle + initialAngle;

                if (allItems.size === 1)
                    item_angle -= initialAngle;

                item_angle = (Math.abs(item_angle) > 360) ? item_angle % 360 : item_angle;

                if (canUpdateDOM) {
                    let current_item = document.getElementById(this.props.selectedItems.get(0));
                    current_item.style.transform = "translate(" + (item_x * this.props.zoomFactor).toFixed(4) + "px, " + (item_y * this.props.zoomFactor).toFixed(4) + "px) rotateZ(" + (item_angle) + "deg) " + flip;
                }

                this.props.rotateSelection(this.selectionBox.cx, this.selectionBox.cy, rAngle);
            }

            this.selectedItems = allItems;
        } catch (error) { }
    }

    stopRotate(e) {
        try {
            let rAngle;
            if (e.type === "<<IMPERATIVE_ROTATE>>") {
                rAngle = e.angle;
            } else {
                e.preventDefault();
                rAngle = this.getAngle(e);
            }

            document.getElementsByClassName("rotationDisplay")[0].style.display = "none";
            document.getElementsByClassName("rotationLine")[0].style.display = "none";

            this.updateInitialSelectionBox({ transforming: false, rotating: false, angle: rAngle });

            if (!this.props.isMultimove) {

                if (this.multimoveId !== null) {
                    let item = this.props.selectedObjects.get(this.multimoveId);

                    item.get("keyframes").entrySeq().forEach(([key, keyframe]) => {
                        let anchors = keyframe.get("anchors");
                        if (anchors !== undefined && anchors.size > 0) {
                            if (anchors.size > 0) {
                                keyframe.get("anchors").entrySeq().forEach(([keyIndex, keyItem]) => {
                                    let item_x = (Math.cos((this.anchorAngles[key][keyIndex].angle_to_center + rAngle) * Math.PI / 180) * this.anchorAngles[key][keyIndex].distance + this.selectionBox.cx),
                                        item_y = (Math.sin((this.anchorAngles[key][keyIndex].angle_to_center + rAngle) * Math.PI / 180) * this.anchorAngles[key][keyIndex].distance + this.selectionBox.cy);
                                    anchors = anchors.set(keyIndex, fromJS({ x: item_x, y: item_y }));
                                });
                            }
                        }
                        this.selectedItems = this.selectedItems.setIn([keyframe.get("id"), "anchors"], fromJS(anchors));
                    });
                    this.anchorAngles = null;
                    this.props.stopRotate();
                }
                else {
                    let allChildrens = this.groupedItems;
                    allChildrens.entrySeq().forEach(([cKey, child]) => {
                        if (child.get("isMultimove") || child.get("isSwitch")) {
                            let allKeyframes = child.get("keyframes");
                            child.get("keyframes").entrySeq().forEach(([key, keyframe]) => {
                                let keyX = (Math.cos((keyframe.get("angle_to_center") + rAngle) * Math.PI / 180) * keyframe.get("distance") + this.selectionBox.cx) - keyframe.get("width") / 2,
                                    keyY = (Math.sin((keyframe.get("angle_to_center") + rAngle) * Math.PI / 180) * keyframe.get("distance") + this.selectionBox.cy) - keyframe.get("height") / 2;

                                let item_angle = rAngle + keyframe.get("angle");

                                item_angle = (Math.abs(item_angle) > 360) ? item_angle % 360 : item_angle;
                                allKeyframes = allKeyframes.setIn([key, "x"], keyX).setIn([key, "y"], keyY).setIn([key, "angle"], item_angle);

                                let anchors = keyframe.get("anchors");
                                if (anchors !== undefined && anchors.size > 0) {
                                    if (anchors.size > 0) {
                                        keyframe.get("anchors").entrySeq().forEach(([keyIndex, keyItem]) => {
                                            let item_x = (Math.cos((child.getIn(["anchorAngles", cKey, key, keyIndex, "angle_to_center"]) + rAngle) * Math.PI / 180) * child.getIn(["anchorAngles", cKey, key, keyIndex, "distance"]) + this.selectionBox.cx),
                                                item_y = (Math.sin((child.getIn(["anchorAngles", cKey, key, keyIndex, "angle_to_center"]) + rAngle) * Math.PI / 180) * child.getIn(["anchorAngles", cKey, key, keyIndex, "distance"]) + this.selectionBox.cy);
                                            anchors = anchors.set(keyIndex, fromJS({ x: item_x, y: item_y }));
                                        });
                                    }
                                    allKeyframes = allKeyframes.setIn([key, "anchors"], fromJS(anchors));
                                }
                            });
                            this.groupedItems = this.groupedItems.setIn([cKey, "keyframes"], allKeyframes);
                        }
                    });

                    let allItems = this.selectedItems;
                    allItems.entrySeq().forEach(([iKey, item]) => {
                        if (item.get("isMultimove") || item.get("isSwitch")) {
                            let allKeyframes = item.get("keyframes");
                            item.get("keyframes").entrySeq().forEach(([key, keyframe]) => {
                                let keyX = (Math.cos((keyframe.get("angle_to_center") + rAngle) * Math.PI / 180) * keyframe.get("distance") + this.selectionBox.cx) - keyframe.get("width") / 2,
                                    keyY = (Math.sin((keyframe.get("angle_to_center") + rAngle) * Math.PI / 180) * keyframe.get("distance") + this.selectionBox.cy) - keyframe.get("height") / 2;

                                let item_angle = rAngle + keyframe.get("angle");

                                item_angle = (Math.abs(item_angle) > 360) ? item_angle % 360 : item_angle;
                                allKeyframes = allKeyframes.setIn([key, "x"], keyX).setIn([key, "y"], keyY).setIn([key, "angle"], item_angle);
                                let anchors = keyframe.get("anchors");
                                if (anchors !== undefined && anchors.size > 0) {
                                    if (anchors.size > 0) {
                                        keyframe.get("anchors").entrySeq().forEach(([keyIndex, keyItem]) => {
                                            let item_x = (Math.cos((item.getIn(["anchorAngles", key, keyIndex, "angle_to_center"]) + rAngle) * Math.PI / 180) * item.getIn(["anchorAngles", key, keyIndex, "distance"]) + this.selectionBox.cx),
                                                item_y = (Math.sin((item.getIn(["anchorAngles", key, keyIndex, "angle_to_center"]) + rAngle) * Math.PI / 180) * item.getIn(["anchorAngles", key, keyIndex, "distance"]) + this.selectionBox.cy);
                                            anchors = anchors.set(keyIndex, fromJS({ x: item_x, y: item_y }));
                                        });
                                    }
                                    allKeyframes = allKeyframes.setIn([key, "anchors"], fromJS(anchors));
                                }
                            });

                            this.selectedItems = this.selectedItems.setIn([iKey, "keyframes"], allKeyframes);
                        }
                    });
                }

                this.props.rotationUpdate({
                    "items": this.selectedItems,
                    "children": this.groupedItems,
                    "currentContainer": this.currentItemContainer,
                    selectedItems: this.props.selectedItems,
                    multimoveId: this.multimoveId
                });
            }
            else {
                let handle = this.initialSelectionBox.handle;
                this.selectionBox.rotateCount = this.rotateCount;
                const restrictResizeBelowMin = ((this.isImageOnly() || this.isGridOnly()) && (handle === "left" || handle === "right" || handle === "top" || handle === "bottom"));
                let resize_position = this.getResizePosition(e, restrictResizeBelowMin);
                this.props.setKeyframeSelection({ selectedItems: this.selectedItems, selectionBox: this.selectionBox, scaleX: resize_position.scale_x, scaleY: resize_position.scale_y });
                let getAllItems = this.selectedItems;
                this.selectedItems.entrySeq().forEach(([key, single_item]) => {
                    getAllItems = getAllItems.set(key, single_item.set("sAngle", single_item.get("angle")));
                });
                this.selectedItems = getAllItems;
            }

            window.removeEventListener('mousemove', this.rotateSelection, false);
            window.removeEventListener('mouseup', this.stopRotate, false);
        } catch (error) { }
    }

    /**
     * @description This function checks whether item is uploaded svg image and whether it would be rendered using CustomSVGRender
     */
    isUploadSvgImage = (item) => {
        return (
            item.get("type") === "UPLOADS"
            && typeof item.get("sourceType") === "string"
            && item.get("sourceType").includes("svg")
            && item.get("colors")
        );
    }

    isImageOnly = () => {
        if (this.props.selectedItems.size === 1) {
            let selectedItem = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            return isImageOnly(selectedItem.get("type"), selectedItem.get("subType"));
        }
        return false;
    }

    isGridOnly = () => {
        if (this.props.selectedItems.size === 1) {
            let selectedItem = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            if (selectedItem.get("type") === "GRID") {
                return true;
            }
        }
        return false;
    }

    isTextOnly() {
        let result = false;
        if (this.props.selectedItems.size === 1) {
            let itemType = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            if (itemType !== undefined && itemType.get("type") === "TEXT" && itemType.get("subType") === "DTXT") {
                result = true;
            }
        }
        return result;
    }

    isGrid() {
        let result = false;
        if (this.props.selectedItems.size === 1) {
            let itemType = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            if (itemType !== undefined && itemType.get("type") === "GRID") {
                result = true;
            }
        }
        return result;
    }

    isCalloutOnly() {
        let result = false;
        if (this.props.selectedItems.size === 1) {
            let itemType = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            if (itemType !== undefined && itemType.get("type") === "SHAPE") {
                result = true;
            }
        }
        return result;
    }

    isShapeOnly() {
        let result = false;
        if (this.props.selectedItems.size === 1) {
            let itemType = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            if (itemType !== undefined && itemType.get("type") === "SHAPE") {
                result = true;
            }
        }
        return result;
    }

    isPermenantGroup() {
        if (this.props.selectedItems.size === 1) {
            if (this.props.selectedObjects.getIn([this.props.selectedItems.get(0), "type"]) === "GROUP") {
                return true;
            }
        }
        return false;
    }

    getCorner(pivotX, pivotY, cornerX, cornerY, angle) {
        let x, y, distance, diffX, diffY;

        /// get distance from center to point
        diffX = cornerX - pivotX;
        diffY = cornerY - pivotY;
        distance = Math.sqrt(diffX * diffX + diffY * diffY);

        /// find angle from pivot to corner
        angle += Math.atan2(diffY, diffX);

        /// get new x and y and round it off to integer
        x = pivotX + distance * Math.cos(angle);
        y = pivotY + distance * Math.sin(angle);

        return { x: x, y: y };
    }

    getSelectionBoxBounds(data) {
        let angle = data.angle * Math.PI / 180
        let c1, c2, c3, c4,    /// corners
            bx1, by1, bx2, by2, bounds;
        c1 = this.getCorner(data.cx, data.cy, data.x, data.y, angle);
        c2 = this.getCorner(data.cx, data.cy, data.x + data.width, data.y, angle);
        c3 = this.getCorner(data.cx, data.cy, data.x + data.width, data.y + data.height, angle);
        c4 = this.getCorner(data.cx, data.cy, data.x, data.y + data.height, angle);
        /// get bounding box
        bx1 = Math.min(c1.x, c2.x, c3.x, c4.x);
        by1 = Math.min(c1.y, c2.y, c3.y, c4.y);
        bx2 = Math.max(c1.x, c2.x, c3.x, c4.x);
        by2 = Math.max(c1.y, c2.y, c3.y, c4.y);

        bounds = { x: parseFloat(bx1.toFixed(2)), y: parseFloat(by1.toFixed(2)), width: parseFloat((bx2 - bx1).toFixed(2)), height: parseFloat((by2 - by1).toFixed(2)) };

        return bounds;
    }

    getObjectsBounds(objects) {
        let minX = 0, maxX = 0, minY = 0, maxY = 0;
        let count = 0;
        objects.entrySeq().forEach(([key, item]) => {
            let bounds, x, y, width, height;
            bounds = this.getSelectionBoxBounds({ x: item.get("x"), y: item.get("y"), cx: item.get("x") + item.get("width") / 2, cy: item.get("y") + item.get("height") / 2, width: item.get("width"), height: item.get("height"), angle: item.get("angle") });

            x = bounds.x; y = bounds.y; width = bounds.width; height = bounds.height;

            if (count === 0) {
                minX = x; maxX = x + width; minY = y; maxY = y + height;
                count++;
            } else {
                minX = Math.min(minX, x); maxX = Math.max(maxX, x + width); minY = Math.min(minY, y); maxY = Math.max(maxY, y + height);
            }
        });

        return { x: minX, y: minY, width: maxX - minX, height: maxY - minY }
    }

    setItemPositions(item) {
        let scx = this.selectionBox.cx, scy = this.selectionBox.cy, icx = (item.get("x") / this.props.zoomFactor) + (item.get("width") / this.props.zoomFactor) / 2, icy = (item.get("y") / this.props.zoomFactor) + (item.get("height") / this.props.zoomFactor) / 2;

        let item_angle = Math.atan2((icy * this.props.zoomFactor) - scy, (icx * this.props.zoomFactor) - scx) * (180 / Math.PI);

        let dis = Math.sqrt((Math.pow(scx - (icx * this.props.zoomFactor), 2)) + (Math.pow(scy - (icy * this.props.zoomFactor), 2)));

        let itemToMerge = Map({ "angle_to_center": item_angle, "dis_to_center": dis });

        if (item.get("isMultimove") || item.get("isSwitch")) {
            let anchorAngles = [];
            itemToMerge = itemToMerge.set("keyframes", item.get("keyframes"));
            item.get("keyframes").entrySeq().forEach(([key, keyframe]) => {
                let icx = (keyframe.get("x") / this.props.zoomFactor) + (keyframe.get("width") / this.props.zoomFactor) / 2, icy = (keyframe.get("y") / this.props.zoomFactor) + (keyframe.get("height") / this.props.zoomFactor) / 2;
                let keyAngle = Math.atan2((icy * this.props.zoomFactor) - scy, (icx * this.props.zoomFactor) - scx) * (180 / Math.PI);
                let childDis = Math.sqrt((Math.pow(scx - (icx * this.props.zoomFactor), 2)) + (Math.pow(scy - (icy * this.props.zoomFactor), 2)));
                itemToMerge = itemToMerge.setIn(["keyframes", key, "angle_to_center"], keyAngle).setIn(["keyframes", key, "distance"], childDis);
                if (item.get("isMultimove")) {
                    let anchors = keyframe.get("anchors");
                    anchorAngles[key] = [];
                    if (anchors !== undefined && anchors.size > 0) {
                        anchors.entrySeq().forEach(([aIndex, anchor]) => {
                            let scx = this.selectionBox.cx, scy = this.selectionBox.cy, icx = (anchor.get("x") / this.props.zoomFactor), icy = (anchor.get("y") / this.props.zoomFactor);

                            let item_angle = Math.atan2((icy * this.props.zoomFactor) - scy, (icx * this.props.zoomFactor) - scx) * (180 / Math.PI);

                            let dis = Math.sqrt((Math.pow(scx - (icx * this.props.zoomFactor), 2)) + (Math.pow(scy - (icy * this.props.zoomFactor), 2)));

                            anchorAngles[key][aIndex] = { angle_to_center: item_angle, distance: dis };
                            itemToMerge = itemToMerge.set("anchorAngles", fromJS(anchorAngles));
                        })
                    }
                }
            })
        }

        if (item.get("type") === "GROUP") {
            let groupedCX = item.get("x") + item.get("width") / 2, groupedCY = item.get("y") + item.get("height") / 2;
            let groupItems = this.props.selectedChildren;
            let anchorAngles = {};
            item.get("groupChildren").entrySeq().forEach(([childKey, itemId]) => {

                let child = groupItems.get(itemId);
                let groupedItemCX = child.get("x") + child.get("width") / 2, groupedItemCY = child.get("y") + child.get("height") / 2;
                let childAngle = Math.atan2(groupedItemCY - groupedCY, groupedItemCX - groupedCX) * (180 / Math.PI) - item.get("angle");
                let childDis = Math.sqrt((Math.pow(groupedCX - groupedItemCX, 2)) + (Math.pow(groupedCY - groupedItemCY, 2)));
                let childToMerge = Map({ "angle_to_center": childAngle, "dis_to_center": childDis });
                if (child.get("isMultimove") || child.get("isSwitch")) {
                    anchorAngles[itemId] = [];
                    childToMerge = childToMerge.set("keyframes", child.get("keyframes"));
                    child.get("keyframes").entrySeq().forEach(([key, keyframe]) => {

                        let keyCx = keyframe.get("x") + keyframe.get("width") / 2, keyCy = keyframe.get("y") + keyframe.get("height") / 2;
                        let keyAngle = Math.atan2(keyCy - groupedCY, keyCx - groupedCX) * (180 / Math.PI);
                        let childDis = Math.sqrt((Math.pow(groupedCX - keyCx, 2)) + (Math.pow(groupedCY - keyCy, 2)));
                        childToMerge = childToMerge.setIn(["keyframes", key, "angle_to_center"], keyAngle).setIn(["keyframes", key, "distance"], childDis);
                        if (child.get("isMultimove")) {
                            let anchors = keyframe.get("anchors");
                            anchorAngles[itemId][key] = [];
                            if (anchors !== undefined && anchors.size > 0) {
                                anchors.entrySeq().forEach(([aIndex, anchor]) => {
                                    let scx = this.selectionBox.cx, scy = this.selectionBox.cy, icx = (anchor.get("x") / this.props.zoomFactor), icy = (anchor.get("y") / this.props.zoomFactor);

                                    let item_angle = Math.atan2((icy * this.props.zoomFactor) - scy, (icx * this.props.zoomFactor) - scx) * (180 / Math.PI);

                                    let dis = Math.sqrt((Math.pow(scx - (icx * this.props.zoomFactor), 2)) + (Math.pow(scy - (icy * this.props.zoomFactor), 2)));

                                    anchorAngles[itemId][key][aIndex] = { angle_to_center: item_angle, distance: dis };
                                    childToMerge = childToMerge.set("anchorAngles", fromJS(anchorAngles));
                                })
                            }
                        }
                    })
                }

                this.groupedItems = this.groupedItems.set(itemId, child.merge(childToMerge));
            })
        }
        return item.merge(itemToMerge);
    }

    getSelectionBox() {
        let x = null, y = null, width = null, height = null, cx = null, cy = null, angle = 0;

        let selectedObjects = this.state.selectedObjects;
        this.selectedItems = this.state.selectedObjects;

        if (selectedObjects !== null && selectedObjects.size === 1) {
            let item = selectedObjects.first();
            if ((item.get("isMultimove") || item.get("isSwitch")) && this.props.animakerType === "full") {
                let keyframes = this.state.selectedObjects.getIn([this.props.selectedItems.get(0), "keyframes"]);

                this.multimoveId = this.props.selectedItems.get(0);
                this.selectedItems = fromJS({});
                keyframes.entrySeq().forEach(([frameKey, keyframeItem]) => {
                    this.selectedItems = this.selectedItems.set(keyframeItem.get("id"), keyframeItem);
                });

                let bounds = this.getObjectsBounds(this.selectedItems);
                x = bounds.x; y = bounds.y; width = bounds.width; height = bounds.height;
            } else {
                this.multimoveId = null;
                x = item.get("x"); y = item.get("y"); width = item.get("width"); height = item.get("height"); angle = item.get("angle");
            }
        } else {
            this.multimoveId = null;
            if (this.props.selectedTab === "cameraTab")
                this.selectedItems = this.props.selectedItems
            let bounds = this.getObjectsBounds(this.selectedItems);
            x = bounds.x; y = bounds.y; width = bounds.width; height = bounds.height;
        }

        if (x !== null)
            cx = x + (width / 2);
        if (y !== null)
            cy = y + (height / 2);
        if (angle === undefined)
            angle = 0;
        this.selectionBox = Object.assign(this.selectionBox, { x: x, y: y, width: width, height: height, cx: cx, cy: cy, angle: angle });
        this.initialSelectionBox = this.selectionBox;
        this.selectedItems = this.selectedItems.map(x => this.setItemPositions(x));
        let getAllItems = this.selectedItems;
        this.selectedItems.entrySeq().forEach(([key, single_item]) => {
            getAllItems = getAllItems.set(key, single_item.set("sAngle", single_item.get("angle") !== undefined ? single_item.get("angle") : 0));
        });
        this.selectedItems = getAllItems;

        return this.selectionBox;
    }

    isVerticalScale() {
        let result = false;
        if (this.props.selectedItems.size === 1) {
            let item = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            if (item !== undefined) {
                if (item.get("type") === "GROUP" && item.get("subType") === "TXTGRP" && !item.get("isOverlay")) {
                    if (item.get("isExpands")) {
                        let svgItem = this.props.selectedChildren.filter((obj, key) => item.get("groupChildren").indexOf(key) !== -1).find(x => x.get("type") === "TEXT");
                        if (svgItem.get("verticalAlign") !== undefined)
                            result = true;
                    }
                }
                else if (item.get("type") === "SHAPE" || (item.get("type") === "TEXT")) {
                    if (item.get("scaleType") === "square" || item.get("scaleType") === "vertical")
                        return true;
                    return false;
                }
                else if (isVideoOnly(item.get("type"), item.get("subType"))) {
                    return true;
                }
                else if (isImageOnly(item.get("type"), item.get("subType")) && !this.isUploadSvgImage(item) && !this.props.isMultimove && !this.multimoveId) {
                    return true;
                }
                else if (item.get("type") === "GRID" && !this.props.isMultimove && !this.multimoveId) {
                    return true;
                }
            }
        }
        return result;
    }

    isHorizontalScale() {
        let result = false;
        if (this.props.selectedItems.size === 1) {
            let item = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            if (item !== undefined) {
                if (item.get("type") === "TEXT" && item.get("subType") === "DTXT" && !this.props.isMultimove) {
                    result = true;
                }
                else if (item.get("type") === "GROUP" && item.get("subType") === "TXTGRP" && !item.get("isOverlay")) {
                    if (item.get("isExpands")) {
                        let svgItem = this.props.selectedChildren.filter((obj, key) => item.get("groupChildren").indexOf(key) !== -1).find(x => x.get("type") === "TEXT");
                        if (svgItem.get("horizontalAlign") !== undefined)
                            result = true;
                    }
                }
                else if (item.get("type") === "SHAPE" || (item.get("type") === "TEXT")) {
                    if (item.get("scaleType") === "square" || item.get("scaleType") === "horizontal")
                        return true;
                    return false;
                }
                else if (isVideoOnly(item.get("type"), item.get("subType"))) {
                    return true;
                }
                else if (isImageOnly(item.get("type"), item.get("subType")) && !this.isUploadSvgImage(item) && !this.props.isMultimove && !this.multimoveId) {
                    return true;
                } else if (item.get("type") === "GRID" && !this.props.isMultimove && !this.multimoveId) {
                    return true;
                }
            }
        }
        return result;
    }

    getXYRotation(cx, cy, x, y, angle) {
        let x2 = Math.cos(angle / 180 * Math.PI) * (x - cx) - Math.sin(angle / 180 * Math.PI) * (y - cy);
        let y2 = Math.sin(angle / 180 * Math.PI) * (x - cx) + Math.cos(angle / 180 * Math.PI) * (y - cy);
        return [x2 + cx, y2 + cy];
    }

    dist(pot1, pt2) {
        let dx = pot1.x - pt2.x;
        let dy = pot1.y - pt2.y;
        return Math.sqrt(dx * dx + dy * dy);
    }

    getCubicBezierPoints(pointDataArr, t) {
        let helperPoints = [];

        for (let i = 1; i < 4; i++) {
            let p = this.lerp(pointDataArr[i - 1], pointDataArr[i], t);
            helperPoints.push(p);
        }

        helperPoints.push(this.lerp(helperPoints[0], helperPoints[1], t));
        helperPoints.push(this.lerp(helperPoints[1], helperPoints[2], t));

        helperPoints.push(this.lerp(helperPoints[3], helperPoints[4], t));

        let firstBezier = [
            pointDataArr[0],
            helperPoints[0],
            helperPoints[3],
            helperPoints[5]
        ];
        let secondBezier = [
            helperPoints[5],
            helperPoints[4],
            helperPoints[2],
            pointDataArr[3]
        ];

        // returns 2 array of points for the new bezier curves;
        return [firstBezier, secondBezier];
    }

    lerp(A, B, t) {
        return [
            (Number(B[0]) - Number(A[0])) * t + Number(A[0]), // the x coordinate;
            (Number(B[1]) - Number(A[1])) * t + Number(A[1])  // the y coordinate;
        ];
    }

    sfix(arr) {
        let num1 = Number(arr[0]);
        let num2 = Number(arr[1]);
        num1 = num1 < 0 ? Math.floor(Math.abs(num1) * 100) * -1 / 100 : num1.toFixed(2);
        num2 = num2 < 0 ? Math.floor(Math.abs(num2) * 100) * -1 / 100 : num2.toFixed(2);
        return num1 + "," + num2
    }

    sFix2(num1, num2) {
        num1 = Number(num1);
        num2 = Number(num2);
        num1 = num1 < 0 ? Math.floor(Math.abs(num1) * 100) * -1 / 100 : num1.toFixed(2);
        num2 = num2 < 0 ? Math.floor(Math.abs(num2) * 100) * -1 / 100 : num2.toFixed(2);
        return num1 + "," + num2
    }

    drawTail(pathData, calloutPath, callout, dataArray, ptArray, xy, cTail, endPt, update) {
        let individualPtArray = dataArray;
        let plen2 = ptArray.length;
        let pt = calloutPath.getPointAtLength(cTail);
        let pt2 = calloutPath.getPointAtLength(endPt);
        let currentSegment1 = 0;
        let currentSegment2 = 0;

        for (let i = 0; i < plen2; i++) {
            if (cTail < ptArray[i].t) {
                currentSegment1 = i;
                break;
            }
        }

        for (let j = 0; j < plen2; j++) {
            if (endPt < ptArray[j].t) {
                currentSegment2 = j;
                break;
            }
        }

        let istr = ""
        let totalIndi = ((currentSegment1 + 1) * 3) + 1

        for (let v = 0; v < totalIndi - 3; v++) {
            istr = istr + individualPtArray[v] + " "
        }

        let cir2ptDataArr = []
        for (let v = totalIndi - 4; v < totalIndi; v++) {
            if (individualPtArray[v].indexOf("C") !== -1) {
                cir2ptDataArr.push(individualPtArray[v].substring(1).split(","))
            } else {
                cir2ptDataArr.push(individualPtArray[v].split(","))
            }
        }

        let cir3ptDataArr = []

        let totalIndi2 = ((currentSegment2 + 1) * 3) + 1

        for (let d = totalIndi2 - 4; d < totalIndi2; d++) {
            if (individualPtArray[d].indexOf("C") !== -1) {
                cir3ptDataArr.push(individualPtArray[d].substring(1).split(","))
            } else {
                cir3ptDataArr.push(individualPtArray[d].split(","))
            }
        }

        let ustr = ""
        let totalInd = individualPtArray.length
        for (let u = totalIndi2 - 1; u < totalInd; u++) {
            ustr = ustr + individualPtArray[u] + " "
        }

        let curseg1 = currentSegment1
        let t
        if (currentSegment1 <= 0) {
            t = (cTail / ptArray[0].t)
        } else {
            t = ((cTail - ptArray[curseg1 - 1].t) / (ptArray[curseg1].t - ptArray[curseg1 - 1].t))
        }

        let resultcir2Arr = this.getCubicBezierPoints(cir2ptDataArr, t);

        let t2
        let curseg2 = currentSegment2
        if (currentSegment2 - 1 < 0) {
            t2 = (endPt / ptArray[0].t)
        } else {
            t2 = ((endPt - ptArray[curseg2 - 1].t) / (ptArray[curseg2].t - ptArray[curseg2 - 1].t))
        }

        let resultcir3Arr = this.getCubicBezierPoints(cir3ptDataArr, t2);

        let cl0 = this.sFix2(pt.x, pt.y)
        let cl = this.sFix2(xy.x, xy.y)
        let cl2 = this.sFix2(pt2.x, pt2.y)
        let h1 = this.sfix(resultcir2Arr[0][1])
        let h2 = this.sfix(resultcir2Arr[0][2])

        let b1 = this.sfix(resultcir3Arr[1][1])
        let b2 = this.sfix(resultcir3Arr[1][2]);
        let data = "M" + istr + "C" + h1 + " " + h2 + " " + cl0 + " C" + cl0 + " " + cl + " " + cl + " C" + cl + " " + cl2 + " " + cl2 + " C" + b1 + " " + b2 + " " + ustr;

        if (currentSegment2 >= currentSegment1) {
            // callout.setAttributeNS(null,'d',"M"+istr+"C"+h1+" "+h2+" "+cl0+" C"+cl0+" "+cl+" "+cl+" C"+cl+" "+cl2+" "+cl2+" C"+b1+" "+b2+" "+ustr);
        } else {
            let istrArr = istr.split(" ")
            istrArr.shift();
            istrArr.shift();
            istrArr.shift();
            istr = istrArr.join(" ")
            // callout.setAttributeNS(null,'d',"M"+cl2+" C"+b1+" "+    b2+" "+istr+"C"+h1+" "+h2+" "+cl0+" C"+cl0+" "+cl+" "+cl+" C"+cl+" "+cl2+" "+cl2);
            data = "M" + cl2 + " C" + b1 + " " + b2 + " " + istr + "C" + h1 + " " + h2 + " " + cl0 + " C" + cl0 + " " + cl + " " + cl + " C" + cl + " " + cl2 + " " + cl2;
            // callout.setAttributeNS(null,'d',"M"+istr+"C"+h1+" "+h2+" "+cl0+" C"+cl0+" "+cl+" "+cl+" C"+cl+" "+cl2+" "+cl2);
        }
        callout.setAttributeNS(null, 'd', data);
        if (update === true) {
            this.props.updateObjectById({
                container: "workspaceItems",
                id: this.props.selectedItems.get(0),
                toUpdate: { path: [data] }
            });
        }
    }

    gradSearch(l0, pt) {
        l0 = l0 + this.actualLength;

        let l1 = l0,
            dist0 = this.dist(this.calloutPath.getPointAtLength(l0 % this.actualLength), pt),
            dist1,
            searchDir;
        if (this.dist(this.calloutPath.getPointAtLength((l0 - 1) % this.actualLength), pt) >
            this.dist(this.calloutPath.getPointAtLength((l0 + 1) % this.actualLength), pt)) {
            searchDir = 1;
        } else {
            searchDir = -1;
        }

        l1 += searchDir;
        dist1 = this.dist(this.calloutPath.getPointAtLength(l1 % this.actualLength), pt);
        while (dist1 < dist0) {
            dist0 = dist1;
            l1 += searchDir;
            dist1 = this.dist(this.calloutPath.getPointAtLength(l1 % this.actualLength), pt);
        }
        l1 -= searchDir;
        return (l1 % this.actualLength);
    }

    controlShape(e) {
        e.preventDefault();
        e.stopPropagation();
        let t = e.target, et = e.type, m = { x: e.pageX, y: e.pageY };

        if (et === "mousedown" || et === "touchstart") {
            if (!this.drag) {
                this.drag = t;
                this.dPoint = m;
                this.handle = e.target.getAttribute("datahandle");
                this.pathData = this.selectedItems.getIn([this.props.selectedItems.get(0), "pathData"]).toJS();

                this.moveType = this.pathData.controlPoints[this.handle].moveType;
                this.angle = this.selectedItems.getIn([this.props.selectedItems.get(0), "angle"]);

                this.prevX = ((this.pathData.controlPoints[this.handle].x / 100) * this.selectedItems.getIn([this.props.selectedItems.get(0), "width"]));
                this.prevY = ((this.pathData.controlPoints[this.handle].y / 100) * this.selectedItems.getIn([this.props.selectedItems.get(0), "height"]));
                let pathDetails = getPath(this.selectedItems.get(this.props.selectedItems.get(0)), this.props.zoomFactor, this.props.selectedObjects.get(this.props.selectedItems.get(0)));
                if (pathDetails.newControlPoints !== undefined && pathDetails.newControlPoints[this.handle] !== undefined) {
                    this.prevX = ((pathDetails.newControlPoints[this.handle].x / 100) * this.selectedItems.getIn([this.props.selectedItems.get(0), "width"]));
                    this.prevY = ((pathDetails.newControlPoints[this.handle].y / 100) * this.selectedItems.getIn([this.props.selectedItems.get(0), "height"]));
                }
            }
            window.addEventListener('mousemove', this.controlShape, false);
            window.addEventListener('mouseup', this.controlShape, false);
        }

        if (this.drag && (et === "mousemove" || et === "touchmove")) {
            let dx = (m.x - this.dPoint.x) / this.props.zoomFactor, dy = (m.y - this.dPoint.y) / this.props.zoomFactor;
            let adX = ((dx * Math.cos(this.angle * (Math.PI / 180))) + (dy * Math.sin(this.angle * (Math.PI / 180))));
            let adY = ((dy * Math.cos(this.angle * (Math.PI / 180))) - (dx * Math.sin(this.angle * (Math.PI / 180))));

            if (this.selectedItems.getIn([this.props.selectedItems.get(0), "flipPosition"]) === 1)
                adX *= -1;
            else if (this.selectedItems.getIn([this.props.selectedItems.get(0), "flipPosition"]) === 2)
                adY *= -1;
            else if (this.selectedItems.getIn([this.props.selectedItems.get(0), "flipPosition"]) === 3) {
                adX *= -1;
                adY *= -1;
            }

            let currentX = (this.moveType === "x" || this.moveType === "xy") ? this.prevX + adX : this.prevX;
            let currentY = (this.moveType === "y" || this.moveType === "xy") ? this.prevY + adY : this.prevY;

            let xPoint = (currentX / this.selectedItems.getIn([this.props.selectedItems.get(0), "width"])) * 100, yPoint = (currentY / this.selectedItems.getIn([this.props.selectedItems.get(0), "height"])) * 100;

            let currentPathData = fromJS(this.pathData).setIn(["controlPoints", this.handle, "x"], xPoint).setIn(["controlPoints", this.handle, "y"], yPoint);

            let pathDetails = getPath(this.selectedItems.setIn([this.props.selectedItems.get(0), "pathData"], fromJS(currentPathData)).get(this.props.selectedItems.get(0)), this.props.zoomFactor, this.props.selectedObjects.get(this.props.selectedItems.get(0)));
            let dataPath = pathDetails.pathDetails;
            if (Array.isArray(dataPath)) {
                for (let p = 0; p < dataPath.length; p++) {
                    document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].getElementsByClassName("path" + p)[0].setAttribute("d", dataPath[p].data);

                    if (dataPath[p].pathStyle !== undefined)
                        document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].getElementsByTagName("g")[0].style.transform = "translate(" + dataPath[p].pathStyle.left + "px, " + dataPath[p].pathStyle.top + "px)";
                }
            }

            if (pathDetails.shapeStyle !== undefined) {
                document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].style.left = pathDetails.shapeStyle.left;
                document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].style.top = pathDetails.shapeStyle.top;
                document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].style.width = pathDetails.shapeStyle.width;
                document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].style.height = pathDetails.shapeStyle.height;
            }

            if (pathDetails.newControlPoints !== undefined) {
                let newControlPoints = this.getFlipPosition(pathDetails.newControlPoints, this.selectedItems.getIn([this.props.selectedItems.get(0), "flipPosition"]));
                for (let p of Reflect.ownKeys(newControlPoints)) {
                    document.getElementsByClassName("control-point " + p)[0].style.left = "calc(" + newControlPoints[p].x + "% - 7.5px)";
                    document.getElementsByClassName("control-point " + p)[0].style.top = "calc(" + newControlPoints[p].y + "% - 7.5px)";
                }
            }

        }

        if (this.drag && (et === "mouseup" || et === "touchend")) {
            let dx = (m.x - this.dPoint.x) / this.props.zoomFactor, dy = (m.y - this.dPoint.y) / this.props.zoomFactor;
            let adX = ((dx * Math.cos(this.angle * (Math.PI / 180))) + (dy * Math.sin(this.angle * (Math.PI / 180))));
            let adY = ((dy * Math.cos(this.angle * (Math.PI / 180))) - (dx * Math.sin(this.angle * (Math.PI / 180))));

            if (this.selectedItems.getIn([this.props.selectedItems.get(0), "flipPosition"]) === 1)
                adX *= -1;
            else if (this.selectedItems.getIn([this.props.selectedItems.get(0), "flipPosition"]) === 2)
                adY *= -1;
            else if (this.selectedItems.getIn([this.props.selectedItems.get(0), "flipPosition"]) === 3) {
                adX *= -1;
                adY *= -1;
            }

            let currentX = (this.moveType === "x" || this.moveType === "xy") ? this.prevX + adX : this.prevX;
            let currentY = (this.moveType === "y" || this.moveType === "xy") ? this.prevY + adY : this.prevY;

            let xPoint = (currentX / this.selectedItems.getIn([this.props.selectedItems.get(0), "width"])) * 100, yPoint = (currentY / this.selectedItems.getIn([this.props.selectedItems.get(0), "height"])) * 100;

            let currentPathData = fromJS(this.pathData).setIn(["controlPoints", this.handle, "x"], xPoint).setIn(["controlPoints", this.handle, "y"], yPoint);
            let pathDetails = getPath(this.selectedItems.setIn([this.props.selectedItems.get(0), "pathData"], fromJS(currentPathData)).get(this.props.selectedItems.get(0)), 1, this.props.selectedObjects.get(this.props.selectedItems.get(0)));

            let dataPath = pathDetails.pathDetails;

            this.props.updateItem({
                container: "workspaceItems",
                selectedItem: this.props.selectedItems.get(0),
                toUpdate: { pathData: fromJS(pathDetails.pathData), path: dataPath }
            });


            this.drag = null; this.handle = null; /* this.pathData; */ this.prevX = null; this.prevY = null;
            window.removeEventListener('mousemove', this.controlShape, false);
            window.removeEventListener('mouseup', this.controlShape, false);
        }
    }

    moveControlPoint(e) {
        try {
            e.preventDefault();
            e.stopPropagation();

            let t = e.target, et = e.type, m = { x: e.pageX, y: e.pageY };
            let curWidth = this.selectedItems.getIn([this.props.selectedItems.get(0), "width"]);
            let curX = this.selectedItems.getIn([this.props.selectedItems.get(0), "x"]);
            let curY = this.selectedItems.getIn([this.props.selectedItems.get(0), "y"]);
            let curHeight = this.selectedItems.getIn([this.props.selectedItems.get(0), "height"]);

            if (et === "mousedown" || et === "touchstart") {
                if (!this.drag) {
                    this.relativeXY = this.getXYRotation(curX + curWidth / 2, curY + curHeight / 2, curX, curY, this.selectedItems.getIn([this.props.selectedItems.get(0), "angle"]));
                    this.drag = t;
                    this.dPoint = m;

                    this.handle = e.target.getAttribute("datahandle");
                    this.pathData = this.selectedItems.getIn([this.props.selectedItems.get(0), "pathData"]).toJS();

                    if (this.handle === "callout-tail") {
                        this.calloutPath = document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].getElementsByClassName("center")[0].getElementsByClassName("calloutPath")[0];
                        this.callout = document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].getElementsByClassName("center")[0].getElementsByClassName("callout")[0];
                        this.tailElement = document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].getElementsByClassName("tail")[0];
                        this.position = this.pathData.tail.position;

                        let p = document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].createSVGPoint();
                        p.x = e.clientX;
                        p.y = e.clientY;
                        m = this.tailElement.getScreenCTM();
                        p = p.matrixTransform(m.inverse());

                        this.nMouseOffsetX = p.x - parseInt(this.tailElement.getAttribute("cx"), 10);
                        this.nMouseOffsetY = p.y - parseInt(this.tailElement.getAttribute("cy"), 10);

                        let tmpPt = {
                            x: p.x - this.nMouseOffsetX,
                            y: p.y - this.nMouseOffsetY
                        };

                        this.actualLength = this.calloutPath.getTotalLength();

                        let ptArray = [], segInc = 0, totLen = 0, individualPtArray = [];
                        ptArray[segInc] = this.callout.getPointAtLength(0);
                        ptArray[segInc].t = 0;
                        let pathString = this.calloutPath.getAttributeNS(null, "d");
                        this.pathArr = [];

                        let _Data = pathString.slice(0, -1)
                        let ptArrays = []
                        ptArrays = _Data.split(" ");
                        individualPtArray = _Data.split(" ");
                        individualPtArray[0] = individualPtArray[0].substring(1)
                        let tempArr = []
                        let str = ptArrays[0]

                        for (let i = 0; i < ptArrays.length - 1; i += 3) {
                            if (i === 0) {
                                tempArr.push(ptArrays[i] + " " + ptArrays[i + 1] + " " + ptArrays[i + 2] + " " + ptArrays[i + 3] + " ")
                            } else {
                                tempArr.push(ptArrays[i + 1] + " " + ptArrays[i + 2] + " " + ptArrays[i + 3] + " ")
                            }
                            str = str + " " + ptArrays[i + 1] + " " + ptArrays[i + 2] + " " + ptArrays[i + 3] + " "
                            // updatePtArr(str)

                            this.callout.setAttributeNS(null, 'd', str);
                            totLen = this.callout.getTotalLength();
                            ptArray[segInc] = this.callout.getPointAtLength(totLen);
                            ptArray[segInc].t = totLen;
                            segInc++;
                        }

                        this.cTail = this.pathData.tail.position;
                        let callOffset = this.pathData.tail.offset;
                        let endPt = this.cTail + callOffset;

                        if (this.cTail < 0) {
                            this.cTail = this.cTail + this.actualLength;
                        }
                        if (endPt > this.actualLength) {
                            endPt = endPt - this.actualLength;
                        }

                        this.pt = this.calloutPath.getPointAtLength(this.cTail);
                        this.pt2 = this.calloutPath.getPointAtLength(endPt);

                        this.drawTail(this.pathData, this.calloutPath, this.callout, individualPtArray, ptArray, { x: tmpPt.x, y: tmpPt.y }, this.cTail, endPt);

                        this.ptArray = ptArray;
                        this.individualPtArray = individualPtArray;
                    }
                }
                window.addEventListener('mousemove', this.moveControlPoint, false);
                window.addEventListener('mouseup', this.moveControlPoint, false);
            }

            if (this.drag && (et === "mousemove" || et === "touchmove")) {
                let actualWidth = this.pathData.bounds.width;
                let actualHeight = this.pathData.bounds.height;

                if (this.handle !== "callout-tail") {

                    if (this.pathData.controlPoints.top) {
                        let arr1, arr2;
                        let newData = "";
                        if (!this.pathData.isRectangle) {
                            let dX = (m.x - this.dPoint.x) + ((this.pathData.controlPoints.top.x / 100) * curWidth);

                            dX = (dX < 0) ? 0 : (dX > curWidth) ? curWidth : dX;
                            let xPos = (dX / curWidth) * 100;

                            let data = this.pathData.data;

                            let pointArrays = data.split(" ");
                            let ctrlPoint = actualWidth * (xPos / 100);
                            let mY = (pointArrays[0].split(",")[1]);
                            mY = Number(mY).toFixed(2);

                            newData = "M" + ctrlPoint + "," + mY;

                            for (let i = 1; i < pointArrays.length; i += 3) {
                                arr1 = pointArrays[i].substring(1).split(",");
                                arr2 = pointArrays[i + 1].split(",");
                                let arr3 = pointArrays[i + 2].split(",");
                                let arrz = arr3[1].split("z")[0];

                                let neutral1 = (i === 1) ? ctrlPoint : arr1[0];
                                newData = newData + " C" + neutral1 + "," + arr1[1];

                                let neutral2 = (i + 3 === pointArrays.length) ? ctrlPoint : arr2[0];
                                newData = newData + " " + neutral2 + "," + arr2[1];

                                let neutral3 = (i + 3 === pointArrays.length) ? ctrlPoint : arr3[0];
                                newData = newData + " " + neutral3 + "," + arrz;
                            }

                            newData = newData + "z";
                            this.drag.parentNode.style.left = "calc(" + xPos + "% - 9px)";
                        } else {
                            let dX = (((actualWidth * (this.pathData.controlPoints.top.x / 100)) + (m.x - this.dPoint.x)) / actualWidth) * 100;
                            dX = (dX < 10) ? 10 : (dX > 45) ? 45 : dX;

                            let data = this.pathData.data;
                            let pointArrays = data.split(" ");
                            pointArrays[pointArrays.length - 1] = pointArrays[pointArrays.length - 1].slice(0, -1);

                            let cX = (pointArrays[1].split(",")[0]);
                            cX = Number(cX.substring(1)).toFixed(2);
                            let cY = (pointArrays[1].split(",")[1]);
                            cY = Number(cY).toFixed(2);
                            newData = ""
                            newData = pointArrays[0];

                            newData = newData + " C" + (Number(cX)) + "," + (Number(cY));
                            let curviness = (dX / 100) * actualWidth;
                            for (let i = 2; i < pointArrays.length; i += 3) {
                                let xadder = 0
                                let yadder = 0
                                let arr3;
                                arr1 = pointArrays[i].split(",");

                                arr2 = pointArrays[i + 1].split(",");
                                if (i !== pointArrays.length - 2)
                                    arr3 = pointArrays[i + 2].substring(1).split(",");
                                if (i === 5) {
                                    xadder = -curviness
                                }
                                if (i === 2) {
                                    yadder = -curviness
                                }
                                if (i === 8) {
                                    xadder = curviness
                                }
                                if (i === 11) {
                                    yadder = -curviness
                                }
                                if (i === 17) {
                                    xadder = curviness
                                }
                                if (i === 14) {
                                    yadder = curviness
                                }
                                if (i === 20) {
                                    xadder = -curviness
                                }
                                if (i === 23) {
                                    yadder = curviness
                                }
                                // newData=newData+" C"+arr1[0]+","+arr1[1];
                                newData = newData + " " + (Number(arr1[0]) + xadder) + "," + (Number(arr1[1]) + yadder);
                                newData = newData + " " + (Number(arr2[0]) + xadder) + "," + (Number(arr2[1]) + yadder);
                                if (i !== pointArrays.length - 2)
                                    newData = newData + " C" + (Number(arr3[0])) + "," + (Number(arr3[1]));
                            }
                            newData = newData + "z";
                            this.drag.parentNode.style.left = "calc(" + (dX) + "% - 6px)";
                        }
                        // this.drag.style.left = "calc("+ xPos +"% - 6px)";
                        document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].getElementsByClassName("center")[0].getElementsByTagName("path")[0].setAttribute("d", newData);
                    }
                } else {
                    let cTail = this.cTail;
                    let callOffset = this.pathData.tail.offset;
                    let endPt = cTail + callOffset;
                    // cTail = cTail + this.actualLength;

                    let p = document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].createSVGPoint();
                    p.x = e.clientX;
                    p.y = e.clientY;
                    let ctm = this.tailElement.getScreenCTM();
                    p = p.matrixTransform(ctm.inverse());
                    let tmpPt = {
                        x: p.x - this.nMouseOffsetX,
                        y: p.y - this.nMouseOffsetY
                    };

                    this.tailElement.setAttribute("cx", tmpPt.x);
                    this.tailElement.setAttribute("cy", tmpPt.y);

                    let midPoint = cTail + (callOffset / 2);
                    midPoint = this.gradSearch(midPoint, tmpPt);
                    cTail = midPoint - (callOffset / 2);
                    endPt = cTail + callOffset;

                    if (cTail < 0) {
                        cTail = cTail + this.actualLength;
                    }
                    if (endPt > this.actualLength) {
                        endPt = endPt - this.actualLength;
                    }

                    this.cTail = cTail;
                    this.position = (this.cTail) / this.actualLength;

                    // let pt1 = this.calloutPath.getPointAtLength(this.cTail);

                    // let pt2 = this.calloutPath.getPointAtLength(endPt);

                    // var a_angle = (Math.atan2(pt1.y - pt2.y, pt1.x - pt2.x)) * (180 / Math.PI);
                    // a_angle = a_angle + 90
                    // a_angle = a_angle * (Math.PI / 180);

                    midPoint = this.cTail + (callOffset / 2);
                    if (midPoint > this.actualLength)
                        midPoint = midPoint - this.actualLength;

                    let tailPoint = this.calloutPath.getPointAtLength(midPoint);

                    let scaleX = this.selectedItems.getIn([this.props.selectedItems.get(0), "groupBounds", "center", "scaleX"]);
                    let scaleY = this.selectedItems.getIn([this.props.selectedItems.get(0), "groupBounds", "center", "scaleY"]);
                    let translateX = this.selectedItems.getIn([this.props.selectedItems.get(0), "groupBounds", "center", "x"]), translateY = this.selectedItems.getIn([this.props.selectedItems.get(0), "groupBounds", "center", "y"]);

                    let dX = ((tailPoint.x + tmpPt.x) + (translateX - tailPoint.x));
                    let dY = ((tailPoint.y + tmpPt.y) + (translateY - tailPoint.y));


                    let svgX = 0, svgY = 0, distX = 0, distY = 0;
                    if (dX < 0) {
                        svgX = dX * scaleX;
                        distX = this.pathData.bounds.width - dX;
                    }

                    if (dX > this.pathData.bounds.width) {
                        distX = dX - this.pathData.bounds.width;
                    }

                    if (dY < 0) {
                        svgY = dY * scaleY;
                        distY = this.pathData.bounds.height - dY;
                    }

                    if (dY > this.pathData.bounds.height) {
                        distY = dY - this.pathData.bounds.height;
                    }

                    let svgWidth = ((actualWidth + distX) * scaleX);
                    let svgHeight = ((actualHeight + distY) * scaleY);

                    document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].style.top = (svgY * this.props.zoomFactor) + "px";
                    document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].style.left = (svgX * this.props.zoomFactor) + "px";

                    document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].getElementsByClassName("center")[0].style.transform = "matrix(" + (scaleX * this.props.zoomFactor) + ", 0, 0, " + (scaleY * this.props.zoomFactor) + ", " + ((translateX - svgX) * this.props.zoomFactor) + ", " + ((translateY - svgY) * this.props.zoomFactor) + ")";

                    // if(actualWidth < svgWidth){
                    document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].style.width = (svgWidth) + "px";
                    // }
                    // if(actualHeight < svgHeight){
                    document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].style.height = (svgHeight - svgY) + "px";
                    // }

                    this.drawTail(this.pathData, this.calloutPath, this.callout, this.individualPtArray, this.ptArray, { x: tmpPt.x, y: tmpPt.y }, this.cTail, endPt);
                    this.drag.parentNode.style.left = ((tmpPt.x * scaleX * this.props.zoomFactor) - 10) + "px";
                    this.drag.parentNode.style.top = ((tmpPt.y * scaleY * this.props.zoomFactor) - 10) + "px";
                }
            }

            if (this.drag && (et === "mouseup" || et === "touchend")) {
                let actualWidth = this.pathData.bounds.width;
                // let actualHeight = this.pathData.bounds.height;
                if (this.handle !== "callout-tail") {
                    if (this.pathData.controlPoints.top) {
                        if (!this.pathData.isRectangle) {
                            let dX = (m.x - this.dPoint.x) + ((this.pathData.controlPoints.top.x / 100) * curWidth);

                            dX = (dX < 0) ? 0 : (dX > curWidth) ? curWidth : dX;
                            let xPos = (dX / curWidth) * 100;
                            this.pathData.controlPoints.top.x = xPos;
                        } else {
                            let dX = (((actualWidth * (this.pathData.controlPoints.top.x / 100)) + (m.x - this.dPoint.x)) / actualWidth) * 100;
                            dX = (dX < 10) ? 10 : (dX > 45) ? 45 : dX;

                            this.pathData.controlPoints.top.x = dX;
                        }
                        let svgPath = document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].getElementsByClassName("center")[0].getElementsByTagName("path")[0].getAttribute("d");

                        this.props.updateObjectById({
                            container: "workspaceItems",
                            id: this.props.selectedItems.get(0),
                            toUpdate: { pathData: this.pathData, path: [svgPath] }
                        });
                    }
                } else {
                    let p = document.getElementById(this.props.selectedItems.get(0)).getElementsByTagName("svg")[0].createSVGPoint();
                    p.x = e.clientX;
                    p.y = e.clientY;
                    let ctm = this.tailElement.getScreenCTM();
                    p = p.matrixTransform(ctm.inverse());
                    let tmpPt = {
                        x: p.x - this.nMouseOffsetX,
                        y: p.y - this.nMouseOffsetY
                    };

                    let endPt = this.cTail + this.pathData.tail.offset;

                    if (this.cTail < 0) {
                        this.cTail = this.cTail + this.actualLength;
                    }
                    if (endPt > this.actualLength) {
                        endPt = endPt - this.actualLength;
                    }

                    this.drawTail(this.pathData, this.calloutPath, this.callout, this.individualPtArray, this.ptArray, { x: tmpPt.x, y: tmpPt.y }, this.cTail, endPt, true);

                    this.pathData.tail.position = this.cTail;
                    this.pathData.tail.point = tmpPt;

                    let midPoint = this.cTail + (this.pathData.tail.offset / 2);
                    if (midPoint > this.actualLength)
                        midPoint = midPoint - this.actualLength;

                    this.pathData.tail.distance = -this.dist(this.calloutPath.getPointAtLength(midPoint), tmpPt);
                    this.props.updateObjectById({
                        container: "workspaceItems",
                        id: this.props.selectedItems.get(0),
                        toUpdate: { pathData: this.pathData }
                    });

                    this.pathArr = null;
                    this.callout = null;
                    this.calloutPath = null;
                    this.actualLength = null;
                    this.ptArray = null;
                    this.individualPtArray = null;
                    this.pt = null;
                    this.pt3 = null;
                    this.tailElement = null;
                    this.nMouseOffsetX = null;
                    this.nMouseOffsetY = null;
                }
                this.drag = null; this.handle = null; this.relativeXY = null;
                window.removeEventListener('mousemove', this.moveControlPoint, false);
                window.removeEventListener('mouseup', this.moveControlPoint, false);
            }
        } catch (error) { }
    }

    getFlipPosition(controlPoints, flipPosition) {
        if (flipPosition === 1) {
            for (let key of Reflect.ownKeys(controlPoints)) {
                controlPoints[key].x = 100 - controlPoints[key].x;
            }
        } else if (flipPosition === 2) {
            for (let key of Reflect.ownKeys(controlPoints)) {
                controlPoints[key].y = 100 - controlPoints[key].y;
            }
        }
        else if (flipPosition === 3) {
            for (let key of Reflect.ownKeys(controlPoints)) {
                controlPoints[key].x = 100 - controlPoints[key].x;
                controlPoints[key].y = 100 - controlPoints[key].y;
            }
        }
        return controlPoints;
    }

    getSelectedItemType() {
        let result = '';
        let itemType;

        if (this.props.selectedItems.size === 1) {
            itemType = this.props.selectedObjects.get(this.props.selectedItems.get(0));
            if (itemType !== undefined && (itemType.get("type") === "SHAPE" || itemType.get("type") === "PROP" || itemType.get("type") === "FRAME" || itemType.get("type") === "GRID")) {
                result = " select-prop";
            }
            else if (itemType !== undefined && (itemType.get("type") === "CHR"))
                result = " select-chr";
            else if (itemType !== undefined && (itemType.get("type") === "TEXT"))
                result = " select-txt";
            else if (itemType !== undefined && (itemType.get("type") === "SCR"))
                result = " select-scr";
            else if (itemType !== undefined && (itemType.get("type") === "STOCKIMG" || itemType.get("subType") === "UPIMAGE" || itemType.get("type") === "PIXABAY" || itemType.get("type") === "PEXELS"))
                result = " select-img";
            else if (itemType !== undefined && (itemType.get("type") === "GROUP")) {
                if (this.props.childrenSelection && this.props.childrenSelection.get("childIds").size > 0) {
                    result = " select-multi-select";
                } else {
                    result = " select-group";
                }
            }
            else if (itemType !== undefined && (itemType.get("type") === "IMG") && (itemType.get("GIPHY") === undefined))
                result = " select-gif"
            else
                result = ""
        } else if (this.props.selectedCamera) {
            result = " select-group"
        } else {
            result = " select-multi-select"
        }
        if (itemType !== undefined && (itemType.get("height") * this.props.zoomFactor < this.SHORT_HANDLE_THRESHOLD || itemType.get("width") * this.props.zoomFactor < this.SHORT_HANDLE_THRESHOLD)) {
            result += " is-short"
        }
        return result;
    }

    isPlayheadInSelectionTimeRange() {
        const { playHeadValue, selectedItems, selectedObjects } = this.props;
        return selectedItems.size && selectedItems.some((id) => {
            const item = selectedObjects.get(id);
            return (
                item
                && playHeadValue >= item.get("enterStart")
                && playHeadValue <= item.get("exitEnd")
            );
        });
    }

    assignSelectionBoxRef = (ref) => {
        this.selectionBoxElement = ref;
        if (this.props.transformManagerRef) {
            if (typeof this.props.transformManagerRef === "function") {
                this.props.transformManagerRef(ref);
            } else {
                this.props.transformManagerRef.current = ref;
            }
        }
    }

    // Function to update the subtitle position (x, y)
    updateSubtitlePosition = () => {
        const toUpdate = [];
        this.props.localSubtitle.entrySeq().forEach(([key, value]) => {
            if (this.props.selectedSubtitles.includes(key)) {
                toUpdate.push({
                    container: "subtitleData",
                    id: value.get("id"),
                    dropId: value.getIn(["subtitleId", "dropId"]),
                    langId: value.getIn(["subtitleId", "langId"]),
                    timelineId: value.getIn(["subtitleId", "timelineId"]),
                    toUpdate: { x: this.selectionBox.x, y: this.selectionBox.y }
                });
            } else {
                toUpdate.push({
                    container: "subtitleData",
                    id: value.get("id"),
                    dropId: value.getIn(["subtitleId", "dropId"]),
                    langId: value.getIn(["subtitleId", "langId"]),
                    timelineId: value.getIn(["subtitleId", "timelineId"]),
                    toUpdate: { y: this.selectionBox.y < 0 ? 0 : this.selectionBox.y } // To prevent Subtitle going out of the workspace.
                });
            }
        })
        this.props.updateSubtitleMove({ toUpdate });
    }

    render() {
        let selectionBounds = {};
        if (!this.transformingByKey)
            selectionBounds = this.getSelectionBox();
        else
            selectionBounds = this.selectionBox;

        let selection_styles = {
            transform: "translate(" + parseFloat(selectionBounds.x * this.props.zoomFactor).toFixed(2) + "px, " + parseFloat(selectionBounds.y * this.props.zoomFactor).toFixed(2) + "px) rotateZ(" + selectionBounds.angle + "deg)",
            width: selectionBounds.width * this.props.zoomFactor,
            height: selectionBounds.height * this.props.zoomFactor,
        };

        if (this.props.isMultimove) {
            selection_styles.transformOrigin = this.props.transformOrigin;
        }

        let id = null;
        // selection_styles.display = "none";
        let selectionBoxClass = "selectionBox pointer-events-none";
        let innerBoxClass = "inner-box pointer-events-none";
        let handlesClass = "handles";

        if (this.isTextOnly()) {
            selectionBoxClass += " text-only";
        }

        if (this.isPermenantGroup() || this.isTextOnly() || this.isCalloutOnly()) {
            selectionBoxClass += " text-content";
        }

        if (!this.isPlayheadInSelectionTimeRange() || this.props.transformStatus.get("transforming") || this.props.selectedSubtitles.size) {
            selectionBoxClass += " invisible";
        }

        if (this.props.lockTransform) {
            handlesClass += " hide pointer-events-none";
        }

        if (this.props.textStatus.get("isFocused")) {
            handlesClass += " pointer-events-auto";
        }

        if (this.selectionBox.angle >= 337.5 || this.selectionBox.angle <= 22.5)
            selectionBoxClass += " e"
        else if (this.selectionBox.angle >= 22.5 && this.selectionBox.angle <= 67.5)
            selectionBoxClass += " se"
        else if (this.selectionBox.angle >= 67.5 && this.selectionBox.angle <= 112.5)
            selectionBoxClass += " s"
        else if (this.selectionBox.angle >= 112.5 && this.selectionBox.angle <= 157.5)
            selectionBoxClass += " sw"
        else if (this.selectionBox.angle >= 157.5 && this.selectionBox.angle <= 202.5)
            selectionBoxClass += " w"
        else if (this.selectionBox.angle >= 202.5 && this.selectionBox.angle <= 247.5)
            selectionBoxClass += " nw"
        else if (this.selectionBox.angle >= 247.5 && this.selectionBox.angle <= 292.5)
            selectionBoxClass += " n"
        else if (this.selectionBox.angle >= 292.5 && this.selectionBox.angle <= 337.5)
            selectionBoxClass += " ne"

        // if (this.props.transformStatus.get("transforming") && (this.props.selectedCamera === "" || this.props.selectedCamera === undefined))
        //     selectionBoxClass += " invisible";

        let controlPoints = [];

        if (this.isShapeOnly() && !this.props.isMultimove) {
            if (this.selectedItems.getIn([this.props.selectedItems.get(0), "subType"]) === "CSTMPTH") {
                let pathDetails = getPath(this.selectedItems.get(this.props.selectedItems.get(0)), this.props.zoomFactor, this.props.selectedObjects.get(this.props.selectedItems.get(0)));
                let pathData = fromJS(pathDetails.pathData);

                if (pathDetails.newControlPoints !== undefined) {
                    let flilpPostion = this.selectedItems.getIn([this.props.selectedItems.get(0), "flipPosition"]);
                    let newControlPoints = this.getFlipPosition(pathDetails.newControlPoints, flilpPostion);
                    for (let p of Reflect.ownKeys(newControlPoints)) {
                        controlPoints.push(
                            <span key={p} className={"control-point " + p} style={{ left: "calc(" + newControlPoints[p].x + "% - 7.5px)", top: "calc(" + newControlPoints[p].y + "% - 7.5px)", display: "block" }} datahandle={p} onMouseDown={this.controlShape}><img datahandle={p} src={this.appUrl + "transform-icons/control-point.svg"} alt="alterShapeIcon" /></span>
                        );
                    }
                }
                else if (pathData) {
                    pathData.get("controlPoints").entrySeq().forEach(([key, pos]) => {
                        controlPoints.push(
                            <span key={key} className={"control-point " + key} style={{ left: "calc(" + pos.get("x") + "% - 7.5px)", top: "calc(" + pos.get("y") + "% - 7.5px)", display: "block" }} datahandle={key} onMouseDown={this.controlShape}><img datahandle={key} src={this.appUrl + "transform-icons/control-point.svg"} alt="alterShapeIcon" /></span>
                        );
                    });
                }
            }
            else if (this.selectedItems.getIn([this.props.selectedItems.get(0), "pathData", "controlPoints"])) {
                let pathData = this.selectedItems.getIn([this.props.selectedItems.get(0), "pathData"]);
                if (pathData) {
                    pathData.get("controlPoints").entrySeq().forEach(([key, pos]) => {
                        if (key === "top" || key === "right" || key === "bottom" || key === "left") {
                            let /* left = this.selectedItems.getIn([this.props.selectedItems.get(0), "width"]) * (pos.get("x") / 100), */ top = this.selectedItems.getIn([this.props.selectedItems.get(0), "height"]) * (pos.get("y") / 100);
                            controlPoints.push(
                                <span key={key} className={"control-point " + key} style={{ left: "calc(" + pos.get("x") + "% - 9px)", top: (top - 9) + "px", display: "block" }} datahandle={key} onMouseDown={this.moveControlPoint}><img datahandle={key} src={this.appUrl + "transform-icons/control-point.svg"} alt="alterShapeIcon" /></span>
                            );
                        } else {
                            let left = pos, top = pos;

                            let style = {};
                            if (key === "topLeft")
                                style = { left: (left - 9) + "px", top: (top - 9) + "px" };
                            else if (key === "topRight")
                                style = { right: (left - 9) + "px", top: (top - 9) + "px" };
                            else if (key === "bottomRight")
                                style = { right: (left - 9) + "px", bottom: (top - 9) + "px" };
                            else if (key === "bottomLeft")
                                style = { left: (left - 9) + "px", bottom: (top - 9) + "px" };

                            controlPoints.push(
                                <span key={key} className={"control-point " + key} style={style} datahandle={key} onMouseDown={this.moveControlPoint}><img datahandle={key} src={this.appUrl + "transform-icons/control-point.svg"} alt="alterShapeIcon" /></span>
                            );
                        }
                    });
                }
            } else if (this.selectedItems.getIn([this.props.selectedItems.get(0), "subType"]) === "CALLOUT") {
                let calloutTail = this.selectedItems.getIn([this.props.selectedItems.get(0), "pathData", "tail"]).toJS();
                // var callout = document.getElementById(this.props.selectedItems.get(0)).getElementsByClassName("center")[0].getElementsByClassName("callout")[0];
                let calloutPath = document.getElementById(this.props.selectedItems.get(0)).getElementsByClassName("center")[0].getElementsByClassName("calloutPath")[0];
                let actualLength = calloutPath.getTotalLength();

                let cTail = calloutTail.position;
                let callOffset = calloutTail.offset;
                // var endPt = cTail + callOffset;

                // var pt = calloutPath.getPointAtLength(cTail);
                // var pt2 = calloutPath.getPointAtLength(endPt);

                // var a_angle = (Math.atan2(pt.y - pt2.y, pt.x - pt2.x)) * (180 / Math.PI);
                // a_angle = a_angle + 90;
                // a_angle = a_angle * (Math.PI / 180);

                let midPoint = cTail + (callOffset / 2);
                if (midPoint > actualLength)
                    midPoint = midPoint - actualLength;
                // var pt3 = calloutPath.getPointAtLength(midPoint);
                let scaleX = this.selectedItems.getIn([this.props.selectedItems.get(0), "groupBounds", "center", "scaleX"]);
                let scaleY = this.selectedItems.getIn([this.props.selectedItems.get(0), "groupBounds", "center", "scaleY"]);
                let dx2 = calloutTail.point.x;
                let dy2 = calloutTail.point.y;

                let style = { top: ((dy2 * scaleY * this.props.zoomFactor) - 10) + "px", left: ((dx2 * scaleX * this.props.zoomFactor) - (10)) + "px" };
                controlPoints.push(
                    <span key="clt" className={"control-point callout-tail"} style={style} datahandle="callout-tail" onMouseDown={this.moveControlPoint}><img datahandle="callout-tail" src={this.appUrl + "transform-icons/control-point.svg"} alt="alterShapeIcon" /></span>
                );
            }
        }

        let selectedItemType = this.getSelectedItemType();
        selectionBoxClass += selectedItemType;

        const MOVE_VISIBILITY_THRESHOLD = 70; // in px
        let showMoveIcon = (
            selectionBounds.width * this.props.zoomFactor < MOVE_VISIBILITY_THRESHOLD
            || selectionBounds.height * this.props.zoomFactor < MOVE_VISIBILITY_THRESHOLD
        );

        let transformManager;
        transformManager = (
            <SelectionBox data-html2canvas-ignore="true" key={this.props.keyframe} className={selectionBoxClass} ref={this.assignSelectionBoxRef} data-angle={selectionBounds.angle} data-id={id} style={selection_styles} >
                <div ref={this.innerBoxRef} className={innerBoxClass} data-id={id} onPointerDown={this.initiateMove} />
                <div className={handlesClass}>
                    {controlPoints}
                    {(this.isTextOnly() && this.props.textStatus.get("isFocused")) ? <span className="text-inner-box" data-id={id} onMouseDown={this.initiateMove} style={{ top: "-15px", left: "-15px", right: "-15px", height: "15px" }} /> : null}
                    {(this.isTextOnly() && this.props.textStatus.get("isFocused")) ? <span className="text-inner-box" data-id={id} onMouseDown={this.initiateMove} style={{ top: "-15px", right: "-15px", bottom: "-15px", width: "15px" }} /> : null}
                    {(this.isTextOnly() && this.props.textStatus.get("isFocused")) ? <span className="text-inner-box" data-id={id} onMouseDown={this.initiateMove} style={{ left: "-15px", right: "-15px", bottom: "-15px", height: "15px" }} /> : null}
                    {(this.isTextOnly() && this.props.textStatus.get("isFocused")) ? <span className="text-inner-box" data-id={id} onMouseDown={this.initiateMove} style={{ left: "-15px", top: "-15px", bottom: "-15px", width: "15px" }} /> : null}

                    <span className={"resize-handle top-left" + (((this.props.transformStatus.get("resizing") || this.props.transformStatus.get("rotating")) && this.selectionBox.handle === "top-left") || this.props.isToolCropping ? " active" : "")} data-handle="top-left" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />
                    <span className={"rotate-handle top-left" + (this.props.selectedItems.size === 0 ? " disabled" : "")} data-handle="top-left" onMouseDown={this.initiateRotate} />

                    <span className={"resize-handle top" + (this.isVerticalScale() ? "" : " disabled") + ((this.props.transformStatus.get("resizing") || this.props.transformStatus.get("rotating")) && this.selectionBox.handle === "top" ? " active" : "") + ((selectionBounds.width * this.props.zoomFactor) > 60 ? "" : " hide-handle")} data-handle="top" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />

                    <span className={"resize-handle top-right" + (((this.props.transformStatus.get("resizing") || this.props.transformStatus.get("rotating")) && this.selectionBox.handle === "top-right") || this.props.isToolCropping ? " active" : "")} data-handle="top-right" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />
                    <span className={"rotate-handle top-right" + (this.props.selectedItems.size === 0 ? " disabled" : "")} data-handle="top-right" onMouseDown={this.initiateRotate} />

                    <span className={"resize-handle right" + (this.isHorizontalScale() ? "" : " disabled") + (((this.props.transformStatus.get("resizing") || this.props.transformStatus.get("rotating")) && this.selectionBox.handle === "right") || this.props.isToolCropping ? " active" : "") + ((selectionBounds.height * this.props.zoomFactor) > 60 ? "" : " hide-handle")} data-handle="right" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />

                    <span className={"resize-handle bottom-left" + (((this.props.transformStatus.get("resizing") || this.props.transformStatus.get("rotating")) && this.selectionBox.handle === "bottom-left") || this.props.isToolCropping ? " active" : "")} data-handle="bottom-left" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />
                    <span className={"rotate-handle bottom-left" + (this.props.selectedItems.size === 0 ? " disabled" : "")} data-handle="bottom-left" onMouseDown={this.initiateRotate} />

                    <span className={"resize-handle bottom" + (this.isVerticalScale() ? "" : " disabled") + ((this.props.transformStatus.get("resizing")) && this.selectionBox.handle === "bottom" ? " active" : "") + ((selectionBounds.width * this.props.zoomFactor) > 60 ? "" : " hide-handle")} data-handle="bottom" onMouseDown={this.initiateResize} onTouchStart={this.initiateResize} />

                    <span className={"resize-handle bottom-right" + (((this.props.transformStatus.get("resizing") || this.props.transformStatus.get("rotating")) && this.selectionBox.handle === "bottom-right") || this.props.isToolCropping ? " active" : "")} data-handle="bottom-right" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />
                    <span className={"rotate-handle bottom-right" + (this.props.selectedItems.size === 0 ? " disabled" : "")} data-handle="bottom-right" onMouseDown={this.initiateRotate} />

                    <div className="transform-bottom-holder">
                        {showMoveIcon && (
                            <img alt="move icon" className={"transform-bottom-handle move-handle"} src={this.appUrl + "transform-icons/move.svg"} data-handle="move" onMouseDown={this.initiateMove} />
                        )}
                        {(this.props.selectedItems.size > 0) ? <span className={"transform-bottom-handle rotate-handle bottom" + ((this.props.transformStatus.get("resizing") || this.props.transformStatus.get("rotating")) && this.selectionBox.handle === "bottom" ? " active" : "")} data-handle="bottom" onMouseDown={this.initiateRotate} /> : null}
                    </div>

                    <span className={"resize-handle left" + (this.isHorizontalScale() ? "" : " disabled") + ((this.props.transformStatus.get("resizing") || this.props.transformStatus.get("rotating")) && this.selectionBox.handle === "left" ? " active" : "") + ((selectionBounds.height * this.props.zoomFactor) > 60 ? "" : " hide-handle")} data-handle="left" onTouchStart={this.initiateResize} onMouseDown={this.initiateResize} />
                </div>
            </SelectionBox>
        );

        return transformManager;
    }
}

const mapStateToProps = () => {
    const memoizedSelectionFunction = createMemoizedFunction();
    // const memoizedWSChildrenFunction = createMemoizedFunction(); // uncomment this if workspaceChildren is implemented in vmaker

    return (state, ownProps) => {
        const selectedItems = state.app.get("selectedItems");
        const workspaceItems = state.projectDetails.get("workspaceItems");
        const selectedSubtitles = state.app.get("selectedSubtitles");
        const localSubtitle = state.projectDetails.get("localSubtitle");
        let workspaceChildren = state.projectDetails.get("workspaceChildren");

        const { selectedObjects, unSelectedObjects } = memoizedSelectionFunction(() => {
            let selectedObjects = OrderedMap();
            let unSelectedObjects = fromJS({});

            if (selectedItems.size > 0) {
                for (let item of workspaceItems.valueSeq()) {
                    if (item.get("isSingleClipFrame")) {
                        item = singleClipFrameToFrame(item);
                    }
                    if (selectedItems.includes(item.get("id"))) {
                        selectedObjects = selectedObjects.set(item.get("id"), item);
                    } else {
                        unSelectedObjects = unSelectedObjects.set(item.get("id"), item);
                    }
                }
            }

            if (selectedSubtitles.size > 0) {
                for (let selectedId of selectedSubtitles.valueSeq()) {
                    if (localSubtitle.get(selectedId)) {
                        selectedObjects = selectedObjects.set(selectedId, localSubtitle.get(selectedId));
                    }
                }
            }

            return { selectedObjects, unSelectedObjects };
        }, [selectedItems, workspaceItems, selectedSubtitles, localSubtitle]);

        // uncomment this if workspaceChildren is implemented in vmaker
        // workspaceChildren = memoizedWSChildrenFunction(() => {
        //     return workspaceChildren.map((item) => {
        //         if (item.get("isSingleClipFrame")) {
        //             item = singleClipFrameToFrame(item);
        //         }
        //         return item;
        //     });
        // }, [workspaceChildren]);

        return {
            imperativeRotate: state.app.get("imperativeRotate"),
            selectedItems: state.app.get("selectedItems").size > 0 ? state.app.get("selectedItems") : state.app.get("selectedSubtitles"),
            selectedObjects,
            unSelectedObjects,
            localSubtitle: state.projectDetails.get("localSubtitle"),
            workspaceWidth: state.projectDetails.get("width"),
            workspaceHeight: state.projectDetails.get("height"),
            selectedChildren: workspaceChildren,
            zoomFactor: state.app.get("zoomFactor"),
            playHeadValue: state.app.get("playhead"),
            textStatus: state.app.get("textStatus"),
            isCropping: state.app.get("isCropping"),
            isFraming: state.app.get("isFraming"),
            workspaceGrid: state.app.get("workspaceGrid"),
            transformStatus: state.app.get("transformStatus"),
            isWorkspaceTextFocus: state.app.get("isWorkspaceTextFocus"),
            animakerType: "full",
            isToolCropping: false,
            textOffset: selectedObjects.some(
                (item) => item.get("type") === "TEXT" && item.getIn(["textData", "formats", "containerStyle", "margin"]) !== "0px" && item.getIn(["textData", "formats", "containerStyle", "margin"]) !== undefined
            ) ? 30 : state.app.get("textOffset"),
            childrenSelection: state.app.get("childrenSelection"),
            isLibraryItemDragging: Boolean(state.app.getIn(["libraryDragItem", "data"])),
            swapHoverDrop: state.app.get("swapHoverDrop"),
            selectedFrameClip: state.app.get("selectedFrameClip"),
            selectedSubtitles: state.app.get("selectedSubtitles"),
        };
    };
};

const mapDispatchToProps = (dispatch) => ({
    moveUpdate: (data) => dispatch(moveUpdate(data)),
    resizeUpdate: (data) => dispatch(resizeUpdate(data)),
    rotationUpdate: (data) => dispatch(rotationUpdate(data)),
    updateTransformStatus: (data) => dispatch(updateTransformStatus(data)),
    updateTextStatus: (data) => dispatch(updateTextStatus(data)),
    toggleCrop: () => dispatch(toggleCrop()),
    updateObjectById: (data) => dispatch(updateObjectById(data)),
    updateItem: (data) => dispatch(updateItem(data)),
    updateSubtitleMove: (payload) => dispatch(moveSubtitle(payload)),
})

const TransformManager = connect(
    mapStateToProps,
    mapDispatchToProps
)(TransformManagerComponent);

export default TransformManager;