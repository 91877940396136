/* eslint-disable no-restricted-syntax, object-shorthand, no-console */
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { IS_LOCAL, SW_URL, SW_SCOPE, SW_VERSION } from "../constants/config";
import { setSWStatus } from "../redux/actions/appUtils";

const localConsoleLog = IS_LOCAL ? console.log : () => { };

const registerValidSW = async (swUrl, regParams = {}) => {
    const {
        swScope,
        swVersion,
        onWorkerUpdate,
        onWorkerInstall,
        onWorkerActivation,
        onWorkerReady,
        onWorkerFail,
        onWorkerVersionChange,
    } = regParams;

    const prevWorkerExists = Boolean(navigator.serviceWorker.controller);
    let isCheckDone = false;
    /** @type {ServiceWorkerState | undefined} */
    let state; // eslint-disable-line no-unused-vars
    let timer;

    if (!navigator.serviceWorker.controller) {
        try {
            const registrations = await navigator.serviceWorker.getRegistrations();
            const reg = registrations.find((reg) => {
                return Boolean(reg.active);
            });
            if (reg) {
                // cmd + shift + r will load page without service worker
                // user intentionally tried to load page with hard refresh
                isCheckDone = true;
                onWorkerFail("possible: hard refresh");
                return;
            }

            // to handle a case where user manually unregisters service and hits refresh without reopening the page
            timer = setTimeout(() => {
                // cmd + shift + r will load page without service worker
                if (!isCheckDone /* && state === undefined */) {
                    isCheckDone = true;
                    onWorkerFail("time limit reached");
                }
            }, 7000);
        } catch (error) {
            isCheckDone = true;
            onWorkerFail(error);
            clearTimeout(timer);
            return;
        }
    }

    navigator.serviceWorker
        .register(swUrl, { scope: swScope })
        .then((registration) => {
            const checkVersion = (event) => {
                if (event.data && event.data.type === "GET_SERVICE_VERSION") {
                    if (event.data.version === swVersion) {
                        if (!isCheckDone) {
                            onWorkerReady(registration);
                        }
                        isCheckDone = true;
                        clearTimeout(timer);
                    } else if (isCheckDone) {
                        // different tab of this app has installed new service worker which might serve different icons
                        // show toast or modal to let user know they need to refresh if needed
                        localConsoleLog("ServiceWorkerRegister getVersion", event.data.version);
                        onWorkerVersionChange(event.data.version);
                    }
                }
            };

            const onControllerChange = () => {
                if (navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage({ type: "GET_SERVICE_VERSION" });
                } else {
                    // service might have uninstalled
                }
            };

            navigator.serviceWorker.addEventListener("message", checkVersion);
            navigator.serviceWorker.addEventListener("controllerchange", onControllerChange);

            const requestVersion = () => {
                if (!navigator.serviceWorker.controller) {
                    return;
                }
                navigator.serviceWorker.controller.postMessage({ type: "GET_SERVICE_VERSION" });
            };

            if (prevWorkerExists) {
                requestVersion();
            }

            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (!installingWorker) {
                    return;
                }

                installingWorker.onstatechange = () => {
                    state = installingWorker.state;
                    if (installingWorker.state === "installed") {
                        if (prevWorkerExists) {
                            // service worker updated for existing page
                            onWorkerUpdate(registration);
                        } else {
                            // new service worker installed
                            onWorkerInstall(registration);
                        }
                    } else if (installingWorker.state === "activated") {
                        // service worker activated
                        onWorkerActivation(registration);
                        if (!prevWorkerExists && !isCheckDone) {
                            onWorkerReady(registration);
                            isCheckDone = true;
                            clearTimeout(timer);
                        }

                        requestVersion();
                    } else if (installingWorker.state === "redundant") {
                        if (!isCheckDone) {
                            isCheckDone = true;
                            clearTimeout(timer);
                            onWorkerFail("possible: activation failed");
                        }
                    }
                };
            };
        })
        .catch((error) => {
            // worker registration failed
            if (!isCheckDone) {
                isCheckDone = true;
                onWorkerFail(error);
            }
            clearTimeout(timer);
        });
};

const checkValidServiceWorker = (swUrl, regParams = {}) => {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, { headers: { "Service-Worker": "script" } }).then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get("content-type");
        if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
            // No service worker found.
            navigator.serviceWorker.ready.then((registration) => {
                registration.unregister();
            });
            regParams.onWorkerFail("worker file not found");
        } else {
            // Service worker found. Proceed as normal.
            registerValidSW(swUrl, regParams);
        }
    }).catch((error) => {
        regParams.onWorkerFail(error);
    });
};

const register = (regParams = {}) => {
    if (!(process.env.NODE_ENV === "production" && "serviceWorker" in navigator)) {
        regParams.onWorkerReady("registration skipped");
    } else {
        const swUrl = new URL(regParams.swUrl, window.location.href);
        if (swUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            regParams.onWorkerFail();
            return;
        }
        const isLocalhost = Boolean(
            window.location.hostname === "localhost"
            // [::1] is the IPv6 localhost address.
            || window.location.hostname === "[::1]"
            // 127.0.0.0/8 are considered localhost for IPv4.
            || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
        );
        if (isLocalhost) {
            // This is running on localhost. Let's check if a service worker still exists or not.
            checkValidServiceWorker(regParams.swUrl, regParams);
        } else {
            // Is not localhost. Just register service worker
            registerValidSW(regParams.swUrl, regParams);
        }
    }
};

const unregister = async (currentScope) => {
    try {
        if ("serviceWorker" in navigator) {
            const scopes = [];
            const registrations = await navigator.serviceWorker.getRegistrations();
            const unregisterPromises = [];
            for (const r of registrations) {
                if (r.scope !== currentScope) {
                    scopes.push(r.scope);
                    unregisterPromises.push(r.unregister());
                }
            }
            if (unregisterPromises.length) {
                await Promise.all(unregisterPromises);
                localConsoleLog("unregister@ServiceWorkerRegister: unregistered scopes: ", scopes);
            } else {
                localConsoleLog("unregister@ServiceWorkerRegister: no workers to unregister: ", scopes);
            }
        }
    } catch (error) {
        localConsoleLog("unregister@ServiceWorkerRegister failed: ", error);
    }
};

const ServiceWorkerRegister = () => {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        const init = async () => {
            const swScope = (new URL(SW_SCOPE, window.location.href)).toString();
            await unregister(swScope);
            register({
                swUrl: SW_URL,
                swScope: swScope,
                swVersion: SW_VERSION,
                onWorkerUpdate: (registration) => {
                    localConsoleLog("ServiceWorkerRegister updated: ", registration);
                },
                onWorkerInstall: (registration) => {
                    localConsoleLog("ServiceWorkerRegister installed: ", registration);
                },
                onWorkerActivation: (registration) => {
                    localConsoleLog("ServiceWorkerRegister activated: ", registration);
                },
                onWorkerReady: (registration) => {
                    localConsoleLog("ServiceWorkerRegister ready: ", registration);
                    dispatch(setSWStatus({ status: "fulfilled" }));
                },
                onWorkerFail: (reason) => {
                    localConsoleLog("ServiceWorkerRegister failed: ", reason);
                    dispatch(setSWStatus({ status: "rejected" }));
                },
                onWorkerVersionChange: (version) => {
                    localConsoleLog("ServiceWorkerRegister version changed: ", version);
                    // if (verChangeCB) {
                    //     verChangeCB(version);
                    // }
                },
            });
        }
        init();
    }, [dispatch]);

    return null;
};

export default ServiceWorkerRegister;
