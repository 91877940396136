import styled, { css } from "styled-components";
import { font } from "../../../constants/font";
import { NumberInputStyled } from "../../../common-components/NumberInputComponent";

export const ScrollbarCSS = css`
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--common-scroll-thumb-color, #e4e8ee);
        border-radius: 4px;
    }
    &::-webkit-scrollbar {
        width: 6px;
    }

    // mozilla scrollbar
    scrollbar-width: thin;
    scrollbar-color: var(--common-scroll-thumb-color, #e4e8ee) transparent;
`;

export const PropertyWindowContainer = styled.div.attrs((props) => {
    const style = {
        zIndex: 0,
    };

    if (Number.isFinite(props.$windowLevel)) {
        style.zIndex = props.$windowLevel;
    }

    return {
        style,
    };
})`
    background-color: ${props => props.theme.polarColor};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @media(max-width: 768px){
        border-radius: 20px 20px 0px 0px;
    }

    * {
        margin: 0;
        padding: 0;
    }
`;
PropertyWindowContainer.displayName = "PropertyWindowContainer";

export const SettingsWindowStyled = styled(PropertyWindowContainer)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 53px 1fr;

    > .sw--header {
        display: flex;
        align-items: center;
        font: ${font.boldMini_15};
        color: ${(props) => props.theme.panelPrimaryColor};
        padding: 0 16px;
    }

    > .sw--body {
        padding: 8px 8px 8px 16px;
        margin-right: 2px;
        overflow-x: hidden;
        overflow-y: scroll;

        display: flex;
        flex-direction: column;

        ${ScrollbarCSS}

        > .sw--section {
            display: flex;
            flex-direction: column;

            > .sw-section--heading {
                font: ${font.boldMini_15};
                color: ${(props) => props.theme.panelPrimaryColor};

                &:not(:only-child) {
                    margin-bottom: 20px;
                }
            }

            &:not(:first-of-type) {
                margin-top: 24px;
            }
        }
        @media (max-width: 768px) {
        * {
            touch-action: auto;
        }
        touch-action: auto;
        }
    }
`;

export const SettingButtonCSS = css`
    background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.5px 1px ${(props) => props.theme.rgbaBlack};
    border: 1px solid ${(props) => props.theme.primaryBorderColor};
    border-radius: 8px;
`;

export const ImageFilterContainer = styled(PropertyWindowContainer)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 53px 44px 64px 1fr;

    > .imgf--header {
        display: flex;
        align-items: center;
        gap: 4px;
        font: ${font.boldMini_15};
        color: ${(props) => props.theme.panelPrimaryColor};
        padding: 0 16px;
        cursor: pointer;

        img {
            width: 16px;
            height: 16px;
            transform: rotate(90deg);
        }
    }

    > .imgf--tabs {
        position: relative;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 44px;
        border-bottom: 1px solid ${(props) => props.theme.primaryBorderColor};

        > .imgf--tab-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            cursor: pointer;

            font: ${font.normalBase};
            color: ${(props) => props.theme.secondaryColor};

            img {
                width: 16px;
                @media screen and (max-width: 767px){
                    margin-right: 10px;
                }
            }

            &.tab-btn-active {
                color: ${(props) => props.theme.propertyColor};
            }
        }

        ::after {
            content: " ";
            position: absolute;
            width: 50%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: ${(props) => props.theme.secondaryBorderColor};

            transition-property: width, left;
            transition-duration: 0.5s;
            transition-timing-function: ease;
        }

        &.filter-adjust::after {
            left: 50%;
        }
    }

    .imgf--body {
        padding: 13px 16px;
        overflow-x: hidden;
        overflow-y: scroll;

        display: flex;
        flex-direction: column;

        ${ScrollbarCSS}
        @media (max-width: 768px) {
            touch-action: auto;
        }
    }
`;
ImageFilterContainer.displayName = "ImageFilterContainer";

export const ApplyAll = styled.div`
    height: 40px;
    margin: ${(props) => props.margin || "12px 0px 12px auto"};
    display: flex;
    align-items: center;
    justify-content: center;
    font: ${font.normalBase};
    cursor: pointer;
    box-shadow: 0px 0.5px 1px ${props => props.theme.rgbaBlack};
    border: 1px solid ${props => props.theme.iconsBorder};
    border-radius: 8px;
    ${SettingButtonCSS}
    transition: ease 0.4s;
    &:hover {
        transform: scale(1.02);
        border-color: ${(props) => props.theme.secondaryBorderColor};
    }
    max-width: ${(props) => props.maxWidth || "95%"};
    width: ${(props) => props.Width || "unset"};
`;
ApplyAll.displayName = "ApplyAll";

export const FilterList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 12px;
    column-gap: 8px;

    > .imgf-l--filter-btn {
        display: flex;
        flex-direction: column;
        gap: 6px;
        font: ${font.normalMini_12};
        text-align: center;
        color: ${(props) => props.theme.propertyColor};
        cursor: pointer;

        img {
            width: 100%;
            border-radius: 4px;
            @media screen and (max-width: 767px) {
                margin-bottom: 5px;
            }
        }

        &.filter-btn-active {
            img {
                outline: 1.5px solid ${(props) => props.theme.filterBtnOutline};
            }
        }
    }

    > .imgf-l--filter-slider {
        height: 18px;
        grid-column: 1 / span 3;

        display: flex;
        align-items: center;
        justify-content: center;

        > div, > div input {
            width: 100%;
        }
    }
`;
FilterList.displayName = "FilterList";

export const AdjustSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font: ${font.mediumMini};
    color: ${(props) => props.theme.propertyColor};

    > div, > div input {
        width: 100%;
    }

    > div {
        height: 16px;
    }
`;
AdjustSliderContainer.displayName = "AdjustSliderContainer";

export const AdjustmentList = styled.div`
    display: flex;
    flex-direction: column;

    .imgf-a--reset {
        font: ${font.normalBase};
        color: ${(props) => props.theme.panelPrimaryColor};
        text-align: right;
        margin: 0px 0px 12px auto;
        cursor: pointer;
    }

    > *:first-child {
        margin-top: 8px;
    }

    ${AdjustSliderContainer}:not(:first-of-type) {
        margin-top: 20px;
    }
`;
AdjustmentList.displayName = "AdjustmentList";

export const AdjustButtonStyled = styled.div`
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font: ${font.normalBase};
    cursor: pointer;

    ${SettingButtonCSS}

    img {
        width: 16px;
        height: 13px;
    }
`;
AdjustButtonStyled.displayName = "AdjustButtonStyled";

export const ApplyAllContainer = styled.div`
    padding: 41px 24px 24px 24px;

    > .apply-all--heading {
        font: ${font.mediumLarge_27};
        color: ${(props) => props.theme.panelPrimaryColor};
        margin: 0;
    }

    > .apply-all--btn-grp {
        margin-top: 40px;
        display: flex;

        > .apply-all--btn {
            padding: 0px 24px;
            height: 36px;
            border-radius: 4px;
            font: ${font.boldBase};
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;
            @media(max-width: 768px){
                width: 50%;
            }
        }

        > .apply-all--cancel {
            margin-left: auto;
            background: ${(props) => props.theme.btnBgLightColor} 0% 0% no-repeat padding-box;
            color: ${(props) => props.theme.tertiaryColor};
            @media(max-width: 768px){
                margin-left: 0;
            }
            transition: ease 0.5s;
            &:hover {
                    transform: scale(1.05);
            }
        }

        > .apply-all--apply {
            margin-left: 16px;
            background: transparent ${(props) => props.theme.headerColor} 0% 0% no-repeat padding-box;
            color: ${(props) => props.theme.polarColor};
            transition: ease 0.5s;
            &:hover {
                    transform: scale(1.05);
            }
        }
    }
`;
ApplyAllContainer.displayName = "ApplyAllContainer";

export const AudioVolumeContainer = styled.div`
    display: grid;
    grid-template-rows: 28px 16px 27px;
    grid-template-columns: 1fr 1fr;
    row-gap: 12px;
    column-gap: 0px;
    align-items: center;

    .audiovc--heading {
        margin: 0;
        padding: 0;
        color: ${(props) => props.theme.propertyColor};
        font: ${font.mediumMini};
    }

    ${NumberInputStyled} {
        width: 52px;
        margin-left: auto;
        height: 100%;
        background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
        border: 1px solid ${(props) => props.theme.primaryBorderColor};
        border-radius: 4px;
        cursor: text;

        form {
            border-radius: 4px;
            overflow: hidden;
        }

        input {
            font: ${font.normalBase};
            color: ${(props) => props.theme.panelPrimaryColor};
            text-align: center;
        }
    }

    .audiovc--slider {
        grid-column: auto / span 2;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;

        > div, > div input {
            width: 100%;
        }
    }

    .audiovc--mute {
        margin-top: 9px;
        width: 100%;
        height: 18px;

        > div {
            font: ${font.normalMini};
            cursor: pointer;
            width: min-content;

            > div {
                gap: 8px;
            }

            * {
                pointer-events: none;
                margin: 0;
            }
        }
    }

    .audiovc--detach {
        margin: 8px 0 0 auto;
        font: ${font.normalMini};
        color: ${(props) => props.theme.planTextColor};
        cursor: pointer;
    }
`;
AudioVolumeContainer.displayName = "AudioVolumeContainer";

export const SpeedVideoContainer = styled.div`
 display: flex;
 justify-content: space-between;
 padding-top: 16px;
 .video-speed {
    box-shadow: 0px 0.5px 1px #00000029;
    transition: all .3s;
    &:hover {
        border: 1px solid  ${(props) => props.theme.secondaryBorderColor};
        transform: scale(1.04);
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 16px 0px;
  }
`;