import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Publish from "./publish";
import Footer from "../animo-player/footer";
import {
  Flex,
  LeftSection,
  WorkspaceWrapper,
  RightSection,
  HeadingSec,
  Icon,
  Left,
  PlayerSection,
  MobileSection,
  ActionButton,
  RightFlex,
  Hellobar
} from "./publish-component";
import Workspace from "../containers/workspace/workspace";
import { Loader } from "../animo-player/animo-component";
import Spinner, { SPINNER_TYPE } from "../common-components/Spinner";
import PlayerControlPanel from "../animo-player/player-controls";
import ShortsPlayerControlPanel from "../containers/shorts/shorts-player-control";
import Typography from "../common-components/Typography";
import { font } from "../constants/font";
import { ENVIRONMENT, STATIC_PATH } from "../constants/config";
import { validateKeydown } from "../helper/keymap";
import ActionTypes from "../constants/action-types";
import { useCustomCallback } from "../helper/hooks/useCustomCallback";
import content from "../constants/content";
import useWatermark from "../helper/hooks/useWatermark";
import useUpgrade from "../helper/hooks/useUpgrade";
import { secondsToMinutesText } from "../containers/timeline/timeline-helper";

const PublishContainer = ({ isExport, isPublish, shortId }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const appReducer = useSelector((state) => state.app);
  const isWorkspaceTextFocus = appReducer.get("isWorkspaceTextFocus");
  const textStatus = appReducer.get("textStatus");
  const initStages = appReducer.get("initStages");
  const animoPlayerId = appReducer.get("animoPlayerId");
  const projectDetails = useSelector((state) => state.projectDetails);
  const isShorts = !!shortId;
  const userDetails = useSelector((state) => state.userDetails);
  const { projectId } = userDetails;
  const { isLiteProceed = 0, requestDuration = 0, scriptDuration = 0 } = userDetails;
  const isWaterMark = useWatermark();
  const { showUpgrade } = useUpgrade();
  let shortData;
  if (isShorts) {
    shortData = projectDetails.getIn(["shorts", shortId]);
  }

  const [playerShortcut, setPlayerShortcut] = useState("");
  const [isMobileShow, setIsMobileShow] = useState(false);
  const [hideCloseIcon, setHideCloseIcon] = useState(false);

  const handleKeyDown = (e) => {
    setPlayerShortcut((prevState) => {
      const optionArr = validateKeydown(e, isWorkspaceTextFocus, textStatus);
      let tempState = prevState;
      optionArr.forEach(({ component, option }) => {
        if ((component === "playerControl") && ((!isWorkspaceTextFocus && !textStatus.get("isFocused")))) {
          // Prevent the default behaviour on arrow click.
          if (["ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
          }
          let keyoption = option;
          if (keyoption !== "") {
            if (prevState === keyoption) {
              keyoption += "_AGAIN";
            }
            tempState = keyoption;
          }
        }
      });
      return tempState;
    });
  };

  const handleKeyUp = () => {
    if (playerShortcut) {
      setPlayerShortcut("");
    }
  };

  const handleResize = () => {
    dispatch({
      type: ActionTypes.SET_RESIZE,
    });
  };

  const onKeyDown = useCustomCallback({ memoizedCallback: handleKeyDown });
  const onKeyUp = useCustomCallback({ memoizedCallback: handleKeyUp });
  const onResize = useCustomCallback({ memoizedCallback: handleResize });

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.addEventListener("keyup", onKeyUp);
      window.addEventListener("resize", onResize);
    };
  }, [onKeyDown, onKeyUp, onResize]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const exportType = params.get("export_type");
    setHideCloseIcon(!!exportType);
    if (!isMobileShow) {
      // To toggle up the export popup for mobile on page refresh.
      setIsMobileShow(!!exportType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const upgrade = useCallback((e, isWaterMark) => {
    e?.preventDefault();
    showUpgrade({ show: true, upgradesource: "Direct-download", headerText: isWaterMark ? "Upgrade to Remove Watermark" : "Upgrade for AI Avatar Videos" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAction = () => {
    history.push(`${ENVIRONMENT === "LOCAL" ? "" : "/editproject"}/${projectId}`);
  };

  const toggleMobilePopup = () => {
    setIsMobileShow(!isMobileShow);
  }

  let workspaceComp = null;
  if (initStages.get("projectLoaded")) {
    workspaceComp = <Workspace />;
  }

  return (
    <Flex>
      <LeftSection>
        <HeadingSec>
          <Left>
            <Typography
              content={content.VIDEO_IS_READY_FOR_EXPORT}
              color="#32304A"
              font={font.boldSmall}
              display="block"
            />
          </Left>
          <RightFlex>
            {isWaterMark ? <ActionButton
              text={content.REMOVE_WATERMARK}
              className="watermark-btn"
              icon={`${STATIC_PATH}upgrade-stars.svg`}
              onClick={(e) => upgrade(e, isWaterMark)}
            /> : null}
            {!isShorts &&
              <ActionButton
                text={content.EDIT_VIDEO}
                icon={`${STATIC_PATH}edit.svg`}
                className="editpreview-btn"
                onClick={onAction}
              />}
          </RightFlex>
        </HeadingSec>
        <PlayerSection>
          <WorkspaceWrapper id={animoPlayerId}>
            {workspaceComp || (
              <Loader>
                <Spinner
                  type={SPINNER_TYPE.RING_SPINNER}
                  spinnerHeight={"130px"}
                />
              </Loader>
            )}
            {isShorts && shortData ? (
              <ShortsPlayerControlPanel
                shortcutName={playerShortcut}
                startTime={shortData.get("start")}
                endTime={shortData.get("end")}
              />
            ) : (
              <PlayerControlPanel shortcutName={playerShortcut} />
            )}
          </WorkspaceWrapper>
          {/* is watermark condition to avoid non avatar project */}
          {+isLiteProceed && isWaterMark ?
            <Hellobar>
              <p>
                <Icon src={`${STATIC_PATH}info-outline.svg`} height="20px" width="20px" className="info-icon" />
                {`You've requested a ${secondsToMinutesText(+requestDuration, false, true)} video. We've generated a ${secondsToMinutesText(scriptDuration, false, true)} sample with your available credits. `}
                <a onClick={(e) => upgrade(e, false)} href="">Upgrade for full video!</a>
              </p>
            </Hellobar> :
            <>
              <Icon src={`${STATIC_PATH}info-outline.svg`} />
              <Typography
                content={content.PREVIEW_IN_SD_QUALITY}
                color="#787B80"
                font={font.normalMicroLarge_18}
              />

            </>
          }
          <MobileSection>
            {!isShorts &&
              <ActionButton
                text={content.EDIT_VIDEO}
                icon={`${STATIC_PATH}edit.svg`}
                className="mobEditpreview-btn"
                onClick={onAction}
              />}
            <ActionButton
              text={content.DOWNLOAD}
              icon={`${STATIC_PATH}download-icon2.svg`}
              className="download-btn"
              onClick={toggleMobilePopup}
            />
            {isWaterMark ? <ActionButton
              text={content.REMOVE_WATERMARK}
              className="mobWatermark-btn"
              icon={`${STATIC_PATH}upgrade-stars.svg`}
              onClick={(e) => upgrade(e, isWaterMark)}
            /> : null}
          </MobileSection>
        </PlayerSection>
        <Footer isExport={isExport} />
      </LeftSection>
      <RightSection
        isMobileShow={isMobileShow}
      >
        {!hideCloseIcon && (
          <Icon
            src={`${STATIC_PATH}close-icon2.svg`}
            className="closeIcon"
            onClick={toggleMobilePopup}
          />
        )}
        <Publish
          isShortsPlayer={!!(isShorts && shortData)}
          shortId={shortId}
          isPublish={isPublish}
        />
      </RightSection>
    </Flex>
  );
};

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

PublishContainer.propTypes = {
  isExport: PropTypes.bool,
  isPublish: PropTypes.bool,
  shortId: PropTypes.string
};

export default PublishContainer;
