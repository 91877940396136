import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Typography from "./Typography";
import { font } from "../constants/font";
import { STATIC_PATH } from "../constants/config";
import vmTheme from "../constants/theme";
import Restriction from "./Restriction";

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  font: ${font.normal};
  cursor: pointer;
`;

const Options = styled.ul`
  display: ${(props) => (props.display ? "block" : "none")};
  background: ${(props) => props.theme.polarColor};
  box-shadow: 0px 0px 4px ${(props) => props.theme.arrangeMenuColor};
  border: 1px solid ${(props) => props.theme.optionBorderColor};
  border-radius: 8px;
  padding: 0px;
  position: fixed;
  z-index: 999;
  min-width: ${props => props.minWidth || "184px"};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  @media screen and (max-width: 767px) {
    width: ${(props) => props.mobileWidth};
    top: auto;
    right: 0px;
  }
`;

const Option = styled.li`
  display: inline-flex;
  width: 100%;
  overflow: hidden;
  height: 36px;
  padding: 2px 5px 2px 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
  background: ${(props) =>
    props.openSubMenu ? props.theme.btnBgLightColor : "transparent"};
  a {
    display: inline-block !important;
    width: 100%;
    padding: 5px 13px;
    text-align: left;
    text-decoration: none;
    color: ${(props) => props.theme.panelPrimaryColor};
    cursor: pointer;
    border-radius: 8px;
  }

  &:nth-child(${(props) => props.isBorder}) {
    border-bottom: 1px solid ${(props) => props.theme.subtitleBgColor};
  }

  &:hover {
    background: ${(props) => props.theme.btnBgLightColor};
    border-radius: ${(props) => props.hoverBroderRadius || "unset"};
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const SubMenuUl = styled.ul`
  position: absolute;
  top: ${(props) => props.subTop || "0px"};
  left: calc(100% + 1px);
  min-width: 148px;
  background: ${(props) => props.theme.polarColor};
  box-shadow: 0px 0px 4px ${(props) => props.theme.arrangeMenuColor};
  border: 1px solid ${(props) => props.theme.optionBorderColor};
  border-radius: 8px;
  opacity: 1;
  padding: 0;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    right: 0px;
    z-index: 2;
  }
`;

const SubOption = styled.li`
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
  background: ${(props) => props.theme.polarColor};
  color: ${(props) => props.color || props.theme.panelPrimaryColor};
  font: ${font.normalBase_21};
  padding: 8px 12px;

  &:hover {
    background: ${(props) => props.theme.btnBgLightColor};
  }
`;

const Arrow = styled.span`
  position: absolute;
  right: 10px;

  .arrow-icon {
    width: 16px;
    height: 16px;
    transform: rotate(270deg);
  }
`;

const DotMenuIcon = ({
  subMenu = [{ icon: "", name: "", actionName: "", subOptions: [] }],
  onSelect,
  icon,
  isBorder,
  onOpen = () => {},
  className,
  subTop,
  mobileWidth,
  minWidth,
  customTop = 53,
  customLeft = 130,
  color,
  hoverBroderRadius
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const theme = useSelector((state) => state.app.get("theme"));
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
        setOpenSubMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useLayoutEffect(() => {
    if (isOpen && ref.current) {
      const node = ref.current.getBoundingClientRect();
      const dropdownTop = node.bottom + window.scrollY - customTop;
      const dropdownLeft = node.left + node.width / 2 + window.scrollX - customLeft;
      setMenuPosition({
        top: dropdownTop,
        left: dropdownLeft,
      });
    }
  }, [customLeft, customTop, isOpen, ref]);

  useEffect(() => {
    onOpen(isOpen);
  }, [isOpen, onOpen]);

  const handleMainOptionClick = (option) => {
    setOpenSubMenu(openSubMenu === option ? null : option);
  };

  const handleMouseEnterOption = (option) => {
    if (isOpen) {
      setOpenSubMenu(option);
    }
  };

  const handleMouseLeaveOption = () => {
    setOpenSubMenu(null);
  };

  const handleSubMenuMouseEnter = () => {
    if (isOpen) {
      setOpenSubMenu(openSubMenu);
    }
  };

  const handleSubMenuMouseLeave = () => {
    setOpenSubMenu(null);
  };

  return (
    <Dropdown
      className={className || "dot-menu"}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      }}
      zIndex={isOpen ? 9 : 1}
      ref={ref}
    >
      <img
        src={icon}
        style={{
          filter: isOpen && "unset",
          background: isOpen ? vmTheme[theme].stImgBgColor : "transparent",
          borderRadius: "6px",
        }}
        width="20px"
        className="dot-icon"
        alt="menu-icon"
      />
      <Options
        display={isOpen}
        minWidth={minWidth}
        top={menuPosition.top}
        left={menuPosition.left}
        className="option-ul"
        mobileWidth={mobileWidth}
      >
        {(subMenu || []).map((m) => (
          <React.Fragment key={m.name}>
            <Option
              onClick={() => {
                if (!m.subOptions || (m.subOptions && m.isUpgrade)) {
                  onSelect(m);
                  handleMainOptionClick(m);
                }
              }}
              onMouseEnter={!m.isUpgrade ? () => handleMouseEnterOption(m) : undefined}
              onMouseLeave={!m.isUpgrade ? handleMouseLeaveOption : undefined}
              isBorder={isBorder}
              hoverBroderRadius={hoverBroderRadius}
              openSubMenu={openSubMenu === m}
            >
              {m.icon && !m.isUpgrade && (
                <img
                  src={`${STATIC_PATH}${m.icon}`}
                  width="20px"
                  height="20px"
                  alt={m.name}
                />
              )}
              {m.isUpgrade && (
                <Restriction
                  type="LIMIT_EXCEED_WITH_UPGRADE"
                  position="absolute"
                  top="4px"
                  right="0px"
                />
              )}
              <Typography
                isAnchor={true}
                innerContent={m.name}
                font={font.normalBase_21}
                cursor="pointer"
              />
              {m.subOptions && m.subOptions.length > 0 && (
                <Arrow>
                  <img
                    src={`${STATIC_PATH}downArrow.svg`}
                    alt="arrow"
                    className="arrow-icon"
                  />
                </Arrow>
              )}
            </Option>
            {openSubMenu === m && m.subOptions && (
              <SubMenuUl
                subTop={subTop}
                className="submenu-ul"
                onMouseEnter={handleSubMenuMouseEnter}
                onMouseLeave={handleSubMenuMouseLeave}
              >
                {m.subOptions.map((subOption) => (
                  <SubOption
                    key={subOption}
                    color={color}
                    onClick={() => onSelect(m, subOption)}
                  >
                    {subOption.name}
                  </SubOption>
                ))}
              </SubMenuUl>
            )}
          </React.Fragment>
        ))}
      </Options>
    </Dropdown>
  );
};

DotMenuIcon.propTypes = {
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      name: PropTypes.string,
      subOptions: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  onSelect: PropTypes.func,
  onOpen: PropTypes.func,
  icon: PropTypes.string,
  isBorder: PropTypes.number,
  className: PropTypes.string,
  subTop: PropTypes.string,
  mobileWidth: PropTypes.string,
  minWidth: PropTypes.string,
  customTop: PropTypes.string,
  customLeft: PropTypes.string,
  color: PropTypes.string,
  hoverBroderRadius: PropTypes.string,
};

export default DotMenuIcon;
