/* eslint-disable no-lonely-if, max-len, object-shorthand, operator-assignment, prefer-const, prefer-destructuring, react/no-unknown-property, react/sort-comp, jsx-a11y/media-has-caption */

import React, { createRef, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { clamp } from "../timeline/timeline-helper";
import {
  addTween,
  prefetchComplete,
  setPlayAll,
  updateVideoBufferStatus,
} from "../../redux/actions/appUtils";
import { PlayerAudioCallbackContext } from "./player-context";
import CanvasImage from "./canvas-image";
import TransparentVideo from "./transparent-video";
import CanvasVideo from "./canvas-video";
import { addCacheClearQuery } from "../../helper/addCacheClearQuery";

class PlayerMediaComponent extends React.Component {
  constructor(props) {
    super(props);

    this.changeMediaTime = this.changeMediaTime.bind(this);
    this.isTimePropChanged = this.isTimePropChanged.bind(this);
    this.checkMediaHasEnoughData = this.checkMediaHasEnoughData.bind(this);
    this.handleBuffering = this.handleBuffering.bind(this);
    this.prefetchMedia = this.prefetchMedia.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.playPauseTweenCallback = this.playPauseTweenCallback.bind(this);
    this.handleLoop = this.handleLoop.bind(this);
    this.handleError = this.handleError.bind(this);
    this.assignRef = this.assignRef.bind(this);

    this.mediaElRef = createRef(null);
    this.imageRef = createRef(null);

    this.reverseInterval = 1000 / 30; // ms
    this.reverseTimer = null;

    this.mediaPrefetchTimer = null;
    this.prefetchCheckInterval = 500; // ms
    this.prefetchTries = 10;

    this.bufferStartMediaTime = null;
    this.bufferCheckTimer = null;
    this.bufferCheckInterval = 200; // ms

    this.preload = null;
  }

  componentDidMount() {
    const { runningState } = this.props;

    let { playhead } = this.props;
    if (this.props.isPlaying) {
      playhead = runningState.get("seekPlayhead");
    }

    this.changeMediaTime(playhead);

    if (this.props.isPlaying) {
      this.prefetchMedia();
    }
  }

  componentDidUpdate(prevProps) {
    const isPlayStarted = !prevProps.isPlaying && this.props.isPlaying;
    const isPlayEnded = prevProps.isPlaying && !this.props.isPlaying;
    const isPlayerLoaded = !prevProps.isLoaded && this.props.isLoaded;
    const isSeeked = this.props.runningState.get("seekToken") !== prevProps.runningState.get("seekToken");
    const isIdleTimeChanged = !this.props.isPlaying && this.isTimePropChanged(prevProps, this.props);

    const isBuffering = this.props.videoBufferStatus.size > 0;
    const isCurrentMediaBuffering = this.props.videoBufferStatus.has(this.props.id);

    if (isPlayStarted) {
      this.changeMediaTime(this.props.runningState.get("seekPlayhead"));
      this.prefetchMedia();
    } else if (isSeeked || isPlayerLoaded) {
      const playhead = this.props.runningState.get("seekPlayhead");
      const isWithinPlayRange = playhead >= this.props.playAt && playhead < this.props.pauseAt;

      if (!isWithinPlayRange || this.props.isPaused) {
        this.pause(playhead);
        if (isCurrentMediaBuffering) {
          clearInterval(this.bufferCheckTimer);
          this.props.updateVideoBufferStatus({ videoKey: this.props.id, isBuffering: false });
        }
      } else if (isBuffering && !isCurrentMediaBuffering) {
        this.pause(playhead);
      } else if (isWithinPlayRange && !isBuffering) {
        this.play(playhead);
      }
    } else if (isIdleTimeChanged) {
      this.changeMediaTime(this.props.playhead);
    }

    if (isPlayEnded || isPlayerLoaded) {
      clearInterval(this.bufferCheckTimer);
      clearInterval(this.mediaPrefetchTimer);
      if (isPlayEnded) {
        this.pause(this.props.playhead);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.bufferCheckTimer);
    clearInterval(this.mediaPrefetchTimer);
    clearInterval(this.reverseTimer);

    if (this.mediaElRef.current) {
      this.mediaElRef.current.pause();
      this.mediaElRef.current.src = "";
      this.mediaElRef.current.removeAttribute("src");
      this.mediaElRef.current.load();
    }
  }

  changeMediaTime(playhead, timeProps = this.props) {
    try {
      const { playStart, playEnd, mediaStart, mediaEnd, loop = false, reverse = false, } = timeProps;
      let { speed = 1 } = timeProps;

      if (this.props.isPlaying) {
        const isWithinItemRange = playStart - 20 <= playhead && playhead <= playEnd + 20;
        if (!isWithinItemRange) {
          // changing currentTime will make browser fetch video
          // if there are multiple videos, it will be heavy
          // so skip changing current time if video is not going to be displayed on screen anytime soon
          return;
        }
      }

      if (!Number.isFinite(speed)) {
        speed = 1;
      }

      let loopDuration = (mediaEnd - mediaStart) / speed;
      let loopPlayhead = playhead - playStart;
      if (loop) {
        const currentLoop = Math.max(Math.floor(loopPlayhead / loopDuration), 0);
        const currentLoopStart = currentLoop * loopDuration;
        loopPlayhead = loopPlayhead - currentLoopStart;
      }
      let currentTime = clamp(mediaStart + loopPlayhead * speed, mediaStart, mediaEnd);
      if (reverse) {
        currentTime = mediaEnd - (currentTime - mediaStart);
      }

      this.mediaElRef.current.playbackRate = speed;
      this.mediaElRef.current.currentTime = currentTime;

      const duration = this.mediaElRef.current.duration;
      if (this.props.isPlaying && reverse && Number.isFinite(duration) && this.props.swStatus === "fulfilled" && navigator.serviceWorker.controller) {
        const range = 60 / speed; // secs
        const reqInterval = 10; // secs
        const preloadReqTime = Math.min(Math.ceil(currentTime / reqInterval) * reqInterval, duration);

        if (this.lastPreloadReqTime !== preloadReqTime && preloadReqTime > 0) {
          navigator.serviceWorker.controller.postMessage({
            type: "FETCH_MEDIA_BY_TIME",
            data: {
              startTime: Math.max(preloadReqTime - range, 0),
              endTime: preloadReqTime,
              duration: duration,
              src: this.mediaElRef.current.src,
            },
          });
          this.lastPreloadReqTime = preloadReqTime;
        }
      }
    } catch (error) {
      if (this.props.isPlayAll) {
        this.props.setPlayAll(false);
      }
    }
  }

  play(playhead) {
    try {
      if (this.mediaElRef.current.preload !== "auto") {
        this.preload = "auto";
        this.mediaElRef.current.preload = "auto";
        this.mediaElRef.current.src = addCacheClearQuery(this.props.src, this.props.allowCustomCaching, this.props.reverse);
        this.mediaElRef.current.load();
      }

      clearInterval(this.reverseTimer);
      this.changeMediaTime(playhead);

      if (this.props.reverse) {
        // let lastReverseSeek = null;
        // let lastBufferCheckTime = playhead;
        this.reverseTimer = setInterval(() => {
          try {
            let playhead = this.props.t1.time();

            // const isBuffering = this.props.videoBufferStatus.size > 0;
            // const isCurrentVideoBuffering = isBuffering && this.props.videoBufferStatus.has(this.props.id);

            // if (isCurrentVideoBuffering) {
            //     if (lastReverseSeek === null) {
            //         lastReverseSeek = playhead;
            //     }
            //     lastReverseSeek += 0.1;
            //     playhead = lastReverseSeek;
            // } else {
            //     lastReverseSeek = null;
            // }

            this.changeMediaTime(playhead);

            // if (!isCurrentVideoBuffering && (playhead - lastBufferCheckTime) > 0.5) {
            //     this.handleBuffering();
            //     lastBufferCheckTime = playhead;
            // }
          } catch (error) { }
        }, this.reverseInterval);
      } else {
        this.props.playPauseAudio({
          id: this.props.id,
          playhead,
          mediaType: this.props.mediaType,
        });
        if (this.mediaElRef.current.paused) {
          this.mediaElRef.current.play().then(() => {
            this.changeMediaTime(this.props.t1.time());
          }).catch(() => { });
        }
      }
    } catch (error) { }
  }

  pause(playhead) {
    try {
      if (this.props.playAt - 20 <= playhead && playhead < this.props.playEnd) {
        if (this.mediaElRef.current.preload !== "auto") {
          this.preload = "auto";
          this.mediaElRef.current.preload = "auto";
          this.mediaElRef.current.src = addCacheClearQuery(this.props.src, this.props.allowCustomCaching, this.props.reverse);
          this.mediaElRef.current.load();
        }
      } else {
        this.preload = "none";
        this.mediaElRef.current.preload = "none";
        this.mediaElRef.current.removeAttribute("src");
        this.mediaElRef.current.load();
      }

      clearInterval(this.reverseTimer);
      if (!this.mediaElRef.current.paused) {
        this.mediaElRef.current.pause();
      }
      this.changeMediaTime(playhead);
    } catch (error) { }
  }

  playPauseTweenCallback(isPausedSeek) {
    // use current time from gsap instead of playStart
    // gsap will call this function even if user seeks to a time beyond playStart
    // addCallback is actually a zero duration tween with onComplete attached
    const playhead = this.props.t1.time();
    if (playhead >= this.props.pauseAt || playhead < this.props.playAt || this.props.isPaused) {
      this.pause(playhead);
    } else if (!isPausedSeek) {
      this.play(playhead);
    }
  }

  handleLoop() {
    try {
      const isBuffering = this.props.videoBufferStatus.size > 0;
      if (this.props.isPlaying && this.props.loop && this.mediaElRef.current && !isBuffering && !this.props.isPaused) {
        const playhead = this.props.t1.time();
        const { currentTime, paused } = this.mediaElRef.current;
        const isWithinPlayRange = this.props.playAt <= playhead && playhead < this.props.pauseAt;
        const isWithinMediaRange = this.props.mediaStart < currentTime && currentTime < this.props.mediaEnd;

        if (isWithinPlayRange && (!isWithinMediaRange || paused)) {
          this.playPauseTweenCallback(false);
        }
      }
    } catch (error) { }
  }

  prefetchMedia() {
    try {
      this.props.addTween({ callBack: this.playPauseTweenCallback, startTime: this.props.playAt, dataArr: [false] }, "addCallback");
      this.props.addTween({ callBack: this.playPauseTweenCallback, startTime: this.props.pauseAt, dataArr: [false] }, "addCallback");
      if (this.props.playAt - 19 > 0) {
        // add this tween to seek paused media to proper time
        this.props.addTween({ callBack: this.playPauseTweenCallback, startTime: this.props.playAt - 19, dataArr: [true] }, "addCallback");
      }
      if (this.props.maybeWebkit && this.props.from === "workspace" && this.props.pauseAt < this.props.playEnd) {
        // to change preload attr for frame/grid videos
        this.props.addTween({ callBack: this.playPauseTweenCallback, startTime: this.props.playEnd, dataArr: [true] }, "addCallback");
      }

      let tries = 0;
      if (this.props.playhead > this.props.playEnd || this.props.playhead - this.props.playStart < -10) {
        // skip checking video that's not going to be played immediately
        this.props.prefetchComplete(this.props.prefetchToken);
      } else if (this.checkMediaHasEnoughData({ useReadyState: true })) {
        const { prefetchToken } = this.props;
        this.mediaElRef.current.muted = true;
        this.mediaElRef.current
          .play()
          .catch(() => { })
          .finally(() => {
            if (prefetchToken === this.props.prefetchToken) {
              this.mediaElRef.current.pause();
              this.mediaElRef.current.muted = false;
              this.props.prefetchComplete(this.props.prefetchToken);
            }
          });
      } else {
        clearInterval(this.mediaPrefetchTimer);
        const { prefetchToken } = this.props;
        this.mediaPrefetchTimer = setInterval(() => {
          if (this.checkMediaHasEnoughData({ useReadyState: true }) || tries >= this.prefetchTries) {
            clearInterval(this.mediaPrefetchTimer);
            this.mediaElRef.current.muted = true;
            this.mediaElRef.current
              .play()
              .catch(() => { })
              .finally(() => {
                if (prefetchToken === this.props.prefetchToken) {
                  if (this.mediaElRef.current) {
                    this.mediaElRef.current.pause();
                    this.mediaElRef.current.muted = false;
                  }
                  this.props.prefetchComplete(this.props.prefetchToken);
                }
              });
          }
          tries += 1;
        }, this.prefetchCheckInterval);
      }
    } catch (error) { }
  }

  isTimePropChanged(prevProps, currentProps) {
    return (
      prevProps.playStart !== currentProps.playStart
      || prevProps.playEnd !== currentProps.playEnd
      || prevProps.mediaStart !== currentProps.mediaStart
      || prevProps.mediaEnd !== currentProps.mediaEnd
      || prevProps.speed !== currentProps.speed
      || prevProps.loop !== currentProps.loop
      || prevProps.reverse !== currentProps.reverse
      || prevProps.playhead !== currentProps.playhead
    );
  }

  checkMediaHasEnoughData(params = {}) {
    let { useReadyState, currentTime = null, requiredReadyState = HTMLMediaElement.HAVE_CURRENT_DATA } = params;

    if (useReadyState) {
      try {
        if (this.mediaElRef.current) {
          return this.mediaElRef.current.readyState >= requiredReadyState;
        }
      } catch (error) { }
    } else {
      try {
        if (this.mediaElRef.current) {
          let bufferLeftDuration = 0; // in secs
          let bufferRightDuration = 1; // in secs

          const { buffered, duration } = this.mediaElRef.current;

          if (!Number.isFinite(currentTime)) {
            currentTime = this.mediaElRef.current.currentTime;
          }

          if (duration >= 1 && duration - currentTime <= 1) { // chrome does not load last second when seeked near to end of the video
            return true;
          }

          if (duration < 1) { // AN-10591 - even if whole audio is loaded, chrome reports that a portion of audio is still not loaded
            bufferRightDuration = 0.1;
          }

          if (this.props.reverse) {
            if (this.props.maybeWebkit) {
              bufferLeftDuration = -1;
            } else {
              bufferLeftDuration = (this.reverseInterval / 1000) * 3; // ~ 3 frames
            }
            bufferRightDuration = 0;
          }

          if (currentTime < bufferLeftDuration) {
            bufferLeftDuration = currentTime;
          }
          if (duration - currentTime < bufferRightDuration) {
            bufferRightDuration = duration - currentTime;
          }

          for (let i = 0; i < buffered.length; i += 1) {
            let start = buffered.start(i);

            // Commenting below code since service worker is implemented, uncomment if any issue is faced
            // if (start < 1) {
            //     // in certain cases, browser does not load first few milliseconds of video for longer time. e.g. start would be 0.5
            //     start = 0;
            // }

            // const isBuffered = (currentTime - start >= bufferLeftDuration) && (buffered.end(i) - currentTime >= bufferRightDuration);
            const isBuffered = (((currentTime - start) - bufferLeftDuration) >= -0.009) && (((buffered.end(i) - currentTime) - bufferRightDuration) >= -0.009);

            if (isBuffered) {
              return true;
            }
          }
        }
      } catch (error) { }
    }

    return false;
  }

  handleBuffering() {
    try {
      const currentPlayhead = this.props.t1.time();
      const { currentTime } = this.mediaElRef.current;
      const isWithinPlayRange = this.props.playAt <= currentPlayhead && currentPlayhead < this.props.pauseAt;

      const isBuffering = this.props.videoBufferStatus.size > 0;
      const isCurrentVideoBuffering = isBuffering && this.props.videoBufferStatus.has(this.props.id);

      const checkParams = {
        useReadyState: false,
        currentTime: currentTime,
      };

      if (this.props.isLoaded && !isCurrentVideoBuffering && isWithinPlayRange && !this.checkMediaHasEnoughData(checkParams) && !this.props.isPaused) {
        this.props.updateVideoBufferStatus({
          videoKey: this.props.id,
          isBuffering: true,
          bufferStartedAt: currentPlayhead,
          currentTime,
        });

        this.bufferStartMediaTime = currentTime;
        clearInterval(this.bufferCheckTimer);
        this.bufferCheckTimer = setInterval(() => {
          try {
            const buffered = this.checkMediaHasEnoughData(checkParams);
            if (buffered) {
              this.bufferStartMediaTime = null;
              clearInterval(this.bufferCheckTimer);
              this.props.updateVideoBufferStatus({
                videoKey: this.props.id,
                isBuffering: false,
              });
            } else {
              const isBuffering = this.props.videoBufferStatus.size > 0;
              const { currentTime, paused } = this.mediaElRef.current;
              const isCurrentVideoBuffering = isBuffering && this.props.videoBufferStatus.has(this.props.id);

              if (isCurrentVideoBuffering && Number.isFinite(this.bufferStartMediaTime)) {
                if (this.props.reverse) {
                  if (this.bufferStartMediaTime - currentTime >= 3) {
                    this.pause(currentPlayhead);
                  } else if (paused && this.props.maybeWebkit) {
                    this.play(currentPlayhead);
                  }
                } else {
                  if (currentTime - this.bufferStartMediaTime >= 1) {
                    this.pause(currentPlayhead);
                  } else if (paused && this.props.maybeWebkit) {
                    this.play(currentPlayhead);
                  }
                }
              }
            }
          } catch (error) { }
        }, this.bufferCheckInterval);
      }
    } catch (error) { }
  }

  handleError() {
    try {
      if (this.props.isPlaying) {
        const src = addCacheClearQuery(this.props.src, this.props.allowCustomCaching, this.props.reverse);
        if (this.mediaElRef.current && this.mediaElRef.current.src === src) {
          if (this.props.isPlayAll) {
            this.props.setPlayAll(false);
          }
        }
      }
    } catch (error) { }
  }

  assignRef(r) {
    this.mediaElRef.current = r;
    if (typeof this.props.mediaElRef === "function") {
      this.props.mediaElRef(r, this.props.id);
    } else if (this.props.mediaElRef && typeof this.props.mediaElRef === "object") {
      this.props.mediaElRef.current = r;
    }
  }

  render() {
    const src = addCacheClearQuery(this.props.src, this.props.allowCustomCaching, this.props.reverse);
    const isBuffering = this.props.videoBufferStatus.size > 0;
    const isWithinItemRange = this.props.playStart <= this.props.playhead && this.props.playhead <= this.props.playEnd;
    const canHandleLoop = this.props.isPlaying && this.props.loop && !this.props.reverse;
    const isNotReversePlay = this.props.isPlaying && !this.props.reverse;
    const willPrefetch = this.props.isPlaying && !(this.props.playhead > this.props.playEnd || this.props.playhead - this.props.playStart < -10);
    let media = null;
    const VideoComponent = this.props.isBackgroundRemoval ? TransparentVideo : CanvasVideo;

    let preload = "none";
    if (this.preload) {
      preload = this.preload;
    } else if (isWithinItemRange || willPrefetch) {
      preload = "auto";
    }

    if (this.props.mediaType === "video") {
      media =
        this.props.isBackgroundRemoval || this.props.chromaKey ? (
          <>
            {!this.props.isPlayAll && (
              <CanvasImage
                className={this.props.className}
                style={this.props.style}
                src={this.props.poster}
                alt=""
                isBackgroundRemoval={this.props.isBackgroundRemoval}
                chromaKey={this.props.chromaKey}
                videoRef={this.imageRef}
                tolerance={this.props.tolerance}
              />
            )}
            <VideoComponent
              assignRef={this.assignRef}
              src={src}
              className={this.props.className}
              style={this.props.style}
              preload={preload}
              crossOrigin={this.props.crossOrigin || "anonymous"}
              muted={isBuffering}
              isPlayAll={this.props.isPlayAll}
              onWaiting={this.onWaiting}
              mediaElRef={this.mediaElRef}
              isBackgroundRemoval={this.props.isBackgroundRemoval}
              chromaKey={this.props.chromaKey}
              imageRef={this.imageRef}
              tolerance={this.props.tolerance}
            />
          </>
        ) : (
          <>
            {!this.props.isPlaying && this.props.poster && !this.props.isAvatar && !this.props.item?.get("isTransparentAvatar") && (
              <img
                className={this.props.className}
                style={this.props.style}
                src={this.props.poster}
                alt=""
              />
            )}
            <video
              ref={this.assignRef}
              key={src}
              src={src}
              className={this.props.className}
              style={this.props.style}
              preload={preload}
              playsInline={true}
              crossOrigin={this.props.crossOrigin || "anonymous"}
              muted={isBuffering || this.props.reverse}
              onWaiting={isNotReversePlay ? this.handleBuffering : undefined}
              onTimeUpdate={canHandleLoop ? this.handleLoop : undefined}
              onPause={canHandleLoop ? this.handleLoop : undefined}
              onError={this.props.isPlaying ? this.handleError : undefined}
            />
          </>
        )
    } else if (this.props.mediaType === "audio") {
      media = (
        <audio
          ref={this.assignRef}
          className={this.props.className}
          preload={preload}
          crossOrigin={this.props.crossOrigin || "anonymous"}
          src={src}
          muted={isBuffering}
          onWaiting={isNotReversePlay ? this.handleBuffering : undefined}
          onError={this.props.isPlaying ? this.handleError : undefined}
        />
      );
    }

    return media;
  }
}

PlayerMediaComponent.propTypes = {
  src: PropTypes.string,
  // fallbackSrc: PropTypes.string,
  poster: PropTypes.string,
  // volume: PropTypes.number,
  playStart: PropTypes.number,
  playEnd: PropTypes.number,
  mediaStart: PropTypes.number,
  mediaEnd: PropTypes.number,
  playhead: PropTypes.number,
  isPlayAll: PropTypes.bool,
  mediaElRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  style: PropTypes.object,
  className: PropTypes.string,
  runningState: PropTypes.object,
  prefetchToken: PropTypes.string,
  prefetchComplete: PropTypes.func,
  setPlayAll: PropTypes.func,
  isLoaded: PropTypes.bool,
  addTween: PropTypes.func,
  videoBufferStatus: PropTypes.object,
  t1: PropTypes.object,
  updateVideoBufferStatus: PropTypes.func,
  id: PropTypes.string,
  mediaType: PropTypes.oneOf(["video", "audio"]),
  crossOrigin: PropTypes.string,
  playPauseAudio: PropTypes.func,
  isPaused: PropTypes.bool,
  isBackgroundRemoval: PropTypes.bool,
  chromaKey: PropTypes.string,
  tolerance: PropTypes.number,
  // speed: PropTypes.number,
  isAvatar: PropTypes.bool,
  allowCustomCaching: PropTypes.bool,
  item: PropTypes.object,
  maybeWebkit: PropTypes.bool,
  isPlaying: PropTypes.bool,
  playAt: PropTypes.number,
  pauseAt: PropTypes.number,
  loop: PropTypes.bool,
  reverse: PropTypes.bool,
  swStatus: PropTypes.string,
  from: PropTypes.string,
};

const PlayerMediaContainer = (props) => {
  let {
    playStart,
    playEnd,
    playhead,
    playAt,
    pauseAt,
    speed = 1,
  } = props;

  const playerAudioCallback = useContext(PlayerAudioCallbackContext);

  if (!Number.isFinite(playAt)) {
    playAt = playStart;
  }
  if (!Number.isFinite(pauseAt)) {
    pauseAt = playEnd;
  }

  if (!props.loop) {
    const mediaDuration = (props.mediaEnd - props.mediaStart) / speed;
    const playDuration = playEnd - playStart;
    if (mediaDuration < playDuration) { // for frame and grid
      const newPlayEnd = playStart + mediaDuration;
      if (pauseAt > newPlayEnd) {
        pauseAt = newPlayEnd;
      }
    }
  }

  return (
    <PlayerMediaComponent
      {...props}
      playStart={playStart}
      playEnd={playEnd}
      playhead={playhead}
      playAt={playAt}
      pauseAt={pauseAt}
      mediaElRef={playerAudioCallback.assignMediaRef}
      playPauseAudio={playerAudioCallback.playPauseAudio}
    />
  );
};

PlayerMediaContainer.propTypes = PlayerMediaComponent.propTypes;

const mapStateToProps = (state) => ({
  t1: state.app.get("t1"),
  runningState: state.app.get("runningState"),
  playhead: state.app.get("playhead"),
  isPlayAll: state.app.get("isPlayAll"),
  isPlaying: state.app.get("isPlayAll"),
  prefetchToken: state.app.get("prefetchToken"),
  isLoaded: state.app.get("isLoaded"),
  videoBufferStatus: state.app.get("videoBufferStatus"),
  isPaused: state.app.get("isPausePlayer"),
  swStatus: state.app.get("swStatus"),
  maybeWebkit: state.app.get("maybeWebkit"),
});

const mapDispatchToProps = (dispatch) => ({
  prefetchComplete: (token, count) => dispatch(prefetchComplete(token, count)),
  addTween: (data, tweenType) => dispatch(addTween(data, tweenType)),
  setPlayAll: (data) => dispatch(setPlayAll(data)),
  updateVideoBufferStatus: (data) => dispatch(updateVideoBufferStatus(data)),
});

const PlayerMedia = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerMediaContainer);

export default PlayerMedia;
