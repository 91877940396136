import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AppConfig from "../../constants/config";
import triggerSimpleAjax from "../httpHelper";
import proceedWait from "../proceedWait";
import ActionTypes from "../../constants/action-types";
import content from "../../constants/content";
import useNotify from "./useNotify";
import { randomString } from "../uuid";
import { getucm, preflightCheck, updateBlob } from "../../redux/actions/appUtils";
import btoaConvert from "../btoaConvert";

const {
  FILE_NOT_SUPPORTED,
  TRY_AGAIN_WITH_DIFFERENT_FILE_TYPE,
  INVALID_REQUEST,
  UPLOAD_LIMIT_REACHED,
  FILE_TOO_BIG,
  UPLOAD_ERROR,
  FAILED_TO_UPLOAD,
} = content;

const useFileUpload = () => {
  const dispatch = useDispatch();
  const { warn } = useNotify();
  const [uploadResponse, setUploadResponse] = useState(null);
  const [isFailed, setFailed] = useState(false);
  const [failedFile, setFailedFile] = useState(null);

  // let interval = 0;
  let fileType = null;

  useEffect(() => {
    setFailed(false);
    setFailedFile(null);
  }, [])

  const errorHandler = (code, file) => {
    setFailed(true);
    setFailedFile(file)
    try {
      switch (code) {
        case "406":
          return TRY_AGAIN_WITH_DIFFERENT_FILE_TYPE;
        case "400":
          return INVALID_REQUEST;
        case "405":
          return UPLOAD_LIMIT_REACHED;
        case "530":
          return FILE_NOT_SUPPORTED;
        case "407":
          return FILE_TOO_BIG;
        default:
          return FAILED_TO_UPLOAD;
      }
    } catch (e) {
      return UPLOAD_ERROR;
    }
  };

  const dispatchProgressValue = (progressValue) => {
    dispatch({
      type: ActionTypes.SET_UPLOAD_PROGRESS,
      payload: {
        progress: progressValue,
        isUploadInProgress: progressValue === null ? false : progressValue >= 0,
      },
    });
  };

  const jdStatus = async (jd, fileType, fileObj, parentValue) => {
    const req = {
      jd,
      parent: parentValue,
      filename: fileObj.name,
    };
    await proceedWait(10000);
    triggerSimpleAjax(
      AppConfig.UPLOADS.POST_UPLOAD_STATUS,
      "POST",
      true,
      req,
      (res) => {
        if (res.status === "COMPLETE") {
          dispatchProgressValue(null);
          setUploadResponse({ ...res, type: fileType, blobId: fileObj.blobId });
        } else if (res.code === "900") {
          if (res.status === "STITCHING") {
            dispatchProgressValue(95);
          }
          jdStatus(jd, fileType, fileObj, parentValue);
        } else if (res.code === "500") {
          dispatchProgressValue(null, true);
          warn(errorHandler(res.code, fileObj));
        } else {
          jdStatus(jd, fileType, fileObj, parentValue);
        }
      },
      (err) => {
        dispatchProgressValue(null);
        warn(errorHandler(err.code, fileObj));
      }
    );
  };

  const postUpload = (fileObj, idValue, parentValue, keyValue, fileType) => {
    dispatchProgressValue(62);
    setUploadResponse(null);
    const req = {
      subtype: fileType,
      fileType: fileObj.type,
      key: keyValue,
      idValue,
      filename: fileObj.name,
      parent: parentValue,
    };

    triggerSimpleAjax(
      AppConfig.UPLOADS.POST_UPLOAD,
      "POST",
      true,
      req,
      (response) => {
        if (response.code === "900") {
          // clearInterval(interval);
          if (response.jd) {
            jdStatus(response.jd, fileObj.type, fileObj, parentValue)
          } else {
            response.blobId = fileObj.blobId
            dispatchProgressValue(null);
            setUploadResponse({ ...response });
          }
        } else {
          dispatchProgressValue(null);
          warn(errorHandler(response.code, fileObj));
        }
      },
      (err) => {
        dispatchProgressValue(null);
        warn(errorHandler(err.code, fileObj));
      }
    );
  };

  const uploadMedia = (isImage = false, fileObj, idValue, parentValue, fileType) => {
    const chunkSize = 5 * 1024 * 1024;
    setUploadResponse(null);
    let filename = fileObj.name;
    if (isImage) {
      filename = `${idValue}.${fileObj.name.substr(
        fileObj.name.lastIndexOf(".") + 1
      )}`
    }
    const totalParts = Math.ceil(fileObj.size / chunkSize);
    const count = btoaConvert(totalParts);
    const { type } = fileObj;
    const payload = {
      filename: btoaConvert(unescape(encodeURIComponent(filename))),
      partcount: count,
      fileType: type,
    }
    preflightCheck(payload).then(async (response) => {
      if (response.sucess) {
        const presignedUrls = response.part_urls;
        const arr = new Array(totalParts).fill("");
        let progressValue = 0;
        const promises = arr.map(async (_ele, partNumber) => {
          const start = partNumber * chunkSize;
          const end = Math.min(start + chunkSize, fileObj.size);
          const partBlob = fileObj.slice(start, end);
          const result = await updateBlob(partBlob, presignedUrls[partNumber], type);
          const totalProgress = 55; // Max progress
          const increment = totalProgress / totalParts;
          const formatedValue = Math.min(totalProgress, progressValue + increment);
          progressValue = Math.ceil(formatedValue)
          dispatchProgressValue(progressValue);
          return result;
        })
        await Promise.all(promises);
        const objectKey = btoaConvert(response.object_key);
        const id = btoaConvert(response.upload_id);
        const payload = {
          object_key: objectKey,
          uploadid: id
        }
        getucm(payload).then((res) => {
          if (res.sucess) {
            postUpload(fileObj, idValue, parentValue, res.response.Key, fileType);
          } else {
            dispatchProgressValue(null);
            warn(errorHandler(null, fileObj));
          }
        }).catch((error) => {
          // Handle error appropriately, maybe retry logic:`, error);
          throw error; // Re-throw to propagate error to Promise.all
        });
      } else {
        dispatchProgressValue(null);
        warn(errorHandler(response.code, fileObj));
      }
    }).catch((err) => {
      dispatchProgressValue(null);
      warn(errorHandler(err.code, fileObj));
    })
  };

  const prepareMedia = (file, parent = 0) => {
    setFailed(false);
    setFailedFile(null);
    const { UP_IMG, UP_VID, UP_AUD } = AppConfig.UPLOADS;
    const { type } = file;
    // fileObj = file;
    // parentValue = parent;
    // idValue = randomString();
    const randomKey = randomString();
    dispatchProgressValue(0);
    if (UP_IMG.indexOf(type) > -1) {
      fileType = "UPIMAGE";
      uploadMedia(true, file, randomKey, parent, "UPIMAGE")
    } else if (UP_VID.indexOf(type) > -1) {
      fileType = "UPVIDEO";
      uploadMedia(false, file, randomKey, parent, "UPVIDEO")
    } else if (UP_AUD.indexOf(type) > -1) {
      fileType = "UPAUDIO";
      uploadMedia(false, file, randomKey, parent, "UPAUDIO")
    } else {
      warn(errorHandler(FILE_NOT_SUPPORTED, file));
      dispatchProgressValue(null);
    }
  };

  return { fileType, prepareMedia, uploadResponse, isFailed, failedFile };
};

export default useFileUpload;
