export default {
    TEST: 'TEST',
    S_ADD_USER: 'ADD_USER',
    S_UPDATE_PROJECT_DATA: "updateProjectData",
    S_PROJECT_TRACKER: 'project_tracker',
    S_UPDATE_TIMELINE: 'updateTimeline',
    S_MOVE_UPDATE: 'moveUpdate',
    S_UPDATE_OBJECT: 'updateObject',
    S_APPLY_FLIP: 'applyFlip',
    S_APPLY_COLOR: 'applyColor',
    S_BULK_OBJECT_UPDATE: 'bulkObjectUpdate',
    S_APPLY_FRAME_CLIP_PLOT: 'applyFrameClipPlot',
    S_UPDATE_SUBTITLE_LIST: 'updateSubtitleList',
    S_UPDATE_GROUP_TEXT: 'updateGroupText',
    S_TRIGGER_SUBTITLE_RECONCILE: 'triggerSubtitleReconcile'
};