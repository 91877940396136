import React from "react";
import PropTypes from "prop-types";
import { STATIC_PATH } from "../../constants/config";
import { WatermarkHolder, WatermarkWrapper } from "./workspace-components";
import useWatermark from "../../helper/hooks/useWatermark";

const WaterMark = (props) => {
  const {
    workspaceHeight,
    workspaceWidth,
    zoomFactor,
    isPlayAll,
    showUpgrade,
  } = props;
  const workspaceEle = document.getElementById("workspace");
  let imgStyle = {};
  let wrapperStyle = {};
  if (workspaceEle) {
    const height = workspaceHeight * zoomFactor;
    const width = workspaceWidth * zoomFactor;

    /* This ratio is based on the different sizes of project Square, Horizontal and Vertical respectively,
           Padding and borderRadius values are based on zoomFactor, when the zoomFactor value is 1
        */
    const ratio =
      height === width
        ? 0.25
        : width > height * 2
        ? 0.17
        : width > height
        ? 0.2
        : 0.4;

    imgStyle = {
      width: `${width * ratio}px`,
      top: `${10 * zoomFactor}px`,
      right: `${10 * zoomFactor}px`,
      pointerEvents: isPlayAll ? "none" : "all",
    };

    wrapperStyle = {
      width,
      height,
    };
  }

  const isShow = useWatermark();

  return (
    <WatermarkWrapper isShow={isShow} data-html2canvas-ignore="true" style={wrapperStyle}>
      <WatermarkHolder
        style={imgStyle}
        onClick={() => {
          showUpgrade();
        }}
      >
        <img
          alt="watermark"
          src={`${STATIC_PATH}watermark.svg`}
          draggable="false"
          height="100%"
          width="100%"
        />
      </WatermarkHolder>
    </WatermarkWrapper>
  );
};

WaterMark.propTypes = {
  workspaceHeight: PropTypes.number,
  workspaceWidth: PropTypes.number,
  zoomFactor: PropTypes.number,
  isPlayAll: PropTypes.bool,
  showUpgrade: PropTypes.func,
};

export default WaterMark;
