import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { font } from "../constants/font";

const TabContainer = styled.div`
  position: relative;
  display: flex;
  background: ${(props) => props.theme.liBgColor};
  height: 28px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const Tab = styled.div`
  position: relative;
  padding: 10px 15px;
  cursor: ${props => props.isDisabled ? "not-allowed" : "pointer"};
  width: 128px;
  border-radius: ${(props) => (props.active ? "6px" : "unset")};
  text-align: center;
  font: ${font.mediumMini};
  text-transform: capitalize;
  color: ${(props) => props.theme.tabTextColor};
  z-index: 1;
  transition:
    background-color 0.3s linear,
    transform 0.3s linear;
  &:hover {
    transform: scale(1.02);
    color: ${(props) => props.theme.blackColor};
  }
  @media screen and (max-width: 767px){
    padding: 4px 10px;
  }
`;

const TabIndicator = styled.div`
  position: absolute;
  bottom: 1px;
  left: 2px;
  height: 26px;
  z-index: 0;
  width: 128px;
  background-color: ${(props) => props.theme.polarColor};
  box-shadow: ${(props) => `0px 1px 3px ${props.theme.boxShadowBgColor}`};
  border-radius: 6px;
  transform: ${(props) => `translateX(${props.activeTab * 128}px)`};
  transition: transform 0.3s linear;
    @media screen and (max-width: 767px) {
    // width: 33.33%;
    transform: ${(props) => `translateX(${props.activeTab * 98}%)`};
  }
`;

const ToggleTab = ({ tabs, activeTab, onTabChange, currentCycle }) => {
  return (
    <TabContainer>
      {tabs.map((tab, index) => {
        const isDisabled = currentCycle && index === 0;

        return (
          <Tab
            key={tab.label}
            totalTabs={tabs.length}
            active={index === activeTab}
            isDisabled={isDisabled}
            onClick={() => !isDisabled && onTabChange(index)}
          >
            {tab.label}
          </Tab>
        )
      })}
      <TabIndicator totalTabs={tabs.length} activeTab={activeTab} />
    </TabContainer>
  );
};

ToggleTab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  currentCycle: PropTypes.bool,
};

export default ToggleTab;
