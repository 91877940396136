import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import useFileUpload from "../../helper/hooks/useFileUpload";
import content from "../../constants/content";
import useNotify from "../../helper/hooks/useNotify";
import { addObject, updateBlobItem } from "../../redux/actions/timelineUtils";
import { generatePartialLibItem } from "../panels/library-helper";
import { randomString } from "../timeline/timeline-helper";
import AppConfig, { MAX_FILE_DURATION } from "../../constants/config";
import fileExtensions from "../../constants/mime-types.json";
import ActionTypes from "../../constants/action-types";
import { UploadWrapperContainer } from "../panels/library-panels/upload-panel/upload-panel-components";
import { getImageMetaData } from "../../helper/getImageMetaData";
import { getVideoMetaData } from "../../helper/getVideoMetaData";
import ApplyAllModal from "../../common-components/ApplyAllModal";
import { font } from "../../constants/font";
import { getAudioMetaData } from "../../helper/getAudioMetaData";
import { PLAN_CONFIG } from "../../constants";
import { showUpgrade } from "../../redux/actions/modalAction";
import getElement from "../../helper/getElement";

const UploadWrapper = (props) => {
  const { success, warn } = useNotify();
  const dispatch = useDispatch();
  const [isOpenItemExistModal, setOpenItemExistModal] = useState(false);
  const [dummyItemPayload, setDummyItemPayload] = useState(null);
  const [existingItem, setExistingItem] = useState(null);
  const [transferFile, setTransferFile] = useState(null);
  const [isuploadPanel, setUploadPanel] = useState(false)
  const [canDrag, setDrag] = useState(false);
  const [isInvalidType, setInvalidType] = useState(false);
  const [failedUploadFile, setFailedUploadFile] = useState(null);
  const [uploadingItem, setUploadingItem] = useState([]);
  const [isUploadComplete, setUploadComplete] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const userDetails = useSelector((store) => store.userDetails);
  const { currentFolder } = useSelector(
    (state) => state.library.dataSource.uploadLibrary
  );
  const { isUploadInProgress, multiItems } = useSelector(
    (state) => state.library.dataSource.uploadProgress
  );
  const { folderData, fileData } = useSelector(
    (state) => state.library.dataSource.uploadData
  );
  const { plan } = userDetails;
  const { prepareMedia, uploadResponse, isFailed, failedFile } = useFileUpload();

  useEffect(() => {
    if (isFailed || failedFile) {
      setInvalidType(true);
      setFailedUploadFile(failedFile)
    }
  }, [isFailed, failedFile]);

  const addObjectDispatch = (containerName, payload) => {
    // updating to workspace from here.
    dispatch(
      addObject({
        toUpdate: [
          {
            container: containerName,
            id: payload.id,
            newItemData: payload,
            isAdd: true,
            isLibraryDrop: true,
          },
        ],
      })
    );
  }

  const removeUploadedItems = (files) => {
    if (files && files.length > 1) {
      return files.slice(1);
    }
    return [];
  };

  const onDrop = async (e) => {
    const element = getElement('#upload-panel-drag-area');
    // If target to upload panel, need to 
    setUploadPanel(false);
    if (element) {
      setUploadPanel(true);
    } else {
      setUploadPanel(false);
    }
    e && e.preventDefault() && e.stopPropagation();
    setDrag(false);
    const files = Array.from(e.dataTransfer.files);
    if (isUploadInProgress) {
      warn(content.UPLOAD_IN_PROCESS);
      return true;
    }
    if (!files || !(files || []).length) {
      return true;
    }

    // Upload Restriction: Restrict if all file size is more than actual size
    const isAllFilesNotValid = Array.from(files).every((item) => PLAN_CONFIG.SIZE_OPTIONS[plan] < item.size);
    if (isAllFilesNotValid) {
      dispatch(showUpgrade({ upgradesource: 'Upload-file-size' }));
      e.target.value = '';
      return true;
    }

    window.onbeforeunload = (evt) => {
      evt.preventDefault();
      (evt || window.event).returnValue = 'Gonna Reload';
    }

    const handleImageMetaData = async (blobSrc) => {
      const data = await getImageMetaData(blobSrc)
        .then((res) => {
          let itemWidth;
          let itemHeight;
          let xVal = (props.workspaceWidth - itemWidth) / 2;
          let yVal = (props.workspaceHeight - itemHeight) / 2;
          // Set image position ,height and width to workspace
          const width = res.naturalWidth;
          const height = res.naturalHeight;
          itemWidth = props.workspaceWidth;
          itemHeight = props.workspaceHeight;
          const setSpace = 50; // px
          const leastVal = Math.min(props.workspaceWidth, props.workspaceHeight) - setSpace;
          const aspectRatio = width / height;

          if (width > height) {
            itemWidth = leastVal;
            itemHeight = itemWidth / aspectRatio;
          } else {
            itemHeight = leastVal;
            itemWidth = itemHeight * aspectRatio;
          }
          xVal = (props.workspaceWidth - itemWidth) / 2;
          yVal = (props.workspaceHeight - itemHeight) / 2;
          return {
            x: xVal,
            y: yVal,
            width: itemWidth,
            height: itemHeight
          }
        })
        .catch(() => { });
      return data;
    }

    const handleVideoMetaData = async (blobSrc) => {
      const data = await getVideoMetaData(blobSrc)
        .then((res) => {
          // Set video position, height and width to workspace
          const width = res.videoWidth;
          const height = res.videoHeight;
          let scaledWidth = props.workspaceWidth;
          let scaledHeight = (height / width) * scaledWidth;
          if (scaledHeight < props.workspaceHeight) {
            scaledHeight = props.workspaceHeight;
            scaledWidth = (width / height) * scaledHeight;
          }

          const scaledCenter = { x: scaledWidth / 2, y: scaledHeight / 2 };
          const workspaceCenter = {
            x: props.workspaceWidth / 2,
            y: props.workspaceHeight / 2,
          };

          const x = workspaceCenter.x - scaledCenter.x;
          const y = workspaceCenter.y - scaledCenter.y;

          return {
            x,
            y,
            width: scaledWidth,
            height: scaledHeight,
            duration: res.duration
          }
        })
        .catch(() => { });

      return data;
    }
    const isFileSizeExceeding = Array.from(files).some((item) => PLAN_CONFIG.SIZE_OPTIONS[plan] < item.size);
    const processFile = async (file, index) => {
      const blobSrc = URL.createObjectURL(file);
      const randomAsset = randomString("asset");
      const fileSize = file.size
      // Upload Restriction: Restrict if file size is more than actual size
      if (PLAN_CONFIG.SIZE_OPTIONS[plan] < fileSize) {
        dispatch(
          showUpgrade({ upgradesource: 'Upload-file-size' })
        );
        e.target.value = '';
        return true;
      }

      let srcType;
      let sourcetype;
      const { UP_IMG, UP_VID, UP_AUD } = AppConfig.UPLOADS;
      // Check valid asset or not
      if (UP_IMG.indexOf(file.type) > -1) {
        srcType = fileExtensions[file.type].replace(".", "");
        sourcetype = "UPIMAGE";
      } else if (UP_VID.indexOf(file.type) > -1) {
        srcType = fileExtensions[file.type].replace(".", "");
        sourcetype = "VIDEO";
      } else if (UP_AUD.indexOf(file.type) > -1) {
        srcType = fileExtensions[file.type].replace(".", "");
        sourcetype = "MUSIC";
      } else {
        setInvalidType(true);
      }

      let itemWidth;
      let itemHeight;
      let xVal = (props.workspaceWidth - itemWidth) / 2;
      let yVal = (props.workspaceHeight - itemHeight) / 2;
      let videoDuration;
      if (sourcetype === "UPIMAGE") {
        const response = await handleImageMetaData(blobSrc);
        itemWidth = response.width;
        itemHeight = response.height;
        xVal = response.x;
        yVal = response.y;

      } else if (sourcetype === "VIDEO") {
        const response = await handleVideoMetaData(blobSrc);
        videoDuration = response.duration;
        itemWidth = response.width;
        itemHeight = response.height;
        xVal = response.x;
        yVal = response.y;
      } else if (sourcetype === "MUSIC") {
        await getAudioMetaData(blobSrc).then((res) => {
          videoDuration = res.duration
        })
      }

      const assetData = {
        width: itemWidth,
        height: itemHeight,
        x: 50,
        y: 50,
        type: sourcetype,
        subType: sourcetype,
        thumbnail: blobSrc,
        id: randomAsset,
        src: blobSrc,
        duration: videoDuration || 30,
        source_type: srcType,
        name: file.name,
      };

      const data = generatePartialLibItem({
        data: assetData,
        isUserUpload: true,
      });

      const uploadDropId = randomString("dropup");
      file.blobId = uploadDropId;  // Set dummy blobId to handle

      // enterStart and exitEnd will be updated in the autosave middleware before updating it in the Redux store.
      const dummyPayload = {
        enterStart: 0,
        enterEnd: 0,
        exitStart: 0,
        exitEnd: data.videoDuration || 5,
        track: 0,
        opacity: 1,
        flipPosition: 0,
        enterEffectName: "no_Effect",
        exitEffectName: "no_Effect",
        height: itemHeight,
        width: itemWidth,
        x: xVal,
        y: yVal,
        thumb: data.thumb || "",
        angle: 0,
        subType: data.subType,
        type: data.type,
        sourceType: data.sourceType,
        isCropped: false,
        id: uploadDropId,
        src: blobSrc,
        isBlob: true,
        dropId: uploadDropId,
        ...data,
      };

      const audioDummyPayload = {
        id: uploadDropId,
        volume: 1,
        subType: data.subType,
        src: blobSrc,
        type: data.type,
        musicDuration: videoDuration,
        musicStart: 0,
        musicEnd: videoDuration,
        fadeDetails: [],
        playStart: 0,
        playEnd: videoDuration,
        dropId: uploadDropId,
        track: 0,
        isBlob: true,
        ...data
      }

      // Upload Restriction
      if (sourcetype === "VIDEO") {
        const isDurationRestriction = (MAX_FILE_DURATION * 60) < videoDuration;
        // Upload Restriction: Restrict if video length is more than actual length
        if (isDurationRestriction) {
          warn(content.VIDEO_TOO_LONG, content.RESTRICT_VIDEO_DURATION);
          e.target.value = '';
          return null;
        }

        // Check video exist or not
        const filteredItems = fileData && fileData.assets && fileData.assets.filter((item) => {
          return (item._source.name === file.name) && (item._source.duration >= Math.floor(videoDuration))
        });

        if ((filteredItems || []).length > 0) {
          // Don't allow to show existing modal if it is isFileSizeExceeding
          if (!isFileSizeExceeding) {
            setExistingItem(filteredItems[0])
            setOpenItemExistModal(true);
            setDummyItemPayload(dummyPayload)
            setTransferFile({ file: files[index] })
            e.target.value = '';
          }
          return null
        }
      }

      e.target.value = '';
      return { isValid: "valid", audio: audioDummyPayload, video: dummyPayload, sourcetype, file };
    }

    const validFilesPromises = files.map(processFile);
    const results = await Promise.all(validFilesPromises);
    const validFiles = results.filter(result => result?.isValid === "valid");

    // While multiple upload, First element should call here, remaining will be called from useEffect
    if (validFiles.length > 0) {
      let isDone = false;
      validFiles.forEach((file) => {
        if (!isDone) {
          prepareMedia(file.file, currentFolder);
          const data = removeUploadedItems(validFiles);
          setUploadingItem(Array.isArray(data) ? data : [data]);
          isDone = true;
        }
        if (validFiles.length > 1) {
          dispatch({
            type: ActionTypes.SET_UPLOAD_PROGRESS,
            payload: {
              multiItems: validFiles.length - 1
            },
          });
        }

        if (!element) {
          // Workspace drag and drop to show the dummy workspace item
          setUploadPanel(false);
          if (file.sourcetype === "MUSIC") {
            addObjectDispatch("audios", file.audio);
          } else {
            addObjectDispatch("workspaceItems", file.video);
          }
        }
      })
    }

    e.target.value = '';
    return true;
  };

  useEffect(() => {
    if (uploadingItem.length > 0 && isUploadComplete) {
      prepareMedia(uploadingItem[0].file, currentFolder);
      const data = removeUploadedItems(uploadingItem);
      setUploadingItem(Array.isArray(data) ? data : [data]);
      dispatch({
        type: ActionTypes.SET_UPLOAD_PROGRESS,
        payload: {
          multiItems: uploadingItem.length - 1
        },
      });
    }
    setUploadComplete(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadComplete]);

  useEffect(() => {
    try {
      // Update uploaded data for drag and drop, also for upload panel
      if (uploadResponse?.code === "900" && !isInvalidType) {
        dispatch({
          type: ActionTypes.SET_UPLOAD_DATA,
          payload: {
            folderData,
            fileData: {
              ...fileData,
              assets: [{ _source: uploadResponse.res }, ...(Array.isArray(fileData?.assets) ? fileData.assets : [])],
              total: fileData.total + 1,
            },
          },
        });
        setUploadComplete(true);
        if (uploadingItem.length === 0) {
          setShowSuccessToast(true);
        }
      }
      window.onbeforeunload = null;
    } catch (error) {
      console.error("An error occurred while updating upload data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResponse, isInvalidType]);

  useEffect(() => {
    if (showSuccessToast) {
      success(content.FILE_UPLOADED);
      setShowSuccessToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSuccessToast])

  useEffect(() => {
    if (uploadResponse?.code === "900" && !isInvalidType && !isuploadPanel) {
      // Update workspace blob json once upload is success 
      const updateItems = uploadResponse.res;
      let containerName;
      let toUpdateItem;
      if (updateItems.type === "VIDEO") {
        containerName = "workspaceItems";
        toUpdateItem = {
          src: updateItems.src,
          sourceType: updateItems.source_type,
          thumb: updateItems.thumbnail,
          assetId: updateItems.id,
          isBlob: false,
        };
      } else if (updateItems.type === "AUDIO") {
        containerName = "audios";
        toUpdateItem = {
          isBlob: false,
          assetId: updateItems.id,
          src: updateItems.src,
        };
      } else {
        containerName = "workspaceItems";
        toUpdateItem = {
          src: updateItems.src,
          sourceType: updateItems.source_type,
          thumb: updateItems.thumbnail,
          assetId: updateItems.id,
          isBlob: false
        };
      }

      if (uploadResponse && uploadResponse.blobId) {
        props.updateBlobItem({
          toUpdate: [
            {
              container: containerName,
              toUpdate: toUpdateItem,
              blobId: uploadResponse.blobId
            },
            {
              uploadHistory: updateItems,
            },
          ],
        });
      }

    } else if (isInvalidType && !isuploadPanel) {
      // Remove blob item from workspace if something went wrong
      if (failedUploadFile && failedUploadFile.blobId) {
        props.updateBlobItem({
          toUpdate: [
            {
              container: "workspaceItems",
              blobId: failedUploadFile.blobId,
              isDelete: true,
            },
          ],
        });
      }

      setInvalidType(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResponse, isInvalidType]);

  const onDragOver = useCallback((e) => {
    e && e.preventDefault() && e.stopPropagation();
    setDrag(true);
  }, []);

  const onDragLeave = useCallback(
    (e) => {
      e && e.preventDefault() && e.stopPropagation();
      setDrag(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canDrag]
  );

  const handleUseExistingItem = () => {
    const payload = dummyItemPayload
    payload.videoStart = 0
    payload.exitEnd = existingItem._source.duration
    payload.volume = 1
    payload.flipPosition = 0
    payload.name = existingItem._source.name
    payload.thumb = existingItem._source.thumbnail
    payload.src = existingItem._source.src
    payload.assetId = existingItem._source.id
    payload.sourceType = existingItem._source.source_type
    payload.isBlob = false
    if (isuploadPanel) {
      setOpenItemExistModal(false);
      return true;
    }
    addObjectDispatch("workspaceItems", payload);
    setOpenItemExistModal(false);
    window.onbeforeunload = null;
    return true;

  }

  const handleUploadAnyway = (e) => {
    const buttonText = e.target.textContent.trim();
    if (buttonText === "Upload, anyway") {
      if (isUploadInProgress) {
        setUploadingItem(prevItems => [...prevItems, transferFile])
        dispatch({
          type: ActionTypes.SET_UPLOAD_PROGRESS,
          payload: {
            multiItems: multiItems + 1
          },
        });
      } else {
        prepareMedia(transferFile.file, currentFolder);
      }
      if (isuploadPanel) {
        setOpenItemExistModal(false);
        return true;
      }
      addObjectDispatch("workspaceItems", dummyItemPayload);
    }
    window.onbeforeunload = null;
    setOpenItemExistModal(false);
    return true;

  }

  return (
    <div id="upload-wrapper-drag-area" onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave}>
      <UploadWrapperContainer >{props.children}</UploadWrapperContainer>
      {
        isOpenItemExistModal && <ApplyAllModal
          isOpen={isOpenItemExistModal}
          onClose={handleUploadAnyway}
          modalData={content.UPLOAD_EXIST}
          onSave={handleUseExistingItem}
          cancelText={content.UPLOAD_ANYWAY}
          confirmText={content.WILL_USE_THAT}
          headerText={content.HEY_WAIT}
          textFont={font.normalLarge_27}
        />
      }
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addObject: (payload) => dispatch(addObject(payload)),
  updateBlobItem: (payload) => dispatch(updateBlobItem(payload)),
});

const mapStateToProps = (state) => {
  return {
    workspaceStage: state.app.get("workspaceStage"),
    workspaceWidth: state.projectDetails.get("width"),
    workspaceHeight: state.projectDetails.get("height"),
  };
};

UploadWrapper.propTypes = {
  children: PropTypes.element,
  updateBlobItem: PropTypes.func,
  workspaceWidth: PropTypes.number,
  workspaceHeight: PropTypes.number,
};

const UploadWorkspaceWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadWrapper);

export default UploadWorkspaceWrapper;
