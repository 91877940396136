import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import Action from "./Action";
import Typography from "./Typography";
import { font } from "../constants/font";
import { SearchInput } from "./SearchInput";
import { setWorkspaceTextFocus } from "../redux/actions/appUtils";

const { normalBase } = font;

/**
 * Use this when existing SVG properties need to be customized
 */

const GroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 316px;
  .grouped-action-style {
    width: ${(props) => props.buttonWidth};
    height: 36px;
    padding: 0px 5px;
    min-width: 24px;
    box-shadow: 0px 0.5px 1px ${(props) => props.theme.rgbaBlack};
  }
  ${SearchInput} {
    width: 70px;
    height: 36px;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.primaryBorderColor};
    border-radius: 6px;
    padding-left: 5px;
    ${(props) => props.customInputStyle}
    &:hover {
      ${(props) => props.hoverEffects}
    }
    &:focus {
      ${(props) => props.activeEffects}
    }
  }
`;

const GroupedButtons = (props) => {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState(props.selectedButton);
  const [inputText, setInputText] = useState('');
  const [customInputActive, togglCustomInputState] = useState(false);

  const {
    buttonsArray,
    hoverEffects,
    activeEffects,
    onClick,
    isMultiSelect,
    enableCustomInput,
    onChange,
    buttonWidth,
    fontColor,
    inputType,
    selectedButton,
    isCustomInput,
    borderRadius
  } = props;

  const onActionClick = useCallback((name) => {
    if (!isMultiSelect) {
      setActiveButton(name);
    }
    if (enableCustomInput) {
      togglCustomInputState(false);
      setInputText("");
    }
    onClick(name);
  }, [enableCustomInput, isMultiSelect, onClick]);

  const customInputChange = (e) => {
    if (!isMultiSelect) {
      setActiveButton(e.target.value);
    }
    togglCustomInputState(true);

    if (inputType === "number") {
      const { value } = e.target;
      const valid =
        value === "" || (/^\d*\.?\d*$/.test(value) && parseInt(value, 10) <= 10); // Regex to check for exactly one number
      if (valid) {
        setInputText(value);
        onChange(value);
      }
    }
  };

  const handleInputFocus = () => {
    dispatch(setWorkspaceTextFocus(true))
  }

  useEffect(() => {
    if (selectedButton && !isCustomInput) {
      setActiveButton(selectedButton);
      setInputText("");
      togglCustomInputState(false);
    } else {
      setActiveButton(null);
      setInputText(selectedButton);
      togglCustomInputState(true);
    }
  }, [selectedButton, isCustomInput]);

  return (
    <GroupWrapper
      className="grouped-buttons-wrapper"
      customInputStyle={customInputActive && activeEffects}
      hoverEffects={hoverEffects}
      activeEffects={activeEffects}
      buttonWidth={buttonWidth}
    >
      {buttonsArray.map((buttonContent, index) => {
        const { name, icon, value } = buttonContent;
        return (
          <Action
            key={`${index + name}`}
            hoverEffects={hoverEffects}
            activeEffects={activeEffects}
            borderColor={!isMultiSelect && name === activeButton ? "#00BAFF" : "#E3E4E8"}
            borderWidth={!isMultiSelect && name === activeButton ? "2px " : "1px"}
            background={!isMultiSelect && name === activeButton ? "#F0FBFE " : "transparent"}
            onClick={() => onActionClick(name)}
            customClass="grouped-action-style"
            removeActiveEffects={!isMultiSelect && name !== activeButton}
            borderRadius={borderRadius}
            showHoverColor={false}
          >
            {icon ? (
              <img src={icon} alt="" />
            ) : value ? (
              <Typography
                innerContent={value}
                font={normalBase}
                color={fontColor}
                cursor="pointer"
              />
            ) : null}
          </Action>
        );
      })}
      {enableCustomInput && (
        <SearchInput type={"text"} value={inputText} placeholder="Custom" onChange={customInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputFocus}
        />
      )}
    </GroupWrapper>
  );
};

GroupedButtons.defaultProps = {
  buttonsArray: [],
  hoverEffects: "",
  onClick: () => {},
  isMultiSelect: true,
  enableCustomInput: false,
  onChange: () => {},
  buttonWidth: "fit-content",
};

GroupedButtons.propTypes = {
  buttonsArray: PropTypes.array,
  hoverEffects: PropTypes.string,
  activeEffects: PropTypes.string,
  onClick: PropTypes.func,
  isMultiSelect: PropTypes.bool,
  enableCustomInput: PropTypes.bool,
  onChange: PropTypes.func,
  buttonWidth: PropTypes.string,
  fontColor: PropTypes.string,
  inputType: PropTypes.string,
  selectedButton: PropTypes.number,
  isCustomInput: PropTypes.bool,
  borderRadius: PropTypes.string
};

export default GroupedButtons;
