import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import useCopy from "../../../helper/hooks/useCopy";
import Typography from "../../../common-components/Typography";
import { font } from "../../../constants/font";
import ShareInput from "./shareInput";
import LabelDropdown from "../../../common-components/LabelDropdown";
import AppConfig, { STATIC_PATH } from "../../../constants/config";
import { CopyWrapper, ShareOption } from "../header-components";
import { setWorkspaceTextFocus } from "../../../redux/actions/appUtils";
import content from "../../../constants/content";
import { Backdrop } from "../../modal/modal-components";

const Share = ({ onClose, isShorts }) => {
  const [onOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [permission, setPermission] = useState(content.EDIT);
  const onFocus = () => dispatch(setWorkspaceTextFocus(true));
  const onBlur = () => dispatch(setWorkspaceTextFocus(false));
  const { projectId } = useSelector((state) => state.userDetails);

  const onChange = useCallback((value) => {
    setPermission(value);
  }, []);

  const copy = useCopy();

  const copyUrl = useCallback(() => {
    if (permission === content.EDIT) {
      copy(`${AppConfig.SITE_URL}editproject/${projectId}`);
    } else {
      copy(`${AppConfig.SITE_URL}preview/${projectId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission, projectId]);

  // Add useEffect to manage viewport meta tag
  useEffect(() => {
    // Save the original viewport meta content
    const originalViewport = document.querySelector('meta[name="viewport"]')?.content;
    
    // Set viewport to prevent zooming
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.content = 'width=device-width, initial-scale=1, maximum-scale=1';
    }

    return () => {
      // Restore original viewport on cleanup
      if (viewportMeta && originalViewport) {
        viewportMeta.content = originalViewport;
      }
    };
  }, []);

  return (
    <div>
      <Backdrop onClick={onClose} zIndex={5} />
      <ShareOption isShorts={isShorts} className="share-option-tool-bar">
        <Typography
          content={content.SHARE_PROJECT_WITH_OTHERS}
          align="center"
          font={font.boldMini_18}
          display="block"
          padding="10px 0"
          margin="10px 0"
          className="share-heading"
        />
        <ShareInput
          icon={`'${STATIC_PATH}email-icon.svg'`}
          left="8px"
          onBlur={onBlur}
          onFocus={onFocus}
          onModalClose={onClose}
          onOpen={onOpen}
          setOpen={setOpen}
        />
        {!onOpen && (
          <CopyWrapper>
            <Typography
              innerContent="Copy"
              className="copy"
              font={font.boldMicro}
              cursor="pointer"
              onClick={copyUrl}
            />
            <LabelDropdown
              className="inputDropdown"
              width="105px"
              margin="12px 3px"
              optionWidth="auto"
              border="none"
              font={font.boldBase}
              borderActive="none"
              options={[
                { label: "Edit Link", value: "edit" },
                { label: "View Link", value: "view" },
              ]}
              onChange={onChange}
            />
          </CopyWrapper>
        )}
      </ShareOption>
    </div>
  );
};

Share.propTypes = {
  onClose: PropTypes.func,
  isShorts: PropTypes.bool,
};

export default Share;
