/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-template */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { List, OrderedMap, fromJS } from "immutable";
import ColorPaletteWindow from "./propertywindow-colorpalette";
import { ColorButton, ColorButtonContainer, ColorPropertyStyled, ColorThemeContainer, ColorThemeDropDown } from "./color-components";
import content from "../../../../constants/content";
import { getSelectedItemIds, getSelectionContainer } from "../../../../helper/IndividualSelectHelper";
import { STATIC_PATH } from "../../../../constants/config";
import { isPropertyOnly, isUpImageSVG } from "../../../timeline/timeline-helper";
import { DialogBox } from "./propertywindow-dialogbox";
import { applyColor } from "../../../../redux/actions/timelineUtils";
import { DEFAULT_SELECTED_FRAME_CLIP, singleClipFrameToFrame } from "../../../frame/frame-helper";
import { createMemoizedFunction } from "../../../../helper/createMemoizedFunction";
import { DEFAULT_FRAME_BG } from "../../../../constants";
import Typography from "../../../../common-components/Typography";
import { font } from "../../../../constants/font";

class ColorPropertyWindowComponent extends React.Component {
    constructor(props) {
        super(props);

        this.propColorClasses = Object.freeze(["prop1", "prop2", "prop3", "prop4", "prop5"]);
        this.defaultBgColorList = Object.freeze(["#FFFFFF"]); // should be 6-letter hex

        this.getInitialState = this.getInitialState.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        this.getSelectionColors = this.getSelectionColors.bind(this);
        this.getThemeTabData = this.getThemeTabData.bind(this);
        this.getCurrentTheme = this.getCurrentTheme.bind(this);
        this.showPalette = this.showPalette.bind(this);
        this.toggleTheme = this.toggleTheme.bind(this);
        this.changeThemeTab = this.changeThemeTab.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.resetColor = this.resetColor.bind(this);
        this.selectColor = this.selectColor.bind(this);
        this.removeColor = this.removeColor.bind(this);

        this.state = this.getInitialState();

        this.themeBtnRef = React.createRef(null);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedItem !== prevProps.selectedItem
            || this.props.selectedFrameClip !== prevProps.selectedFrameClip
            || this.props.bgColor !== prevProps.bgColor
        ) {
            this.updateSelection();
        }
    }

    getInitialState() {
        const { selectedItem } = this.props;

        const selectedColors = this.getSelectionColors();
        const colorState = {
            colorIndex: null, // colorIndex = index of current color in colors map, used for SHAPE type by color palette component
            colorPart: selectedColors.entrySeq().first()[0], // colorPart = key of current color in colors map
            currentColor: selectedColors.entrySeq().first()[1], // currentColor = current color

            selectedColors, // colors map of selected item
        };

        const themeState = {
            currentTheme: null,
            isThemeOpen: false,
            themeTab: "all",
            themeBtnBounds: null,

            themeTabData: this.getThemeTabData(),
        };

        if (selectedItem && selectedItem.get("type") === "PROP" && colorState.selectedColors) {
            themeState.currentTheme = this.getCurrentTheme(
                themeState.themeTabData,
                colorState.selectedColors,
                selectedItem.get("type"),
            );
        }

        return {
            colorState,
            themeState,
            addNewColor: false
        };
    }

    updateSelection() {
        const { colorState, themeState } = this.state;
        const { selectedItem } = this.props;

        const newColorState = {
            ...colorState,
            selectedColors: this.getSelectionColors(),
        };

        if (newColorState.selectedColors.get(newColorState.colorPart)) {
            const index = this.props.bgColor.get("selectedKey") ? this.props.bgColor.get("selectedKey") : newColorState.colorPart;
            newColorState.currentColor = newColorState.selectedColors.get(index);
        } else {
            newColorState.colorIndex = null;
            newColorState.colorPart = null;
            newColorState.currentColor = null;
        }

        const newThemeState = {
            ...themeState,
            currentTheme: null,
        };

        if (selectedItem && selectedItem.get("type") === "PROP" && newColorState.selectedColors) {
            newThemeState.currentTheme = this.getCurrentTheme(
                themeState.themeTabData,
                newColorState.selectedColors,
                selectedItem.get("type"),
            );
        }

        this.setState({
            colorState: newColorState,
            themeState: newThemeState,
        });
    }

    getSelectionColors() {
        const { bgColor, selectedItem, selectedFrameClip } = this.props;
        let colors = null;
   
        if (selectedItem) {
            colors = selectedItem.get("colors");

            if (selectedItem.get("type") === "FRAME") {
                const clipId = selectedFrameClip.get("clipId");

                if (clipId !== DEFAULT_SELECTED_FRAME_CLIP.get("clipId")) {
                    colors = OrderedMap({
                        [clipId]: selectedItem.getIn(["clipDetails", clipId, "imgDetails", "color"]),
                    });
                } else {
                    colors = OrderedMap(
                        selectedItem
                            .get("d")
                            .map((path, index) => [index, path.get("fill")])
                    );
                }
            } else if (selectedItem.get("type") === "PROP" || isUpImageSVG(selectedItem)) {
                let newColors = fromJS({});

                for (const colorKey of this.propColorClasses) {
                    if (colors.get(colorKey)) {
                        newColors = newColors.set(colorKey, colors.get(colorKey));
                    }
                }

                colors = newColors;
            }
        } else {
            colors = bgColor.get("colors") || ["#ffffff"];
        }
        return colors;
    }

    getThemeTabData() {
        const { themes } = this.props;

        let themeTabs = OrderedMap({
            all: [],
        });
        let allThemesList = List();

        for (const [themeKey, themeList] of themes.entrySeq()) {
            const sortedThemeList = themeList.sortBy(t => t.get("index"));
            themeTabs = themeTabs.set(themeKey, sortedThemeList);
            allThemesList = allThemesList.concat(sortedThemeList);
        }

        allThemesList = allThemesList.sortBy(t => t.get("index"));
        themeTabs = themeTabs.set("all", allThemesList);

        return themeTabs;
    }

    getCurrentTheme(themeTabData, selectedColors, itemType) {
        let currentTheme = null;

        if (!itemType) {
            return currentTheme;
        }

        try {
            itemType = itemType.toLowerCase();
            const allThemesList = themeTabData.get("all");

            if (itemType === "prop") {
                currentTheme = allThemesList.find(
                    (theme) => {
                        if (!theme.getIn(["colors", itemType])) {
                            return false;
                        }

                        const themeColorList = theme.getIn(["colors", itemType]);
                        let filteredColors = {};
                        let filteredThemeColors = {};

                        for (let clrIdx = 0; clrIdx < this.propColorClasses.length; clrIdx += 1) {
                            const colorKey = this.propColorClasses[clrIdx];
                            const themeColor = themeColorList.get(clrIdx);
                            const itemColor = selectedColors.get(colorKey);

                            if (!itemColor || !themeColor) {
                                continue;
                            }

                            filteredColors[colorKey] = itemColor;
                            filteredThemeColors[colorKey] = themeColor;
                        }

                        filteredColors = fromJS(filteredColors);
                        filteredThemeColors = fromJS(filteredThemeColors);

                        return filteredColors.equals(filteredThemeColors);
                    }
                );
            }

            if (!currentTheme) {
                currentTheme = null;
            }
        } catch (error) {
            currentTheme = null;
        }

        return currentTheme;
    }

    showPalette(colorPart, currentColor) {
        const { selectedItem } = this.props;
        const { colorState } = this.state;
        let newColorState = colorState;

        if (colorPart === colorState.colorPart) {
            newColorState = {
                ...newColorState,
                colorPart: null,
                colorIndex: null,
                currentColor: null,
            };
        } else {
            let colorIndex = 0;
            if (selectedItem && selectedItem.get("type") === "SHAPE" && selectedItem.get("colors")) {
                colorIndex = selectedItem.get("colors").keySeq().toArray().indexOf(colorPart);
            }

            newColorState = {
                ...colorState,
                colorPart,
                colorIndex,
                currentColor,
            };
        }

        this.setState({ colorState: newColorState });
    }

    handleDefaultColor(colorPart) {
        const { colorState } = this.state;
        let newColorState = {
            ...colorState,
            colorPart,
            colorIndex: 0,
            currentColor: null
        };
        this.setState({
            colorState: newColorState
        });
    }

    selectColor(key, type) {
        const colorKey = Number(key);
        const toUpdate = {
            container: "bgColor",
            replaceAllColor: false,
            selectedKey: colorKey,
        };

        this.setState({ addNewColor: false })
        this.props.applyColor([toUpdate]);
    }    

    removeColor(type, key) {
        const colorKey = Number(key);
        const toUpdate = [
            {
                container: "bgColor",
                replaceAllColor: false,
                type
            },
        ];
        if (type === "remove") {
            toUpdate[0].colorKey = colorKey;
        }
        if (type === "deleteAll") {
            toUpdate[0].replaceAllColor = true,
            this.handleDefaultColor(0, "#ffffff")
        }
        this.props.applyColor(toUpdate);
    }

    toggleTheme() {
        const { themeState } = this.state;

        const newThemeState = {
            ...themeState,
            isThemeOpen: !themeState.isThemeOpen,
            themeTab: "all",
        };

        if (newThemeState.isThemeOpen) {
            if (this.themeBtnRef.current) {
                newThemeState.themeBtnBounds = this.themeBtnRef.current.getBoundingClientRect();
            } else {
                newThemeState.isThemeOpen = false;
            }
        } else {
            newThemeState.themeBtnBounds = false;
        }

        this.setState({ themeState: newThemeState });
    }

    changeThemeTab(tabName) {
        const { themeState } = this.state;

        if (tabName !== themeState.themeTab) {
            const newThemeState = {
                ...themeState,
                themeTab: tabName,
            };
            this.setState({ themeState: newThemeState });
        }
    }

    changeColor(color) {
        try {
            const { selectedItem, selectedFrameClip, selectionContainer } = this.props;
            const { colorState } = this.state;
            const { colorPart } = colorState;

            let colorKey = colorPart;

            if (selectedItem && (selectionContainer === "workspaceItems" || selectionContainer === "workspaceChildren")) {
                let isFrameClip = false;
                let isFrameSVG = false;

                if (selectedItem.get("type") === "FRAME") {
                    isFrameClip = selectedFrameClip.get("clipId") !== DEFAULT_SELECTED_FRAME_CLIP.get("clipId");
                    isFrameSVG = !isFrameClip;
                }

                if (isFrameSVG) {
                    colorKey = Number(colorKey);
                }

                const toUpdate = [
                    {
                        container: selectionContainer,
                        replaceAllColor: false,
                        color,
                        // colors: undefined,
                        colorKey,
                        id: selectedItem.get("id"),
                        isFrameClip,
                        isFrameSVG,
                    },
                ];

                this.props.applyColor(toUpdate);
            } else if (this.state.addNewColor) {
                const colors = this.props.bgColor.get("colors");
                const toUpdate = [
                    {
                        container: "bgColor",
                        replaceAllColor: false,
                        color,
                        // colors: undefined,
                        selectedKey: colors.size,
                        colorKey: colors.size,
                    },
                ];
                this.setState({ addNewColor: false });
                this.props.applyColor(toUpdate);
            } else if (!selectedItem) {
                const selectedKey = this.props.bgColor.get("selectedKey");
                colorKey = selectedKey ? Number(selectedKey) : Number(colorKey);
                const toUpdate = [
                    {
                        container: "bgColor",
                        replaceAllColor: false,
                        color,
                        // colors: undefined,
                        selectedKey,
                        colorKey,
                    },
                ];
                this.props.applyColor(toUpdate);
            }
        } catch (error) { }
    }

    changeTheme(theme) {
        try {
            const { selectedItem, selectionContainer } = this.props;

            if (selectedItem && selectedItem.get("type") === "PROP") {
                const themeType = selectedItem.get("type").toLowerCase();

                if (theme.getIn(["colors", themeType])) {
                    const themeColorList = theme.getIn(["colors", themeType]);
                    const newColors = {};
                    const currentColors = selectedItem.get("colors");

                    for (let clrIdx = 0; clrIdx < this.propColorClasses.length; clrIdx += 1) {
                        const colorKey = this.propColorClasses[clrIdx];
                        const themeColor = themeColorList.get(clrIdx);

                        if (!currentColors.has(colorKey) || !themeColor) {
                            continue;
                        }

                        newColors[colorKey] = themeColor;
                    }

                    const toUpdate = [
                        {
                            container: selectionContainer,
                            replaceAllColor: true,
                            colors: newColors,
                            id: selectedItem.get("id"),
                        },
                    ];

                    this.props.applyColor(toUpdate);
                }
            }
        } catch (error) { }
    }

    resetColor() {
        try {
            const { selectedItem, selectedFrameClip, selectionContainer } = this.props;

            if (selectedItem && (selectionContainer === "workspaceItems" || selectionContainer === "workspaceChildren")) {
                if (selectedItem.get("type") === "FRAME") {
                    const clipId = selectedFrameClip.get("clipId");
                    const isFrameClip = clipId !== DEFAULT_SELECTED_FRAME_CLIP.get("clipId");
                    const color = (selectedItem.get("sourceType") === "webm" || selectedItem.get("isTransparentAvatar")) ? DEFAULT_FRAME_BG : "";

                    if (isFrameClip) {
                        const toUpdate = [
                            {
                                container: selectionContainer,
                                replaceAllColor: false,
                                color,
                                colorKey: clipId,
                                id: selectedItem.get("id"),
                                isFrameClip: true,
                                isFrameSVG: false,
                            },
                        ];
                        this.props.applyColor(toUpdate);
                    }
                } else if (selectedItem.get("defaultColor")) {
                    const defaultColor = selectedItem.get("defaultColor");
                    const toUpdate = [
                        {
                            container: selectionContainer,
                            replaceAllColor: true,
                            colors: defaultColor.toJS(),
                            id: selectedItem.get("id"),
                            isFrameClip: false,
                            isFrameSVG: false,
                        },
                    ];
                    this.props.applyColor(toUpdate);
                }
            } else if (!selectedItem) {
                // bg color
                const toUpdate = [
                    {
                        container: "bgColor",
                        replaceAllColor: true,
                        colors: this.defaultBgColorList,
                    },
                ];
                this.props.applyColor(toUpdate);
            }
        } catch (error) { }
    }

    render() {
        const { selectedItem, selectedFrameClip, bgColor } = this.props;
        const { colorState, themeState } = this.state;
        const { selectedColors, colorPart, colorIndex, currentColor } = colorState;
        const isFrameClip = selectedFrameClip.get("clipId") !== DEFAULT_SELECTED_FRAME_CLIP.get("clipId");

        let themeBtn = null;
        let themeType = "";

        if (selectedItem && selectedItem.get("type")) {
            themeType = selectedItem.get("type").toLowerCase();
        }

        if (themeType === "prop") {
            const { currentTheme, themeTabData, themeTab, isThemeOpen, themeBtnBounds } = themeState;

            let themeName = "";
            if (currentTheme) {
                themeName = currentTheme.get("name");
            } else {
                themeName = <FormattedMessage id={content.CUSTOM} />;
            }

            let dropdown = null;
            if (isThemeOpen && themeBtnBounds) {
                const tabNames = [];
                let tabContent = null;

                for (const [tabName, tabData] of themeTabData.entrySeq()) {
                    const isActive = tabName === themeTab;

                    let tabNameCls = "";
                    if (isActive) {
                        tabNameCls += " active";
                    }

                    tabNames.push(
                        <li
                            key={tabName}
                            className={tabNameCls}
                            onClick={() => this.changeThemeTab(tabName)}
                        >
                            {tabName}
                        </li>
                    );

                    if (isActive) {
                        const themeCollections = [];

                        for (const theme of tabData.valueSeq()) {
                            if (theme.getIn(["colors", themeType])) {
                                const colors = theme
                                    .getIn(["colors", themeType])
                                    .map((color, index) => {
                                        return (
                                            <div key={color + index} style={{ backgroundColor: color }} />
                                        );
                                    });

                                const isActive = currentTheme === theme;
                                let themeCls = "";
                                if (isActive) {
                                    themeCls += " active";
                                }

                                themeCollections.push(
                                    <li
                                        key={theme.get("name") + theme.get("index")}
                                        className={themeCls}
                                        onClick={() => this.changeTheme(theme)}
                                    >
                                        <div className="theme-name">{theme.get("name")}</div>
                                        <div className="theme-list">{colors}</div>
                                    </li>
                                );
                            }
                        }

                        tabContent = (
                            <ul className="theme-collections">
                                {themeCollections}
                            </ul>
                        );
                    }
                }

                dropdown = (
                    <DialogBox
                        open={isThemeOpen}
                        onOutsideMouseDown={this.toggleTheme}
                        style={{
                            left: themeBtnBounds.left + "px",
                            top: themeBtnBounds.top + "px",
                            width: themeBtnBounds.width + "px",
                            height: themeBtnBounds.height + "px",
                        }}
                    >
                        <ColorThemeDropDown>
                            <ul className="themes-tab">{tabNames}</ul>
                            {tabContent}
                        </ColorThemeDropDown>
                    </DialogBox>
                );
            }

            themeBtn = (
                <>
                    <ColorThemeContainer ref={this.themeBtnRef} onClick={!isThemeOpen ? this.toggleTheme : undefined}>
                        <div className="theme-container">
                            <div className="current-theme-name">
                                <div>
                                    <FormattedMessage id={content.THEMES} />
                                </div>
                                <div>
                                    {themeName}
                                </div>
                            </div>
                            <div className="current-theme-img">
                                <img draggable={false} src={`${STATIC_PATH}color-palette.svg`} alt="theme selection" />
                            </div>
                        </div>
                    </ColorThemeContainer>
                    {dropdown}
                </>
            );
        }

        const style = {
            gridTemplateRows: "53px",
        };
        if (themeBtn) {
            style.gridTemplateRows += " 84px";
        }
        style.gridTemplateRows += " min-content 1fr";

        const colorBtns = [];
        let isDefaultColor 
        if(this.props.bgColor.get("selectedKey")){
            isDefaultColor  = this.props.bgColor.getIn(["colors", this.props.bgColor.get("selectedKey")]) === "default"
        }
        
        if (colorState.colorPart !== null) {
            for (const [colorKey, color] of selectedColors.entrySeq()) {
                const isSelected = colorKey === this.props.bgColor.get("selectedKey");
                const clrCls = isSelected ? "is-selected" : "";
                if(color !== "default"){
                    colorBtns.push(
                        <ColorButton
                            key={colorKey}
                            className={clrCls}
                            onClick={() => this.selectColor(colorKey, color)}
                            style={{ backgroundColor: color }}
                        >

                            {
                                (bgColor.get("colors").size > 1 && !isPropertyOnly(selectedItem?.get("type")) ) &&
                                <div
                                    className="remove-icon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.removeColor("remove", colorKey);
                                    }}
                                />
                            }

                        </ColorButton>
                    );
                }


            }
        }

        const canAddMoreColors = colorBtns.length > 0 && colorBtns.length < 5 && colorState.colorPart !== null && !isPropertyOnly(selectedItem?.get("type")) ;
        try {
            if (canAddMoreColors && !this.state.addNewColor) { // Adding additional colors
                colorBtns.push(
                    <ColorButton
                        key={"add"}
                        onClick={()=> this.setState({ addNewColor: true })}
                        style={{
                            backgroundImage: `url(${STATIC_PATH}add-color.svg)`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                );
            } 
        } catch (e) {
            // console.log(e);
        }
        
        if (colorBtns.length < 5 && this.state.addNewColor) {
            const isSelected = this.props.bgColor.get("selectedKey");
            const clrCls = isSelected ? "is-selected" : "";
            colorBtns.push(
                <ColorButton
                    key={1}
                    className={clrCls}
                    style={{ backgroundColor: "#ffffff", width: 50, height: 50, borderRadius: 0}}
                />
            );
        }

        let palette = null;
        if (typeof colorPart === "string" || typeof colorPart === "number") {
            let type;
            let subType;
            let id;

            if (selectedItem) {
                type = selectedItem.get("type");
                subType = selectedItem.get("subType");
                id = selectedItem.get("id");
            }

            palette = (
                <ColorPaletteWindow
                    type={type}
                    subType={subType}
                    currentColor={currentColor}
                    colorIndex={colorIndex}
                    colorKey={colorPart}
                    colorSelected={this.changeColor}
                    componentFrom="CLR_PROP_WIN"
                    itemId={id}
                    isFrameClip={isFrameClip}
                />
            );
        }

        let resetBtn = null;
        if (
            selectedItem
            && (
                selectedItem.get("type") !== "FRAME"
                || (selectedItem.get("type") === "FRAME" && isFrameClip)
            )
        ) {
            resetBtn = (
                <div className="clrp--reset" onClick={this.resetColor}>
                    <FormattedMessage id={content.RESET_CLR} />
                </div>
            );
        }

        return (
            <ColorPropertyStyled style={style}>
                <div className="clrp--header">
                    <div className="clrp--heading">
                        <FormattedMessage id={content.COLOR} />
                    </div>

                    {
                       (bgColor.get("colors").size > 1 && !isPropertyOnly(selectedItem?.get("type")) ) &&
                        <Typography
                            className={"clrdel"}
                            innerContent="Delete All"
                            color="#424156"
                            font={font.normal_15}
                            cursor={"pointer"}
                            onClick={() => this.removeColor("deleteAll")}
                            hoverColor={"#000000"}
                            enableHover={true}   
                        />
                    }
                    {resetBtn}
                </div>
                {themeBtn}
                <ColorButtonContainer style={{ paddingRight: "20px" }}>
                    {colorBtns}
                </ColorButtonContainer>
                <div className="clrp--body">
                    {palette}
                </div>
            </ColorPropertyStyled>
        );
    }
}

ColorPropertyWindowComponent.propTypes = {
    selectedItem: PropTypes.object,
    selectedFrameClip: PropTypes.object,
    bgColor: PropTypes.object,
    selectionContainer: PropTypes.string,
    applyColor: PropTypes.func,
    themes: PropTypes.object,
};

const mapStateToProps = () => {
    const memoizedFunction = createMemoizedFunction();

    return (state) => {
        const selectedItems = getSelectedItemIds({
            childrenSelection: state.app.get("childrenSelection"),
            selectedItems: state.app.get("selectedItems")
        });
        const selectionContainer = getSelectionContainer({
            childrenSelection: state.app.get("childrenSelection"),
            selectedItems: state.app.get("selectedItems"),
        });
        let selectedItem = null;
        if (selectedItems.size) {
            selectedItem = state.projectDetails.getIn([selectionContainer, selectedItems.first()]);
        }

        selectedItem = memoizedFunction(() => {
            if (selectedItem && selectedItem.get("isSingleClipFrame")) {
                selectedItem = singleClipFrameToFrame(selectedItem);
            }
            return selectedItem;
        }, [selectedItem]);

        return {
            themes: state.app.get("themes"),
            selectedItem,
            selectionContainer,
            bgColor: state.projectDetails.get("bgColor"),
            selectedFrameClip: state.app.get("selectedFrameClip"),
        };
    };
};

const mapDispatchToProps = (dispatch) => ({
    applyColor: (data) => dispatch(applyColor(data)),
});

const ColorPropertyWindow = connect(mapStateToProps, mapDispatchToProps)(ColorPropertyWindowComponent);

export default ColorPropertyWindow;
