import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { STATIC_PATH } from "../constants/config";
import { font } from "../constants/font";
import Typography from "./Typography";
import vmTheme from "../constants/theme";


const DropDownWrapper = styled.div`
  position: relative;
  .downarrow {
    cursor: pointer;
    &::before {
      content: url(${(props) => props.url});
      position: absolute;
      right: -3px;
      top: 5px;
      padding: 2px;
      width: 30px;
      text-align: center;
      border-radius: 0px 8px 0px 0px;
    }
  }
`;

const DropDownInput = styled.input`
  width: 51px;
  height: 32px;
  padding: 8px;
  font-size: 16px;
  color: #54536c;
  font: ${font.normalMini_14};
  background: #ffffff;
  border: 1px solid ${({ isOpen }) => (isOpen ? "#00BAFF" : "#d5dadb")};
  border-radius: 8px;
  cursor: pointer;
`;

const Option = styled.div`
  color: #46445d;
  padding: 5px 10px 5px 10px;
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
  font: ${font.normalBase};
  position: relative;
  
  &:hover {
    background: #e6e6e6;
  }
    ${(props) =>
    props.selectedOption &&
    css`
      &:after {
        content: url(${STATIC_PATH}tick-icon.svg);
        position: absolute;
        right: 12px;
        top: 8px;
      }
    `}
`;

const OptionWrapper = styled.ul`
  list-style: none;
  margin-top: 5px;
  padding: 0px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px #dddddd;
  width: 148px;
  position: absolute;
  text-align: left;
  right: -17px;
  height: 188px;
  border-radius: 6px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 7px #00000014;
  border: 1px solid #E8EAEB;
  overflow-y: auto;
  z-index: 999;
  display: flex;
  top: 28px;
  flex-direction: column;
  justify-content: center;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ArrowButton = styled.div``;


const SelectableDropdown = ({ onAction, dropDownValue, defaultValue }) => {

  const options = dropDownValue.map((month) => ({ label: month, value: month }));
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const [dropdownTop, setDropdownTop] = useState("");
  const theme = useSelector((state) => state.app.get("theme"));


  useEffect(() => {
    onAction(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const onOptionClick = useCallback((month) => {
    setSelectedValue(month.value);
    setIsOpen(false);
  }, []);

  const onDropdownClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (buttonRef.current) {
      setDropdownTop(`${buttonRef.current.getBoundingClientRect().height}px`);
    }
  }, [isOpen]);

  return (
    <DropDownWrapper url={`${STATIC_PATH}${vmTheme[theme].icons.timelineMinimize}`}>
      <DropDownInput
        isOpen={isOpen}
        type="text"
        onClick={onDropdownClick}
        value={selectedValue}
        readOnly
      />
      <ArrowButton
        className="downarrow"
        direction="down"
        onClick={onDropdownClick}
      />
      {isOpen && (
        <>
          <div role="button" tabIndex={0} aria-label="Close" onClick={() => setIsOpen(false)} style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 111 }} />
          <OptionWrapper style={{ top: dropdownTop, position: "absolute" }}>
            {options.map((option) => (
              <Option
                key={option.value}
                onClick={() => onOptionClick(option)}
                selectedOption={selectedValue === option.value}
              >
                <Typography font={selectedValue === option.value ? font.boldBase : font.normal_21} content={option.label} cursor="pointer" padding="1px" />
              </Option>
            ))}
          </OptionWrapper>
        </>
      )}
    </DropDownWrapper>
  );
};

SelectableDropdown.propTypes = {
  onAction: PropTypes.func,
  dropDownValue: PropTypes.array,
  defaultValue: PropTypes.string
};

export default SelectableDropdown;
