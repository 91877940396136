
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isPaidUser } from "../checkUser";

const useWatermark = () => {
    const [isShow, setIsShow] = useState(false);
    const { plan } = useSelector((state) => state.userDetails);
    const workspaceItems = useSelector((state) => state.projectDetails.get("workspaceItems"));
  
    useEffect(() => {
      const hasAvatar = workspaceItems
        .some((item) => (item.get('subType') === "AVATAR" || item.get('id').indexOf('avatar') > -1));
        
      setIsShow(!isPaidUser(plan) && hasAvatar);
    }, [plan, workspaceItems]);
  
    return isShow;
  };

  export default useWatermark;