import styled from "styled-components";
import { STATIC_PATH } from "../../../../constants/config";

export const TextAnimationContainer = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  border-radius: 6px;
  background: ${(props) => props.theme.groupBtnDropshadow};
  padding: 2px;
  position: relative;
  margin: 0px 0px 0px 17px;
  @media screen and (max-width: 767px){
    width: auto;
  }
`;

export const AnimationContainer = styled.div`
  z-index: 8;
  .group-btn {
    transition: all 0.3s;
    &:hover {
      transform: ${(props) => (props.disableTab ? 'scale(1)' : 'scale(1.01)')};
      font-weight: ${(props) => (props.disableTab ? 400 :600)};
      border-color: transparent;
      label {
        color: ${(props) => (props.disableTab ? props.theme.primaryColor : props.theme.secondaryBorderColor)};

      }
    }
    &::before {
      content: "";
      background-image: ${(props) =>
        props.selectedIcon
          ? `url(${STATIC_PATH}${props.selectedIcon})`
          : `url(${STATIC_PATH}effects-default.svg)`};
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: center center;
      position: absolute;
      left: 30px;
      top: 6px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      overflow: hidden;
    }
    @media screen and (max-width: 767px){
    margin-left: 15px;
  }
  }
`;

export const AnimWrapper = styled.div`
  padding-bottom: 15px;
`;

export const InnerAction = styled.div`
  position: absolute;
  top: 1.5px;
  left: 2px;
  height: 34px;
  width: 92px;
  z-index: 0;
  width: 152px;
  background-color: ${(props) => props.theme.polarColor};
  box-shadow: ${(props) => `0px 1px 3px ${props.theme.boxShadowBgColor}`};
  border-radius: 6px;
  transform: ${(props) =>
    props.activeTab ? "translateX(157px)" : "translateX(0px)"};
  transition: transform 0.3s linear;
  @media screen and (max-width: 767px){
    width: 50%;
    transform: ${(props) =>
    props.activeTab ? "translateX(98%)" : "translateX(0px)"};

  }
`;
InnerAction.displayName = "InnerAction";