import React from "react";
import PropTypes from "prop-types";
import { PANEL } from "../../constants";
import {
  ImagePanelLoadable,
  MusicPanelLoadable,
  PropertyPanelLoadable,
  TextPanelLoadable,
  // TransitionPanelLoadable,
  UploadPanelLoadable,
  VideoPanelLoadable,
  VersionHistoryLoadable,
  FavoritesPanelLoadable,
  GiphyPanelLoadable,
  SubtitlePanelLoadable,
  TransitionPanelLoadable,
  UnsplashPanelLoadable,
  PexelsPanelLoadable,
  PexelsVideoPanelLoadable,
  PixabayPanelLoadable,
  PixabayVideoPanelLoadable,
  MorePanelLoadable,
  AvatarPanelLoadable
} from "../../helper/loadableComponent";
import Placeholder from "../../common-components/Placeholder";

const {
  PROPERTIES,
  VIDEO,
  IMAGE,
  UPLOAD,
  TEXT,
  MUSIC,
  FAVORITES,
  VERSION_HISTORY,
  GIPHY,
  TRANSITIONS,
  SUBTITLE,
  UNSPLASH,
  PEXELS,
  PEXELS_VIDEO,
  PIXABAY,
  PIXABAY_VIDEO,
  MORE,
  AVATAR
} = PANEL;

const Panel = ({ selectedMenu }) => {
  switch (selectedMenu) {
    case PROPERTIES:
      return <PropertyPanelLoadable />;
    case VIDEO:
      return <VideoPanelLoadable />;
    case IMAGE:
      return <ImagePanelLoadable />;
    case UPLOAD:
      return <UploadPanelLoadable />;
    case TEXT:
      return <TextPanelLoadable />;
    case MUSIC:
      return <MusicPanelLoadable />;
    case FAVORITES:
      return <FavoritesPanelLoadable />;
    case GIPHY:
      return <GiphyPanelLoadable />;
    case TRANSITIONS:
      return <TransitionPanelLoadable />;
    case VERSION_HISTORY:
      return <VersionHistoryLoadable />;
    case SUBTITLE:
      return <SubtitlePanelLoadable />;
    case UNSPLASH:
      return <UnsplashPanelLoadable />;
    case PEXELS:
      return <PexelsPanelLoadable />;
    case PEXELS_VIDEO:
      return <PexelsVideoPanelLoadable />;
    case PIXABAY:
      return <PixabayPanelLoadable />;
    case PIXABAY_VIDEO:
      return <PixabayVideoPanelLoadable />;
    case MORE:
      return <MorePanelLoadable />;
    case AVATAR: 
      return <AvatarPanelLoadable />;
    default:
      return <Placeholder />;
  }
};

Panel.propTypes = {
  selectedMenu: PropTypes.string,
};

export default Panel;
