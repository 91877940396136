import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography from "./Typography";
import BreadCrumbTooltip from "./BreadCrumbTooltip";

const SectionWrapper = styled.div`
  position: relative;
  top: ${(props) => props.dotMenuTop};
`;

const FolderLinks = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  background: transparent;
  margin: ${(props) => (props.margin ? "16px 24px 10px" : "unset")};
  .breadCrumbIcon.folderAnchor {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    .firstIndex {
      &:first-child {
        display: none;
      }
    }
  }
  .folderAnchor {
    position: relative;
    padding-right: 20px;
    display: block;
    height: ${(props) => (props.height ? props.height : "20px")};
    &:after {
      content: "";
      position: absolute;
      right: 8px;
      z-index: 11;
      display: block;
      width: 5px;
      height: 5px;
      top: ${(props) => (props.top ? props.top : "4px")};
      border-bottom: 1px solid #8796c1;
      border-right: 1px solid #8796c1;
      transform: rotate(-45deg);
    }
    &:last-child() {
      &:after {
        content: "";
      }
    }
  }
  svg {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;

const BreadCrumb = ({
  data = [],
  collapseCount,
  onAction,
  font,
  height,
  top,
  disableNavigation = false,
  width = "fit-content",
  dotMenuTop = "8px",
  maxWidth,
  margin,
  icon,
  searchValue,
}) => {
  const [maskedFolderList, setMaskedFolderList] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (searchValue) {
      const folderData = data.filter((e) => e.folder_id === "0");
      setFolderList(folderData);
    } else if (data.length >= collapseCount) {
      const maskedArray = data.splice(1, data.length - collapseCount);
      setMaskedFolderList(maskedArray);
      setFolderList(data);
    } else if (data.length) {
      setFolderList(data);
      setMaskedFolderList([]);
    }
  }, [data, collapseCount, searchValue]);

  const navigate = useCallback((item) => {
    if (item.url && !disableNavigation) {
      history.push(item.url);
    } else {
      onAction && onAction(item);
    }
  }, [disableNavigation, history, onAction]);

  return (
    <FolderLinks
      height={height}
      top={top}
      width={width}
      margin={margin}
      className="breadcrumb"
    >
      {folderList.map((item, index) => {
        return (
          <>
            <Typography
              key={item.name}
              font={font}
              content={item.name}
              display="block"
              cursor="pointer"
              padding="0 0 4px 0"
              maxWidth={maxWidth}
              margin="8px 0 8px 0"
              enableTrim={true}
              className={`folderAnchor ${index === 0 && "firstIndex"}`}
              onClick={() => navigate(item)}
              color={folderList.length - 1 === index ? "#424156" : "#8796C1"}
            />
            {index === 0 && (
              <span className="breadCrumbIcon folderAnchor">
                <Icon src={icon} alt="icon" onClick={() => navigate(item)} />
              </span>
            )}
            {maskedFolderList.length && index === 0 ? (
              <SectionWrapper dotMenuTop={dotMenuTop}>
                <BreadCrumbTooltip
                  options={maskedFolderList}
                  onAction={navigate}
                />
              </SectionWrapper>
            ) : null}
          </>
        );
      })}
    </FolderLinks>
  );
};

BreadCrumb.propTypes = {
  data: PropTypes.array,
  collapseCount: PropTypes.number,
  onAction: PropTypes.func,
  font: PropTypes.string,
  height: PropTypes.string,
  top: PropTypes.string,
  disableNavigation: PropTypes.bool,
  width: PropTypes.string,
  dotMenuTop: PropTypes.string,
  maxWidth: PropTypes.string,
  icon: PropTypes.string,
  margin: PropTypes.bool,
  searchValue: PropTypes.bool,
};

export default BreadCrumb;