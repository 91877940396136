/* eslint-disable react/sort-comp */

import React, { createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CanvasImage from "../player/canvas-image";

const loadedImageCache = new Set();

export class ProgressiveImageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: true, loadingImg: null };
    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
    this.loadImg = this.loadImg.bind(this);

    if (loadedImageCache.has(props.src)) {
      this.state.isLoading = false;
      this.state.loadingImg = { src: props.src };
    }
    this.imageRef = createRef(null);
  }

  componentDidMount() {
    const { isLoading } = this.state;
    if (isLoading) {
      this.loadImg(this.props.src);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPlayAll !== prevProps.isPlayAll && this.props.isPlayAll) {
      // load anyway even if its already loaded
      this.setState({ isLoading: true }, () => {
        this.loadImg(this.props.src);
      });
    } else if (this.props.src !== prevProps.src) {
      if (loadedImageCache.has(this.props.src)) {
        this.setState({
          isLoading: false,
          loadingImg: { src: this.props.src },
        });
      } else {
        this.setState({ isLoading: true }, () => {
          this.loadImg(this.props.src);
        });
      }
    }
  }

  componentWillUnmount() {
    const { loadingImg } = this.state;
    if (loadingImg) {
      loadingImg.onload = null;
      loadingImg.onerror = null;
    }
  }

  onLoad(loadEvent) {
    if (this.state.loadingImg === loadEvent.target) {
      this.setState({ isLoading: false });
      loadedImageCache.add(this.state.loadingImg.src);
    }
  }

  onError(errorEvent) {
    if (this.state.loadingImg === errorEvent.target) {
      this.setState({ isLoading: false });
      if (typeof this.props.onError === "function") {
        // to handle asset delete if needed
        this.props.onError(errorEvent);
      }
    }
  }

  loadImg(src) {
    if (this.state.loadingImg) {
      const { loadingImg } = this.state;
      // here we can directly unassign callbacks since we are not dependent on these
      loadingImg.onload = null;
      loadingImg.onerror = null;
    }

    const image = new Image();
    image.onload = this.onLoad;
    image.onerror = this.onError;
    image.src = src;
    this.setState({ loadingImg: image });
  }

  filterImageProps = () => {
    return {
      style: this.props.style,
      alt: this.props.alt,
      src: this.props.src,
      className: this.props.className,
    };
  };

  render() {
    let imgToReturn = null;
    const { loaderSrc, customLoader } = this.props;
    const imgProps = this.filterImageProps();

    if (this.state.isLoading) {
      if (loaderSrc) {
        let { className = "" } = this.props;
        className = `${className} progressive-img-loader`;
        imgToReturn = (
          <img
            alt="progressive-img-loader"
            draggable={false}
            className={className}
            src={loaderSrc}
          />
        );
      } else if (customLoader) {
        imgToReturn = customLoader;
      }
    } else if (this.state.loadingImg) {
      imgToReturn = this.props.chromaKey ? (
        <CanvasImage
          className={imgProps.className}
          style={imgProps.style}
          src={imgProps.src}
          alt={imgProps.alt}
          chromaKey={this.props.chromaKey}
          tolerance={this.props.tolerance}
          isBackgroundRemoval={false}
          videoRef={this.imageRef}
        />
      ) : (
        <img
          alt=""
          draggable={false}
          {...imgProps}
          src={this.state.loadingImg.src}
          onLoad={undefined}
          onError={undefined}
        />
      );
    }

    return imgToReturn;
  }
}

ProgressiveImageComponent.propTypes = {
  onError: PropTypes.func,
  src: PropTypes.string.isRequired,
  loaderSrc: PropTypes.string,
  className: PropTypes.string,
  customLoader: PropTypes.object,
  isPlayAll: PropTypes.bool,
  style: PropTypes.object,
  alt: PropTypes.string,
  chromaKey: PropTypes.bool,
  tolerance: PropTypes.number,
};

const mapStateToProps = (state) => ({
  isPlayAll: state.app.get("isPlayAll"),
});

const mapDispatchToProps = () => ({});

const ProgressiveImage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressiveImageComponent);

export default ProgressiveImage;
