import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Draggable from "react-draggable";
import { STATIC_PATH } from "../constants/config";
import { PORTAL_ID } from "../constants/index";
import { useCustomCallback } from "../helper/hooks/useCustomCallback";

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 0% 0% no-repeat padding-box padding-box
    ${(props) => props.theme.overlayBgColor};
  z-index: 9850;
  display: ${(props) => props.display};
`;

const Overlay = styled.div`
  z-index: 50;
  width: ${(props) => props.width || "384px"};
  height: ${(props) => props.height || "auto"};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  background: ${(props) => props.theme.polarColor} 0% 0% no-repeat padding-box;
  border-radius: ${(props) => props.borderRadius || "8px"};
  transform: translate(-50%, -50%) !important;
  @media (max-width: 768px) {
    max-width: calc(100% - 20px);
    width: 100%;
    * {
        touch-action: auto;
        -webkit-overflow-scrolling: touch;
      }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    overflow: ${props => props.smOverflow || "auto"};;
    height: ${props => props.smHeight || "auto"};
    * {
        touch-action: auto;
        -webkit-overflow-scrolling: touch;
      }
  }
`;

// const Wrapper = styled.div`
//   padding: 16px 16px 6px 16px;
//   input {
//     width: "100%";
//   }
// `;

const CloseIcon = styled.img`
  width: 17px;
  height: 17px;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  z-index: 99999;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 767px) {
      right: 2%;
      top: 2%;
   }
`;

export const Modal = ({
  children,
  customStyles,
  isDisabled = true,
  showModal = false,
  width,
  height,
  smHeight,
  smOverflow,
  borderRadius,
  preventClose = false,
  onClose = () => {},
  isRender = false
}) => {
  const _onKeyDown = (e) => {
    if (e.keyCode === 27 && !preventClose) {
      onClose();
    }
  };
  const onKeyDown = useCustomCallback({ memoizedCallback: _onKeyDown });

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  const modalContent = (showModal || isRender) && (
    <OverlayContainer display={showModal ? "flex" : "none"}>
      <Draggable disabled={isDisabled}>
        <Overlay
          style={customStyles}
          width={width}
          height={height}
          smHeight={smHeight}
          smOverflow={smOverflow}
          borderRadius={borderRadius}
        >
          {!preventClose && (
            <CloseIcon
              src={`${STATIC_PATH}close-icon.svg`}
              alt="close"
              onClick={onClose}
              draggable={false}
            />
          )}
          {children}
        </Overlay>
      </Draggable>
    </OverlayContainer>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById(PORTAL_ID)
  );
};

Modal.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  smOverflow: PropTypes.string,
  smHeight: PropTypes.string,
  borderRadius: PropTypes.string,
  preventClose: PropTypes.bool,
};
