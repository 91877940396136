
const getDeviceType = () => {
    const {userAgent} = navigator;

    if (/Mobi|Android|iPhone|iPad|iPod/i.test(userAgent)) {
        if (/Tablet|iPad/i.test(userAgent)) {
            return "Tablet";
        }
        return "Mobile";
    }
    return "Desktop";
};
const getDeviceDetails = () => {
    return {
        userAgent: navigator.userAgent,  // Full user agent string
        platform: navigator.platform,    // Operating system
        language: navigator.language,    // Preferred language
        screenWidth: window.screen.width,       // Screen width
        screenHeight: window.screen.height,     // Screen height
        deviceMemory: navigator.deviceMemory || 'Unknown',  // Device memory (some browsers support this)
        hardwareConcurrency: navigator.hardwareConcurrency || 'Unknown',  // Number of CPU cores,
        deviceType: getDeviceType()
    };
};

export const isIOS = () => /iPhone|iPad|iPod/.test(navigator.userAgent);

export default getDeviceDetails;
