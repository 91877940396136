/* eslint-disable camelcase */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getWorkspaceItemSource } from "../../helper/URLHelper";
import { getFlipPosition } from "./workspace-helper";
import {
  ProgressiveWorkspaceImage,
  Vignette,
  WorkspaceItemContainer,
  WorkspaceVideo,
} from "./workspace-components";
import { isImageOnly, isUpImageSVG, isVideoOnly } from "../timeline/timeline-helper";
import FilterItem from "./workspace-filter";
import CustomSVGRender from "./workspace-customsvgrender";
import Text from "../text/text";
import Frames from "../frame/frame-frames";
import Callout from "../callout/callout";
import { getRadiusClipPath } from "../../helper/TransformManagerHelper";
import SwapDropArea from "./workspace-swaphoverdrop";
import { ANIMO_RENDER, ITEM_CONFIG } from "../../constants/config";
import { canSwapByDnD } from "../panels/library-helper";
import SpecialTextWrapper from "../text/special-text-wrapper";
import { singleClipFrameToFrame } from "../frame/frame-helper";

const WorkspaceItem = (props) => {
  const orgItem = props.item;
  const item = useMemo(() => {
    let item = orgItem;
    if (item.get("isSingleClipFrame")) {
      item = singleClipFrameToFrame(item);
    }
    return item;
  }, [orgItem]);

  const {
    className,
    opacity = item.get("opacity"),
    pointerEvents,
    groupItem,
    projectWidth,
    projectHeight,
    id,
    groupId,
    browserName,
    isVisible,
    zoomFactor,
    selectedItems,
    isPlayAll,
    setPlayAll,
    addTween,
    swapDetails,
    prefetchToken,
    prefetchComplete,
    isMouseOnSelectionBox,
    libraryDragItem,
    onPointerDown,
    onPointerUp,
    onContextMenu,
  } = props;

  const { animsrc, src, thumb, fallbackSrc } = getWorkspaceItemSource({
    item: {
      isBlob: item.get("isBlob"),
      bgremoval: item.get("bgremoval"),
      src: item.get("src"),
      animsrc: item.get("animsrc"),
      stickerify: item.get("stickerify"),
      subType: item.get("subType"),
      thumb: item.get("thumb"),
      thumbnail: item.get("thumbnail"),
      type: item.get("type"),
    },
    workspaceHeight: projectHeight,
    workspaceWidth: projectWidth,
  });

  const itemPosition = {
    x: item.get("x"),
    y: item.get("y"),
  };
  if (groupItem) {
    // if this is a child of a group
    itemPosition.x = item.get("x") - groupItem.get("x");
    itemPosition.y = item.get("y") - groupItem.get("y");
  }

  const flip = getFlipPosition(item.get("flipPosition"));
  const angle = item.get("angle") !== undefined ? item.get("angle") : 0;
  let clipPath;
  if (item.get("radius")) {
    clipPath = getRadiusClipPath(item, zoomFactor);
    clipPath = `path("${clipPath}")`;
  }
  const itemStyles = {
    transform: `translate(${itemPosition.x * zoomFactor}px, ${itemPosition.y * zoomFactor}px) rotate(${angle}deg) ${flip}`,
    opacity: isVisible ? opacity : 0,
    width: `${item.get("width") * zoomFactor}px`,
    height: `${item.get("height") * zoomFactor}px`,
    index: item.get("track"),
    pointerEvents,
    clipPath,
  };

  // To bring swap item to top layer.
  if (item.get("id") === swapDetails?.get("id")) {
    itemStyles.zIndex = 1;
  }

  if (groupId) {
    // apply actual opacity for children regardless of playhead position
    itemStyles.opacity = opacity;
  }

  const isImage = isImageOnly(item.get("type"), item.get("subType"));
  const isUploadedSVGWithColor = isUpImageSVG(item);

  let wsItem = null;
  let filter = null;

  if (item.get("type") === "GROUP") {
    wsItem = props.children;
    itemStyles.overflow = "visible";
  } else if (item.get("type") === "SHAPE") {
    itemStyles.overflow = "visible";
    itemStyles.willChange = "transform, opacity";

    let itemId = null;
    let data_id = null;

    if (groupId) {
      itemId = id;
      data_id = groupId;
    } else {
      data_id = id;
    }

    wsItem = (
      <Callout
        key={id}
        zoomFactor={zoomFactor}
        isMouseOnSelectionBox={isMouseOnSelectionBox}
        data={item}
        data_id={data_id}
        itemId={itemId}
        isGrouped={groupId !== undefined}
        isPlay={false}
        isPlayAll={isPlayAll}
        setPlayAll={setPlayAll}
        workspaceWidth={projectWidth * zoomFactor}
        workspaceHeight={projectHeight * zoomFactor}
        selectedItems={selectedItems}
        prefetchToken={prefetchToken}
        prefetchComplete={prefetchComplete}
      />
    );
  } else if (
    (isImage && !isUploadedSVGWithColor) ||
    isVideoOnly(item.get("type"), item.get("subType"))
  ) {
    const imgStyles = {};

    if (item.get("filter")) {
      const filterCode = item.get("filter");
      const vignetteValue = parseInt(filterCode.substr(12, 2), 16);
      const vignetteId = `scene-item-vignette-${id}`;
      const svgId = `scene-item-filtersvg-${id}`;
      const filterId = `${svgId}-filter`;

      filter = (
        <>
          <Vignette
            id={vignetteId}
            style={{
              "--vignette": vignetteValue * 0.007,
            }}
          />
          <FilterItem
            svgId={svgId}
            filterId={filterId}
            browserName={browserName}
            filterCode={filterCode}
          />
        </>
      );
      imgStyles.filter = `url(#${filterId})`;
    }

    if (item.get("original") && item.get("isCropped")) {
      imgStyles.width = `${item.getIn(["original", "width"]) * item.get("width") * zoomFactor}px`;
      imgStyles.height = `${item.getIn(["original", "height"]) * item.get("height") * zoomFactor}px`;
      imgStyles.left = `${item.getIn(["original", "x"]) * item.get("width") * zoomFactor}px`;
      imgStyles.top = `${item.getIn(["original", "y"]) * item.get("height") * zoomFactor}px`;
    }

    if (isImage) {
      const useDefaultLoaderStyle =
        item.get("stickerify") || item.get("bgremoval");

      wsItem = (
        <ProgressiveWorkspaceImage
          style={imgStyles}
          src={src}
          className={useDefaultLoaderStyle ? "default-loader-style" : ""}
          loaderSrc={thumb}
          chromaKey={item.get("chromaKey")}
          tolerance={item.get("tolerance")}
        />
      );
    } else if (isVideoOnly(item.get("type"), item.get("subType"))) {
      itemStyles.willChange = "transform";
      wsItem = (
        <WorkspaceVideo
          from="workspace"
          id={id}
          style={imgStyles}
          src={src}
          fallbackSrc={fallbackSrc}
          poster={thumb}
          speed={item.get("speed")}
          playStart={item.get("enterStart")}
          playEnd={item.get("exitEnd")}
          mediaStart={item.get("videoStart")}
          mediaEnd={item.get("videoEnd")}
          mediaType={"video"}
          volume={item.get("volume")}
          item={item}
          isBackgroundRemoval={item.get("enableBgRemoval")}
          chromaKey={item.get("chromaKey")}
          tolerance={item.get("tolerance")}
          isAvatar={item.get("isAvatar")}
          allowCustomCaching={item.get("type") !== "PIXABAY"}
          isBlob={item.get("isBlob")}
        />
      );
    }
  } else if (
    isUploadedSVGWithColor ||
    item.get("type") === "PROP" ||
    item.get("type") === "SCR"
  ) {
    wsItem = (
      <CustomSVGRender
        isAnimated={item.get("isAnimated")}
        selectedItems={selectedItems}
        data_id={id}
        assetType={item.get("type")}
        assetSubType={item.get("subType")}
        prefetchToken={prefetchToken}
        prefetchComplete={prefetchComplete}
        isPlay={false}
        isPlayAll={isPlayAll}
        setPlayAll={setPlayAll}
        uploadSVG={isUploadedSVGWithColor}
        colors={item.get("colors")}
        isBG={false}
        isKeyframe={false}
        animdata={item.get("animdata")}
        isRepeated={item.get("isRepeated")}
        repeatLoop={item.get("repeatLoop")}
        sceneStart={0}
        completeWorkspaceItems={item}
        addTween={addTween}
        workspaceWidth={projectWidth * zoomFactor}
        workspaceHeight={projectHeight * zoomFactor}
        src={src}
        animsrc={animsrc}
        swapDetails={swapDetails}
      />
    );
  } else if (item.get("type") === "TEXT") {
    itemStyles.overflow = "visible";
    itemStyles.willChange = "transform, opacity";

    let itemId = null;
    let data_id = null;

    if (groupId) {
      itemId = id;
      data_id = groupId;
    } else {
      data_id = id;
    }

    const EFF_WITH_SPLIT_ANIM = ["lineSideZigZag", "lineSide", "textRevealWordbyWord", "textPopupWordbyWord", "textColorHighlight", "textFloatUp", "textFloatDown", "textAlphaFadeIn", "textAlphaFadeOut", "textDropIn", "textHighlight", "textReveal", "bounceWords", "lettersRise", "pulseWords", "shiftWords", "stompWords", "bounceLetters", "skateLetters", "skateWords", "handWriteAsian", "handWriteIndian", "handWriteAfrican", "handWriteAsian1", "handWriteIndian1", "handWriteAfrican1", "textWrite"]
    const EFF_WITH_MASK_ANIM = ["pan", "emerge", "slideOpen"]

    const isSplitAnim = EFF_WITH_SPLIT_ANIM.includes(item.get("enterEffectName")) || EFF_WITH_SPLIT_ANIM.includes(item.get("exitEffectName"))
    const isMaskAnim = EFF_WITH_MASK_ANIM.includes(item.get("enterEffectName")) || EFF_WITH_MASK_ANIM.includes(item.get("exitEffectName"))
    const isCollideAnim = EFF_WITH_MASK_ANIM.includes(item.get("enterEffectName")) && EFF_WITH_SPLIT_ANIM.includes(item.get("exitEffectName"))

    item.get("thumbnail")
    if(isSplitAnim || isMaskAnim){
      wsItem = (
        <SpecialTextWrapper
          isSplitAnim={isSplitAnim}
          isMaskAnim={isMaskAnim}
          isCollideAnim={isCollideAnim}
          // isMouseOnSelectionBox={this.isMouseOnSelectionBox}
          text={item}
          key={id}
          linkEnabled={false}
          data_id={data_id}
          itemId={itemId}
          isGrouped={groupId !== undefined}
          // socket={this.props.socket}
          isSplitLineText={Boolean(
            item.getIn([
              "textData",
              "formats",
              "containerStyle",
              "boxDesign",
            ]) === "split"
          )}
          isPlay={false}
          isPlayAll={isPlayAll}
          zoomFactor={zoomFactor}
        />
      );
    }else {
      wsItem = (
        <Text
          key={id}
          isMouseOnSelectionBox={isMouseOnSelectionBox}
          text={item}
          data_id={data_id}
          itemId={itemId}
          isGrouped={groupId !== undefined}
        // socket={this.props.socket}
        />
      );
    }

  } else if (item.get("type") === "FRAME") {
    wsItem = (
      <Frames
        key={id}
        isFrameVisible={isVisible}
        id={id}
        frameItem={item}
        grouped={groupId !== undefined}
        groupId={groupId}
        isPlay={false}
        isPlayAll={isPlayAll}
      // socket={this.props.socket}
      />
    );
  }

  let swapDrop = null;
  if (
    !groupId // not child
    && item.get("type") !== "GROUP" // not group item
    && libraryDragItem.get("data") // library item is being dragged
    && canSwapByDnD(libraryDragItem.get("data"), item) // can swap library item with current workspace item
  ) {
    swapDrop = (
      <SwapDropArea type={ITEM_CONFIG.SWAP_TARGETS.WORKSPACE} item={item} />
    );
  }

  wsItem = (
    <div id={`${id}animo`} style={{
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      opacity: ANIMO_RENDER ? 0 : opacity
    }}>
      {wsItem}
    </div>
  )

  return (
    <WorkspaceItemContainer
      style={itemStyles}
      className={`scene-item ${className}`}
      id={id}
      data-id={id}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onContextMenu={onContextMenu}
    >
      {swapDrop}
      {wsItem}
      {filter}
    </WorkspaceItemContainer>
  );
};

WorkspaceItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  groupItem: PropTypes.object,
  projectWidth: PropTypes.number,
  projectHeight: PropTypes.number,
  id: PropTypes.string,
  groupId: PropTypes.string,
  browserName: PropTypes.string,
  children: PropTypes.object,
  isVisible: PropTypes.bool,
  zoomFactor: PropTypes.number,
  selectedItems: PropTypes.object,
  isPlayAll: PropTypes.bool,
  setPlayAll: PropTypes.func,
  addTween: PropTypes.func,
  swapDetails: PropTypes.object,
  prefetchToken: PropTypes.string,
  prefetchComplete: PropTypes.func,
  onPointerDown: PropTypes.func,
  onPointerUp: PropTypes.func,
  onContextMenu: PropTypes.func,
  isMouseOnSelectionBox: PropTypes.func,
  libraryDragItem: PropTypes.object,
  opacity: PropTypes.number,
  pointerEvents: PropTypes.string,
};

export default WorkspaceItem;
