/* eslint-disable jsx-a11y/media-has-caption */

import React from "react";
import PropTypes from "prop-types";
import {
  ProgressiveWorkspaceImage,
  Vignette,
  WorkspaceBGContainer,
} from "./workspace-components";
import { isImageOnly, isVideoOnly } from "../timeline/timeline-helper";
import { getWorkspaceItemSource } from "../../helper/URLHelper";
import CustomBGRender from "./workspace-custombgrender";
import { getFlipPosition } from "./workspace-helper";
import FilterItem from "./workspace-filter";
import Media from "../player/player-media";

const WorkspaceBG = (props) => {
  const {
    workspaceBG,
    zoomFactor,
    projectHeight,
    projectWidth,
    browserName,
    isVisible,
    onContextMenu,
  } = props;

  const { src, thumb, fallbackSrc } = getWorkspaceItemSource({
    item: {
      isBlob: workspaceBG.get("isBlob"),
      bgremoval: workspaceBG.get("bgremoval"),
      src: workspaceBG.get("src"),
      stickerify: workspaceBG.get("stickerify"),
      subType: workspaceBG.get("subType"),
      thumb: workspaceBG.get("thumb"),
      thumbnail: workspaceBG.get("thumbnail"),
      type: workspaceBG.get("type"),
    },
    workspaceHeight: projectHeight,
    workspaceWidth: projectWidth,
  });

  let innerEl = null;
  if (isImageOnly(workspaceBG.get("type"), workspaceBG.get("subType"))) {
    const useDefaultLoaderStyle =
      workspaceBG.get("stickerify") || workspaceBG.get("bgremoval");
    innerEl = (
      <ProgressiveWorkspaceImage
        src={src}
        className={useDefaultLoaderStyle ? "default-loader-style" : ""}
        loaderSrc={thumb}
      />
    );
  } else if (isVideoOnly(workspaceBG.get("type"), workspaceBG.get("subType"))) {
    innerEl = (
      <Media
        from="workspace"
        id={workspaceBG.get("id")}
        src={src}
        fallbackSrc={fallbackSrc}
        poster={thumb}
        playStart={workspaceBG.get("playStart")}
        playEnd={workspaceBG.get("playEnd")}
        mediaStart={workspaceBG.get("videoStart")}
        mediaEnd={workspaceBG.get("videoEnd")}
        mediaType={"video"}
        volume={workspaceBG.get("volume")}
        allowCustomCaching={workspaceBG.get("type") !== "PIXABAY"}
      />
    );
  } else if (
    workspaceBG.get("type") === "BG" &&
    workspaceBG.get("subType") !== "OBGVID" &&
    workspaceBG.get("subType") !== "OBGIMG"
  ) {
    innerEl = (
      <CustomBGRender
        key={workspaceBG.get("id") + workspaceBG.get("assetId")}
        colors={workspaceBG.get("bgColor")}
        proxyImg={thumb}
        data_id={workspaceBG.get("id")}
        src={src}
        hasGradient={workspaceBG.get("hasGradient")}
      />
    );
  }

  const flip = getFlipPosition(workspaceBG.get("flipPosition"));
  const bgStyles = {
    width: `${projectWidth * workspaceBG.get("width") * zoomFactor}px`,
    height: `${projectHeight * workspaceBG.get("height") * zoomFactor}px`,
    transform: `translate(${
      projectWidth * workspaceBG.get("x") * zoomFactor
    }px, ${
      projectHeight * workspaceBG.get("y") * zoomFactor
    }px) rotate(${workspaceBG.get("angle")}deg) ${flip}`,
    opacity: isVisible ? 1 : 0,
  };

  let filter = null;
  if (workspaceBG.get("filter")) {
    const filterCode = workspaceBG.get("filter");
    const vignetteValue = parseInt(filterCode.substr(12, 2), 16);
    const svgId = `workspace-bg-filtersvg-${workspaceBG.get("id")}`;
    const filterId = `${svgId}-filter`;
    filter = (
      <>
        <Vignette
          style={{
            "--vignette": vignetteValue * 0.007,
          }}
        />
        <FilterItem
          svgId={svgId}
          filterId={filterId}
          browserName={browserName}
          filterCode={filterCode}
        />
      </>
    );

    bgStyles.filter = `url(#${filterId})`;
  }

  return (
    <WorkspaceBGContainer>
      <div
        id={workspaceBG.get("id")}
        data-id={workspaceBG.get("id")}
        style={bgStyles}
        onContextMenu={onContextMenu}
      >
        {innerEl}
        {filter}
      </div>
    </WorkspaceBGContainer>
  );
};

WorkspaceBG.propTypes = {
  workspaceBG: PropTypes.object.isRequired,
  projectWidth: PropTypes.number.isRequired,
  projectHeight: PropTypes.number.isRequired,
  zoomFactor: PropTypes.number.isRequired,
  browserName: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  onContextMenu: PropTypes.func,
};

export default WorkspaceBG;
