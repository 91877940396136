/**
 * Determines the favorite library type based on the given type string.
 *
 * @param {string} type - The input type (e.g., "giphy-image", "pexel-video").
 * @returns {string} - Returns "image" if type contains "image",
 *                     "video" if type contains "video",
 *                     "prop" if type contains "property",
 *                     or the original type if no match is found.
 */

export const getFavouriteLibraryType = (type) => {
    if (type.includes("image")) return "image";
    if (type.includes("video")) return "video";
    if (type.includes("music")) return "music";
    if (type.includes("property")) return "prop";
    return type;
};

